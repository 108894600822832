import { useTable } from "@/hooks/web/useTable";
import { useForm } from "@/hooks/web/useForm";
import { useCharts } from "@/hooks/web/useCharts";
import { useButtons } from "@/hooks/web/useButtons";
import { useIconTooltip } from "@/hooks/web/useIconTooltip";
import { useDialog } from "@/hooks/web/useDialog";
import { isArray } from "@/utils/is";

function UseRenderCom() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const that: any = this as any;
  const h = that.$createElement;
  /**
   * 获取form表单源dom(el-form)，详细操作方法可参考el-form
   * @param form 对应form的ref字段，用于获取dom
   */
  const getFormElRef = (form: string) => {
    const formRef: any = (that.$refs as any)[form];
    return formRef?.getElFormRef();
  };
  /**
   * 获取form表单对象结果
   * @param form 对应form的ref字段，用于获取dom
   */
  const getFormValue = (form: string) => {
    const formRef: any = (that.$refs as any)[form];
    return formRef?.getFormModel();
  };
  /**
   * 批量获取form表单对象结果
   * @param forms 对应form的ref字段集合，用于批量获取dom
   */
  const getFormsValue = (forms: string[]) => {
    let obj: any = {};
    forms.forEach((ele: string) => {
      const formList: any = getFormValue(ele);
      obj = { ...obj, ...formList };
    });
    return obj;
  };

  /**
   * 批量修改表单的原有属性
   * @param form 对应form的ref字段，用于获取dom
   * @param data 根据path修改对应的field的原有属性为value的集合
   */
  const setFormSchema = (form: string, data: FormSetPropsType[]) => {
    const formRef: any = (that.$refs as any)[form];
    formRef?.setSchema(data);
  };

  /**
   * 更新表单的所有值
   * @param form 对应form的ref字段，用于获取dom
   * @param list 更新后的值，为对象属性
   */
  const setFormValues = (form: string, list: any) => {
    const formRef: any = (that.$refs as any)[form];
    formRef ? formRef?.setValues(list) : "";
  };
  /**
   * 批量更新表单的所有值
   * @param forms 对应form的ref字段集合，用于批量获取dom
   * @param list 更新后的值，为对象属性
   */
  const setFormsValues = (forms: string[], list: any) => {
    forms.forEach((ele: string) => {
      setFormValues(ele, list);
    });
  };
  /**
   * 清空对应form的所有
   * @param form 对应form的ref字段，用于获取dom
   */
  const clearFormList = (form: string) => {
    const formRef: any = (that.$refs as any)[form];
    const elRef: any = formRef?.getElFormRef();
    elRef?.resetFields();
  };
  /**
   * 校验表单是否填写所有的必填，然后再回调
   * @param form 对应form的ref字段，用于获取dom
   * @param callback 回调方法，携带对应的表单数据
   */
  const checkFormRequire = (form: string, callback: Function) => {
    const elRef: any = getFormElRef(form);
    elRef?.validate((valid: any) => {
      if (valid) {
        const formList: any = getFormValue(form);
        callback && callback(formList);
      }
    });
  };

  /**
   * 校验表单是否填写所有的必填，然后再回调
   * @param forms 对应form的ref字段集合，如：["filterRef", "filterRef2"]
   * @param callback 回调方法，携带forms对应的的所有表单数据
   */
  const batchCheckFormRequire = (forms: string[], callback: Function) => {
    let num = 0;
    let formList: any = {};
    for (const form of forms) {
      checkFormRequire(form, (value: any) => {
        num++;
        formList = { ...formList, ...value };
      });
    }
    if (num === forms.length) {
      callback && callback(formList);
    }
  };
  /**
   * 动态处理表格高度
   * @param tableRefList
   */
  const dealContentTableHeight = (tableRefList?: CountTableHeightProps) => {
    countTableHeightBefore(tableRefList);
    window.onresize = () => {
      countTableHeightBefore(tableRefList);
    };
  };
  /**
   * 计算表格高度前，先判断是否有多个表格
   * @param tableRefList
   */
  const countTableHeightBefore = (tableRefList?: CountTableHeightProps) => {
    if (
      tableRefList &&
      tableRefList.contentRef &&
      isArray(tableRefList.contentRef)
    ) {
      tableRefList.contentRef.forEach((contentRef: any, index: number) => {
        const tableRefs: any = tableRefList.tableRef;
        const tableHeights: any = tableRefList.tableHeight;
        countTableHeight({
          contentRef: contentRef,
          tableRef: tableRefs[index],
          tableHeight: tableHeights[index]
        });
      });
    } else {
      countTableHeight(
        tableRefList
          ? tableRefList
          : {
              contentRef: "contentRef",
              tableRef: "tableRef",
              tableHeight: "tableHeight"
            }
      );
    }
  };

  /**
   * 实时计算表格高度
   * @param tableRefList
   */
  const countTableHeight = (tableRefList?: any) => {
    const contentRefValue: any = tableRefList.contentRef;
    const contentRef: any = that.$refs[contentRefValue];
    if (contentRef) {
      const tableRefValue: any = tableRefList.tableRef;
      const parentRef: any = `${tableRefList.tableRef}Parent`;
      const parent = that.$refs[parentRef];
      const childNodes = contentRef.childNodes;
      let diffHeight: any = 0;
      for (const item of childNodes) {
        if (item.className !== parent.className) {
          const computedStyle = getComputedStyle(item, null);
          const domRealHeight =
            Number(computedStyle.height.replace("px", "")) +
            Number(computedStyle.marginBottom.replace("px", "")) +
            Number(computedStyle.marginTop.replace("px", ""));
          diffHeight += domRealHeight;
        }
      }
      that.dataStore[tableRefList.tableHeight] = `calc(100% - ${diffHeight}px)`;
      if (isArray(tableRefValue)) {
        tableRefValue.forEach((zle) => {
          reloadTableHeader(that.$refs[zle]);
        });
      } else {
        reloadTableHeader(that.$refs[tableRefValue]);
      }
    }
  };
  const reloadTableHeader = (dom: any) => {
    if (dom) {
      dom.reloadTableHeader();
    }
  };
  /**
   * 表格hooks渲染方法
   * @param options 渲染时传入的属性，具体属性可查看TableProps
   */
  const renderTable = (options: TableProps) => useTable.call(that, options);
  /**
   * 表单hooks渲染方法
   * @param options 渲染时传入的属性，具体属性可查看FormProps
   * @param callback 回调方法，即渲染后表单时触发的方法，非必填
   */
  const renderForm = (options: FormProps, callback?: Function) =>
    useForm.call(that, options, callback);

  /**
   * 按钮组hooks渲染方法
   * @param options 渲染时传入的属性，具体属性可查看FormProps
   */
  const renderButtons = (options: ButtonsProps) =>
    useButtons.call(that, options);

  /**
   * 图表hooks渲染方法
   * @param options 渲染时传入的属性，具体属性可查看FormProps
   */
  const renderChart = (options: ChartType) => useCharts.call(that, options);

  const renderIconToolTip = (options: any) =>
    useIconTooltip.call(that, options);

  /**
   * 弹窗hooks渲染方法
   * @param options 渲染时传入的属性，具体属性可查看TableProps
   */
  const renderDialog = (options: DialogProps) => {
    useDialog.call(that, options);
  };

  return {
    getFormElRef,
    getFormValue,
    getFormsValue,
    setFormValues,
    setFormsValues,
    setFormSchema,
    clearFormList,
    renderTable,
    renderForm,
    renderButtons,
    renderChart,
    renderIconToolTip,
    renderDialog,
    checkFormRequire,
    batchCheckFormRequire,
    countTableHeight,
    dealContentTableHeight
  };
}
export default UseRenderCom;
