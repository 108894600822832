export const containers = [
  {
    type: "grid",
    category: "container",
    icon: "grid",
    cols: [],
    options: {
      name: "",
      hidden: false,
      gutter: 12,
      colHeight: null, //栅格列统一高度属性，用于解决栅格列设置响应式布局浮动后被挂住的问题！！
      customClass: "" //自定义css类名
    }
  },

  {
    type: "table",
    category: "container",
    icon: "table",
    rows: [],
    options: {
      name: "",
      hidden: false,
      customClass: "" //自定义css类名
    }
  },

  {
    type: "tab",
    category: "container",
    icon: "tab",
    displayType: "border-card",
    tabs: [],
    options: {
      name: "",
      hidden: false,
      customClass: "" //自定义css类名
    }
  },

  {
    type: "grid-col",
    category: "container",
    icon: "grid-col",
    internal: true,
    widgetList: [],
    options: {
      name: "",
      hidden: false,
      span: 12,
      offset: 0,
      push: 0,
      pull: 0,
      responsive: false, //是否开启响应式布局
      md: 12,
      sm: 12,
      xs: 12,
      customClass: "" //自定义css类名
    }
  },

  {
    type: "table-cell",
    category: "container",
    icon: "table-cell",
    internal: true,
    widgetList: [],
    merged: false,
    options: {
      name: "",
      cellWidth: "",
      cellHeight: "",
      colspan: 1,
      rowspan: 1,
      customClass: "" //自定义css类名
    }
  },

  {
    type: "tab-pane",
    category: "container",
    icon: "tab-pane",
    internal: true,
    widgetList: [],
    options: {
      name: "",
      label: "",
      hidden: false,
      active: false,
      disabled: false,
      customClass: "" //自定义css类名
    }
  }
];

export const basicFields = [
  {
    field: "text",
    label: "text",
    component: "Input",
    componentProps: {},
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  },
  {
    field: "textarea",
    label: "textarea",
    component: "Input",
    componentProps: {
      type: "textarea"
    },
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  },
  {
    field: "select",
    label: "select",
    component: "Select",
    componentProps: {},
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  },
  {
    field: "radio",
    label: "radio",
    component: "Radio",
    componentProps: {
      options: [
        {
          label: "单选一",
          value: "1"
        },
        {
          label: "单选二",
          value: "2"
        }
      ]
    },
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  },
  {
    field: "checkbox",
    label: "",
    component: "Checkbox",
    componentProps: {
      label: "我是判断"
    },
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {
      labelWidth: "0px"
    }
  },
  {
    field: "checkbox-group",
    label: "checkbox-group",
    component: "CheckboxGroup",
    value: [],
    componentProps: {
      options: [
        {
          label: "测试一",
          value: "1"
        },
        {
          label: "测试二",
          value: "2"
        },
        {
          label: "测试三",
          value: "3"
        }
      ]
    },
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  },
  {
    field: "cascader",
    label: "cascader",
    component: "Cascader",
    componentProps: {
      options: [
        {
          label: "测试一",
          value: "1",
          children: [
            {
              label: "测试二",
              value: "5"
            }
          ]
        },
        {
          label: "测试二",
          value: "2"
        },
        {
          label: "测试三",
          value: "3"
        }
      ]
    },
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  },
  {
    field: "switch",
    label: "switch",
    component: "Switch",
    value: false,
    componentProps: {},
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  },
  {
    field: "slider",
    label: "slider",
    component: "Slider",
    value: 20,
    componentProps: {},
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  },
  {
    field: "time-picker",
    label: "time-picker",
    component: "TimePicker",
    componentProps: {},
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  },
  {
    field: "date-picker",
    label: "date-picker",
    component: "DatePicker",
    componentProps: {},
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  },
  {
    field: "rate",
    label: "rate",
    value: 0,
    component: "Rate",
    componentProps: {},
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  },
  {
    field: "color",
    label: "color",
    value: "#409EFF",
    component: "ColorPicker",
    componentProps: {},
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  },
  {
    field: "transfer",
    label: "transfer",
    value: [],
    component: "Transfer",
    componentProps: {},
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  },
  {
    field: "divider",
    label: "divider",
    component: "Divider",
    componentProps: {
      contentPosition: "center"
    },
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  },
  {
    field: "input-password",
    label: "input-password",
    component: "InputPassword",
    componentProps: {},
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  }
  //
];
export const advancedFields = [
  {
    field: "button-group",
    label: "button-group",
    component: "ButtonGroup",
    componentProps: {
      buttons: [
        {
          label: "按钮一",
          prop: "button1",
          style: {
            padding: "8px 16px",
            background: "#E34D59",
            border: "1px solid #E34D59",
            color: "#FFFFFF"
          }
        },
        {
          label: "按钮二",
          prop: "button2",
          type: "primary",
          style: {
            padding: "8px 16px"
          }
        }
      ],
      callback: "onBtnCallback"
    },
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  },
  {
    field: "tabs",
    label: "标签",
    component: "Tabs",
    componentProps: {
      tabData: [
        {
          label: "标签一"
        },
        {
          label: "标签二"
        }
      ]
    },
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {
      labelWidth: "0px"
    }
  },
  {
    field: "table",
    label: "table",
    component: "Table",
    componentProps: {
      style: {
        height: "500px"
      },
      tableData: [
        {
          column1: "99993"
        }
      ],
      tableHeader: [
        {
          label: "列信息",
          field: "column1",
          component: "Text",
          align: "center",
          "show-overflow-tooltip": true,
          componentProps: {}
        }
      ],
      isPagination: true
    },
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  }
];
export const specialPrivateFields = {
  ElDivider: [
    {
      field: "direction",
      label: "设置方向",
      component: "Select",
      componentProps: {
        on: {
          change: true
        },
        options: [
          {
            label: "水平",
            value: "horizontal"
          },
          {
            label: "垂直",
            value: "vertical"
          }
        ]
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "10px"
        }
      }
    },
    {
      field: "contentPosition",
      label: "分割线文案位置",
      component: "Select",
      componentProps: {
        on: {
          change: true
        },
        options: [
          {
            label: "左侧",
            value: "left"
          },
          {
            label: "右侧",
            value: "right"
          },
          {
            label: "中间",
            value: "center"
          }
        ]
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "10px"
        }
      }
    }
  ],
  ElTransfer: [
    {
      field: "filterPlaceholder",
      label: "占位内容",
      component: "Input",
      componentProps: {
        on: {
          input: true
        }
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "10px"
        }
      }
    },
    {
      field: "format",
      label: "顶部勾选状态文案",
      component: "Input",
      componentProps: {},
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "10px"
        }
      }
    },
    {
      field: "targetOrder",
      label: "右侧排列策略",
      component: "Select",
      componentProps: {
        on: {
          change: true
        },
        options: [
          {
            label: "与数据源相同的顺序",
            value: "original"
          },
          {
            label: "新加入的元素排在最后",
            value: "push"
          },
          {
            label: "新加入的元素排在最前",
            value: "unshift"
          }
        ]
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "4px"
        }
      }
    }
  ],
  SlButtonGroup: [
    {
      field: "callback",
      label: "回调方法名",
      component: "Input",
      componentProps: {
        on: {
          input: true
        }
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "4px"
        }
      }
    },
    {
      field: "buttons",
      label: "按钮自定义",
      component: "Select",
      componentProps: {},
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "4px"
        }
      }
    }
  ],
  SlTable: [
    {
      field: "style",
      label: "style",
      component: "Input",
      componentProps: {},
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "4px"
        }
      }
    },
    {
      field: "tableHeader",
      label: "表头信息",
      component: "Switch",
      path: "componentProps",
      componentProps: {
        height: "400px"
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {}
    },
    {
      field: "isPagination",
      label: "是否分页",
      component: "Switch",
      path: "componentProps",
      componentProps: {
        on: {
          change: true
        }
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {}
    },
    {
      field: "paginationLayout",
      label: "分页属性",
      component: "Input",
      path: "componentProps",
      screen: {
        key: "isPagination",
        value: true
      },
      componentProps: {
        type: "textarea",
        on: {
          input: true
        }
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {}
    },
    {
      field: "requestApi",
      label: "表格接口",
      component: "Input",
      path: "componentProps",
      componentProps: {
        type: "textarea",
        on: {
          input: true
        }
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {}
    },
    {
      field: "isShowTool",
      label: "是否显示操作栏",
      component: "Switch",
      path: "componentProps",
      componentProps: {
        on: {
          change: true
        }
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {}
    }
  ],
  ElDatePicker: [
    {
      field: "valueFormat",
      label: "valueFormat",
      component: "Input",
      componentProps: {},
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "4px"
        }
      }
    },
    {
      field: "format",
      label: "format",
      component: "Input",
      componentProps: {
        on: {
          input: true
        }
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "4px"
        }
      }
    },
    {
      field: "type",
      label: "type",
      component: "Select",
      componentProps: {
        options: [
          { label: "year", value: "year" },
          { label: "month", value: "month" },
          { label: "date", value: "date" },
          { label: "dates", value: "dates" },
          { label: "months", value: "months" },
          { label: "years ", value: "years " },
          { label: "week", value: "week" },
          { label: "datetime", value: "datetime" },
          { label: "datetimerange", value: "datetimerange" },
          { label: "daterange", value: "daterange" },
          { label: "monthrange", value: "monthrange" }
        ],
        on: {
          change: true
        }
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "4px"
        }
      }
    },
    {
      field: "size",
      label: "size",
      component: "Select",
      componentProps: {
        options: [
          { label: "large", value: "large" },
          { label: "small", value: "small" },
          { label: "mini", value: "mini" }
        ],
        on: {
          change: true
        }
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "4px"
        }
      }
    }
  ],
  ElColorPicker: [
    {
      field: "size",
      label: "size",
      component: "Select",
      componentProps: {
        options: [
          { label: "medium", value: "medium" },
          { label: "small", value: "small" },
          { label: "mini", value: "mini" }
        ],
        on: {
          change: true
        }
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "4px"
        }
      }
    }
  ],
  ElInput: [
    {
      field: "type",
      label: "类型选择",
      component: "Select",
      componentProps: {
        on: {
          change: true
        },
        options: [
          {
            label: "文本",
            value: "text"
          },
          {
            label: "数字",
            value: "number"
          },
          {
            label: "密码",
            value: "password"
          },
          {
            label: "文本域",
            value: "textarea"
          }
        ]
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "10px"
        }
      }
    },
    {
      field: "showWordLimit",
      label: "显示字数统计",
      component: "Switch",
      screen: {
        key: "type",
        value: "textarea"
      },
      componentProps: {
        on: {
          change: true
        }
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "10px"
        }
      }
    },
    {
      field: "maxlength",
      label: "最大输入长度",
      component: "InputNumber",
      value: 140,
      componentProps: {
        type: "number",
        on: {
          input: true
        }
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "10px"
        }
      }
    }
  ],
  ElTabs: [
    {
      field: "type",
      label: "类型选择",
      component: "Select",
      value: "card",
      componentProps: {
        on: {
          change: true
        },
        options: [
          {
            label: "默认",
            value: "card"
          },
          {
            label: "卡片化",
            value: "border-card"
          }
        ]
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "10px"
        }
      }
    },
    {
      field: "tabPosition",
      label: "标签位置",
      component: "Select",
      componentProps: {
        on: {
          change: true
        },
        options: [
          {
            label: "顶部",
            value: "top"
          },
          {
            label: "右侧",
            value: "right"
          },
          {
            label: "左侧",
            value: "left"
          },
          {
            label: "底部",
            value: "bottom"
          }
        ]
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        style: {
          marginBottom: "10px"
        }
      }
    }
  ]
};

export const customFields: any[] = [];

export const cascaderFields: any = [
  {
    field: "multiple",
    label: "是否多选",
    component: "Switch",
    path: "componentProps.props",
    componentProps: {
      on: {
        change: true
      }
    },
    colProps: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24
    },
    formItemProps: {}
  }
];

export function addContainerWidgetSchema(containerSchema) {
  containers.push(containerSchema);
}

export function addBasicFieldSchema(fieldSchema) {
  basicFields.push(fieldSchema);
}

export function addAdvancedFieldSchema(fieldSchema) {
  advancedFields.push(fieldSchema);
}

export function addCustomWidgetSchema(widgetSchema: any) {
  customFields.push(widgetSchema);
}
