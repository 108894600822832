import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import { Dictionary } from "vue-router/types/router";
import { SlForm, SvgIcon } from "@/components";
import { Input, Button, Message } from "element-ui";
import { UserModule } from "@/store/modules/user";
import { isValidUsername } from "@/utils/validate";
import { useDesign } from "@/hooks/web/useDesign";
import tools from "@/utils/tools";
import { getUserInfoApi, getLoginCodeApi, loginApi } from "@/api/system/login";
import settings from "./settings";
import "./login.scss";
import { useComProps } from "@/utils/useComProps";
import { setToken } from "@/utils/cookies";

const { getPrefixCls } = useDesign();

const prefixCls = getPrefixCls("login-container");
@Component({
  name: "Login"
})
export default class extends Vue {
  $refs!: {
    loginFormRef: any;
    password: any;
  };
  private title = settings.loginTile;
  private formData: any[] = [
    {
      field: "username",
      label: "",
      component: "Input",
      componentProps: {
        placeholder: "请输入用户名",
        style: {
          background: "#F8F8F8",
          border: "none"
        }
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        required: true,
        rules: [
          {
            required: true,
            message: "用户名不能为空",
            trigger: ["blur", "change"]
          }
        ]
      }
    },
    {
      field: "password",
      label: "",
      component: "InputPassword",
      componentProps: {
        placeholder: "请输入登录密码",
        style: {
          background: "#F8F8F8",
          border: "none"
        }
        // prefixIcon: "el-icon-lock"
      },
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {
        required: true,
        rules: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur"
          },
          {
            required: true,
            validator: this.validatePassword,
            trigger: "change"
          }
        ]
      }
    },
    // {
    //   field: "code",
    //   label: "",
    //   component: "Input",
    //   componentProps: {
    //     placeholder: "请输入结果",
    //     clearable: false,
    //     style: {
    //       background: "#F8F8F8",
    //       border: "none"
    //     }
    //   },
    //   screen: {
    //     key: "captchaEnabled",
    //     value: [1]
    //   },
    //   colProps: {
    //     xs: 24,
    //     sm: 24,
    //     md: 24,
    //     lg: 24,
    //     xl: 24
    //   },
    //   formItemProps: {
    //     required: true,
    //     rules: [
    //       {
    //         required: true,
    //         message: "结果不能为空",
    //         trigger: "blur"
    //       }
    //     ]
    //   }
    // },
    {
      field: "handle",
      label: "",
      colProps: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24
      },
      formItemProps: {}
    }
  ];
  private comProps: any = useComProps.call(this, { prefixCls });

  private validateUsername(rule: any, value: string, callback: Function) {
    if (!isValidUsername(value)) {
      callback(new Error("请输入正确的用户名"));
    } else {
      callback();
    }
  }

  private validatePassword(rule: any, value: string, callback: Function) {
    if (!value) return callback(new Error("密码不能为空"));
    if (value.length < 6) {
      callback(new Error("密码不能少于6位"));
    } else {
      callback();
    }
  }

  private passwordType = "password";
  private redirect?: string;
  private otherQuery: Dictionary<string> = {};

  private dataStore: any = {
    codeImgUrl: "",
    codeMsg: {},
    formList: {
      captchaEnabled: 0,
      uuid: ""
    },
    isLoading: false
  };

  @Watch("$route", { immediate: true })
  private onRouteChange(route: Route) {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query as Dictionary<string>;
    if (query) {
      this.redirect = query.redirect;
      this.otherQuery = this.getOtherQuery(query);
    }
  }

  private showPwd() {
    if (this.passwordType === "password") {
      this.passwordType = "";
    } else {
      this.passwordType = "password";
    }
    this.$nextTick(() => {
      (this.$refs.password as Input).focus();
    });
  }

  private async handleLogin() {
    const formRef: any = this.$refs.loginFormRef;
    if (!formRef) return;
    const elFormRef: any = formRef.getElFormRef();
    if (!elFormRef) return;
    elFormRef.validate(async (valid: boolean) => {
      if (valid) {
        this.dataStore.isLoading = true;
        const formList: any = formRef.getFormModel();
        const params: any = JSON.parse(JSON.stringify(formList));
        if (this.dataStore.codeMsg.captchaEnabled) {
          params["uuid"] = this.dataStore.codeMsg.uuid;
        }
        params["password"] = tools.Md5(params.password);
        loginApi(params)
          .then((res) => {
            UserModule.setLoginMsg({
              userResult: res,
              userInfo: params
            });
            this.dataStore.isLoading = false;
            this.$router
              .push({
                path: this.redirect ? this.redirect : "/",
                query: this.redirect ? {} : this.otherQuery
              })
              .catch(() => {});
            console.log(this.$router);
          })
          .catch(() => {
            // this.getLoginCode();
          });
      }
    });
  }

  private getOtherQuery(query: Dictionary<string>) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== "redirect") {
        acc[cur] = query[cur];
      }
      return acc;
    }, {} as Dictionary<string>);
  }

  // 快速登录
  private async quickLogin(account) {
    const result: any = await UserModule.LoginByAccount(account);
    if (result) {
      this.dataStore.isLoading = false;
      this.$router
        .push({
          path: this.redirect ? this.redirect : "/"
        })
        .catch(() => {});
    }
  }

  private getLoginCode() {
    getLoginCodeApi().then((result: any) => {
      this.dataStore.codeImgUrl = `data:image/png;base64,${result.img}`;
      this.dataStore.codeMsg = result;
      this.$nextTick(() => {
        this.comProps.setFormValues(this, "loginFormRef", {
          captchaEnabled: result.captchaEnabled ? 1 : 0
        });
      });
    });
  }

  created() {
    if (this.$route.query && this.$route.query.account) {
      this.quickLogin(this.$route.query.account);
    }
  }

  mounted() {
    // this.getLoginCode();
  }

  render() {
    const scopedSlots: any = {
      handle: () => {
        return (
          <Button
            props={{
              type: "primary",
              style: "width: 100%",
              size: "large"
            }}
            size={"medium"}
            style={"width: 100%"}
            on-click={this.handleLogin}
          >
            立即登录
          </Button>
        );
      },
      "username-prefix": () => {
        return (
          <SvgIcon
            class-name={`${prefixCls}-icon`}
            icon-class={"icon-username"}
          ></SvgIcon>
        );
      },
      "password-prefix": () => {
        return (
          <SvgIcon
            class-name={`${prefixCls}-password`}
            icon-class={"icon-password"}
          ></SvgIcon>
        );
      },
      "code-prefix": () => {
        return (
          <SvgIcon
            class-name={`${prefixCls}-icon`}
            icon-class={"icon-auth-code"}
          ></SvgIcon>
        );
      },
      "code-suffix": () => {
        return (
          <img
            style={{ height: "45px", width: "auto", cursor: "pointer" }}
            src={this.dataStore.codeImgUrl}
            onClick={this.getLoginCode}
            alt=""
          />
        );
      }
    };
    const renderRight = () => {
      return (
        <div class={`${prefixCls}-right`}>
          <div class={`${prefixCls}-right-wrap`}>
            <div class={"title"}>{this.title}</div>
            <div class={"form"}>
              <div class={"header"}>账号密码登陆</div>
              <div class={"form-list"}>
                {this.comProps.renderForm({
                  ref: "loginFormRef",
                  schema: this.formData,
                  formList: this.dataStore.formList,
                  slots: scopedSlots
                })}
              </div>
            </div>
          </div>
          <div class={`${prefixCls}-right-footer`}>
            <a href="https://beian.miit.gov.cn/" target={"_blank"}>
              苏ICP备2023048651号-1
            </a>
          </div>
        </div>
      );
    };
    return this.$route.query.account ? (
      <div></div>
    ) : (
      <div class={`${prefixCls}`}>
        <div class={`${prefixCls}-left`}>
          <div class={["s-icon", "s-icon-logo-bg"]}></div>
        </div>
        {renderRight()}
      </div>
    );
  }
}
