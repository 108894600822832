import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import {
  filterEmptyField,
  formatTime,
  publicDealTableData,
  publicDelete
} from "@/utils";
import { Message, Tree } from "element-ui";
import { getOnlineListApi, deleteOnlineApi } from "@/api/monitor/online";
import { useComProps } from "@/utils/useComProps";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("monitor-online-list");
@Component({
  name: "MonitorOnlineList"
})
export default class MonitorOnlineList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  private comRoleProps: any = useComProps.call(this, { prefixCls });
  private dataStore: any = {
    isLoading: false,
    treeIsLoading: false,
    title: "角色列表",
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNum: 1
    },
    total: 0,
    tableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: []
  };

  // 遍历结构树
  private deepDealTreeData(data: any[], parentId: any) {
    return data.map((ele: any) => {
      ele.label = ele.title;
      if (!ele.parentId && ele.parentId !== 0) {
        ele.parentId = parentId;
      }
      if (ele.children) {
        this.deepDealTreeData(ele.children, ele.id);
      }
      return ele;
    });
  }

  private deepScreenAllParent(data: any[], id: any, result: any[]) {
    if (!id) return;
    data.forEach((item, index) => {
      if (item.id === id) {
        if (!result.includes(item.id)) {
          result.unshift(item.id);
        }
        this.deepScreenAllParent(
          this.dataStore.treeData,
          item.parentId,
          result
        );
      } else {
        if (item.children) {
          this.deepScreenAllParent(item.children, id, result);
        }
      }
    });
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      refresh: () => {
        this.comRoleProps.clearFormList(this, "filterRef");
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      deleteRow: () => {
        // publicDelete({
        //   message: `是否强退定时任务【${row.name}】？`,
        //   title: "确认信息",
        //   callback: () => {
        //     this.deleteDataList([row.id], [row.name]);
        //   }
        // });
        this.comRoleProps.openDialogPage(this, {
          width: "586px",
          title: `强退`,
          titleSlots: {
            prefix: () => {
              return (
                <i
                  class={"el-icon-warning"}
                  style={{
                    marginRight: "18px",
                    color: "#E34D59",
                    fontSize: "24px"
                  }}
                ></i>
              );
            }
          },
          contentSlots: () => {
            return <div>{` 是否强退名称为【${row.userName}】的用户？`}</div>;
          },
          onConfirm: (callback) => {
            this.deleteDataList(row.tokenId, callback);
          }
        });
      }
    };
    obj[item.prop] && obj[item.prop]();
  }

  // 强退数据
  private deleteDataList(id: any, callback) {
    const params: any = id;
    deleteOnlineApi(params).then(() => {
      Message.success(`强退用户成功`);
      callback && callback();
      this.getTableData();
    });
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNum = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }

  private getTableData() {
    this.dataStore.isLoading = true;
    const filterList: any = this.comRoleProps.getFormValue(this, "filterRef");
    const params: any = { ...filterList, ...this.dataStore.queryList };
    getOnlineListApi(filterEmptyField(params))
      .then((res: any) => {
        if (res) {
          this.dataStore.tableData = publicDealTableData(
            res.rows,
            this.dataStore.queryList,
            { current: "pageNum", size: "pageSize" }
          );
          this.dataStore.total = res.total;
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }

  mounted() {
    this.comRoleProps.dealContentTableHeight(this, ["header", "filter"]);
    this.getTableData();
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.comRoleProps.renderForm({
              ref: "filterRef",
              schema: config.monitor.online.filterSchema,
              slots: {
                handle: (data) => {
                  return this.comRoleProps.renderButtons({
                    buttons: data.componentProps.buttons
                  });
                },
                tableHandle: (data) => {
                  return (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {this.comRoleProps.renderButtons({
                        buttons: data.componentProps.buttons
                      })}
                    </div>
                  );
                }
              }
            })}
          </div>
          {this.comRoleProps.renderTable({
            tableData: this.dataStore.tableData,
            tableHeader: config.monitor.online.tableHeader,
            isShowCheck: true,
            queryList: this.dataStore.queryList,
            total: this.dataStore.total,
            isLoading: this.dataStore.isLoading,
            paginationLayout: "sizes, prev, pager, next, slot, jumper",
            style: {
              height: this.dataStore.tableHeight
            },
            slots: {
              loginTime: ({ row }) => {
                return formatTime(row.loginTime, "yyyy-MM-dd HH:mm:ss");
              }
            },
            ref: "tableRef"
          })}
        </div>
      </div>
    );
  }
}
