import {
  Cascader,
  DatePicker,
  Image,
  Input,
  Option,
  Popover,
  Select,
  Tooltip,
  Checkbox,
  CheckboxGroup
} from "element-ui";
import { SlForm, SlTable, SlButton, SvgIcon, SlCharts } from "@/components";
import { isArray, isBoolean, isFunction, isObject } from "@/utils/is";
import {
  filterTreeArray,
  firstToUpper,
  publicDealFileUrl
} from "@/utils/index";
import { getSlot } from "@/utils/tsxHelper";
import { number } from "echarts/core";
import { componentMap } from "@/components/SlForm/src/componentMap";
import Draggable from "vuedraggable";

export function useComProps(comProps: any) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const that: any = this as any;
  const { prefixCls } = comProps;
  const h = that.$createElement;
  const defaultStore: any = {
    isCheckAll: true,
    isIndeterminate: false
  };
  const getFormElRef = (vm: any, form: any) => {
    const formRef: any = (vm.$refs as any)[form];
    return formRef?.getElFormRef();
  };
  const getFormValue = (vm: any, form: any) => {
    const formRef: any = (vm.$refs as any)[form];
    return formRef?.getFormModel();
  };

  const setFormSchema = (vm: any, form: any, data: any[]) => {
    const formRef: any = (vm.$refs as any)[form];
    formRef?.setSchema(data);
  };

  const setFormValues = (vm: any, form: any, list: any) => {
    const formRef: any = (vm.$refs as any)[form];
    formRef ? formRef?.setValues(list) : "";
  };

  const clearFormList = (vm: any, form: any) => {
    const formRef: any = (vm.$refs as any)[form];
    const elRef: any = formRef?.getElFormRef();
    elRef?.resetFields();
  };
  // 遍历所有的label插槽
  const dealLabelSlots = (data: any[], slots?: any) => {
    if (!slots) return data;
    const keys: any[] = Object.keys(slots);
    keys.forEach((item: any) => {
      const index: any = data.findIndex((ele: any) => ele.field === item);
      if (index >= 0) {
        data[index].formItemProps.labelSlots = slots[item];
      }
    });
    return data;
  };
  const screenLabel = (options: any, value: any) => {
    const item: any = filterTreeArray(options, value, {
      nodeKey: "value",
      childrenKey: "children"
    });
    return item
      ? isArray(item) && item.length
        ? item[0].label
        : item.label
      : "";
  };
  const dealSelectShowValue = (item: any) => {
    if (!item.row) return "";
    const value: any = item.row[item.header.prop];
    if (!value) return;
    let result: any;
    if (isArray(value)) {
      result = value
        .map((ele: any) => {
          let nItem: any;
          if (isArray(ele)) {
            nItem = ele.map((sle: any) => {
              return screenLabel(item.header.componentProps.options, sle);
            });
          } else {
            nItem = screenLabel(item.header.componentProps.options, ele);
          }
          return nItem ? (isArray(nItem) ? nItem.join("/") : nItem) : null;
        })
        .join(",");
    } else {
      const nItem: any = screenLabel(item.header.componentProps.options, value);
      result = nItem && nItem.label ? nItem.label : nItem;
    }
    return result;
  };
  const publicBindMethod = (options: any) => {
    const { schema, list, keyValue, index, scopedSlots, callback, path, type } =
      options;
    return schema.map((item: any) => {
      if (item.componentProps) {
        if (item.componentProps.on) {
          const keys = Object.keys(item.componentProps.on);
          keys.forEach((ele: any) => {
            if (
              isBoolean(item.componentProps.on[ele]) &&
              that[`on${firstToUpper(ele)}`]
            ) {
              item.componentProps.on[ele] = (value: any) =>
                that[`on${firstToUpper(ele)}`]({
                  value,
                  item,
                  index,
                  list,
                  keyValue,
                  schema,
                  type,
                  path: item.path ? item.path : path
                });
            }
          });
        }
        if (item.componentProps.slots) {
          console.log("slots");
          const sKeys = Object.keys(item.componentProps.slots);
          sKeys.forEach((sKey: any) => {
            if (scopedSlots[`${item.field}-${sKey}`]) {
              item.componentProps.slots[sKey] =
                scopedSlots[`${item.field}-${sKey}`];
            }
          });
        }
      }
      return item;
    });
  };

  const dealTableListData = (data: any[]) => {
    data.map((ele: any, index: number) => {
      ele["index"] = index + 1;
      return ele;
    });
  };

  // 切换既往疾病史表格数据状态
  const changeTableStatus = (row: any, status: any, data: any[]) => {
    const index: any = data.findIndex((ele: any) => ele.index === row.index);
    data.splice(index, 1, { ...row, status: status });
  };

  // 动态表格方法事件
  const autoincrementTableMethod = (btnOptions: any) => {
    const {
      item,
      data,
      tableHeader,
      row,
      requestCallback,
      callback,
      createBefore,
      createFun,
      editFun
    } = btnOptions;
    const obj: any = {
      create: () => {
        if (createFun) {
          createFun();
        } else {
          if (createBefore) {
            createBefore().then(() => {
              const nItem: any = data.length ? data[data.length - 1] : null;
              data.push({
                index: nItem ? nItem.index + 1 : 1,
                status: 1
              });
            });
          } else {
            const nItem: any = data.length ? data[data.length - 1] : null;
            data.push({
              index: nItem ? nItem.index + 1 : 1,
              status: 1
            });
          }
        }
      },
      edit: () => {
        if (editFun) {
          editFun(row);
        } else {
          changeTableStatus(row, 1, data);
        }
      },
      save: () => {
        if (callback && isFunction(callback)) {
          callback({ item, row, type: "save" });
        } else {
          console.log("保存请求", requestCallback, callback);
        }
      },
      cancel: () => {
        changeTableStatus(row, 0, data);
      },
      delete: () => {
        if (callback) {
          callback({ item, row, type: "delete" });
        } else {
          const dIndex: any = data.findIndex(
            (ele: any) => ele.index === row.index
          );
          data.splice(dIndex, 1);
          dealTableListData(data);
        }
      }
    };
    obj[item.prop] && obj[item.prop]();
  };
  // 渲染所有的按钮
  const renderButtons = (options?: any) => {
    const { status, row, buttons, style, rowIndex } = options;
    if (!buttons || !buttons.length) return null;
    const buttonCallBack: Function = options.callback
      ? options.callback
      : that.onClickMethod;
    return (
      <div class={`${prefixCls}-buttons`} style={style}>
        {buttons.map((item: any, index: number) => {
          const getProps = () => {
            const obj: any = {};
            const keys: any = Object.keys(item);
            keys.forEach((ele: any) => {
              if (!["style"].includes(ele)) {
                obj[ele] = item[ele];
              }
            });
            return obj;
          };
          return (item.screen && item.screen.includes(status)) ||
            !item.screen ? (
            <SlButton
              key={index}
              {...{
                attrs: getProps()
              }}
              customStyle={item?.style}
              title={item.label}
              on-click={buttonCallBack.bind(that, {
                item,
                row,
                rowIndex
              })}
            ></SlButton>
          ) : null;
        })}
      </div>
    );
  };
  const renderExplain = (options: any) => {
    const { props, style, icon } = options;
    const defaultProps: any = {
      placement: "top",
      effect: "dark"
    };
    const nProps: any = { ...defaultProps, ...props };
    return (
      <Tooltip {...{ attrs: nProps }}>
        <SvgIcon
          style={style}
          iconClass={icon ? icon : "icon-explain"}
        ></SvgIcon>
      </Tooltip>
    );
  };
  const renderTable = (item: TableProps) => {
    const tableCallback: Function = item.callback
      ? item.callback
      : that.onTableCallback;
    const objectSpanMethod = (options: any) => {
      const { row, column, rowIndex, columnIndex, headerData, item } = options;
      if (!item.spanMethods) return;
      const spanMethods: any = item.spanMethods;
      for (let i = 0; i < spanMethods.length; i++) {
        //划分出需合并的每一个区域（spanArr[i]）
        if (
          columnIndex >= spanMethods[i].columnIndex &&
          columnIndex <=
            spanMethods[i].columnIndex + spanMethods[i].colspan - 1 &&
          rowIndex >= spanMethods[i].rowIndex &&
          rowIndex <= spanMethods[i].rowIndex + spanMethods[i].rowspan - 1
        ) {
          // 保留展示的单元格，合并单元格都为向右与向下延伸
          if (
            columnIndex === spanMethods[i].columnIndex &&
            rowIndex === spanMethods[i].rowIndex
          ) {
            return {
              rowspan: spanMethods[i].rowspan,
              colspan: spanMethods[i].colspan
            };
          } else {
            //删除冗余单元格
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      }
    };
    const headerData: any[] = item.tableHeader;
    const tableStyle: any = {};
    if (item.height) {
      tableStyle["height"] = item.height;
    }
    const scopedSlots: any = {
      default: (data: any) => {
        const propStr: any = data.header.prop || data.header.field;
        return item.slots && item.slots[propStr]
          ? getSlot(item.slots, propStr, {
              ...data,
              header: { ...data.header, prop: propStr }
            })
          : propStr === "handle" && (data.header.buttons || item.buttons)
          ? renderButtons({
              buttons: data.header.buttons ? data.header.buttons : item.buttons,
              row: data.row,
              status: data.row[item.status]
            })
          : data.row[propStr];
      }
    };
    const parentDefaultStyle = {
      height: "100%"
    };
    return (
      <div
        v-loading={item.isLoading}
        class={item.class ? item.class : `${prefixCls}-table`}
        style={{ ...parentDefaultStyle, ...item.style }}
      >
        <SlTable
          tableData={item.tableData}
          tableHeader={item.tableHeader}
          tableProps={{
            ...item.tableProps,
            "span-method": (options: any) =>
              objectSpanMethod(Object.assign(options, { headerData, item }))
          }}
          ref={item.ref}
          isPagination={item.isPagination}
          isShowCheck={item.isShowCheck}
          paginationLayout={item.paginationLayout}
          queryList={item.queryList}
          rowKey={item.rowKey}
          total={item.total}
          on-callback={tableCallback}
          on-selection-change={(data: any) =>
            tableCallback({ type: "checkbox", data })
          }
          on-row-click={(row, column) =>
            tableCallback({ type: "rowClick", data: { row, column } })
          }
          scopedSlots={scopedSlots}
          disabled={item.disabled}
          style={{ ...tableStyle }}
        />
      </div>
    );
  };
  const renderAutoincrementTable = (optionList: any) => {
    const {
      ref,
      title,
      tableData,
      tableHeader,
      style,
      buttons,
      requestCallback,
      createBefore,
      callback,
      defaultData,
      createFun,
      editFun
    } = optionList;
    const scopedSlots: any = {
      default: (item: any) => {
        const allSlots: any = {
          Select: () => {
            const renderOption = (optionData: any) => {
              return optionData?.map((optionItem: any) => {
                return (
                  <Option label={optionItem.label} value={optionItem.value} />
                );
              });
            };
            return (
              <Select
                v-model={item.row[item.header.prop]}
                {...{ props: item.header.componentProps }}
                style={item.header.componentProps?.style}
                onChange={() => {
                  callback && callback({ item, row: item.row });
                }}
              >
                {renderOption(item.header.componentProps?.options)}
              </Select>
            );
          },
          Input: () => {
            return (
              <Input
                v-model={item.row[item.header.prop]}
                {...{ props: item.header.componentProps }}
                style={item.header.componentProps?.style}
                onInput={() => {
                  callback && callback({ item, row: item.row });
                }}
              ></Input>
            );
          },
          DatePicker: () => {
            return (
              <DatePicker
                v-model={item.row[item.header.prop]}
                {...{ props: item.header.componentProps }}
                style={item.header.componentProps?.style}
                onChange={() => {
                  callback && callback({ item, row: item.row });
                }}
              ></DatePicker>
            );
          },
          Cascader: () => {
            return (
              <Cascader
                v-model={item.row[item.header.prop]}
                {...{ props: item.header.componentProps }}
                style={item.header.componentProps?.style}
                onChange={() => {
                  callback && callback({ item, row: item.row });
                }}
              ></Cascader>
            );
          },
          Checkbox: () => {
            return (
              <Checkbox
                v-model={item.row[item.header.prop]}
                {...{ props: item.header.componentProps }}
                style={item.header.componentProps?.style}
                onChange={() => {
                  callback && callback({ item, row: item.row });
                }}
              ></Checkbox>
            );
          }
        };
        const showSlots: any = {
          Image: () => {
            const imageUrl: any = publicDealFileUrl(item.row, item.header.prop);
            return (
              <Image
                style={{
                  width: "56px",
                  height: "52px"
                }}
                src={imageUrl}
                fit={"cover"}
                preview-src-list={[imageUrl]}
              ></Image>
            );
          }
        };
        const isSelect: any =
          item.header.component &&
          ["Select", "Cascader"].includes(item.header.component);
        return item.header.prop === "handle"
          ? renderButtons({
              buttons: item.header.buttons,
              row: {
                ...item.row
              },
              status: item.row.status ? item.row.status : 0,
              callback: (nItem) =>
                autoincrementTableMethod({
                  ...nItem,
                  data,
                  requestCallback,
                  createBefore,
                  callback,
                  editFun,
                  createFun
                })
            })
          : item.row &&
            Object.keys(item.row).includes("status") &&
            allSlots[item.header.component]
          ? allSlots[item.header.component](item)
          : isSelect
          ? dealSelectShowValue(item)
          : showSlots[item.header.component]
          ? showSlots[item.header.component]()
          : item.row
          ? item.row[item.header.prop]
          : "";
      }
    };
    const data: any[] =
      tableData && defaultData ? tableData.concat(defaultData) : tableData;
    return (
      <div class={`${prefixCls}-autoincrement`} style={{ width: "100%" }}>
        <div
          class={`${prefixCls}-autoincrement-header`}
          style={{ marginBottom: "20px" }}
        >
          {title ? (
            <span
              class={`${prefixCls}-autoincrement-header-title`}
              style={{ marginRight: "20px", fontWeight: "bold" }}
            >
              {title ? title : null}
            </span>
          ) : null}
          {buttons
            ? renderButtons({
                buttons: buttons,
                callback: (btnOptions) =>
                  autoincrementTableMethod({
                    ...btnOptions,
                    data,
                    tableHeader,
                    requestCallback,
                    createBefore,
                    callback,
                    createFun,
                    editFun
                  })
              })
            : null}
        </div>
        <SlTable
          ref={ref}
          tableData={data}
          tableHeader={tableHeader}
          isPagination={false}
          isShowCheck={false}
          style={{
            height: "300px",
            ...style
          }}
          scopedSlots={scopedSlots}
        ></SlTable>
      </div>
    );
  };
  const renderForm = (item: any, callback?: Function) => {
    const schema = publicBindMethod({
      schema: JSON.parse(JSON.stringify(item.schema)),
      keyValue: item.keyValue
        ? item.keyValue
        : item.formList && item.formList.id
        ? item.formList.id
        : item.ref,
      path: item.path,
      type: item.type,
      scopedSlots: item.slots
    });
    callback && callback();
    return (
      <SlForm
        key={item.ref}
        ref={item.ref}
        isCol={item.isCol}
        disabled={item.disabled}
        rowGutter={item.rowGutter}
        formType={item.formType}
        schema={schema}
        style={item?.style}
        designer={item.designer}
        labelWidth={item?.labelWidth}
        props={{
          model: item.formList
        }}
        hideRequiredAsterisk={item.hideRequiredAsterisk}
        scopedSlots={item.slots}
        onInput={(value) => item.onInputChange && item.onInputChange(value)}
      ></SlForm>
    );
  };

  const renderCharts = (options: any) => {
    return (
      <SlCharts
        ref={options.ref ? options.ref : "chartRef"}
        options={options.chartOptions}
        height={options.chartHeight}
      />
    );
  };
  const openDialogPage = (vm: any, options: any) => {
    const schema: any = options.schema
      ? dealLabelSlots(
          JSON.parse(JSON.stringify(options.schema)),
          options.labelSlots
        )
      : [];
    const defaultProps = {
      customClass: `${prefixCls}-dialog`,
      showCancelButton: true,
      closeOnClickModal: false
    };
    vm.$myMessageBox({
      ...defaultProps,
      slots: {
        title: () => {
          return options.titleSlots && options.titleSlots.title ? (
            getSlot(options.titleSlots, "title")
          ) : (
            <div
              style={{
                fontSize: "20px",
                fontWeight: 600,
                borderBottom: "1px solid #D8D8D8",
                paddingBottom: "20px",
                display: "flex",
                alignItems: "center"
              }}
            >
              {options.titleSlots && options.titleSlots.prefix ? (
                getSlot(options.titleSlots, "prefix")
              ) : (
                <span
                  style={{
                    display: "flex",
                    width: "4px",
                    height: "21px",
                    background: "#268BFC",
                    borderRadius: "3px",
                    marginRight: "16px"
                  }}
                ></span>
              )}
              <div>{options.title}</div>
              {options.titleSlots && options.titleSlots.suffix
                ? getSlot(options.titleSlots, "suffix")
                : null}
            </div>
          );
        },
        content: () => {
          return options.contentSlots &&
            !isObject(options.contentSlots) &&
            options.contentSlots
            ? getSlot(options, "contentSlots")
            : renderForm({
                schema,
                ref: options.ref ? options.ref : "dialogRef",
                formList: options.formList,
                slots: options?.formSlots,
                style: options?.formStyle
              });
        }
      },
      onCloseDialog: (callback: Function) => {
        clearFormList(vm, options.ref ? options.ref : "dialogRef");
        callback && callback();
      },
      ...options
    });
  };
  const checkFormRequire = (elRef: any, callback: Function) => {
    elRef?.validate((valid: any) => {
      if (valid) {
        callback && callback();
      }
    });
  };
  const renderMore = (item: any, data: any[]) => {
    return (
      <Popover trigger={"hover"}>
        <div class={`${prefixCls}-more`}>
          {data.map((ele: any, index: number) => {
            return (
              <div
                class={`${prefixCls}-more-item`}
                on-click={that.onClickMore.bind(that, {
                  data: item,
                  item: ele
                })}
              >
                {ele.label}
              </div>
            );
          })}
        </div>
        <SvgIcon iconClass={"icon-more-line"} slot={"reference"}></SvgIcon>
      </Popover>
    );
  };
  const renderIconToolTip = (options: any) => {
    return (
      <Tooltip content={options.content}>
        <i class={options.icon}></i>
      </Tooltip>
    );
  };

  const dealContentTableHeight = (vm, countDoms: any[], tableRefList?: any) => {
    countTableHeight(vm, countDoms, tableRefList);
    window.onresize = () => {
      countTableHeight(vm, countDoms, tableRefList);
    };
  };
  const countTableHeight = (vm, countDoms: any[], tableRefList?: any) => {
    const contentRefValue: any =
      tableRefList && tableRefList.contentRef
        ? tableRefList.contentRef
        : "contentRef";
    setTimeout(() => {
      const contentRef: any = vm.$refs[contentRefValue];
      if (contentRef) {
        const tableRefValue: any =
          tableRefList && tableRefList.tableRef
            ? tableRefList.tableRef
            : "tableRef";
        const className: any = contentRef.className.split(" ")[0];
        const childNodes = contentRef.childNodes;
        let diffHeight: any = 0;
        for (const item of childNodes) {
          const isNeed = countDoms.filter((ele: any) =>
            item.className.includes(`${className}-${ele}`)
          );
          if (isNeed && isNeed.length) {
            const computedStyle = getComputedStyle(item, null);
            const domRealHeight =
              Number(computedStyle.height.replace("px", "")) +
              Number(computedStyle.marginBottom.replace("px", "")) +
              Number(computedStyle.marginTop.replace("px", ""));
            diffHeight += domRealHeight;
          }
        }
        vm.dataStore.tableHeight = `calc(100% - ${diffHeight}px)`;
        if (isArray(tableRefValue)) {
          tableRefValue.forEach((zle) => {
            reloadTableHeader(vm.$refs[zle]);
          });
        } else {
          reloadTableHeader(vm.$refs[tableRefValue]);
        }
      }
    }, 20);
  };

  const reloadTableHeader = (dom: any) => {
    if (dom) {
      dom.reloadTableHeader();
    }
  };
  const getFormDataValue = (vm, refs) => {
    let obj: any = {};
    refs.forEach((ele: any) => {
      const formList: any = getFormValue(vm, ele);
      obj = { ...obj, ...formList };
    });
    return obj;
  };

  const handleCheckAllChange = (vm, value, data) => {
    vm.dataStore.checkData = value ? data.map((ele) => ele.label) : [];
    vm.dataStore.isIndeterminate = false;
  };

  const handleCheckedCitiesChange = (vm, value, data) => {
    setTimeout(() => {
      const checkedCount = value.length;
      defaultStore.isCheckAll = checkedCount === data.length;
      vm.dataStore.isIndeterminate =
        checkedCount > 0 && checkedCount < data.length;
      console.log(vm.dataStore.isIndeterminate);
      vm.$forceUpdate();
    });
  };
  const filterTableHeader = (vm, data: any[], cb: any) => {
    vm.dataStore.isIndeterminate = false;
    openDialogPage(vm, {
      title: "设置显示列",
      width: "720px",
      top: "200px",
      showClose: false,
      customClass: "public-dialog",
      confirmButtonText: "确定",
      cancelButtonText: "重置",
      cancelButtonStyle: {
        border: "2px solid #206AD7",
        background: "white",
        color: "#206AD7"
      },
      confirmButtonStyle: {
        border: "2px solid #206AD7",
        background: "#206AD7",
        color: "white"
      },
      contentSlots: () => {
        return (
          <div class={`public-dialog-content`}>
            <div class={`public-dialog-content-left`}>
              <Checkbox
                v-model={defaultStore.isCheckAll}
                indeterminate={vm.dataStore.isIndeterminate}
                on-change={(value) => handleCheckAllChange(vm, value, data)}
                style={{ marginBottom: "30px" }}
              >
                选择所有字段
              </Checkbox>
              <CheckboxGroup
                v-model={vm.dataStore.checkData}
                on-change={(value) =>
                  handleCheckedCitiesChange(vm, value, data)
                }
              >
                {data.map((ele) => {
                  return (
                    <Checkbox
                      style={{ marginBottom: "20px", width: "120px" }}
                      label={ele.label}
                      key={ele.id}
                    ></Checkbox>
                  );
                })}
              </CheckboxGroup>
            </div>
            <div class={`public-dialog-content-right`}>
              <div class={"public-dialog-content-right-title"}>
                <span
                  style={{
                    display: "flex",
                    width: "4px",
                    height: "21px",
                    background: "#268BFC",
                    borderRadius: "3px",
                    marginRight: "16px"
                  }}
                  class={`${prefixCls}-dialog-header-icon`}
                ></span>
                已选字段
              </div>
              <Draggable
                v-model={vm.dataStore.checkData}
                handle={".drag-handle"}
                animation={"300"}
              >
                <transition-group>
                  {vm.dataStore.checkData.map((ele) => {
                    return (
                      <div class={"public-dialog-content-right-item"} key={ele}>
                        <div class={"public-dialog-content-right-item-left"}>
                          <SvgIcon
                            icon-class={"icon-more"}
                            class-name={"drag-handle"}
                            style={{
                              color: "#E4E4E4",
                              marginRight: "13px",
                              cursor: "move"
                            }}
                          ></SvgIcon>
                          {ele}
                        </div>
                        <SvgIcon
                          icon-class={"el-icon-close"}
                          style={{ color: "#E4E4E4", cursor: "pointer" }}
                          on-click={() => {
                            const zIndex = vm.dataStore.checkData.findIndex(
                              (zle) => zle === ele
                            );
                            vm.dataStore.checkData.splice(zIndex, 1);
                          }}
                        ></SvgIcon>
                      </div>
                    );
                  })}
                </transition-group>
              </Draggable>
            </div>
          </div>
        );
      },
      onConfirm: (callback: Function) => {
        const result: any[] = [];
        vm.dataStore.checkData.forEach((ele) => {
          const zItem: any = data.find((zle) => zle.label === ele);
          result.push(zItem);
        });
        cb && cb(callback, result);
      },
      onCancel: (callback: Function) => {
        vm.dataStore.checkData = data.map((ele) => ele.label);
        defaultStore.isCheckAll = true;
        vm.dataStore.isIndeterminate = false;
      }
    });
  };
  return {
    getFormElRef,
    getFormValue,
    setFormSchema,
    setFormValues,
    clearFormList,
    renderExplain,
    renderButtons,
    renderTable,
    renderAutoincrementTable,
    renderForm,
    renderCharts,
    renderMore,
    openDialogPage,
    checkFormRequire,
    dealContentTableHeight,
    getFormDataValue,
    renderIconToolTip,
    filterTableHeader
  };
}
