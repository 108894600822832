import { Component, Vue } from "vue-property-decorator";
import "./404.scss";

@Component({
  name: "Page404"
})
export default class Page404 extends Vue {
  private message = "404找不到页面";

  render() {
    return (
      <div class="wscn-http404-container">
        <div class="wscn-http404">
          <div class="pic-404">
            <img
              class="pic-404__parent"
              src="@/assets/404-images/404.png"
              alt="404"
            />
            <img
              class="pic-404__child left"
              src="@/assets/404-images/404-cloud.png"
              alt="404"
            />
            <img
              class="pic-404__child mid"
              src="@/assets/404-images/404-cloud.png"
              alt="404"
            />
            <img
              class="pic-404__child right"
              src="@/assets/404-images/404-cloud.png"
              alt="404"
            />
          </div>
          <div class="text-404">
            <div class="text-404__oops">OOPS!</div>
            <div class="text-404__headline">{this.message}</div>
            <div class="text-404__info">
              请检查您输入的URL是否正确，或单击下面的按钮返回主页。
            </div>
            <a href="" class="text-404__return-home">
              返回首页
            </a>
          </div>
        </div>
      </div>
    );
  }
}
