import { Component, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/modules/app";
import { TagsViewModule } from "@/store/modules/tags-view";
import { Dropdown, DropdownItem, DropdownMenu } from "element-ui";
import { SvgIcon } from "@/components/SvgIcon";

@Component({
  name: "SlSizeSelect"
})
export default class SlSizeSelect extends Vue {
  private sizeOptions = [
    { label: "Default", value: "default" },
    { label: "Medium", value: "medium" },
    { label: "Small", value: "small" },
    { label: "Mini", value: "mini" }
  ];

  get size() {
    return AppModule.size;
  }

  private handleSetSize(size: string) {
    (this as any).$ELEMENT.size = size;
    AppModule.SetSize(size);
    this.refreshView();
    this.$message({
      message: "Switch Size Success",
      type: "success"
    });
  }

  private refreshView() {
    // In order to make the cached page re-rendered
    TagsViewModule.delAllCachedViews();
    const { fullPath } = this.$route;
    this.$nextTick(() => {
      this.$router
        .replace({
          path: "/redirect" + fullPath
        })
        .catch((err) => {
          console.warn(err);
        });
    });
  }
  render() {
    const renderContent = () => {
      const renderContentItem = () => {
        return this.sizeOptions.map((item: any, index: number) => {
          return (
            <DropdownItem
              key={item.value}
              disabled={this.size === item.value}
              command={item.value}
            >
              {item.label}
            </DropdownItem>
          );
        });
      };
      const scopedSlots = {
        dropdown: () => renderContentItem()
      };

      return <DropdownMenu scopedSlots={scopedSlots}></DropdownMenu>;
    };
    return (
      <Dropdown
        id="size-select"
        trigger="click"
        on-command={this.handleSetSize}
      >
        <div>
          <SvgIcon class="size-icon" name="size" />
        </div>
        {renderContent()}
      </Dropdown>
    );
  }
}
