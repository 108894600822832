export const VARIANT_FORM_VERSION = "2.2.9";

//export const MOCK_CASE_URL = 'https://www.fastmock.site/mock/2de212e0dc4b8e0885fea44ab9f2e1d0/vform/'
export const MOCK_CASE_URL =
  "https://ks3-cn-beijing.ksyuncs.com/vform-static/vcase/";

//export const ACE_BASE_PATH = 'public/lib/ace/src-min-noconflict'
export const ACE_BASE_PATH =
  "https://ks3-cn-beijing.ksyun.com/vform2021/ace-mini";

export const BEAUTIFIER_PATH =
  "https://ks3-cn-beijing.ksyun.com/vform2021/js-beautify/1.14.0/beautifier.min.js";

export const designerConfig = {
  languageMenu: true, //是否显示语言切换菜单
  externalLink: true, //是否显示GitHub、文档等外部链接
  formTemplates: true, //是否显示表单模板
  eventCollapse: true, //是否显示组件事件属性折叠面板
  widgetNameReadonly: false, //禁止修改组件名称

  clearDesignerButton: true, //是否显示清空设计器按钮
  previewFormButton: true, //是否显示预览表单按钮
  importJsonButton: true, //是否显示导入JSON按钮
  exportJsonButton: true, //是否显示导出JSON器按钮
  exportCodeButton: true, //是否显示导出代码按钮
  generateSFCButton: true, //是否显示生成SFC按钮
  toolbarMaxWidth: 420, //设计器工具按钮栏最大宽度（单位像素）
  toolbarMinWidth: 300, //设计器工具按钮栏最小宽度（单位像素）

  presetCssCode: "", //设计器预设CSS样式代码

  resetFormJson: false //是否在设计器初始化时将表单内容重置为空
};
