import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import { publicDealTableData, publicDelete } from "@/utils";
import { Message, Tree } from "element-ui";
import {
  getRoleListApi,
  getRoleMenuListApi,
  inertRoleApi,
  updateRoleApi,
  deleteRoleApi,
  updateRoleMenuApi
} from "@/api/system/role";
import { useComProps } from "@/utils/useComProps";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("monitor-druid-list");
@Component({
  name: "MonitorDruidList"
})
export default class MonitorDruidList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  private comRoleProps: any = useComProps.call(this, { prefixCls });
  private dataStore: any = {
    isLoading: false,
    treeIsLoading: false,
    title: "角色列表",
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNumber: 1
    },
    total: 0,
    tableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: []
  };

  private handleNodeClick(node: any) {}

  // 操作角色信息
  private handleRole(options: any) {
    const { callback, row, title } = options;
    const elRef: any = this.comRoleProps.getFormElRef(this, "dialogRef");
    this.comRoleProps.checkFormRequire(elRef, () => {
      const params: any = this.comRoleProps.getFormValue(this, "dialogRef");
      const api: any = row ? updateRoleApi : inertRoleApi;
      if (row && row.id) {
        params["id"] = row.id;
      }
      api(title && row ? row : params).then((res: any) => {
        Message.success(
          `${row ? "编辑" : "添加"}角色${title ? title : ""}成功`
        );
        this.getTableData();
        callback && callback();
      });
    });
  }

  // 遍历结构树
  private deepDealTreeData(data: any[], parentId: any) {
    return data.map((ele: any) => {
      ele.label = ele.title;
      if (!ele.parentId && ele.parentId !== 0) {
        ele.parentId = parentId;
      }
      if (ele.children) {
        this.deepDealTreeData(ele.children, ele.id);
      }
      return ele;
    });
  }

  private deepScreenAllParent(data: any[], id: any, result: any[]) {
    if (!id) return;
    data.forEach((item, index) => {
      if (item.id === id) {
        if (!result.includes(item.id)) {
          result.unshift(item.id);
        }
        this.deepScreenAllParent(
          this.dataStore.treeData,
          item.parentId,
          result
        );
      } else {
        if (item.children) {
          this.deepScreenAllParent(item.children, id, result);
        }
      }
    });
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNumber = 1;
        this.getTableData();
      },
      refresh: () => {
        this.comRoleProps.clearFormList(this, "filterRef");
        this.dataStore.queryList.pageNumber = 1;
        this.getTableData();
      },
      createRow: () => {
        this.comRoleProps.openDialogPage(this, {
          width: "650px",
          title: "新增角色",
          confirmButtonText: "立即添加",
          schema: config.role.dialogSchema,
          onConfirm: (callback: any) => this.handleRole({ callback })
        });
      },
      deleteBatch: () => {
        if (this.dataStore.selectData.length) {
          const names: any[] = this.dataStore.selectData.map(
            (ele: any) => ele.name
          );
          publicDelete({
            message: `是否删除角色【${names.join(",")}】？`,
            title: "确认信息",
            callback: () => {
              const ids: any = [];
              const names: any[] = [];
              this.dataStore.selectData.forEach((ele: any) => {
                ids.push(ele.id);
                names.push(ele.name);
              });
              this.deleteDataList(ids, names);
            }
          });
        } else {
          Message.warning("请先选择要删除的数据");
        }
      },
      editRow: () => {
        this.comRoleProps.openDialogPage(this, {
          width: "650px",
          title: "编辑角色",
          schema: config.role.dialogSchema,
          formList: row,
          onConfirm: (callback: any) => this.handleRole({ callback, row })
        });
      },
      deleteRow: () => {
        publicDelete({
          message: `是否删除角色【${row.name}】？`,
          title: "确认信息",
          callback: () => {
            this.deleteDataList([row.id], [row.name]);
          }
        });
      },
      authAssign: () => {
        this.comRoleProps.openDialogPage(this, {
          width: "502px",
          title: "权限分配",
          schema: config.role.authDialogSchema,
          contentSlots: {
            treeModel: () => {
              return (
                <Tree
                  v-loading={this.dataStore.treeIsLoading}
                  class={`${prefixCls}-dialog-tree`}
                  props={this.dataStore.treeProps}
                  data={this.dataStore.treeData}
                  node-key={"id"}
                  check-strictly
                  show-checkbox={true}
                  default-expand-all={true}
                  expand-on-click-node={false}
                  on-node-click={this.handleNodeClick}
                  ref={"treeRef"}
                ></Tree>
              );
            }
          },
          onConfirm: (callback: Function) => {
            const menuIds: any[] = this.$refs.treeRef.getCheckedKeys();
            const allMenuIds: any[] = [];
            menuIds.forEach((ele: any) => {
              this.deepScreenAllParent(
                this.dataStore.treeData,
                ele,
                allMenuIds
              );
            });
            const params: any = { menuIds: menuIds.join(","), roleId: row.id };
            updateRoleMenuApi(params).then((res: any) => {
              Message.success(`编辑角色权限成功`);
              callback && callback();
            });
          }
        });
      }
    };
    obj[item.prop] && obj[item.prop]();
  }

  // 删除数据
  private deleteDataList(ids: any[], names: any[]) {
    const api: any = deleteRoleApi;
    const params: any = ids.length > 1 ? { ids: ids.join(",") } : ids.join(",");
    api(params).then(() => {
      Message.success(`删除角色【${names.join(",")}】成功`);
      this.getTableData();
    });
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNumber = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNumber = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }

  private getTableData() {
    this.dataStore.isLoading = true;
    const filterList: any = this.comRoleProps.getFormValue(this, "filterRef");
    const params: any = { ...filterList, ...this.dataStore.queryList };
    getRoleListApi(params)
      .then((res: any) => {
        if (res) {
          this.dataStore.tableData = publicDealTableData(
            res.records,
            this.dataStore.queryList,
            { current: "pageNumber", size: "pageSize" }
          );
          this.dataStore.total = res.total;
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }

  mounted() {
    this.comRoleProps.dealContentTableHeight(this, ["header", "filter"]);
    this.getTableData();
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        <div class={`${prefixCls}-header`}>{this.dataStore.title}</div>
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.comRoleProps.renderForm({
              ref: "filterRef",
              schema: config.role.filterSchema,
              slots: {
                handle: () => {
                  return this.comRoleProps.renderButtons({
                    buttons: config.role.filterHandleData
                  });
                },
                tableHandle: () => {
                  return (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {this.comRoleProps.renderButtons({
                        buttons: config.role.tableHeaderHandleData
                      })}
                    </div>
                  );
                }
              }
            })}
          </div>
          {this.comRoleProps.renderTable({
            tableData: this.dataStore.tableData,
            tableHeader: config.role.tableHeader,
            isShowCheck: true,
            queryList: this.dataStore.queryList,
            total: this.dataStore.total,
            isLoading: this.dataStore.isLoading,
            paginationLayout: "sizes, prev, pager, next, slot, jumper",
            style: {
              height: this.dataStore.tableHeight
            },
            ref: "tableRef"
          })}
        </div>
      </div>
    );
  }
}
