import request from "@/utils/request";
import ipList from "@/utils/ipConfig";

// 上传图片
export function uploadFileApi(data: any, url?: any) {
  return request({
    url: url ? url : ipList.upload,
    method: "post",
    data
  });
}
