import request from "@/utils/request";

// 部门列表查询
export function getGenListApi(params: any) {
  return request({
    url: "/tool/gen/list",
    method: "get",
    params
  });
}

// 批量生成
export function batchGenCodeApi(params: any) {
  return request({
    url: "/tool/gen/batchGenCode",
    method: "get",
    responseType: "blob",
    params
  });
}

// 导入数据查询/tool/gen/db/list
export function importDataApi(params: any) {
  return request({
    url: "/tool/gen/db/list",
    method: "get",
    params
  });
}

// 预览/tool/gen
export function previewDataListApi(id: any) {
  return request({
    url: `/tool/gen/preview/${id}`,
    method: "get"
  });
}
// 删除/tool/gen
export function deleteGenListApi(ids: any) {
  return request({
    url: `/tool/gen/${ids}`,
    method: "delete"
  });
}

// 同步表
export function syncGenListApi(key: any) {
  return request({
    url: `/tool/gen/synchDb/${key}`,
    method: "get"
  });
}

// 保存导入的表数据
export function saveImportTableApi(key: any) {
  return request({
    url: `/tool/gen/importTable?tables=${key}`,
    method: "post"
  });
}

// 编辑模块
export function queryGenDetailApi(id: any) {
  return request({
    url: `/tool/gen/${id}`,
    method: "get"
  });
}

// 编辑保存模块
export function saveGenDetailApi(data: any) {
  return request({
    url: `/tool/gen`,
    method: "put",
    data
  });
}
