import request from "@/utils/request";
import ipList from "@/utils/ipConfig";
export function getDictListApi(params?: any) {
  return request({
    url: ipList.system.dict.list,
    method: "get",
    params
  });
}

export function getDictStatusListApi(params?: any) {
  return request({
    url: ipList.system.dict.sysNormalDisable,
    method: "get",
    params
  });
}
export function insetDictApi(query: any) {
  return request({
    url: ipList.system.dict.crudDict,
    method: "post",
    data: query
  });
}

export function updateDictApi(query: any) {
  return request({
    url: ipList.system.dict.crudDict,
    method: "put",
    data: query
  });
}

export function deleteDictApi(id: any) {
  return request({
    url: `${ipList.system.dict.crudDict}/${id}`,
    method: "DELETE"
  });
}

export function exportDictApi(query: any) {
  return request({
    url: ipList.system.dict.export,
    method: "post",
    responseType: "blob",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: query
  });
}

// 字段查询选中
export function getOptionSelectApi() {
  return request({
    url: `/system/dict/type/optionselect`,
    method: "get"
  });
}

// 获取字典分页数据
export function getDictDataApi(params: any) {
  return request({
    url: "/system/dict/data/list",
    method: "get",
    params
  });
}

// 单个-根据类型获取字典所有数据
export function getDictAllDataApi(type: any) {
  return request({
    url: `/system/dict/data/type/${type}`,
    method: "get"
  });
}

// 批量-根据类型获取字典所有数据
export function batchDictAllDataApi(types: any) {
  return request({
    url: `/system/dict/data/batchType/${types}`,
    method: "get"
  });
}

// 新增字典数据
export function insertDictDataApi(data: any) {
  return request({
    url: "/system/dict/data",
    method: "post",
    data
  });
}

// 更新字典数据
export function updateDictDataApi(data: any) {
  return request({
    url: "/system/dict/data",
    method: "put",
    data
  });
}

// 更新字典数据
export function deleteDictDataApi(ids: any) {
  return request({
    url: `/system/dict/data/${ids}`,
    method: "delete"
  });
}
