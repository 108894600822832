import request from "@/utils/request";
import ipList from "@/utils/ipConfig";

export function getCachesNamesApi(params?: any) {
  return request({
    url: ipList.monitor.cachesList.list,
    method: "get",
    params
  });
}

export function getCachesTokensApi(query?: any) {
  return request({
    url: `${ipList.monitor.cachesList.getTokens}/${query}`,
    method: "get",
    data: query
  });
}

export function getCachesValuesApi(id: any) {
  return request({
    url: `${ipList.monitor.cachesList.getValues}/${id}`,
    method: "get"
  });
}
export function clearCacheNameApi(id: any) {
  return request({
    url: `${ipList.monitor.cachesList.clearCacheName}/${id}`,
    method: "DELETE"
  });
}

export function clearCacheKeyApi(id: any) {
  return request({
    url: `${ipList.monitor.cachesList.clearCacheKey}/${id}`,
    method: "DELETE"
  });
}
export function clearCacheAllApi() {
  return request({
    url: `${ipList.monitor.cachesList.clearCacheAll}`,
    method: "DELETE"
  });
}

export function exportCachesApi(query: any) {
  return request({
    url: ipList.monitor.cachesList.export,
    method: "post",
    responseType: "blob",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: query
  });
}

export function runCachesApi(query: any) {
  return request({
    url: ipList.monitor.cachesList.run,
    method: "put",
    data: query
  });
}
