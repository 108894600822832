import { Vue, Component, Watch } from "vue-property-decorator";
import config from "@/config/config.json";
import {
  filterEmptyField,
  formatTime,
  publicDealFileUrl,
  publicDelete,
  publicDownFile
} from "@/utils";
import { Image, Message, TimeSelect } from "element-ui";
import {
  getCourseListApi,
  insertCourseApi,
  changeCourseStatusApi,
  updateCourseApi,
  copyCourseListApi,
  deleteCourseListApi,
  courseExportApi,
  getCourseUserListApi,
  getCourseListByTimeRangeApi
} from "@/api/course";
import { getAllVenueListApi } from "@/api/venue";
import { getAllCoachListApi } from "@/api/coach";
import { getDictAllDataApi, batchDictAllDataApi } from "@/api/system/dict";
import { useDesign } from "@/hooks/web/useDesign";
import { SlDateRangePicker } from "@/components";
import "./index.scss";
import UseRenderCom from "@/hooks/useRenderCom";
import { isArray, isBoolean } from "@/utils/is";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("course-list");
@Component({
  name: "CourseList"
})
export default class CourseList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  @Watch("dataStore.timeValues", { immediate: true })
  onChangeValues(val: any) {
    if (val) {
      this.dataStore.isLoading = true;
      this.getTableData();
    }
  }
  get useRenderCom() {
    return UseRenderCom.call(this);
  }
  private dataStore: any = {
    isLoading: false,
    timeValues: [],
    treeIsLoading: false,
    title: "课程列表",
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNum: 1
    },
    total: 0,
    tableData: [],
    realTableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: [],
    optionsList: {},
    timeSlots: {
      start: "",
      end: ""
    },
    dictData: [],
    optionList: {},
    isShowUserLoading: false,
    userList: [],
    selectCourseList: {}
  };

  private renderTableColumn({ row, header }) {
    const disabled = new Date(`${header.prop} 23:59:59`).getTime() < Date.now();
    return row[header.field] ? (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div>
          <div
            style={{
              cursor: "pointer",
              "font-size": "18px",
              "font-weight": "bold"
            }}
            on-click={this.onClickMethod.bind(this, {
              item: {
                prop: "editRow"
              },
              row: row[header.field]
            })}
          >
            {row[header.field].name}
          </div>
          <div>{row[header.field].coachName}</div>
          <div>
            <span>
              {row[header.field].enrollments
                ? row[header.field].enrollments
                : 0}
            </span>
            <span
              style={{
                margin: "0 5px"
              }}
            >
              /
            </span>
            {row[header.field].orderIds ? (
              <span
                style={{
                  color: "#155BD4",
                  cursor: "pointer"
                }}
                on-click={this.onClickPerson.bind(this, row[header.field])}
              >
                {row[header.field].orderIds.split(",").length}
              </span>
            ) : (
              0
            )}
          </div>
        </div>
        <i
          class={"iconfont icon-copy"}
          style={{
            cursor: "pointer",
            fontSize: "17px",
            color: "#D1D1D1",
            marginLeft: "15px"
          }}
          on-click={this.onClickMethod.bind(this, {
            item: {
              prop: "copyRow"
            },
            row: row[header.field]
          })}
        ></i>
        <i
          class={"iconfont icon-delete"}
          style={{
            cursor: "pointer",
            fontSize: "17px",
            color: "#D1D1D1",
            marginLeft: "5px"
          }}
          on-click={this.onClickMethod.bind(this, {
            item: {
              prop: "deleteRow"
            },
            row: row[header.field]
          })}
        ></i>
      </div>
    ) : (
      <div style={{ color: "rgba(0,0,0,.2)" }}>暂无排课</div>
    );
  }

  private dealSlotList(data: any) {
    const result: any = {};
    data.forEach((ele: any) => {
      result[ele.date] = (options: any) => this.renderTableColumn(options);
    });
    return result;
  }

  private dealTableHeader(data: any) {
    const tableHeader = [
      {
        label: "上课时间",
        field: "timeSlots",
        width: 150,
        fixed: "left"
      }
    ];
    data.forEach((ele: any) => {
      const header: any = {
        ncWeek: ele.ncWeek.replace("星期", "周"),
        label: `${ele.date}（${ele.ncWeek.replace("星期", "周")}）`,
        field: ele.date,
        "min-width": "200px"
      };
      tableHeader.push(header);
    });
    this.dataStore.tableHeader = tableHeader;
  }

  private onChange(item: any) {
    if (item.dates) {
      this.dealTableHeader(item.dates);
      this.dataStore.slotList = this.dealSlotList(item.dates);
      this.dataStore.dateData = item.dates;
    }
  }

  // 操作课程信息
  private handleRole(options: any) {
    const { callback, row, title } = options;
    this.useRenderCom.checkFormRequire("dialogRef", (formList: any) => {
      const params: any = { ...formList };
      delete params.permission;
      const api: any = row && row.id ? updateCourseApi : insertCourseApi;
      if (row && row.id) {
        params["id"] = row.id;
      }
      if (isArray(formList.times)) {
        params.times = formList.times.join(",");
      }
      api(params).then((res: any) => {
        Message.success(
          `${row ? "编辑" : "添加"}课程${title ? title : ""}成功`
        );
        this.getTableData();
        callback && callback();
      });
    });
  }

  private async handleDialog(type: any, row?: any) {
    this.useRenderCom.renderDialog({
      width: "1400px",
      top: "30px",
      title: `${type === "insert" ? "新增" : "编辑"}课程`,
      schema: config.course.dialogSchema,
      customClass: `${prefixCls}-dialog`,
      formList: row
        ? {
            ...row,
            status: row.status ? "0" : "1",
            times: row.times.split(",")
          }
        : {},
      onConfirm: (callback: any) => this.handleRole({ callback, row })
    });
    this.$nextTick(() => {
      this.useRenderCom.setFormSchema("dialogRef", [
        {
          field: "venueId",
          path: "componentProps.options",
          value: this.dataStore.optionsList["venueId"]
        },
        {
          field: "coachId",
          path: "componentProps.options",
          value: this.dataStore.optionsList["coachId"]
        },
        {
          field: "type",
          path: "componentProps.options",
          value: this.dataStore.optionsList["course_type"]
        },
        {
          field: "times",
          path: "componentProps.options",
          value: this.dataStore.optionsList["course_times"]
        }
      ]);
    });
  }

  private handleExport() {
    const filterList: any = this.useRenderCom.getFormValue("filterRef");
    const params: any = {
      ...filterList,
      ...this.dataStore.queryList
    };
    courseExportApi(filterEmptyField(params)).then((res: any) => {
      publicDownFile(res, "课程列表");
      Message.success("导出数据成功");
    });
  }

  // 签到和取消统一处理
  private signHandle(userList: any) {
    const signIds = this.dataStore.selectCourseList.signIds
      ? this.dataStore.selectCourseList.signIds.split(",")
      : [];
    const index: any = signIds.findIndex(
      (ele: any) => Number(ele) === userList.userId
    );
    const isSign = index < 0;
    if (!isSign) {
      signIds.splice(index, 1);
    } else {
      signIds.push(String(userList.userId));
    }
    const params: any = {
      id: this.dataStore.selectCourseList.id,
      signIds: signIds.join(",")
    };
    updateCourseApi(params).then((res: any) => {
      Message.success(
        `客户【${userList.nickName}】${isSign ? "" : "取消"}签到成功`
      );
      this.getTableData(() => {
        const item: any = this.dataStore.realTableData.find(
          (sle: any) => sle.id === this.dataStore.selectCourseList.id
        );
        this.dataStore.selectCourseList = { ...item };
        this.getCourseUserList(item.orderIds, item.signIds);
      });
    });
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      refresh: () => {
        this.useRenderCom.clearFormList("filterRef");
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      createRow: () => this.handleDialog("insert"),
      copyRow: () => {
        const nRow: any = { ...row };
        nRow.id && delete nRow.id;
        this.handleDialog("insert", nRow);
      },
      batchCopy: () => {
        publicDelete({
          message: `确定将【${this.dataStore.timeValues.join(
            "~"
          )}】的课程复制到下一周吗？`,
          title: "确认信息",
          callback: () => {
            const nData: any = this.dataStore.realTableData.map((ele: any) => {
              const nRow: any = { ...ele };
              const startTime =
                new Date(`${ele.startDate} 00:00:00`).getTime() +
                7 * 24 * 3600 * 1000;
              nRow["startDate"] = formatTime(startTime, "yyyy-MM-dd");
              nRow.orderIds && delete nRow.orderIds;
              nRow.waitIds && delete nRow.waitIds;
              nRow.currentNum && delete nRow.currentNum;
              nRow.queueAppointmentNum && delete nRow.queueAppointmentNum;
              nRow.waitOrderIds && delete nRow.waitOrderIds;
              nRow.signIds && delete nRow.signIds;
              nRow.id && delete nRow.id;
              if (isBoolean(nRow["status"])) {
                nRow["status"] = nRow["status"] ? "0" : "1";
              }
              if (["true", "false"].includes(nRow["status"])) {
                nRow["status"] = nRow["status"] === "true" ? "0" : "1";
              }
              return nRow;
            });
            copyCourseListApi({ courseList: nData }).then((res: any) => {
              Message.success(
                `将【${this.dataStore.timeValues.join(
                  "~"
                )}】的课程复制到下一周成功`
              );
            });
          }
        });
      },
      deleteBatch: () => {
        if (this.dataStore.selectData.length) {
          const names: any[] = this.dataStore.selectData.map(
            (ele: any) => ele.name
          );
          publicDelete({
            message: `是否删除课程【${names.join(",")}】？`,
            title: "确认信息",
            callback: () => {
              const ids: any = [];
              const names: any[] = [];
              this.dataStore.selectData.forEach((ele: any) => {
                ids.push(ele.id);
                names.push(ele.name);
              });
              this.deleteDataList(ids, names);
            }
          });
        } else {
          Message.warning("请先选择要删除的数据");
        }
      },
      editRow: () => this.handleDialog("update", row),
      deleteRow: () => {
        publicDelete({
          message: `是否删除课程【${row.name}】？`,
          title: "确认信息",
          callback: () => {
            this.deleteDataList([row.id], [row.name]);
          }
        });
      },
      exportRole: () => this.handleExport(),
      sign: () => this.signHandle(row),
      cancelSign: () => this.signHandle(row)
    };
    obj[item.prop] && obj[item.prop]();
  }

  // 删除数据
  private deleteDataList(ids: any[], names: any[]) {
    const api: any = deleteCourseListApi;
    const params: any = ids.join(",");
    api(params).then(() => {
      Message.success(`删除课程【${names.join(",")}】成功`);
      this.getTableData();
    });
  }

  private onChangeStatus(value: any, item: any) {
    this.useRenderCom.renderDialog({
      width: "586px",
      title: `课程${value ? "上架" : "下架"}`,
      titleSlots: {
        prefix: () => {
          return (
            <i
              class={"el-icon-warning"}
              style={{
                marginRight: "18px",
                color: "#F88B3D",
                fontSize: "24px"
              }}
            ></i>
          );
        }
      },
      contentSlots: () => {
        return (
          <div style={{ marginTop: "40px", marginBottom: "40px" }}>
            您确定{value ? "上架" : "下架"}【{item.name}
            】该课程吗？
          </div>
        );
      },
      onConfirm: (callback: Function) => {
        changeCourseStatusApi({
          status: value ? "0" : "1",
          id: item.id
        }).then((res) => {
          Message.success(`${value ? "上架" : "下架"}课程【${item.name}】成功`);
          this.getTableData();
          callback && callback();
        });
      },
      onCloseDialog: (callback: Function) => {
        this.getTableData();
        callback && callback();
      }
    });
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNum = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
      },
      status: () => {
        this.onChangeStatus(item.data.value, item.data.row);
      },
      handle: () => {
        this.onClickMethod({ item: item.data.item, row: item.data.row });
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }

  // 处理表单下拉数据
  private dealFormOptions(field: any, data: any[]) {
    const nData: any[] = data.map((ele: any) => {
      return {
        label: ele.name,
        value: ele.id
      };
    });
    this.dataStore.optionsList[field] = nData;
  }

  private getDictAllData() {
    batchDictAllDataApi("course_times,course_type").then((res: any) => {
      if (res) {
        const keys: any[] = Object.keys(res);
        keys.forEach((ele: any) => {
          this.dataStore.optionsList[ele] = res[ele].map((ele: any) => {
            return {
              ...ele,
              label: ele.dictLabel,
              value: ele.dictValue
            };
          });
        });
      }
    });
  }

  private dealUserList(data: any, signIds) {
    if (!data || !data.length) return [];
    return data.map((ele: any) => {
      const index = signIds
        ? signIds.split(",").findIndex((zle: any) => Number(zle) === ele.userId)
        : -1;
      return {
        ...ele,
        isSign: index >= 0
      };
    });
  }

  private getCourseUserList(ids, signIds) {
    this.dataStore.isShowUserLoading = true;
    const params: any = {
      ids: ids,
      courseId: this.dataStore.selectCourseList.id
    };
    getCourseUserListApi(params)
      .then((res: any) => {
        this.dataStore.userList = this.dealUserList(res, signIds);
      })
      .finally(() => {
        this.dataStore.isShowUserLoading = false;
      });
  }

  private onClickPerson(item: any) {
    this.dataStore.selectCourseList = { ...item };
    this.getCourseUserList(item.orderIds, item.signIds);
    this.useRenderCom.renderDialog({
      title: "预约客户",
      width: "800px",
      cancelButtonText: "关闭",
      showConfirmButton: false,
      contentSlots: () => {
        return this.useRenderCom.renderTable({
          tableData: this.dataStore.userList,
          tableHeader: config.course.userTableHeader,
          isLoading: this.dataStore.isShowUserLoading,
          isPagination: false,
          style: {
            height: "500px"
          },
          slots: {
            isSign: ({ row }) => {
              return row.isSign ? "已签到" : "未签到";
            },
            isAdmin: ({ row }) => {
              return row.isAdmin === "1" ? "是" : "否";
            },
            avatar: ({ row, header }) => {
              if (!row.avatar)
                return (
                  <Image
                    style={{ width: "80px", height: "80px" }}
                    fit={"cover"}
                    src={require("@/assets/images/coach_default.png")}
                  ></Image>
                );
              const images = row.avatar
                ? row.avatar
                    .split(",")
                    .map((ele: any) =>
                      publicDealFileUrl({ image: ele }, "image")
                    )
                : [];
              return images.map((image: any) => {
                return (
                  <Image
                    style={{ width: "80px", height: "80px" }}
                    src={image}
                    fit={"cover"}
                    preview-src-list={images}
                  ></Image>
                );
              });
            }
          }
        });
      },
      onCloseDialog: (cb: Function) => {
        this.dataStore.selectCourseList = {};
        cb && cb();
      }
    });
  }

  // 处理表格数据
  private dealTableData(data: any[]) {
    const nData: any[] = [];
    data.forEach((ele) => {
      const index: any = nData.findIndex(
        (zle: any) =>
          zle.timeSlots === ele.timeSlot.replace(",", "-") &&
          zle.sort === ele.sort
      );
      if (index >= 0) {
        if (
          nData[index][ele.startDate] &&
          nData[index][ele.startDate].name !== ele.name
        ) {
          const obj: any = {
            timeSlots: ele.timeSlot.replace(",", "-"),
            sort: ele.sort
          };
          obj[ele.startDate] = ele;
          nData.push(obj);
        } else {
          nData[index][ele.startDate] = ele;
        }
      } else {
        const obj: any = {
          timeSlots: ele.timeSlot.replace(",", "-"),
          sort: ele.sort
        };
        obj[ele.startDate] = ele;
        nData.push(obj);
      }
      ele["status"] = ele.status === "0";
      // ele["startTime"] =
      //   ele.type === "3"
      //     ? ele.startDate
      //     : `${ele.startDate} ${ele.timeSlot.replace(",", "-")}`;
      // ele["timeSlots"] = ele.timeSlot.replace(",", "-");
      // return ele;
    });
    nData.sort((a, b) => {
      // 将字符串时间转换为可比较的日期对象
      const aStart = a.timeSlots.split("-")[0];
      const bStart = b.timeSlots.split("-")[0];
      const dateA: any = new Date(`1970-01-01T${aStart}Z`);
      const dateB: any = new Date(`1970-01-01T${bStart}Z`);

      // 比较两个日期对象
      return dateA - dateB;
    });
    return nData;
  }

  private getTableData(cb?: Function) {
    this.dataStore.isLoading = true;
    const params: any = {
      startTime: this.dataStore.timeValues[0],
      endTime: this.dataStore.timeValues[1]
    };
    getCourseListByTimeRangeApi(filterEmptyField(params))
      .then((res: any) => {
        this.dataStore.realTableData = res;
        this.dataStore.tableData = this.dealTableData(res);
        // if (res) {
        //   this.dataStore.tableData = this.dealTableData(res.rows);
        //   this.dataStore.total = res.total;
        // }
        // if (this.dataStore.selectCourseList.id) {
        //   this.dataStore.selectCourseList = this.dataStore.tableData.find(
        //     (ele: any) => ele.id === this.dataStore.selectCourseList.id
        //   );
        //   this.dataStore.userList = this.dealUserList(
        //     this.dataStore.userList,
        //     this.dataStore.selectCourseList.signIds
        //   );
        // }
      })
      .finally(() => {
        this.dataStore.isLoading = false;
        cb && cb();
      });
  }

  private async getAllOptionList() {
    const fields = ["venueId", "coachId"];
    const result: any = await Promise.all([
      getAllVenueListApi(),
      getAllCoachListApi()
    ]);
    const schemaData: any[] = [];
    fields.forEach((ele: any, index: any) => {
      this.dealFormOptions(ele, result[index]);
      const nIndex: number = config.course.filterSchema.findIndex(
        (zle: any) => zle.field === ele
      );
      config.course.filterSchema[nIndex].componentProps.options =
        this.dataStore.optionsList[ele];
      schemaData.push({
        field: ele,
        path: "componentProps.options",
        value: this.dataStore.optionsList[ele]
      });
    });
  }

  async mounted() {
    await this.getAllOptionList();
    this.getDictAllData();
    this.$nextTick(() => {
      this.useRenderCom.dealContentTableHeight();
    });
    // this.getTableData();
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            <div class={`${prefixCls}-content-header-title`}>
              <span class={"line"}></span>
              课程列表
            </div>
            <div class={`${prefixCls}-content-header-date`}>
              <SlDateRangePicker
                v-model={this.dataStore.timeValues}
                on-change={this.onChange}
              ></SlDateRangePicker>
              {this.useRenderCom.renderButtons({
                buttons: [
                  {
                    label: "新增课程",
                    type: "primary",
                    prop: "createRow",
                    icon: "el-icon-plus"
                  },
                  {
                    label: "批量复制",
                    type: "primary",
                    prop: "batchCopy",
                    prefixIcon: "icon-batch-copy"
                  }
                ],
                isAuth: false,
                style: {
                  marginLeft: "32px",
                  display: "flex",
                  alignItems: "center"
                }
              })}
            </div>
          </div>
          {this.useRenderCom.renderTable({
            prefixCls,
            tableData: this.dataStore.tableData,
            tableHeader: this.dataStore.tableHeader,
            isShowCheck: false,
            isPagination: false,
            isLoading: this.dataStore.isLoading,
            slots: this.dataStore.slotList,
            ref: "tableRef",
            style: {
              height: this.dataStore.tableHeight
            }
          })}
        </div>
      </div>
    );
  }
}
