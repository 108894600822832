import { useDesign } from "@/hooks/web/useDesign";
import { Component, Vue } from "vue-property-decorator";
import { getCourseDetailApi } from "@/api/course";
import { getAllVenueListApi } from "@/api/venue";
import { getAllCoachListApi } from "@/api/coach";
import { getDictAllDataApi } from "@/api/system/dict";
import "./index.scss";
import UseRenderCom from "@/hooks/useRenderCom";
import config from "@/config";
const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("course-handle");
@Component({
  name: "CourseHandle"
})
export default class CourseHandle extends Vue {
  get useRenderCom() {
    return UseRenderCom.call(this);
  }

  private dataStore = {
    detailList: {}
  };

  private getCourseDetail() {
    const id = this.$route.query.id;
    getCourseDetailApi(id).then((res: any) => {
      console.log(res, "pppppp");
      this.dataStore.detailList = { ...res, times: res.times.split(",") };
    });
  }

  mounted() {
    if (this.$route.query.id) {
      this.getCourseDetail();
    }
  }
  render() {
    return (
      <div class={prefixCls}>
        <div class={`${prefixCls}-content`}>
          {this.useRenderCom.renderForm({
            ref: "formRef",
            schema: config.course.dialogSchema,
            formList: this.dataStore.detailList
          })}
        </div>
      </div>
    );
  }
}
