import request from "@/utils/request";

export function getBannerListApi(params?: any) {
  return request({
    url: "/business/banner/list",
    method: "get",
    params
  });
}

/**
 * 新增场馆信息
 */
export function insertBannerApi(data: any) {
  return request({
    url: `/business/banner/insert`,
    method: "post",
    data
  });
}

/**
 * 修改场馆信息
 */
export function updateBannerApi(data: any) {
  return request({
    url: `/business/banner/update`,
    method: "post",
    data
  });
}

/**
 * 修改场馆状态
 */
export function changeBannerStatusApi(data: any) {
  return request({
    url: `/business/banner/changeStatus`,
    method: "put",
    data
  });
}

export function deleteBannerListApi(ids: any) {
  return request({
    url: `/business/banner/remove/${ids}`,
    method: "get"
  });
}

export function bannerExportApi(data: any) {
  return request({
    url: `/business/banner/export`,
    method: "post",
    responseType: "blob",
    data
  });
}
