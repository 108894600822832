import router from "./router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { Message } from "element-ui";
import { Route } from "vue-router";
import { UserModule } from "@/store/modules/user";
import { PermissionModule } from "@/store/modules/permission";
import settings from "./settings";
import { formatRouter } from "@/utils";

const config = require("@/config/config.json");
NProgress.configure({ showSpinner: false });

const whiteList = [
  "/login",
  "/auth-redirect",
  "/public-Detail",
  "/public-detail"
];

const getPageTitle = (key: string) => {
  const hasKey = key;
  if (hasKey) {
    const pageName = key;
    return `${pageName} - ${settings.title}`;
  }
  return `${settings.title}`;
};

router.beforeEach(async (to: Route, _: Route, next: any) => {
  NProgress.start();
  if (UserModule.token) {
    if (to.path === "/login") {
      next({ path: "/" });
      NProgress.done();
    } else {
      if (!UserModule.name) {
        await UserModule.GetUserInfo();
        await PermissionModule.GenerateRoutes(UserModule.userInfo.id);
        if (PermissionModule.dynamicRoutes.length) {
          PermissionModule.dynamicRoutes.forEach((route) => {
            router.addRoute(route);
          });
          next({ ...to, replace: true });
        } else {
          UserModule.ResetToken();
          localStorage.clear();
          Message.error("该用户没有配置权限，请先配置权限");
          next(`/login`);
          NProgress.done();
        }
      } else {
        next();
      }
    }
  } else {
    if (whiteList.includes(to.path)) {
      next();
    } else {
      next(`/login`);
      NProgress.done();
    }
  }
});

router.afterEach((to: Route) => {
  NProgress.done();
  document.title = getPageTitle(to.meta?.title);
});
