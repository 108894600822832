import request from "@/utils/request";
import ipList from "@/utils/ipConfig";

// 登录
export function loginApi(query?: any) {
  return request({
    url: ipList.system.login.auth,
    method: "post",
    data: query
  });
}

// 登录
export function loginByAccountApi(query?: any) {
  return request({
    url: ipList.system.login.loginByAccount,
    method: "post",
    data: query
  });
}

// 获取登录信息
export function getUserInfoApi() {
  return request({
    url: ipList.system.login.info,
    method: "get"
  });
}

// 登出
export function logoutApi(query?: any) {
  return request({
    url: ipList.system.login.logout,
    method: "post",
    data: query
  });
}

export function getRouters() {
  return request({
    url: "/getRouters",
    method: "get"
  });
}

export function getLoginCodeApi() {
  return request({
    url: ipList.system.code,
    method: "get"
  });
}
