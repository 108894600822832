import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { useDesign } from "@/hooks/web/useDesign";
import { SlTable } from "@/components/SlTable";
import "./index.scss";
import { SlButton } from "@/components";
import { isArray } from "@/utils/is";
import { filterTreeArray } from "@/utils";
import { useTableHandle } from "@/components/SlTableHandle/src/components/useTableHandle";
import { MessageBox } from "element-ui";

const prefixCls = useDesign().getPrefixCls("table-handle");
@Component({
  name: "SlTableHandle"
})
export default class SlTableHandle extends Vue {
  @Prop({
    type: Object
  })
  readonly formInfo: any;

  @Watch("formInfo", { immediate: true })
  watchFormInfo(val: any) {
    this.dataStore.comProps = val ? JSON.parse(JSON.stringify(val)) : {};
  }

  private tableHandleCom = useTableHandle({
    vm: this,
    prefixCls: prefixCls
  });

  private dataStore: any = {
    comProps: {},
    formList: {},
    handleData: [
      {
        label: "新增",
        type: "primary",
        icon: "el-icon-plus",
        prop: "createRow",
        style: {
          marginBottom: "12px"
        }
      }
    ],
    tableData: [],
    oldTableData: []
  };

  // 处理表格数据
  private dealTableData(item: any, tableHeader: any[]) {
    return item.tableData ? item.tableData : this.dataStore.tableData;
  }

  // 处理表格数据
  private dealFormTableData(data: any[]) {
    return data.map((ele: any, index: number) => {
      ele["index"] = index + 1;
      return ele;
    });
  }

  private onClickMethod(options: any) {
    const { item, row, header, data } = options;
    const obj: any = {
      createRow: () => {
        const params: any = {};
        row.tableHeader.forEach((ele: any) => {
          if (ele.component && ele.component !== "Handle") {
            params[ele.prop] = "";
          }
        });
        const nRow: any = [Object.assign({ status: 1 }, params)];
        row.tableData = this.dealFormTableData(
          row.tableData.concat(JSON.parse(JSON.stringify(nRow)))
        );
        console.log(row, "-----------", this.formInfo, this.dataStore.comProps);
        this.dataStore.oldTableData = JSON.parse(JSON.stringify(row.tableData));
      },
      editRow: () => {
        this.changeTableData(row, 1);
      },
      saveRow: () => {
        this.changeTableData(row, 0);
        this.dataStore.comProps.tableData =
          this.dataStore.comProps.tableData.concat(
            JSON.parse(JSON.stringify([row]))
          );
      },
      cancelRow: () => {
        const tableData: any[] = JSON.parse(
          JSON.stringify(this.dataStore.oldTableData)
        );
        const index: any = tableData.findIndex(
          (ele: any) => ele.index === row.index
        );
        for (const key in tableData[index]) {
          row[key] = tableData[index][key];
        }
        this.changeTableData(row, 0);
      },
      deleteRow: () => {
        MessageBox.confirm(`确定删除?`, "提示", {
          type: "warning"
        }).then(() => {
          if (row.id) {
            // 有id调用删除接口
            // this.deleteTableListApi(row[row.cardList.id], row.cardList);
          } else {
            const dIndex: any = data.tableData.findIndex(
              (ele: any) => ele.index === row.index
            );
            data.tableData.splice(dIndex, 1);
          }
        });
      }
    };
    obj[item.prop] && obj[item.prop]();
  }

  private changeTableData(row: any, status: any) {
    console.log(row, status);
    const index: any = this.dataStore.comProps.tableData.findIndex(
      (ele: any) => ele.index === row.index
    );
    this.dataStore.comProps.tableData[index].status = status;
  }

  private isShowForm(item: any) {
    if (!item.screen) return true;
    return isArray(item.screen.value)
      ? item.screen.value.includes(this.dataStore.formList[item.screen.key])
      : this.dataStore.formList[item.screen.key] === item.screen.value;
  }

  private objectSpanMethod(options: any) {
    const { row, column, rowIndex, columnIndex, tableHeader } = options;
    if (!tableHeader[columnIndex].merge) return;
    if (rowIndex % tableHeader[columnIndex].merge === 0) {
      return {
        rowspan: tableHeader[columnIndex].merge,
        colspan: 1
      };
    } else {
      return {
        rowspan: 0,
        colspan: 0
      };
    }
  }

  // 处理选择回显问题
  private dealSelectShowValue(item: any) {
    const value: any = item.row[item.header.prop];
    if (!value) return;
    let result: any;
    if (isArray(value)) {
      result = value
        .map((ele: any) => {
          let nItem: any;
          if (isArray(ele)) {
            nItem = ele.map((sle: any) => {
              return this.screenLabel(item.header.componentProps.options, sle);
            });
          } else {
            nItem = this.screenLabel(item.header.componentProps.options, ele);
          }
          return nItem ? (isArray(nItem) ? nItem.join("/") : nItem) : null;
        })
        .join(",");
    } else {
      const nItem: any = this.screenLabel(
        item.header.componentProps.options,
        value
      );
      result = nItem && nItem.label ? nItem.label : nItem;
    }
    return result;
  }

  // 过滤文本数据
  private screenLabel(options: any, value: any) {
    const item: any = filterTreeArray(options, value, {
      nodeKey: "value",
      childrenKey: "children"
    });
    return item
      ? isArray(item) && item.length
        ? item[0].label
        : item.label
      : "";
  }

  private onChangeInput(options: any) {
    console.log(options, "");
  }
  render() {
    const renderContentTable = (item: any) => {
      const scopedSlots: any = {
        default: (data: any) => {
          const apiSlots: any = {
            Input: this.tableHandleCom.renderInput,
            Select: this.tableHandleCom.renderSelect,
            Date: this.tableHandleCom.renderDatePicker,
            Handle: this.tableHandleCom.renderHandle,
            check: () => {
              return this.dataStore.formList[data.row[data.header.prop]];
            }
          };
          const component =
            data.row[data.row[data.header.prop]] &&
            data.row[data.row[data.header.prop]].component
              ? data.row[data.row[data.header.prop]].component
              : data.header.component;
          const isSelect: any = ["Select", "Cascader"].includes(component);
          const hasStatus = !!data.row.status || component === "Handle";
          return component && hasStatus && apiSlots[component]
            ? apiSlots[component](data, item)
            : isSelect
            ? this.dealSelectShowValue(data)
            : data.row[data.header.prop];
        }
      };
      let tableHeader: any[] = item.tableHeader;
      let tableData: any[] = this.dealTableData(item, tableHeader);
      tableData = tableData.filter((ele: any) => this.isShowForm(ele));
      tableHeader = tableHeader.filter((ele: any) => this.isShowForm(ele));
      console.log("我是渲染", item.tableData);
      return (
        <SlTable
          tableData={item.tableData}
          tableHeader={item.tableHeader}
          tableProps={{
            "span-method": (options: any) =>
              this.objectSpanMethod(Object.assign(options, { tableHeader })),
            cellStyle: {
              borderRight: "1px solid #e6ebf5"
            }
          }}
          border={true}
          isPagination={false}
          isShowCheck={false}
          scopedSlots={scopedSlots}
          ref={"tabRef"}
          style={{
            height: item?.height ? item?.height : "160px"
          }}
        />
      );
    };
    return (
      <div class={prefixCls}>
        {this.tableHandleCom.renderButton({
          buttons: this.dataStore.comProps.handleData
            ? this.dataStore.comProps.handleData
            : this.dataStore.handleData,
          row: this.dataStore.comProps
        })}
        {renderContentTable(this.dataStore.comProps)}
      </div>
    );
  }
}
