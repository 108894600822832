import request from "@/utils/request";
import ipList from "@/utils/ipConfig";

export function getLoginLogListApi(params?: any) {
  return request({
    url: ipList.system.loginLog.list,
    method: "get",
    params
  });
}

export function unlockLoginApi(id: any) {
  return request({
    url: `${ipList.system.loginLog.unlock}/${id}`,
    method: "get"
  });
}
export function getLoginstatusApi() {
  return request({
    url: `${ipList.system.loginLog.status}`,
    method: "get"
  });
}
export function deleteLoginLogApi(id: any) {
  return request({
    url: `${ipList.system.loginLog.delete}/${id}`,
    method: "DELETE"
  });
}
export function cleanLoginApi(id?: any) {
  return request({
    url: `${ipList.system.loginLog.clean}`,
    method: "DELETE"
  });
}

export function exportLoginLogApi(query: any) {
  return request({
    url: ipList.system.loginLog.export,
    method: "post",
    responseType: "blob",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: query
  });
}
