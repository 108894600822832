import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import { filterEmptyField, publicDelete, publicDownFile } from "@/utils";
import { Message } from "element-ui";
import {
  getVenueListApi,
  insertVenueApi,
  changeVenueStatusApi,
  updateVenueApi,
  deleteVenueListApi,
  venueExportApi
} from "@/api/venue";
import { getFeedbackListApi } from "@/api/feedback";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";
import UseRenderCom from "@/hooks/useRenderCom";
import { getDictAllDataApi } from "@/api/system/dict";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("venue-list");
@Component({
  name: "VenueList"
})
export default class VenueList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  get useRenderCom() {
    return UseRenderCom.call(this);
  }
  private dataStore: any = {
    isLoading: false,
    treeIsLoading: false,
    dictData: [],
    title: "场馆列表",
    tableHeight: 0,
    queryList: {
      pageSize: 10,
      pageNum: 1
    },
    total: 0,
    tableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: []
  };

  private handleNodeClick(node: any) {}

  // 操作场馆信息
  private handleRole(options: any) {
    const { callback, row, title } = options;
    this.useRenderCom.checkFormRequire("dialogRef", (formList: any) => {
      const params: any = { ...formList };
      delete params.permission;
      const api: any = row ? updateVenueApi : insertVenueApi;
      if (row && row.id) {
        params["id"] = row.id;
      }
      api(title && row ? row : params).then((res: any) => {
        Message.success(
          `${row ? "编辑" : "添加"}场馆${title ? title : ""}成功`
        );
        this.getTableData();
        callback && callback();
      });
    });
  }

  private async handleDialog(type: any, row?: any) {
    this.useRenderCom.renderDialog({
      width: "700px",
      title: `${type === "insert" ? "新增" : "编辑"}场馆`,
      schema: config.venue.dialogSchema,
      formList: row
        ? {
            ...row,
            status: row.status ? "0" : "1"
          }
        : {},
      onConfirm: (callback: any) => this.handleRole({ callback, row })
    });
  }

  private handleExport() {
    const filterList: any = this.useRenderCom.getFormValue("filterRef");
    const params: any = {
      ...filterList,
      ...this.dataStore.queryList
    };
    venueExportApi(filterEmptyField(params)).then((res: any) => {
      publicDownFile(res, "场馆列表");
      Message.success("导出数据成功");
    });
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      refresh: () => {
        this.useRenderCom.clearFormList("filterRef");
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      createRow: () => this.handleDialog("insert"),
      deleteBatch: () => {
        if (this.dataStore.selectData.length) {
          const names: any[] = this.dataStore.selectData.map(
            (ele: any) => ele.name
          );
          publicDelete({
            message: `是否删除场馆【${names.join(",")}】？`,
            title: "确认信息",
            callback: () => {
              const ids: any = [];
              const names: any[] = [];
              this.dataStore.selectData.forEach((ele: any) => {
                ids.push(ele.id);
                names.push(ele.name);
              });
              this.deleteDataList(ids, names);
            }
          });
        } else {
          Message.warning("请先选择要删除的数据");
        }
      },
      editRow: () => this.handleDialog("update", row),
      deleteRow: () => {
        publicDelete({
          message: `是否删除场馆【${row.name}】？`,
          title: "确认信息",
          callback: () => {
            this.deleteDataList([row.id], [row.name]);
          }
        });
      },
      exportRole: () => this.handleExport()
    };
    obj[item.prop] && obj[item.prop]();
  }

  // 删除数据
  private deleteDataList(ids: any[], names: any[]) {
    const api: any = deleteVenueListApi;
    const params: any = ids.join(",");
    api(params).then(() => {
      Message.success(`删除场馆【${names.join(",")}】成功`);
      this.getTableData();
    });
  }

  private onChangeStatus(value: any, item: any) {
    this.useRenderCom.renderDialog({
      width: "586px",
      title: `场馆${value ? "启用" : "停用"}`,
      titleSlots: {
        prefix: () => {
          return (
            <i
              class={"el-icon-warning"}
              style={{
                marginRight: "18px",
                color: "#F88B3D",
                fontSize: "24px"
              }}
            ></i>
          );
        }
      },
      contentSlots: () => {
        return (
          <div style={{ marginTop: "40px", marginBottom: "40px" }}>
            您确定{value ? "启用" : "停用"}【{item.name}
            】该场馆吗？
          </div>
        );
      },
      onConfirm: (callback: Function) => {
        changeVenueStatusApi({
          status: value ? "0" : "1",
          id: item.id
        }).then((res) => {
          Message.success(`${value ? "启用" : "停用"}场馆【${item.name}】成功`);
          this.getTableData();
          callback && callback();
        });
      },
      onCloseDialog: (callback: Function) => {
        this.getTableData();
        callback && callback();
      }
    });
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNum = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
      },
      status: () => {
        this.onChangeStatus(item.data.value, item.data.row);
      },
      handle: () => {
        this.onClickMethod({ item: item.data.item, row: item.data.row });
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }

  private async getDictAllData() {
    const result: any = await getDictAllDataApi("feedback_type");
    this.dataStore.dictData = result.map((ele: any) => {
      return {
        ...ele,
        label: ele.dictLabel,
        value: ele.dictValue
      };
    });
    config.feedback.filterSchema[0].componentProps.options =
      this.dataStore.dictData;
  }

  // 处理表格数据
  private dealTableData(data: any[]) {
    return data.map((ele) => {
      ele["status"] = ele.status === "0";
      // ele["roleSorts"] = 2;
      return ele;
    });
  }

  private getTableData() {
    this.dataStore.isLoading = true;
    const filterList: any = this.useRenderCom.getFormValue("filterRef");
    const params: any = { ...filterList, ...this.dataStore.queryList };
    getFeedbackListApi(filterEmptyField(params))
      .then((res: any) => {
        if (res) {
          this.dataStore.tableData = this.dealTableData(res.rows);
          this.dataStore.total = res.total;
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }

  async mounted() {
    await this.getDictAllData();
    this.$nextTick(() => {
      this.useRenderCom.dealContentTableHeight();
    });
    this.getTableData();
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.useRenderCom.renderForm({
              ref: "filterRef",
              schema: config.feedback.filterSchema,
              slots: {
                handle: (data: any) => {
                  return this.useRenderCom.renderButtons({
                    buttons: data.componentProps.buttons
                  });
                }
              }
            })}
          </div>
          {this.useRenderCom.renderTable({
            prefixCls: prefixCls,
            tableData: this.dataStore.tableData,
            tableHeader: config.feedback.tableHeader,
            isShowCheck: true,
            queryList: this.dataStore.queryList,
            total: this.dataStore.total,
            isLoading: this.dataStore.isLoading,
            paginationLayout: "sizes, prev, pager, next, slot, jumper",
            style: {
              height: this.dataStore.tableHeight
            },
            ref: "tableRef",
            slots: {
              type: ({ row }) => {
                const item = this.dataStore.dictData.find(
                  (ele: any) => ele.value === row.type
                );
                return item ? item.label : "";
              }
            }
          })}
        </div>
      </div>
    );
  }
}
