import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DeviceType, AppModule } from "@/store/modules/app";
import { SettingsModule } from "@/store/modules/settings";
import {
  AppMain,
  Navbar,
  Settings,
  Sidebar,
  TagsView,
  RightPanel
} from "./components";
import ResizeMixin from "./mixin/resize";
import { useDesign } from "@/hooks/web/useDesign";
import "./Layout.scss";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("app-wrapper");

@Component({
  name: "Layout"
})
export default class Layout extends mixins(ResizeMixin) {
  $refs!: {
    mainRef: any;
  };
  get classObj() {
    return {
      hideSidebar: !this.sidebar.opened,
      openSidebar: this.sidebar.opened,
      withoutAnimation: this.sidebar.withoutAnimation,
      mobile: this.device === DeviceType.Mobile
    };
  }

  get showSettings() {
    return SettingsModule.showSettings;
  }

  get showTagsView() {
    return SettingsModule.showTagsView;
  }

  get fixedHeader() {
    return SettingsModule.fixedHeader;
  }

  private handleClickOutside() {
    AppModule.CloseSideBar(false);
  }

  private onReloadMain() {
    if (this.$refs.mainRef) {
      this.$refs.mainRef?.onReload();
    }
  }

  render() {
    const renderRightPanel = () => {
      const scopedSlots: any = {
        default: () => <Settings />
      };
      return this.showSettings ? (
        <RightPanel scopedSlots={scopedSlots}></RightPanel>
      ) : null;
    };
    return (
      <div class={["app-wrapper", this.classObj]}>
        {this.classObj.mobile && this.sidebar.opened ? (
          <div class={"drawer-bg"} on-click={this.handleClickOutside}></div>
        ) : null}
        <Sidebar class="sidebar-container" />
        <div class={["main-container", this.showTagsView ? "hasTagsView" : ""]}>
          <div class={{ "fixed-header": this.fixedHeader }}>
            <Navbar />
            {this.showTagsView ? (
              <TagsView on-reload={this.onReloadMain} />
            ) : null}
          </div>
          <AppMain ref={"mainRef"} />
          {renderRightPanel()}
        </div>
      </div>
    );
  }
}
