import { Component, Vue, Provide } from "vue-property-decorator";
import { TagsViewModule } from "@/store/modules/tags-view";
import { useDesign } from "@/hooks/web/useDesign";
import "./app-main.scss";

const prefixCls = useDesign().getPrefixCls("app-main");
@Component({
  name: "AppMain"
})
export default class AppMain extends Vue {
  @Provide("reload") reload = this.onReload;
  get cachedViews() {
    return TagsViewModule.cachedViews;
  }

  get key() {
    return this.$route.path;
  }
  private isRouterAlive = true;
  private onReload() {
    this.isRouterAlive = false;
    this.$nextTick(() => {
      this.isRouterAlive = true;
    });
  }
  render() {
    return (
      <section class={`${prefixCls}`}>
        <transition name="fade-transform" mode="out-in">
          <keep-alive include={this.cachedViews} exclude="Login">
            {this.isRouterAlive ? (
              <router-view key={this.key} class={`${prefixCls}-content`} />
            ) : null}
          </keep-alive>
        </transition>
        <div class={`${prefixCls}-footer`}>
          <a href="https://beian.miit.gov.cn/" target={"_blank"}>
            苏ICP备2023048651号-1
          </a>
        </div>
      </section>
    );
  }
}
