import { compile } from "path-to-regexp";
import { Component, Vue, Watch } from "vue-property-decorator";
import { RouteRecord, Route } from "vue-router";
import { Breadcrumb, BreadcrumbItem } from "element-ui";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";

const prefixCls = useDesign().getPrefixCls("breadcrumb");
@Component({
  name: "SlBreadcrumb"
})
export default class SlBreadcrumb extends Vue {
  private breadcrumbs: RouteRecord[] = [];

  @Watch("$route")
  private onRouteChange(route: Route) {
    // if you go to the redirect page, do not update the breadcrumbs
    if (route.path.startsWith("/redirect/")) {
      return;
    }
    this.getBreadcrumb();
  }

  created() {
    this.getBreadcrumb();
  }

  private getBreadcrumb() {
    let matched = this.$route.matched.filter(
      (item) => item.meta && item.meta.title
    );
    const first = matched[0];
    if (!this.isDashboard(first)) {
      matched = [
        {
          path: "/dashboard",
          meta: { title: "dashboard" }
        } as unknown as RouteRecord
      ].concat(matched);
    }
    this.breadcrumbs = matched.filter((item) => {
      return item.meta && item.meta.title && item.meta.breadcrumb !== false;
    });
    this.breadcrumbs = matched.filter((item) => {
      return item.path !== "/dashboard";
    });
  }

  private isDashboard(route: RouteRecord) {
    const name = route && route.name;
    if (!name) {
      return false;
    }
    return name.trim().toLocaleLowerCase() === "Dashboard".toLocaleLowerCase();
  }

  private pathCompile(path: string) {
    // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
    const { params } = this.$route;
    const toPath = compile(path);
    return toPath(params);
  }

  private handleLink(item: any) {
    const { redirect, path } = item;
    if (redirect) {
      this.$router.push(redirect).catch((err) => {
        console.warn(err);
      });
      return;
    }
    console.log(item, "33333333333", this.breadcrumbs);
    this.$router.push(this.pathCompile(path)).catch((err) => {
      console.warn(err);
    });
  }
  render() {
    const renderContent = () => {
      return this.breadcrumbs.map((item: any, index: number) => {
        return (
          <BreadcrumbItem key={item.path}>
            {item.redirect === "noredirect" ||
            index === this.breadcrumbs.length - 1 ? (
              <span class="no-redirect">{item.meta.title}</span>
            ) : (
              <a on-click={this.handleLink.bind(this, item)}>
                {item.meta.title}
              </a>
            )}
          </BreadcrumbItem>
        );
      });
    };
    return (
      <Breadcrumb class={`${prefixCls}`} separator="/">
        <transition-group name="breadcrumb">{renderContent()}</transition-group>
      </Breadcrumb>
    );
  }
}
