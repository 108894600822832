import request from "@/utils/request";
import ipList from "@/utils/ipConfig";
export function getJobLogListApi(params?: any) {
  return request({
    url: ipList.monitor.jobLog.list,
    method: "get",
    params
  });
}

export function exportJobLogApi(query: any) {
  return request({
    url: ipList.monitor.jobLog.export,
    method: "post",
    responseType: "blob",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: query
  });
}

export function getJobDetailtApi(id?: any) {
  return request({
    url: `${ipList.monitor.jobLog.detail}/${id}`,
    method: "get"
  });
}

export function cleanJoblogApi(id?: any) {
  return request({
    url: `${ipList.monitor.jobLog.clean}`,
    method: "DELETE"
  });
}
