import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import { publicDealTableData, publicDelete } from "@/utils";
import { Message, Tree } from "element-ui";
import { getOperateApi } from "@/api/system/dept";
import { useComProps } from "@/utils/useComProps";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("system-role");
@Component({
  name: "SystemRoleList"
})
export default class SystemRoleList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  private comRoleProps: any = useComProps.call(this, { prefixCls });
  private dataStore: any = {
    operateData: {},
    isLoading: false,
    treeIsLoading: false,
    title: "角色列表",
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNumber: 1
    },
    total: 0,
    tableData: [{ status: 1 }],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: []
  };

  private handleNodeClick(node: any) {}

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNumber = 1;
      },
      getDetail: () => {
        this.comRoleProps.openDialogPage(this, {
          width: "650px",
          title: "操作日志详细",
          schema: config.operationLog.dialogSchema
          // onConfirm: (callback: any) => this.handleRole({ callback })
        });
      }
    };
    obj[item.prop] && obj[item.prop]();
  }

  private handleRole(options: any) {
    console.log(options, "-----optionsoptionsoptions");
  }

  mounted() {
    this.comRoleProps.dealContentTableHeight(this, ["header", "filter"]);
    this.getTableData();
  }

  private getTableData() {
    getOperateApi().then((res) => {
      this.dataStore.operateData = res;
    });
  }
  render() {
    return (
      <div style={"overflow:auto;"} class={`${prefixCls}`}>
        {/* <div class={`${prefixCls}-header`}>{this.dataStore.title}</div> */}
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            <div class={"data_top"}>
              <div style={"margin-right:100px"} class={"data_top-l"}>
                <div class={"l_top"}>
                  <span>CPU</span>
                </div>
                <div class={"l_content"}>
                  <div class={"l_txt"}>
                    <div class={"l_txts"}>属性</div>
                    <div>值</div>
                  </div>
                  <div class={"l_txt"}>
                    <div class={"l_txts"}>核心数</div>
                    <div>{this.dataStore.operateData.cpu.cpuNum}</div>
                  </div>
                  <div class={"l_txt"}>
                    <div class={"l_txts"}>用户使用率</div>
                    <div>{this.dataStore.operateData.cpu.used}%</div>
                  </div>
                  <div class={"l_txt"}>
                    <div class={"l_txts"}>系统使用率</div>
                    <div>{this.dataStore.operateData.cpu.wait}%</div>
                  </div>
                  <div class={"l_txt"}>
                    <div class={"l_txts"}>当前空闲率</div>
                    <div>{this.dataStore.operateData.cpu.free}%</div>
                  </div>
                </div>
              </div>
              <div class={"data_top-l"}>
                <div class={"l_top"}>
                  <span>内存</span>
                </div>
                <div class={"l_content"}>
                  <div class={"l_txt"}>
                    <div class={"r_txts"}>属性</div>
                    <div class={"r_txts"}>内存</div>
                    <div>JVM</div>
                  </div>
                  <div class={"l_txt"}>
                    <div class={"r_txts"}>总内存</div>
                    <div class={"r_txts"}>
                      {this.dataStore.operateData.mem.total}G
                    </div>
                    <div>{this.dataStore.operateData.jvm.total}M</div>
                  </div>
                  <div class={"l_txt"}>
                    <div class={"r_txts"}>已用内存</div>
                    <div class={"r_txts"}>
                      {this.dataStore.operateData.mem.used}G
                    </div>
                    <div>{this.dataStore.operateData.jvm.used}M</div>
                  </div>
                  <div class={"l_txt"}>
                    <div class={"r_txts"}>剩余内存</div>
                    <div class={"r_txts"}>
                      {this.dataStore.operateData.mem.free}G
                    </div>
                    <div>{this.dataStore.operateData.jvm.free}M</div>
                  </div>
                  <div class={"l_txt"}>
                    <div class={"r_txts"}>使用率</div>
                    <div class={"r_txts"}>
                      {this.dataStore.operateData.mem.usage}G
                    </div>
                    <div>{this.dataStore.operateData.jvm.usage}%</div>
                  </div>
                </div>
              </div>
            </div>

            <div class={"data_middle"}>
              <div class={"l_top"}>
                <span>服务器信息</span>
              </div>
              <div class={"l_content"}>
                <div class={"l_txt"}>
                  <div class={"r_txts"}>服务器名称</div>
                  <div class={"r_txts"}>
                    {this.dataStore.operateData.sys.computerName}
                  </div>
                  <div class={"r_txts"}>操作系统</div>
                  <div class={"r_txts"}>
                    {this.dataStore.operateData.sys.osName}
                  </div>
                </div>
                <div class={"l_txt"}>
                  <div class={"r_txts"}>服务器ip</div>
                  <div class={"r_txts"}>
                    {this.dataStore.operateData.sys.computerIp}
                  </div>
                  <div class={"r_txts"}>系统架构</div>
                  <div class={"r_txts"}>
                    {this.dataStore.operateData.sys.osArch}
                  </div>
                </div>
              </div>
            </div>

            <div style={"margin-top:20px; height:500px;"} class={"data_middle"}>
              <div class={"l_top"}>
                <span>java虚拟机信息</span>
              </div>
              <div class={"l_content"}>
                <div class={"l_txt"}>
                  <div class={"r_txts"}>java名称</div>
                  <div class={"r_txts"}>java hotuidosaudiso</div>
                  <div class={"r_txts"}>java版本</div>
                  <div class={"r_txts"}>
                    {this.dataStore.operateData.jvm.version}
                  </div>
                </div>
                <div class={"l_txt"}>
                  <div class={"r_txts"}>启动时间</div>
                  <div class={"r_txts"}>
                    {this.dataStore.operateData.jvm.startTime}
                  </div>
                  <div class={"r_txts"}>运行时长</div>
                  <div class={"r_txts"}>
                    {this.dataStore.operateData.jvm.runTime}
                  </div>
                </div>
                <div class={"l_txt"}>
                  <div class={"r_txts"}>安装路径</div>
                  <div class={"r_txts"}>
                    {this.dataStore.operateData.jvm.home}
                  </div>
                </div>
                <div class={"l_txt"}>
                  <div class={"r_txts"}>项目路径</div>
                  <div class={"r_txts"}>/home/ruoyi/projects/ruoyi-vue</div>
                </div>
                <div class={"l_txt"}>
                  <div class={"r_txts"}>运行参数</div>
                  <div class={"r_txts"}>
                    {this.dataStore.operateData.jvm.inputArgs}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
