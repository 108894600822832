import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import {
  filterEmptyField,
  publicDelete,
  publicDownFile,
  stopPropagation
} from "@/utils";
import { Message } from "element-ui";
import { getMenuTreeSelectApi } from "@/api/system/menu";
import {
  getRoleListApi,
  inertRoleApi,
  updateRoleApi,
  deleteRoleApi,
  changeStatusApi,
  getRoleMenuTreeSelectApi,
  getRoleDeptTreeSelectApi,
  updateRoleDataScopeApi,
  roleExportApi
} from "@/api/system/role";
import { useComProps } from "@/utils/useComProps";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";
import { isObject } from "@/utils/is";
import { SvgIcon } from "@/components";
// import { useRenderCom } from "@/hooks/useRenderCom";
import UseRenderCom from "@/hooks/useRenderCom";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("system-role-list");
@Component({
  name: "SystemRoleList"
})
export default class SystemRoleList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  private comRoleProps: any = useComProps.call(this, { prefixCls });
  get useRenderCom() {
    return UseRenderCom.call(this);
  }
  // private useRenderCom = UseRenderCom.call(this);
  private dataStore: any = {
    isLoading: false,
    treeIsLoading: false,
    title: "角色列表",
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNum: 1
    },
    total: 0,
    tableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: []
  };

  private handleNodeClick(node: any) {}

  // 操作角色信息
  private handleRole(options: any) {
    const { callback, row, title } = options;
    this.useRenderCom.checkFormRequire("dialogRef", (formList: any) => {
      const params: any = { ...formList };
      params["menuCheckStrictly"] = formList.permission.checkStrictly;
      params["menuIds"] = Array.from(new Set(formList.permission.keys));
      delete params.permission;
      const api: any = row ? updateRoleApi : inertRoleApi;
      if (row && row.roleId) {
        params["roleId"] = row.roleId;
      }
      api(title && row ? row : params).then((res: any) => {
        Message.success(
          `${row ? "编辑" : "添加"}角色${title ? title : ""}成功`
        );
        this.getTableData();
        callback && callback();
      });
    });
  }

  private async handleDialog(type: any, row?: any) {
    const roleMenu: any = row
      ? await getRoleMenuTreeSelectApi(row.roleId)
      : await getMenuTreeSelectApi();
    config.role.dialogSchema[4].componentProps.treeProps.data = isObject(
      roleMenu
    )
      ? roleMenu.menus
      : roleMenu;
    this.useRenderCom.renderDialog({
      width: "500px",
      top: "20px",
      title: `${type === "insert" ? "新增" : "编辑"}角色`,
      schema: config.role.dialogSchema,
      formList: row
        ? {
            ...row,
            status: row.status ? "0" : "1",
            permission: {
              checkStrictly: row.menuCheckStrictly,
              keys: roleMenu.checkedKeys
            }
          }
        : {},
      formSlots: {
        "roleKey-label-prefix": () => {
          return this.useRenderCom.renderIconToolTip({
            props: {
              content:
                "控制器中定义的权限字符，如：@PreAuthorize(`@ss.hasRole('admin')`)"
            },
            icon: "el-icon-question"
          });
        }
      },
      onConfirm: (callback: any) => this.handleRole({ callback, row })
    });
  }
  private async handleDataAuth(row: any) {
    const roleMenu: any = await getRoleDeptTreeSelectApi(row.roleId);
    config.role.dataDialogSchema[3].componentProps.treeProps.data = isObject(
      roleMenu
    )
      ? roleMenu.depts
      : roleMenu;
    this.useRenderCom.renderDialog({
      width: "500px",
      top: "50px",
      title: `分配数据权限`,
      schema: config.role.dataDialogSchema,
      formList: row
        ? {
            ...row,
            permission: {
              checkStrictly: row.deptCheckStrictly,
              keys: roleMenu.checkedKeys
            }
          }
        : {},
      onConfirm: (callback: any) => {
        const formList: any = this.useRenderCom.getFormValue("dialogRef");
        const params: any = { roleId: row.roleId, ...formList };
        params["deptCheckStrictly"] = formList.permission.checkStrictly;
        params["deptIds"] = Array.from(new Set(formList.permission.keys));
        delete params.permission;
        updateRoleDataScopeApi(params).then((res) => {
          Message.success(`角色【${row.roleName}】数据权限分配成功`);
          this.getTableData();
          callback && callback();
        });
      }
    });
  }

  private handleExport() {
    const filterList: any = this.useRenderCom.getFormValue("filterRef");
    const params: any = {
      ...filterList,
      ...this.dataStore.queryList
    };
    roleExportApi(filterEmptyField(params)).then((res: any) => {
      publicDownFile(res, "角色列表");
      Message.success("导出数据成功");
    });
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      refresh: () => {
        this.useRenderCom.clearFormList("filterRef");
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      createRow: () => this.handleDialog("insert"),
      deleteBatch: () => {
        if (this.dataStore.selectData.length) {
          const names: any[] = this.dataStore.selectData.map(
            (ele: any) => ele.roleName
          );
          publicDelete({
            message: `是否删除角色【${names.join(",")}】？`,
            title: "确认信息",
            callback: () => {
              const ids: any = [];
              const names: any[] = [];
              this.dataStore.selectData.forEach((ele: any) => {
                ids.push(ele.roleId);
                names.push(ele.roleName);
              });
              this.deleteDataList(ids, names);
            }
          });
        } else {
          Message.warning("请先选择要删除的数据");
        }
      },
      editRow: () => this.handleDialog("update", row),
      deleteRow: () => {
        publicDelete({
          message: `是否删除角色【${row.roleName}】？`,
          title: "确认信息",
          callback: () => {
            this.deleteDataList([row.roleId], [row.roleName]);
          }
        });
      },
      authAssign: () => this.handleDataAuth(row),
      exportRole: () => this.handleExport()
    };
    obj[item.prop] && obj[item.prop]();
  }

  // 删除数据
  private deleteDataList(ids: any[], names: any[]) {
    const api: any = deleteRoleApi;
    const params: any = ids.join(",");
    api(params).then(() => {
      Message.success(`删除角色【${names.join(",")}】成功`);
      this.getTableData();
    });
  }

  private onChangeStatus(value: any, item: any) {
    this.useRenderCom.renderDialog({
      width: "586px",
      title: `角色${value ? "启用" : "停用"}`,
      titleSlots: {
        prefix: () => {
          return (
            <i
              class={"el-icon-warning"}
              style={{
                marginRight: "18px",
                color: "#F88B3D",
                fontSize: "24px"
              }}
            ></i>
          );
        }
      },
      contentSlots: () => {
        return (
          <div style={{ marginTop: "40px", marginBottom: "40px" }}>
            您确定{value ? "启用" : "停用"}【{item.roleName}
            】该角色吗？
          </div>
        );
      },
      onConfirm: (callback: Function) => {
        changeStatusApi({
          status: value ? "0" : "1",
          roleId: item.roleId
        }).then((res) => {
          Message.success(
            `${value ? "启用" : "停用"}角色【${item.roleName}】成功`
          );
          this.getTableData();
          callback && callback();
        });
      },
      onCloseDialog: (callback: Function) => {
        this.getTableData();
        callback && callback();
      }
    });
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNum = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
      },
      status: () => {
        this.onChangeStatus(item.data.value, item.data.row);
      },
      handle: () => {
        this.onClickMethod({ item: item.data.item, row: item.data.row });
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }

  // 处理表格数据
  private dealTableData(data: any[]) {
    return data.map((ele) => {
      ele["status"] = ele.status === "0";
      // ele["roleSorts"] = 2;
      return ele;
    });
  }

  private getTableData() {
    this.dataStore.isLoading = true;
    const filterList: any = this.useRenderCom.getFormValue("filterRef");
    const params: any = { ...filterList, ...this.dataStore.queryList };
    if (filterList.createTimes) {
      params["params[beginTime]"] = filterList.createTimes[0];
      params["params[endTime]"] = filterList.createTimes[1];
      delete params.createTimes;
    }
    getRoleListApi(filterEmptyField(params))
      .then((res: any) => {
        if (res) {
          this.dataStore.tableData = this.dealTableData(res.rows);
          this.dataStore.total = res.total;
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }

  mounted() {
    this.$nextTick(() => {
      this.useRenderCom.dealContentTableHeight();
    });
    this.getTableData();
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.useRenderCom.renderForm({
              ref: "filterRef",
              schema: config.role.filterSchema,
              slots: {
                handle: (data: any) => {
                  return this.useRenderCom.renderButtons({
                    buttons: data.componentProps.buttons
                  });
                },
                tableHandle: (data: any) => {
                  return this.useRenderCom.renderButtons({
                    buttons: data.componentProps.buttons
                  });
                }
              }
            })}
          </div>
          {this.useRenderCom.renderTable({
            prefixCls: prefixCls,
            tableData: this.dataStore.tableData,
            tableHeader: config.role.tableHeader,
            isShowCheck: true,
            queryList: this.dataStore.queryList,
            total: this.dataStore.total,
            isLoading: this.dataStore.isLoading,
            paginationLayout: "sizes, prev, pager, next, slot, jumper",
            style: {
              height: this.dataStore.tableHeight
            },
            ref: "tableRef"
          })}
        </div>
      </div>
    );
  }
}
