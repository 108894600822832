import axios from "axios";
import { Message, MessageBox } from "element-ui";
import { UserModule } from "@/store/modules/user";
import { getToken } from "@/utils/cookies";
import { config } from "vuex-module-decorators";
import tools from "@/utils/tools";
import { CacheUtils } from "@/utils/cacheUtils";

const reportRequest = false;
config.rawError = true;
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 300000
});
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
function screenParams(data: any) {
  const arr: any = [];
  for (const key in data) {
    arr.push(`${key}=${data[key]}`);
  }
  return `?${arr.join("&")}`;
}
function autoLoyOut(message, url, code, isReload?: any) {
  Message({
    message: message ? message : "网络异常请求失败，请重试",
    type: "error",
    duration: 5 * 1000
  });
  if (
    ([401, 403, 40101].includes(code) &&
      !["/login", "/captchaImage"].includes(url)) ||
    isReload
  ) {
    UserModule.ResetToken();
    location.reload();
  }
}
// 自定义请求拦截器
service.interceptors.request.use(
  function (config: any) {
    const cacheKey = config.url;
    if (config.method === "get") {
      config.data = { unused: 0 };
    }
    if (UserModule.token) {
      config.headers.Authorization = getToken();
    }
    // 每次发送请求之前将上一个未完成的相同请求进行中断
    CacheUtils.cache[cacheKey] && CacheUtils.clearCache(cacheKey);

    // 将当前请求所对应的取消函数存入缓存
    config.cancelToken = new axios.CancelToken(function executor(c) {
      CacheUtils.cache[cacheKey] = c;
    });

    // 临时保存 cacheKey，用于在响应拦截器中清除缓存
    config.cacheKey = cacheKey;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptors
service.interceptors.response.use(
  (response) => {
    // 响应接收之后清除缓存
    const cacheKey = (response.config as any).cacheKey;
    delete CacheUtils.cache[cacheKey];
    const isEncrypt = response.config.url?.includes("/opo/");
    const res = response.data;
    // const res = isEncrypt

    //   ? JSON.parse(tools.decrypt(response.data))
    //   : response.data;
    // console.log(res, "-------------解密后的数据-------------", response);
    if (res instanceof Blob) {
      return response;
    }
    if (res.code === undefined) {
      return res;
    }
    if (response.status === 204) {
      Message({
        message: res.msg || "暂无数据",
        type: "error",
        duration: 5 * 1000
      });
      return Promise.reject(new Error(res.msg || "暂无数据"));
    }
    if (res.code && ![20000, 200].includes(res.code)) {
      autoLoyOut(res.msg, response.config.url, res.code);
      return Promise.reject(new Error(res.msg || "Error"));
    } else {
      return isEncrypt ? JSON.parse(tools.decrypt(response.data)) : res.data;
    }
  },
  (error) => {
    if (["Network Error"].includes(error.message)) {
      autoLoyOut(
        "服务器异常",
        error.config.url,
        401,
        !["/login", "/captchaImage"].includes(error.config.url)
      );
      return Promise.reject(new Error(error));
    }
    const isEncrypt = error.response.config.url?.includes("/opo/");
    // 响应异常清除缓存
    if (error.config) {
      const cacheKey = error.config.cacheKey;
      delete CacheUtils.cache[cacheKey];
    }
    const message = [500].includes(error.response.status)
      ? "服务异常，请重试"
      : error.response.data
      ? isEncrypt
        ? tools.decrypt(error.response.data)
        : error.response.data
      : "网络异常请求失败，请重试";
    autoLoyOut(
      message,
      error.response.config.url,
      error.response.status,
      [500].includes(error.response.status)
    );
    return Promise.reject(new Error(error));
  }
);

export default service;
