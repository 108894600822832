import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import { publicDealTableData, publicDelete } from "@/utils";
import { Message, Tree } from "element-ui";

import { useComProps } from "@/utils/useComProps";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("system-role");
@Component({
  name: "SystemRoleList"
})
export default class SystemRoleList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  private comRoleProps: any = useComProps.call(this, { prefixCls });
  private dataStore: any = {
    isLoading: false,
    treeIsLoading: false,
    title: "角色列表",
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNumber: 1
    },
    total: 0,
    tableData: [{ status: 1 }],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: []
  };

  private handleNodeClick(node: any) {}

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNumber = 1;
      },
      getDetail: () => {
        this.comRoleProps.openDialogPage(this, {
          width: "650px",
          title: "操作日志详细",
          schema: config.operationLog.dialogSchema
          // onConfirm: (callback: any) => this.handleRole({ callback })
        });
      }
    };
    obj[item.prop] && obj[item.prop]();
  }

  private handleRole(options: any) {
    console.log(options, "-----optionsoptionsoptions");
  }

  mounted() {
    this.comRoleProps.dealContentTableHeight(this, ["header", "filter"]);
    // this.getTableData();
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        {/* <div class={`${prefixCls}-header`}>{this.dataStore.title}</div> */}
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            <div class={"data_middle"}>
              <div class={"l_top"}>
                <span>基本信息</span>
              </div>
              <div class={"l_content"}>
                <div class={"l_txt"}>
                  <div class={"r_txts"}>Redis版本</div>
                  <div class={"r_txts"}>运行模式</div>
                  <div class={"r_txts"}>端口</div>
                  <div class={"r_txts"}>客户端数</div>
                </div>
                <div class={"l_txt"}>
                  <div class={"r_txts"}>运行时间(天)</div>
                  <div class={"r_txts"}>使用内存</div>
                  <div class={"r_txts"}>使用CPU</div>
                  <div class={"r_txts"}>内存配置</div>
                </div>
              </div>
            </div>

            <div class={"data_top"}>
              <div style={"margin-right:100px;"} class={"data_top-l"}>
                <div class={"l_top"}>
                  <span>命令统计</span>
                </div>
                <div class={"l_content"}></div>
              </div>
              <div class={"data_top-l"}>
                <div class={"l_top"}>
                  <span>内存信息</span>
                </div>
                <div class={"l_content"}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
