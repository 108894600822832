import { Component, Prop, Vue } from "vue-property-decorator";
import { RouteConfig } from "vue-router";
import { isExternal } from "@/utils/validate";
import { Submenu, MenuItem } from "element-ui";
import SidebarItemLink from "./SidebarItemLink";
import { SvgIcon } from "@/components";
import "./SidebarItem.scss";
import { useDesign } from "@/hooks/web/useDesign";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const path = require("path");
const prefixCls = useDesign().getPrefixCls("sidebar-item");
@Component({
  name: "SidebarItem"
})
export default class SidebarItem extends Vue {
  @Prop({ required: true }) private item!: RouteConfig;
  @Prop({ default: false }) private isCollapse!: boolean;
  @Prop({ default: true }) private isFirstLevel!: boolean;
  @Prop({ default: "" }) private basePath!: string;

  get alwaysShowRootMenu() {
    if (this.item.meta && this.item.meta.alwaysShow) {
      return true;
    }
    return false;
  }

  private handleColor() {
    // this.$refs.elMenu.$el.classList.add('red')
    // debugger
  }

  get showingChildNumber() {
    if (this.item.children) {
      const showingChildren = this.item.children.filter((item) => {
        if (item.meta && item.meta.hidden) {
          return false;
        } else {
          return true;
        }
      });
      return showingChildren.length;
    }
    return 0;
  }

  get theOnlyOneChild(): any {
    if (this.showingChildNumber > 1) {
      return null;
    }
    if (this.item.children) {
      for (const child of this.item.children) {
        if (!child.meta || !child.meta.hidden) {
          return child;
        }
      }
    }
    // If there is no children, return itself with path removed,
    // because this.basePath already conatins item's path information
    return { ...this.item, path: "" };
  }

  private resolvePath(routePath: string) {
    if (isExternal(routePath)) {
      return routePath;
    }
    if (isExternal(this.basePath)) {
      return this.basePath;
    }
    return path.resolve(this.basePath, routePath);
  }

  render() {
    const renderContent = () => {
      const renderContentLink = () => {
        return this.theOnlyOneChild.meta ? (
          <SidebarItemLink to={this.resolvePath(this.theOnlyOneChild.path)}>
            <MenuItem
              index={this.resolvePath(this.theOnlyOneChild.path)}
              class={{ "submenu-title-noDropdown": this.isFirstLevel }}
              style={{ color: "rgba(255, 255, 255, 0.65)" }}
              on-click={this.handleColor}
              ref={"elMenu"}
            >
              {this.theOnlyOneChild.meta.icon && this.isFirstLevel ? (
                <SvgIcon icon-class={this.theOnlyOneChild.meta.icon} />
              ) : null}
              {this.theOnlyOneChild.meta.title ? (
                <span slot="title">{this.theOnlyOneChild.meta.title}</span>
              ) : null}
            </MenuItem>
          </SidebarItemLink>
        ) : null;
      };
      const renderContentSubmenu = () => {
        const scopedSlots: any = {
          title: () => {
            const renderTitleIcon = () => {
              return this.item.meta &&
                this.item.meta.icon &&
                this.item.children &&
                this.item.children.length ? (
                <SvgIcon
                  style={{ fontSize: "18px" }}
                  icon-class={this.item?.meta?.icon}
                ></SvgIcon>
              ) : null;
            };
            const renderTitle = () => {
              return (!this.isCollapse || !this.isFirstLevel) &&
                this.item.meta &&
                this.item.meta.title ? (
                <span
                  style={{
                    color: "rgba(255, 255, 255, 0.65)",
                    marginLeft: "8px"
                  }}
                >
                  {this.item.meta.title}
                </span>
              ) : null;
            };
            const defaultStyle: any = {
              display: "flex",
              alignItems: "center"
            };
            const styleList: any =
              this.isCollapse && this.isFirstLevel
                ? { "justify-content": "center", ...defaultStyle }
                : defaultStyle;
            return (
              <div style={styleList} slot={"title"}>
                {renderTitleIcon()}
                {renderTitle()}
              </div>
            );
          }
        };
        const renderSidebarItem = () => {
          return this.item.children?.map((child: any) => {
            return (
              <SidebarItem
                key={child.path}
                item={child}
                is-collapse={this.isCollapse}
                is-first-level={false}
                base-path={this.resolvePath(child.path)}
                class="nest-menu"
              />
            );
          });
        };
        const renderSlots = () => {
          return Object.keys(scopedSlots).map((ele: any) => {
            return scopedSlots[ele]();
          });
        };
        return (
          <Submenu
            index={this.resolvePath(this.item.path)}
            popper-append-to-body
            scopedSlots={scopedSlots}
          >
            {renderSlots()}
            {this.item.children ? renderSidebarItem() : null}
          </Submenu>
        );
      };
      return !this.item.meta || !this.item.meta.hidden ? (
        <div
          class={[
            `${prefixCls}`,
            this.isCollapse ? "simple-mode" : "full-mode",
            { "first-level": this.isFirstLevel }
          ]}
        >
          {!this.alwaysShowRootMenu &&
          this.theOnlyOneChild &&
          !this.theOnlyOneChild.children
            ? renderContentLink()
            : renderContentSubmenu()}
        </div>
      ) : null;
    };
    return renderContent();
  }
}
