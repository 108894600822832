export default {
  render: {
    hint: {
      prompt: "提示",
      confirm: "确定",
      cancel: "取消",

      selectPlaceholder: "请选择",
      timePlaceholder: "选择时间",
      startTimePlaceholder: "起始时间",
      endTimePlaceholder: "截止时间",
      datePlaceholder: "选择日期",
      startDatePlaceholder: "起始日期",
      endDatePlaceholder: "截止日期",
      blankCellContent: "--",

      uploadError: "上传错误: ",
      uploadExceed: "最大上传数量(${uploadLimit})已超出.",
      unsupportedFileType: "不支持格式: ",
      fileSizeExceed: "文件大小已超出: ",
      refNotFound: "组件未找到: ",
      fieldRequired: "字段值不可为空",
      invalidNumber: "数据格式错误",
      selectFile: " 选择文件",
      downloadFile: "下载",
      removeFile: "移除",
      validationFailed: "表单数据校验失败",

      subFormAction: "操作",
      subFormAddAction: "新增",
      subFormAddActionHint: "新增行",
      insertSubFormRow: "插入行",
      deleteSubFormRow: "删除行",
      nonSubFormType: "组件类型不是子表单"
    }
  }
};
