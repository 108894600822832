import { Vue, Component, Watch } from "vue-property-decorator";
import config from "@/config/config.json";
import {
  filterEmptyField,
  publicDealTableData,
  publicDelete,
  publicDownFile,
  replaceObjectKey
} from "@/utils";
import {
  Button,
  Dialog,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Input,
  Message,
  Switch,
  TabPane,
  Tabs,
  Tag,
  Tree
} from "element-ui";
import {
  getJobLogListApi,
  exportJobLogApi,
  getJobDetailtApi,
  cleanJoblogApi
} from "@/api/monitor/jobLog";
import { useComProps } from "@/utils/useComProps";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";
import { debounce } from "lodash-es";
import VCronTab from "../compoents/cronTab/index.vue";
import {
  deleteJobApi,
  getJobGropsApi,
  getJobStatusApi
} from "@/api/monitor/job";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("monitor-job-list");
@Component({
  name: "MonitorJobList"
})
export default class MonitorJobList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
    dialogFormRef: any;
  };
  private comJobProps: any = useComProps.call(this, { prefixCls });
  private dataStore: any = {
    isLoading: false,
    formList: {
      cronExpression: ""
    },
    tempcronExpression: "",
    treeIsLoading: false,
    title: "调度日志列表",
    statusList: [
      {
        value: "1",
        label: "失败"
      },
      {
        value: "0",
        label: "成功"
      }
    ],
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNum: 1
    },
    total: 0,
    tableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: []
  };
  // @Watch("dataStore.tempcronExpression", { immediate: true })
  // onChangetempcronExpression=debounce((val)=>{
  //   this.$nextTick(()=>{
  // this.$refs.dialogFormRef.setValues({ cronExpression: val})
  //   })
  // },200)
  private inputHandle(val) {
    this.$refs.dialogFormRef.setValues({ cronExpression: val });
  }
  private handleNodeClick(node: any) {}

  // 遍历结构树
  private deepDealTreeData(data: any[], parentId: any) {
    return data.map((ele: any) => {
      ele.label = ele.title;
      if (!ele.parentId && ele.parentId !== 0) {
        ele.parentId = parentId;
      }
      if (ele.children) {
        this.deepDealTreeData(ele.children, ele.id);
      }
      return ele;
    });
  }

  private deepScreenAllParent(data: any[], id: any, result: any[]) {
    if (!id) return;
    data.forEach((item, index) => {
      if (item.id === id) {
        if (!result.includes(item.id)) {
          result.unshift(item.id);
        }
        this.deepScreenAllParent(
          this.dataStore.treeData,
          item.parentId,
          result
        );
      } else {
        if (item.children) {
          this.deepScreenAllParent(item.children, id, result);
        }
      }
    });
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      export: () => {
        const params: any = this.getParamsList();
        const filterParams = filterEmptyField(params);
        // const filterParams = replaceObjectKey(
        //   [
        //     ["pageNum", "current"],
        //     ["pageSize", "size"]
        //   ],
        //   filterEmptyField(params)
        // );
        exportJobLogApi(filterParams).then((res) => {
          if (res) {
            publicDownFile(res, "定时任务列表");
            Message.success("导出成功！");
          }
        });
      },
      back: () => {
        this.$router.go(-1);
      },
      clean: () => {
        publicDelete({
          message: `是否确认清空所有调度日志项？`,
          title: "确认信息",
          callback: () => {
            cleanJoblogApi().then((res: any) => {
              Message.success("操作成功");
              this.getTableData();
            });
          }
        });
      },
      refresh: () => {
        this.comJobProps.clearFormList(this, "filterRef");
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      deleteBatch: () => {
        if (this.dataStore.selectData.length) {
          const names: any[] = this.dataStore.selectData.map(
            (ele: any) => ele.name
          );
          // publicDelete({
          //   message: `是否删除定时任务【${names.join(",")}】？`,
          //   title: "确认信息",
          //   callback: () => {
          const ids: any = [];
          this.dataStore.selectData.forEach((ele: any) => {
            ids.push(ele.jobLogId);
          });
          //     this.deleteDataList(ids, names);
          //   }
          // });
          this.comJobProps.openDialogPage(this, {
            width: "586px",
            title: `删除`,
            titleSlots: {
              prefix: () => {
                return (
                  <i
                    class={"el-icon-warning"}
                    style={{
                      marginRight: "18px",
                      color: "#E34D59",
                      fontSize: "24px"
                    }}
                  ></i>
                );
              }
            },
            contentSlots: () => {
              return <div>{` 是否确认删除定时任务【${ids}】？`}</div>;
            },
            onConfirm: (callback) => {
              deleteJobApi(ids.join(",")).then((res: any) => {
                Message.success(`删除成功`);
                callback && callback();
                this.getTableData();
              });
            }
          });
        } else {
          Message.warning("请先选择要删除的数据");
        }
      },
      catMoreRow: () => {
        this.comJobProps.openDialogPage(this, {
          width: "586px",
          title: `详情`,
          // schema:config.monitor.jobLog.dialogDetailSchema,
          formList: row,
          contentSlots: () => {
            return this.comJobProps.renderForm({
              ref: "dialogDetailFormRef",
              schema: config.monitor.jobLog.dialogDetailSchema,
              formList: row,
              slots: {
                jobGroup: () => {
                  const items = config.monitor.job.filterSchema;
                  const bItem: any = items.find(
                    (ele: any) => ele.field === "jobGroup"
                  );
                  const sItem = bItem.componentProps.options.find(
                    (ele: any) => ele.value === row.jobGroup
                  );
                  return sItem.label;
                },
                status: () => {
                  const items = config.monitor.job.filterSchema;
                  const bItem: any = items.find(
                    (ele: any) => ele.field === "status"
                  );
                  const sItem = bItem.componentProps.options.find(
                    (ele: any) => ele.value === row.status
                  );
                  return sItem.label;
                }
              }
            });
          },
          onConfirm: (callback) => {
            this.deleteDataList(row.jobLogId, callback);
          }
        });
      }
    };
    obj[item.prop] && obj[item.prop]();
  }
  private getParamsList() {
    const formList: any = this.comJobProps.getFormValue(this, "filterRef");
    const params: any = {
      ...formList,
      ...this.dataStore.queryList,
      "params[beginTime]": formList?.createTime?.[0],
      "params[endTime]": formList?.createTime?.[1]
    };
    delete params.createTime;
    return filterEmptyField(params);
  }
  // 删除数据
  private deleteDataList(id: any, callback) {
    const params: any = id;
    deleteJobApi(params).then(() => {
      Message.success(`删除定时任务【${id}】成功`);
      callback && callback();
      this.getTableData();
    });
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNum = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }
  private getJobGropsList() {
    getJobGropsApi().then((res: any) => {
      const data = res || [];
      this.dataStore.gropsList = data.map((ele: any) => {
        return {
          label: ele.dictLabel,
          value: ele.dictValue
        };
      });
      config.monitor.jobLog.filterSchema[1].componentProps.options =
        this.dataStore.gropsList;
      this.$nextTick(() => {
        this.comJobProps.setFormSchema(this, "dialogRef", [
          {
            field: "jobGroup",
            path: "componentProps.options",
            value: this.dataStore.gropsList
          }
        ]);
        this.$forceUpdate();
      });
      this.$forceUpdate();
    });
  }
  private getStausList() {
    getJobStatusApi().then((res: any) => {
      const data = res || [];
      this.dataStore.statusList = data.map((ele: any) => {
        return {
          label: ele.dictLabel,
          value: ele.dictValue
        };
      });
      config.monitor.jobLog.filterSchema[2].componentProps.options =
        this.dataStore.statusList;
      this.$nextTick(() => {
        this.comJobProps.setFormSchema(this, "dialogRef", [
          {
            field: "status",
            path: "componentProps.options",
            value: this.dataStore.statusList
          }
        ]);
        this.$forceUpdate();
      });
      this.$forceUpdate();
    });
  }
  private getTableData() {
    this.dataStore.isLoading = true;
    const filterList: any = this.comJobProps.getFormValue(this, "filterRef");
    const params: any = this.getParamsList();
    getJobLogListApi(params)
      .then((res: any) => {
        if (res) {
          this.dataStore.tableData = publicDealTableData(
            res.rows,
            this.dataStore.queryList,
            { current: "pageNum", size: "pageSize" }
          );
          this.dataStore.total = res.total;
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }
  private changeStatus() {}
  mounted() {
    this.comJobProps.dealContentTableHeight(this, ["header", "filter"]);
    this.getTableData();
    this.getStausList();
    this.getJobGropsList();
    if (this.$route.query.id) {
      getJobDetailtApi(this.$route.query.id).then((res: any) => {
        // this.$refs.filterRef.setv
        this.comJobProps.setFormValues(this, "filterRef", { ...res });
        this.getTableData();
      });
    } else {
      this.getTableData();
    }
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        <div class={`${prefixCls}-header`}>{this.dataStore.title}</div>
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.comJobProps.renderForm({
              ref: "filterRef",
              schema: config.monitor.jobLog.filterSchema,
              slots: {
                handle: (data) => {
                  return (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {this.comJobProps.renderButtons({
                        buttons: data.componentProps.buttons
                      })}
                    </div>
                  );
                },
                tableHandle: (data) => {
                  return (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {this.comJobProps.renderButtons({
                        buttons: data.componentProps.buttons
                      })}
                    </div>
                  );
                }
              }
            })}
          </div>
          {this.comJobProps.renderTable({
            tableData: this.dataStore.tableData,
            tableHeader: config.monitor.jobLog.tableHeader,
            isShowCheck: true,
            queryList: this.dataStore.queryList,
            total: this.dataStore.total,
            isLoading: this.dataStore.isLoading,
            paginationLayout: "sizes, prev, pager, next, slot, jumper",
            style: {
              height: this.dataStore.tableHeight
            },
            slots: {
              status: ({ row }) => {
                const sItem = this.dataStore.statusList.find(
                  (ele: any) => ele.value === row.status
                );
                return (
                  <Tag type={row?.status === "1" ? "danger" : ""}>
                    {sItem?.label}
                  </Tag>
                );
              }
            },
            ref: "tableRef"
          })}
        </div>
      </div>
    );
  }
}
