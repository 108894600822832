import request from "@/utils/request";

export function getAllCustomListApi() {
  return request({
    url: "/system/appUser/allUser",
    method: "get"
  });
}

export function getListByMobileApi(params?: any) {
  return request({
    url: "/system/appUser/getListByMobile",
    method: "get",
    params
  });
}
export function getCustomListApi(params?: any) {
  return request({
    url: "/system/appUser/list",
    method: "get",
    params
  });
}

export function downloadCustomModuleApi() {
  return request({
    url: `/system/appUser/importTemplate`,
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    responseType: "blob"
  });
}

export function importCustomApi(data: any, updateSupport: boolean) {
  return request({
    url: `system/appUser/importData?updateSupport=${updateSupport}`,
    method: "post",
    data
  });
}

export function insertCustomListApi(data?: any) {
  return request({
    url: "/system/appUser/insert",
    method: "post",
    data
  });
}

export function updateCustomListApi(data?: any) {
  return request({
    url: "/system/appUser/update",
    method: "post",
    data
  });
}

export function updateCustomAuthApi(data?: any) {
  return request({
    url: "/system/appUser/auth",
    method: "post",
    data
  });
}

export function deleteCustomByIdsApi(ids?: any) {
  return request({
    url: `/system/appUser/${ids}`,
    method: "delete"
  });
}
