export default {
  extension: {
    widgetLabel: {
      card: "Card",
      alert: "Alert"
    },

    setting: {
      cardFolded: "Folded",
      cardShowFold: "Show Fold",
      cardWidth: "Width Of Card",
      cardShadow: "Shadow",

      alertTitle: "Title",
      alertType: "Type",
      description: "Description",
      closable: "Closable",
      closeText: "Text On Close Btn",
      center: "Center",
      showIcon: "Show Icon",
      effect: "Effect"
    }
  }
};
