import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { useDesign } from "@/hooks/web/useDesign";
import { isString } from "@/utils/is";
import { AppModule } from "@/store/modules/app";
import type { EChartsOption } from "echarts";
import echarts from "@/plugins/echarts";
import { debounce } from "lodash-es";
import "echarts-wordcloud";

const { getPrefixCls, variables } = useDesign();
const prefixCls = getPrefixCls("charts");

@Component({
  name: "SlCharts"
})
export default class SlCharts extends Vue {
  $refs!: {
    elRef: any;
  };
  @Prop({
    type: Object,
    default: () => ({})
  })
  readonly options: any;

  @Prop({
    type: [Number, String],
    default: ""
  })
  readonly width: any;

  @Prop({
    type: [Number, String],
    default: "100%"
  })
  readonly height: any;

  @Prop({
    type: String,
    default: "echartsId"
  })
  readonly chartId: any;

  @Watch("options.value", { immediate: true })
  onWatchValue(val: any) {
    if (this.dataStore.echartRef) {
      this.dataStore.echartRef?.setOption(this.options);
    }
  }
  get sideIsOpened(): any {
    return AppModule.sidebar.opened;
  }
  @Watch("sideIsOpened", { immediate: false })
  onChangeSide() {
    setTimeout(() => {
      const width = this.getChartWidth();
      if (this.dataStore.echartRef) {
        this.dataStore.echartRef.resize({
          width: width
        });
      }
    }, 200);
  }

  get styles(): any {
    const width = isString(this.width) ? this.width : `${this.width}px`;
    const height = isString(this.height) ? this.height : `${this.height}px`;
    return {
      width,
      height
    };
  }

  private dataStore: any = {
    echartRef: null,
    contentEl: null
  };

  private getChartWidth() {
    const refs: any[] = Object.keys(this.$refs);
    let width = 0;
    refs.forEach((ref) => {
      width = this.$refs[ref].offsetWidth;
    });
    return width;
  }

  private initChart(options: any) {
    const elRef: any = this.$refs[`${this.chartId}Ref`];
    if (elRef && options) {
      if (this.dataStore.echartRef) {
        this.dataStore.echartRef.dispose();
      }
      const echartsIdDom: any = document.getElementById(this.chartId);
      this.dataStore.echartRef = echarts.init(echartsIdDom);
      this.dataStore.echartRef?.setOption(this.options);
    }
  }

  private contentResizeHandler = async (e: TransitionEvent) => {
    if (e.propertyName === "width") {
      this.resizeHandler();
    }
  };

  private resizeHandler = debounce(() => {
    if (this.dataStore.echartRef) {
      this.dataStore.echartRef.resize();
    }
  }, 100);

  mounted() {
    this.initChart(this.options);
    window.addEventListener("resize", this.resizeHandler);
    this.dataStore.contentEl = document.getElementsByClassName(
      `${variables.namespace}-layout-content`
    )[0];
    this.dataStore.contentEl &&
      (this.dataStore.contentEl as HTMLElement).addEventListener(
        "transitionend",
        this.contentResizeHandler
      );
  }
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
    this.dataStore.contentEl &&
      (this.dataStore.contentEl as HTMLElement).removeEventListener(
        "transitionend",
        this.contentResizeHandler
      );
  }
  activated() {
    if (this.dataStore.echartRef) {
      this.dataStore.echartRef.resize();
    }
  }
  render() {
    return (
      <div
        ref={`${this.chartId}Ref`}
        id={this.chartId}
        class={[this.$attrs.class, prefixCls]}
        style={this.styles}
      ></div>
    );
  }
}
