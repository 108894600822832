import { Component, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/modules/app";
import { PermissionModule } from "@/store/modules/permission";
import { SettingsModule } from "@/store/modules/settings";
import { Menu } from "element-ui";
import SidebarItem from "./SidebarItem";
import SidebarLogo from "./SidebarLogo";
import variables from "@/styles/_variables.scss";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";

const prefixCls = useDesign().getPrefixCls("sidebar");
@Component({
  name: "SideBar"
})
export default class Sidebar extends Vue {
  get sidebar() {
    return AppModule.sidebar;
  }

  get routes() {
    return PermissionModule.routes;
  }

  get showLogo() {
    return SettingsModule.showSidebarLogo;
  }

  get menuActiveTextColor() {
    if (SettingsModule.sidebarTextTheme) {
      return SettingsModule.theme;
    } else {
      return variables.menuActiveText;
    }
  }

  get variables() {
    return {
      menuText: "#bfcbd9"
    };
  }

  get activeMenu() {
    const route = this.$route;
    const { meta, path } = route;
    // if set path, the sidebar will highlight the path you set
    if (meta!.activeMenu) {
      return meta!.activeMenu;
    }
    return path;
  }

  get isCollapse() {
    return !this.sidebar.opened;
  }
  render() {
    const renderSidebarItem = () => {
      return this.routes.map((route: any) => {
        return (
          <SidebarItem
            key={route.path}
            item={route}
            base-path={route.path}
            is-collapse={this.isCollapse}
          />
        );
      });
    };
    return (
      <div class={[`${prefixCls}`, { "has-logo": this.showLogo }]}>
        {this.showLogo ? <SidebarLogo collapse={this.isCollapse} /> : null}
        <el-scrollbar wrap-class="scrollbar-wrapper">
          <Menu
            default-active={this.activeMenu}
            collapse={this.isCollapse}
            background-color="#001529"
            text-color={this.variables.menuText}
            active-text-color={this.menuActiveTextColor}
            unique-opened={false}
            collapse-transition={false}
            mode={`vertical`}
          >
            {renderSidebarItem()}
          </Menu>
        </el-scrollbar>
      </div>
    );
  }
}
