import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import { arrayToTree, filterEmptyField, publicDelete } from "@/utils";
import { Message, Tree } from "element-ui";
import {
  getDeptListApi,
  insertDeptListApi,
  deleteDeptListApi,
  updateDeptListApi
} from "@/api/system/dept";
import { useComProps } from "@/utils/useComProps";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("system-dept-list");
@Component({
  name: "SystemDeptList"
})
export default class SystemDeptList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  private comRoleProps: any = useComProps.call(this, { prefixCls });
  private dataStore: any = {
    isLoading: false,
    treeIsLoading: false,
    title: "部门管理",
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNumber: 1
    },
    total: 0,
    tableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: [],
    defaultExpandAll: true
  };

  private handleNodeClick(node: any) {}

  // 操作角色信息
  private handleDept(options: any) {
    const { callback, row, title } = options;
    const elRef: any = this.comRoleProps.getFormElRef(this, "dialogRef");
    this.comRoleProps.checkFormRequire(elRef, () => {
      const params: any = this.comRoleProps.getFormValue(this, "dialogRef");
      const api: any =
        row && row.deptId ? updateDeptListApi : insertDeptListApi;
      if (row && row.deptId) {
        params["deptId"] = row.deptId;
      }
      api(title && row && row.deptId ? { ...row, ...params } : params).then(
        (res: any) => {
          Message.success(
            `${row && row.deptId ? "编辑" : "添加"}部门${
              title ? title : ""
            }成功`
          );
          this.getTableData();
          callback && callback();
        }
      );
    });
  }

  private handleDialog(type: any, row?: any) {
    config.dept.dialogSchema[0].componentProps.treeProps.data =
      this.dataStore.tableData;
    const schema = JSON.parse(JSON.stringify(config.dept.dialogSchema));
    if (row && row.parentId === 0) {
      schema.splice(0, 1);
    }
    this.comRoleProps.openDialogPage(this, {
      width: "600px",
      title: `${type === "insert" ? "新增" : "编辑"}部门`,
      schema: schema,
      formList: row,
      formStyle: {
        marginTop: "30px"
      },
      onConfirm: (callback: any) => this.handleDept({ callback, row })
    });
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row }: any = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNumber = 1;
        this.getTableData();
      },
      refresh: () => {
        this.comRoleProps.clearFormList(this, "filterRef");
        this.dataStore.queryList.pageNumber = 1;
        this.getTableData();
      },
      createRow: () => this.handleDialog("insert"),
      insertChild: () => this.handleDialog("insert", { parentId: row.deptId }),
      editRow: () => this.handleDialog("update", row),
      deleteRow: () => {
        publicDelete({
          message: `是否删除部门【${row.deptName}】？`,
          title: "确认信息",
          callback: () => {
            this.deleteDataList([row.deptId], [row.deptName]);
          }
        });
      },
      expanded: () => {
        this.dataStore.defaultExpandAll = !this.dataStore.defaultExpandAll;
        if (this.$refs.tableRef) {
          this.$refs.tableRef
            .getElTableRef()
            .toggleRowExpansion(this.dataStore.tableData[0]);
        }
      }
    };
    obj[item.prop] && obj[item.prop]();
  }

  // 删除数据
  private deleteDataList(ids: any[], names: any[]) {
    const api: any = deleteDeptListApi;
    const params: any =
      ids.length > 1 ? { ids: ids.join(",") } : { id: ids.join(",") };
    api(params).then(() => {
      Message.success(`删除部门【${names.join(",")}】成功`);
      this.getTableData();
    });
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNumber = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNumber = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }

  private getTableData() {
    this.dataStore.isLoading = true;
    const filterList: any = this.comRoleProps.getFormValue(this, "filterRef");
    const params: any = { ...filterList };
    getDeptListApi(filterEmptyField(params))
      .then((res: any) => {
        if (res && res.length) {
          this.dataStore.tableData = arrayToTree(res, res[0].parentId, {
            id: "deptId"
          });
        } else {
          this.dataStore.tableData = res;
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }

  mounted() {
    this.comRoleProps.dealContentTableHeight(this, ["header", "filter"]);
    this.getTableData();
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.comRoleProps.renderForm({
              ref: "filterRef",
              schema: config.dept.filterSchema,
              slots: {
                handle: ({ componentProps }) => {
                  return this.comRoleProps.renderButtons({
                    buttons: componentProps.buttons,
                    style: {
                      display: "flex"
                    }
                  });
                }
              }
            })}
          </div>
          {this.comRoleProps.renderTable({
            tableData: this.dataStore.tableData,
            tableHeader: config.dept.tableHeader,
            isShowCheck: false,
            isPagination: false,
            isLoading: this.dataStore.isLoading,
            tableProps: {
              rowKey: "deptId",
              defaultExpandAll: this.dataStore.defaultExpandAll
            },
            style: {
              height: this.dataStore.tableHeight
            },
            ref: "tableRef",
            slots: {
              status: (data: any) => {
                const obj = {
                  "0": {
                    border: "1px solid #d1e9ff",
                    background: "#e8f4ff",
                    color: "#1890ff"
                  },
                  "1": {
                    border: "1px solid #ffdbdb",
                    background: "#ffeded",
                    color: "#ff4949"
                  }
                };
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      style={{
                        ...obj[data.row.status],
                        width: "46px",
                        padding: "5px 0",
                        borderRadius: "4px",
                        margin: "0px"
                      }}
                    >
                      {data.row.status === "0" ? "正常" : "停用"}
                    </div>
                  </div>
                );
              }
            }
          })}
        </div>
      </div>
    );
  }
}
