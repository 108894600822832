import { useDesign } from "@/hooks/web/useDesign";
import { Component, Prop, Watch } from "vue-property-decorator";
import {
  Divider,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  Input,
  Button,
  Cascader
} from "element-ui";
import { mixins } from "vue-class-component";
import Draggable from "vuedraggable";
import i18n from "@/plugins/i18n";
import "./index.scss";
import config from "@/config";
import MyMessageBox from "@/components/SlMessageBox";
import { SlCodeEdit } from "@/components";

const prefixCls = useDesign().getPrefixCls("options-config");
@Component({
  name: "SlOptionsConfig"
})
export default class SlOptionsConfig extends mixins(i18n) {
  $refs!: {
    optionsEditor: any;
  };
  @Prop(Object) readonly designer: any;
  @Prop(Object) readonly selectedWidget: any;
  @Prop({
    type: Object,
    default: () => {
      return {
        label: "label",
        value: "value"
      };
    }
  })
  readonly defaultProps: any;
  @Prop({
    type: String,
    default: "options"
  })
  readonly optionName: any;
  @Prop({
    type: String,
    default: "componentProps.options"
  })
  readonly optionPath: any;

  @Prop({
    type: Function
  })
  readonly callback: any;
  @Watch("selectedWidget.value", { immediate: true })
  onChangeValue(val: any) {
    this.dataStore.defaultValue = val ? val : this.isMultiple ? [] : "";
  }

  private dataStore: any = {
    defaultValue: null,
    optionsName: "",
    separator: ","
  };

  get isMultiple() {
    const isExist: any =
      this.selectedWidget && this.selectedWidget.componentProps;
    const multiple: any =
      isExist && this.selectedWidget.componentProps.multiple;
    return (
      multiple ||
      ["CheckboxGroup", "Transfer"].includes(this.selectedWidget.component)
    );
  }

  private deleteOption(option: any, index: number) {
    this.selectedWidget.componentProps[this.optionName].splice(index, 1);
    this.saveOptions(this.selectedWidget.componentProps[this.optionName]);
  }

  private emitDefaultValueChange() {
    console.log(
      this.dataStore.defaultValue,
      "-==========dataStore.defaultValue========"
    );
    this.designer.updateSelected({
      id: this.selectedWidget.id,
      field: "value",
      value: this.dataStore.defaultValue
    });
  }
  private addOption() {
    const options: any = this.selectedWidget.componentProps[this.optionName]
      ? this.selectedWidget.componentProps[this.optionName]
      : [];
    const obj: any = {};
    obj[this.defaultProps.label] = `选项${options.length + 1}`;
    obj[this.defaultProps.value] = options.length + 1;
    options.push(obj);
    this.saveOptions(options);
  }

  private getOptions() {
    const lineArray = this.dataStore.optionsName.split("\n");
    const options: any[] = [];
    if (lineArray.length > 0) {
      lineArray.forEach((optLine) => {
        if (!!optLine && !!optLine.trim()) {
          const obj: any = {};
          obj[this.defaultProps.label] = optLine.includes(
            this.dataStore.separator
          )
            ? optLine.split(this.dataStore.separator)[1]
            : optLine;
          obj[this.defaultProps.value] = optLine.includes(
            this.dataStore.separator
          )
            ? optLine.split(this.dataStore.separator)[0]
            : optLine;
          options.push(obj);
        }
      });
    }
    return options;
  }

  private dealCallback() {
    let value: any = [];
    if (this.selectedWidget.component === "Cascader") {
      value = JSON.parse(this.dataStore.optionsName);
    } else {
      value = this.getOptions();
    }
    this.saveOptions(value);
  }
  private importOptions() {
    const options: any = this.selectedWidget.componentProps[this.optionName]
      ? this.selectedWidget.componentProps[this.optionName]
      : [];
    if (this.selectedWidget.component === "Cascader") {
      this.dataStore.optionsName = JSON.stringify(options);
    } else {
      this.dataStore.optionsName = "";
      if (options.length) {
        options.forEach((opt) => {
          if (opt[this.defaultProps.value] === opt[this.defaultProps.label]) {
            this.dataStore.optionsName += opt[this.defaultProps.value] + "\n";
          } else {
            this.dataStore.optionsName +=
              opt[this.defaultProps.value] +
              this.dataStore.separator +
              opt[this.defaultProps.label] +
              "\n";
          }
        });
      }
    }
    MyMessageBox({
      title: "导入数据",
      slots: {
        content: () => {
          return this.selectedWidget.component === "Cascader" ? (
            <SlCodeEdit
              v-model={this.dataStore.optionsName}
              mode={"json"}
              readonly={false}
              ref={"optionsEditor"}
            ></SlCodeEdit>
          ) : (
            <Input
              v-model={this.dataStore.optionsName}
              rows="10"
              type={"textarea"}
            ></Input>
          );
        }
      },
      onConfirm: (callback: Function) => {
        if (
          this.selectedWidget.component === "Cascader" &&
          this.$refs.optionsEditor
        ) {
          const codeHints = this.$refs.optionsEditor.getEditorAnnotations();
          // let syntaxErrorFlag = false;
          // if (!!codeHints && codeHints.length > 0) {
          //   codeHints.forEach((chItem) => {
          //     if (chItem.type === "error") {
          //       syntaxErrorFlag = true;
          //     }
          //   });
          //
          //   if (syntaxErrorFlag) {
          //     this.$message.error(
          //       this.i18nt("designer.setting.syntaxCheckWarning")
          //     );
          //     return;
          //   }
          // }
        }
        this.dealCallback();
        callback && callback();
      }
    });
  }

  private saveOptions(value) {
    if (this.callback) {
      this.callback(value);
    } else {
      this.designer.updateSelected({
        id: this.selectedWidget.id,
        field: this.optionName,
        value: value,
        path: this.optionPath
      });
    }
  }

  private onChange() {
    const options: any = this.selectedWidget.componentProps[this.optionName]
      ? this.selectedWidget.componentProps[this.optionName]
      : [];
    this.saveOptions(options);
  }

  private resetDefault() {
    this.designer.updateSelected({
      id: this.selectedWidget.id,
      field: "value",
      value: this.isMultiple ? [] : ""
    });
  }
  render() {
    const isExist: any =
      this.selectedWidget && this.selectedWidget.componentProps;
    const componentGroup: any = this.isMultiple ? CheckboxGroup : RadioGroup;
    const componentBox: any = this.isMultiple ? Checkbox : Radio;
    const options: any =
      isExist && this.selectedWidget.componentProps[this.optionName]
        ? this.selectedWidget.componentProps[this.optionName]
        : [];
    return (
      <div class={prefixCls}>
        <Divider>{this.i18nt("designer.setting.optionsSetting")}</Divider>
        {this.selectedWidget.component !== "Cascader" ? (
          <componentGroup
            v-model={this.dataStore.defaultValue}
            on-change={this.emitDefaultValueChange}
          >
            <Draggable
              tag="ul"
              list={options}
              group={"optionsGroup"}
              ghostClass={"ghost"}
              handler={".drag-option"}
            >
              {options.map((item: any, index: number) => {
                return (
                  <li style={{ padding: "0", margin: "0" }}>
                    <componentBox label={item[this.defaultProps.value]}>
                      <Input
                        v-model={item[this.defaultProps.value]}
                        size={"mini"}
                        on-input={this.onChange}
                      ></Input>
                      <Input
                        v-model={item[this.defaultProps.label]}
                        size="mini"
                        on-input={this.onChange}
                      ></Input>
                      <i class="iconfont icon-drag drag-option"></i>
                      <Button
                        circle
                        plain
                        size="mini"
                        type="danger"
                        on-click={this.deleteOption.bind(this, item, index)}
                        icon="el-icon-minus"
                        class="col-delete-button"
                      ></Button>
                    </componentBox>
                  </li>
                );
              })}
            </Draggable>
          </componentGroup>
        ) : (
          <Cascader
            v-model={this.dataStore.defaultValue}
            options={this.selectedWidget.componentProps[this.optionName]}
          ></Cascader>
        )}
        <div class={`${prefixCls}-bottom`}>
          {this.selectedWidget.component !== "Cascader" ? (
            <Button type="text" on-click={this.addOption}>
              {this.i18nt("designer.setting.addOption")}
            </Button>
          ) : null}
          <Button type="text" on-click={this.importOptions}>
            {this.i18nt("designer.setting.importOptions")}
          </Button>
          <Button type="text" on-click={this.resetDefault}>
            {this.i18nt("designer.setting.resetDefault")}
          </Button>
        </div>
      </div>
    );
  }
}
