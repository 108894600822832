// 接口统一管理

// 外网
// const apiSuffix = '/opo/backend'

const apiSuffix = "";

const ipList: any = {
  system: {
    code: "/captchaImage",
    login: {
      auth: "/login",
      loginByAccount: "/management/auth/loginByAccount",
      logout: "/logout",
      info: "/getInfo"
    },
    role: {
      addRole: "/system/role",
      list: "/system/role/list",
      delete: "/system/role",
      setRole: "/system/user/authRole",
      roleMenu: "/management/sys/role/modifyMenu",
      allList: "/management/sys/role/getList",
      insert: "/system/role",
      update: "/system/role"
    },
    menu: {
      list: "/getRouters",
      treeSelect: "/system/menu/treeselect"
    },
    notice: {
      list: "system/notice/list",
      notice: "system/notice"
    },
    config: {
      list: "system/config/list",
      crudConfig: "system/config",
      export: "system/config/export"
    },
    dict: {
      list: "system/dict/type/list",
      sysNormalDisable: "system/dict/data/type/sys_normal_disable", //状态查询
      crudDict: "system/dict/type",
      export: "system/config/export"
    },
    post: {
      list: "system/post/list",
      crudPost: "system/post",
      export: "system/post/export"
    },
    loginLog: {
      list: "monitor/logininfor/list",
      export: "monitor/logininfor/export",
      unlock: "monitor/logininfor/unlock", //解锁
      clean: "monitor/logininfor/clean", //清空
      delete: "monitor/logininfor",
      status: "dict/data/type/sys_common_status" //状态
    }
  },
  monitor: {
    online: {
      list: "monitor/online/list", //get
      stopRun: "monitor/online" //DELETE
    },
    job: {
      list: "monitor/job/list",
      crudJob: "monitor/job",
      jobGrops: "system/dict/data/type/sys_job_group",
      jobStatus: "system/dict/data/type/sys_job_status",
      export: "monitor/job/export",
      changeStatus: "monitor/job/changeStatus",
      run: "monitor/job/run" //执行一次
    },
    jobLog: {
      export: "monitor/jobLog/export",
      list: "monitor/jobLog/list",
      clean: "monitor/jobLog/clean", //清空
      detail: "/monitor/job"
    },
    cachesList: {
      list: "monitor/cache/getNames",
      getTokens: "monitor/cache/getKeys",
      getValues: "monitor/cache/getValue/login_tokens",
      clearCacheName: "monitor/cache/clearCacheName",
      clearCacheKey: "monitor/cache/clearCacheKey",
      clearCacheAll: "monitor/cache/clearCacheAll" //delete
    }
  },
  upload: "/common/upload"
};

export default ipList;
