import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Layout from "@/Layout/Layout";
Vue.use(VueRouter);

export const constantRoutes: RouteConfig[] = [
  {
    path: "/redirect",
    component: Layout,
    name: "Redirect",
    children: [
      {
        path: "/redirect/:path(.*)",
        name: "RedirectIndex",
        component: () => import("@/views/Redirect/Index"),
        meta: {
          hidden: true,
          noCache: true,
          noTagsView: true
        }
      }
    ],
    meta: {
      hidden: true,
      noCache: true,
      noTagsView: true
    }
  },
  {
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login/Login"),
    meta: { hidden: true }
  },
  {
    path: "/404",
    component: Layout,
    redirect: "/error/404",
    meta: { hidden: true },
    children: [
      {
        path: "/error/404",
        component: () =>
          import(/* webpackChunkName: "404" */ "@/views/error-page/404"),
        meta: { hidden: true }
      }
    ]
  },
  {
    path: "/401",
    component: () =>
      import(/* webpackChunkName: "401" */ "@/views/error-page/401"),
    meta: { hidden: true }
  }
];

const createRouter = () =>
  new VueRouter({
    mode: "hash", // Disabled due to Github Pages doesn't support this, enable this if you need.
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
    base: process.env.VUE_APP_BASE_FORDER,
    routes: constantRoutes
  });

const router = createRouter();
export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher; // reset router
}

export default router;
