import { useDesign } from "@/hooks/web/useDesign";
import { Component, Vue } from "vue-property-decorator";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("course-detail");
@Component({
  name: "CourseDetail"
})
export default class CourseDetail extends Vue {
  render() {
    return (
      <div class={prefixCls}>
        <div>我是详情</div>
      </div>
    );
  }
}
