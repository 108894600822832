import { DirectiveOptions } from "vue";

function mountDom(el: any) {
  el.style.position = "relative";

  const div = document.createElement("div");
  div.style.position = "absolute";
  div.style.top = "0";
  div.style.left = "0";
  div.style.right = "0";
  div.style.bottom = "0";
  div.style.backgroundColor = "white";
  div.classList.add("sl-loading");

  div.innerHTML = `
    <div style="position:absolute;top:0;left:0;z-index:999;width: 100%;margin: 10 auto;">
      <div class="fast-loading"></div>
      <div class="fast-loading w40"></div>
      <div class="fast-loading w80"></div>
    </div>
  `;
  el.appendChild(div);
}
export const slLoading: DirectiveOptions = {
  bind: (el, binding) => {
    if (binding.value) {
      mountDom(el);
    }
  },
  update: (el, binding) => {
    const slLoadingDom: any = el.querySelector(".sl-loading");
    if (slLoadingDom) {
      slLoadingDom.style.display = binding.value ? "block" : "none";
    } else {
      binding.value && mountDom(el);
    }
  }
};
