import tools from "@/utils/tools";
import { Loading, Message, MessageBox } from "element-ui";
import { isArray, isString } from "@/utils/is";

const baseUrl = process.env.VUE_APP_BASE_API;
const imageSuffix = process.env.VUE_APP_IMAGE_SUFFIX;
const fileSuffix = process.env.VUE_APP_FILE_SUFFIX;
// Parse the time to string
export const parseTime = (
  time?: object | string | number | null,
  cFormat?: string
): string | null => {
  if (time === undefined || !time) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date: Date;
  if (typeof time === "object") {
    date = time as Date;
  } else {
    if (typeof time === "string") {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time);
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(/-/gm, "/");
      }
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj: { [key: string]: number } = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
  return timeStr;
};

// Format and filter json data using filterKeys array
export const formatJson = (filterKeys: any, jsonData: any) =>
  jsonData.map((data: any) =>
    filterKeys.map((key: string) => {
      if (key === "timestamp") {
        return parseTime(data[key]);
      } else {
        return data[key];
      }
    })
  );

// Check if an element has a class
export const hasClass = (ele: HTMLElement, className: string) => {
  return !!ele.className.match(new RegExp("(\\s|^)" + className + "(\\s|$)"));
};

// Add class to element
export const addClass = (ele: HTMLElement, className: string) => {
  if (!hasClass(ele, className)) ele.className += " " + className;
};

// Remove class from element
export const removeClass = (ele: HTMLElement, className: string) => {
  if (hasClass(ele, className)) {
    const reg = new RegExp("(\\s|^)" + className + "(\\s|$)");
    ele.className = ele.className.replace(reg, "");
  }
};

// Toggle class for the selected element
export const toggleClass = (ele: HTMLElement, className: string) => {
  if (!ele || !className) {
    return;
  }
  let classString = ele.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += "" + className;
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length);
  }
  ele.className = classString;
};

/**
 * @description 小组排版颜色
 * @modifyContent
 * @author 王文涛
 * @date 2021-01-09 09:14:05
 */
export function groupColor(str: any, opcative: any) {
  const color: any = {
    0: `rgba(99,101,255,${opcative})`,
    1: `rgba(255,201,99,${opcative}`,
    2: `rgba(99,211,255,${opcative})`,
    3: `rgba(255,99,170,${opcative})`,
    4: `rgba(46,192,154,${opcative})`
  };
  return color[str];
}

/**
 * @functionName 16进制转RGBA
 *@description:
 * @param
 */
export function colorToRgba(colors: any, opacity = 1) {
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  // 把颜色值变成小写
  let color = colors.toLowerCase();
  if (reg.test(color)) {
    // 如果只有三位的值，需变成六位，如：#fff => #ffffff
    if (color.length === 4) {
      let colorNew = "#";
      for (let i = 1; i < 4; i += 1) {
        colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
      }
      color = colorNew;
    }
    // 处理六位的颜色值，转为RGB
    const colorChange: any[] = [];
    for (let i = 1; i < 7; i += 2) {
      colorChange.push(parseInt("0x" + color.slice(i, i + 2)));
    }
    colorChange.push(opacity);
    return "rgba(" + colorChange.join(",") + ")";
  } else {
    return `rgba(255,255,255,${opacity})`;
  }
}

/**
 * sortArray 对数组根据条件key进行正序或者倒序排列
 * @param tempArr 对象数组
 * @param key 需要的关键字（非必填）
 * @param reverse 是否倒序（非必填）
 */
export function sortArray(tempArr: any[], key?: string, reverse?: boolean) {
  for (let i = 0; i < tempArr.length; i++) {
    for (let j = i + 1; j < tempArr.length; j++) {
      const aItem = key ? tempArr[i][key] : tempArr[i];
      const bItem = key ? tempArr[j][key] : tempArr[j];
      const judge = reverse ? aItem < bItem : aItem > bItem;
      // 如果第一个比第二个大，就交换他们两个位置
      if (judge) {
        const temp = tempArr[i];
        tempArr[i] = tempArr[j];
        tempArr[j] = temp;
      }
    }
  }
  return tempArr;
}

export function publicScreenValue({
  data,
  key,
  value,
  resultKey
}: {
  data: any[];
  key: string;
  value: any;
  resultKey?: string;
}) {
  const item = data.find((ele) => ele[key] === value);
  return resultKey && item && (item[resultKey] || item[resultKey] === 0)
    ? item[resultKey]
    : !resultKey && item
    ? item
    : null;
}

export function publicDealEncrypt(list: any, isPost?: boolean) {
  if (isPost) return tools.encrypt(JSON.stringify(list));
  const obj: any = {};
  for (const i in list) {
    if (i !== "hide") {
      obj[i] =
        typeof list[i] === "string"
          ? tools.encrypt(list[i])
          : tools.encrypt(JSON.stringify(list[i]));
    }
  }
  return obj;
}

export const formatTime = (time: Date | number | string, fmt: string) => {
  if (!time) return "";
  else {
    const date = new Date(time);
    const o: any = {
      "M+": date.getMonth() + 1,
      "d+": date.getDate(),
      "H+": date.getHours(),
      "m+": date.getMinutes(),
      "s+": date.getSeconds(),
      "q+": Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds()
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    }
    for (const k in o) {
      if (new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
      }
    }
    return fmt;
  }
};

export function publicDealFileUrl(list: any, prop: string, isFile?: Boolean) {
  if (!list || !list[prop]) return "";
  const suffixUrl: any = list[prop].substr(0, 1) === "/" ? "" : "/";
  return `${baseUrl}${isFile ? fileSuffix : imageSuffix}${suffixUrl}${
    list[prop]
  }`;
}

export const isServer = typeof window === "undefined";

export const isClient = !isServer;

export const publicMessagebox = (
  item: any,
  type: any,
  callback: any,
  errorCallback?: any
) => {
  const box: any = MessageBox;
  box[type](item.message, item.title, item.options)
    .then(() => {
      callback && callback();
    })
    .catch(() => {
      errorCallback
        ? errorCallback()
        : Message({ type: "info", message: "已取消操作" });
    });
};

/**
 * 查找数组对象的某个下标
 * @param {Array} ary 查找的数组
 * @param {Functon} fn 判断的方法
 */
// eslint-disable-next-line
export const findIndex = <T = Recordable>(ary: Array<T>, fn: Fn): number => {
  if (ary.findIndex) {
    return ary.findIndex(fn);
  }
  let index = -1;
  // eslint-disable-next-line array-callback-return
  ary.some((item: T, i: number, ary: Array<T>) => {
    const ret: T = fn(item, i, ary);
    if (ret) {
      index = i;
      return ret;
    }
  });
  return index;
};

export const screenRealData = (list: any) => {
  const obj: any = {};
  for (const i in list) {
    if ((isArray(list[i]) && list[i].length) || list[i]) {
      obj[i] = list[i];
    }
  }
  return obj;
};

export function getUuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function firstToUpper(str: any) {
  return str.replace(/\b(\w)(\w*)/g, function ($0: any, $1: any, $2: any) {
    return $1.toUpperCase() + $2.toLowerCase();
  });
}

export function getAges(str: string) {
  const r: any = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
  if (r == null) return false;
  const d = new Date(r[1], r[3] - 1, r[4]);
  if (
    d.getFullYear() == r[1] &&
    d.getMonth() + 1 == r[3] &&
    d.getDate() == r[4]
  ) {
    const Y = new Date().getFullYear();
    return Y - r[1];
  }
  return "输入的日期格式错误！";
}

export function screenEmptyField(list: any, filterFields?: any[]) {
  const obj: any = {};
  Object.keys(list).forEach((ele: any) => {
    if (!filterFields || (filterFields && !filterFields.includes(ele))) {
      obj[ele] = list[ele];
    }
  });
  return obj;
}

export function filterEmptyField(list: any) {
  const obj: any = {};
  Object.keys(list).forEach((ele: any) => {
    if (list[ele] || list[ele] === 0) {
      obj[ele] = list[ele];
    }
  });
  return obj;
}

/**
 * countTextStyle 获取文字宽度
 * @param option
 */
export function countTextStyle(option: any) {
  const { text, font } = option;
  const canvas = document.createElement("canvas");
  const context: any = canvas.getContext("2d");
  context.font = font;
  return context.measureText(text).width;
}

export function getImageInfo(src: any) {
  return new Promise((resolve, reject) => {
    const image: any = new Image();
    image.src = src;
    image.onload = () => {
      const infoList: any = {
        width: image.width,
        height: image.height
      };
      resolve(infoList);
    };
  });
}

export function filterTreeArray(
  tree: any[],
  key: any,
  { nodeKey = "id", childrenKey = "children" } = {},
  recursiveParams = { isFind: false, result: null }
) {
  tree.some((v) => {
    if (recursiveParams.isFind) return true;
    const isSame = v[nodeKey] === key;
    if (isSame) {
      recursiveParams.isFind = isSame;
      recursiveParams.result = v;
      return isSame;
    }
    if (v[childrenKey] && v[childrenKey].length) {
      filterTreeArray(
        v[childrenKey],
        key,
        { nodeKey, childrenKey },
        recursiveParams
      );
    }
  });
  return recursiveParams.result;
}

export function publicDownFile(options: any, name?: any) {
  let fileName: any = null;
  const { headers, data } = options;
  if (headers && headers["content-disposition"]) {
    fileName = decodeURIComponent(
      headers["content-disposition"].split(";")[1].split("=")[1]
    );
  }
  console.log(data, "sjjdjdj");
  const blob = new Blob([data], { type: data.type });
  const downloadElement = document.createElement("a");
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  if (fileName || name) {
    downloadElement.download = name ? name : fileName;
  }
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement); //移除元素；防止连续点击创建多个a标签
  window.URL.revokeObjectURL(href);
}

export function publicDownRequestFile(options: any, name?: any) {
  const loadingInstance = Loading.service(options.loadingOptions);
  options
    ?.api(options.params)
    .then((res: any) => {
      publicDownFile(res, name);
    })
    .finally(() => {
      loadingInstance.close();
    });
}

// 图片压缩
export function fileToImg(file: any) {
  return new Promise((resolve, reject) => {
    const img: any = new Image();
    const reader = new FileReader();
    reader.onload = function (e: any) {
      img.src = e.target.result;
    };
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsDataURL(file);
    img.onload = function () {
      resolve(img);
    };
    img.onerror = function (e: any) {
      reject(e);
    };
  });
}

/**
 * @name 文件压缩
 * @description
 *  1、将文件转img对象
 *  2、获取文件宽高比例
 *  3、自定义等比例缩放宽高属性，这里我用的是固定800宽度，高度是等比例缩放
 *  4、canvas重新绘制图片
 *  5、canvas转二进制对象转文件对象，返回
 * @returns { File } 文件
 */
export async function imgCompress(file: any) {
  // 将文件转img对象
  const img: any = await fileToImg(file);
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const context: any = canvas.getContext("2d");
    // 获取文件宽高比例
    const { width: originWidth, height: originHeight } = img;
    // 自定义等比例缩放宽高属性，这里我用的是固定800宽度，高度是等比例缩放
    const scale = +(originWidth / originHeight).toFixed(2); // 比例取小数点后两位)
    const targetWidth = 800; // 固定宽
    const targetHeight = Math.round(800 / scale); // 等比例缩放高

    canvas.width = targetWidth;
    canvas.height = targetHeight;
    context.clearRect(0, 0, targetWidth, targetHeight);
    // canvas重新绘制图片
    context.drawImage(img, 0, 0, targetWidth, targetHeight);
    // canvas转二进制对象转文件对象，返回
    const type = "image/png";
    canvas.toBlob(function (blob: any) {
      const f = new File([blob], file.name, {
        type,
        lastModified: file.lastModified
      });
      resolve(f);
    }, type);
  });
}
export function canvasDataURL(path: any, obj: any, callback: Function) {
  const img = new Image();
  img.src = path;
  img.setAttribute("crossOrigin", "Anonymous");
  img.onload = function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that: any = this;
    // 默认按比例压缩
    let w = that.width,
      h = that.height;
    const scale = w / h;
    w = obj.width || w;
    h = obj.height || w / scale;
    let quality = 0.7; // 默认图片质量为0.7
    //生成canvas
    const canvas = document.createElement("canvas");
    const ctx: any = canvas.getContext("2d");
    // 创建属性节点
    const anw = document.createAttribute("width");
    anw.nodeValue = w;
    const anh = document.createAttribute("height");
    anh.nodeValue = h;
    canvas.setAttributeNode(anw);
    canvas.setAttributeNode(anh);
    ctx.drawImage(that, 0, 0, w, h);
    // 图像质量
    if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
      quality = obj.quality;
    }
    // quality值越小，所绘制出的图像越模糊
    const base64 = canvas.toDataURL("image/jpeg", quality);
    // 回调函数返回base64的值
    callback(base64);
  };
}

export function toChineseBig(number: any) {
  if (number.match(/\D/) || number.length >= 14) return;
  const zhArray = [
    "零",
    "一",
    "二",
    "三",
    "四",
    "五",
    "六",
    "七",
    "八",
    "九",
    "十"
  ]; // 数字对应中文
  const baseArray = [
    "",
    "十",
    "百",
    "千",
    "万",
    "十",
    "百",
    "千",
    "亿",
    "十",
    "百",
    "千",
    "万"
  ]; //进位填充字符，第一位是 个位，可省略
  let string = String(number)
    .split("")
    .reverse()
    .map((item, index) => {
      // 把数字切割成数组并倒序排列，然后进行遍历转成中文
      // 如果当前位为0，直接输出数字， 否则输出 数字 + 进位填充字符
      item =
        Number(item) == 0
          ? zhArray[Number(item)]
          : zhArray[Number(item)] + baseArray[index];
      return item;
    })
    .reverse()
    .join(""); // 倒叙回来数组，拼接成字符串
  string = string.replace(/^一十/, "十"); // 如果以 一十 开头，可省略一
  string = string.replace(/零+/, "零"); // 如果有多位相邻的零，只写一个即可
  return string.replace("零", "");
}

// 全局处理用户信息
export function publicDealTableData(data: any, queryList: any, props?: any) {
  return data.map((ele: any, index: any) => {
    ele["index"] =
      (queryList[props && props.current ? props.current : "current"] - 1) *
        queryList[props && props.size ? props.size : "size"] +
      index +
      1;
    return ele;
  });
}

// 全局处理删除弹窗提示
export function publicDelete(optProps: any) {
  MessageBox.confirm(optProps.message, optProps.title, optProps.options)
    .then(() => {
      optProps.callback && optProps.callback();
    })
    .catch((action) => {
      Message({
        type: "info",
        message: "取消操作"
      });
    });
}

export function transformChar(str: any) {
  // 九十二
  const numChar: any = {
    零: 0,
    一: 1,
    二: 2,
    三: 3,
    四: 4,
    五: 5,
    六: 6,
    七: 7,
    八: 8,
    九: 9
  };
  const levelChar: any = {
    十: 10,
    百: 100,
    千: 1000,
    万: 10000,
    亿: 100000000
  };
  const arr: any[] = Array.from(str);
  let sum = 0,
    temp = 0;
  for (let i = 0; i < arr.length; i++) {
    const char = arr[i];
    if (char === "零") continue;
    if (char === "亿" || char === "万") {
      sum += temp * levelChar[char];
      temp = 0;
    } else {
      const next = arr[i + 1];
      if (next && next !== "亿" && next !== "万") {
        temp += numChar[char] * levelChar[next];
        i++;
      } else {
        temp += numChar[char];
      }
    }
  }
  return sum + temp;
}

// 筛选结构树指定的数据
export function screenListForTree(
  data: any,
  ids: any,
  result: any[],
  props?: any
) {
  const children: any = props && props.children ? props.children : "children";
  const key: any = props && props.key ? props.key : "id";
  data.forEach((ele: any) => {
    if (ids.includes(ele[key])) {
      result.push(ele);
    }
    if (ele[children] && ele[children].length) {
      screenListForTree(ele[children], ids, result, props);
    }
  });
  return result;
}

export function publicDealSaveList(list: any) {
  const obj: any = {};
  if (!list) return obj;
  const keys: any = Object.keys(list);
  keys.forEach((ele: any) => {
    if (list[ele] || list[ele] === 0) {
      if (isArray(list[ele])) {
        obj[ele] = list[ele].join(",");
      } else {
        obj[ele] = list[ele];
      }
    }
  });
  return obj;
}

export function publicDealFormList(list: any, mData?: any[], sData?: any[]) {
  const obj: any = {};
  if (!list) return obj;
  const keys: any = Object.keys(list);
  keys.forEach((ele: any) => {
    if (list[ele] || list[ele] === 0) {
      if (
        isString(list[ele]) &&
        (list[ele].includes(",") || (mData && mData.includes(ele)))
      ) {
        obj[ele] = list[ele].split(",");
      } else {
        obj[ele] = sData && sData.includes(ele) ? String(list[ele]) : list[ele];
      }
    }
  });
  return obj;
}

// 全局导出方法
export function publicExportMethod(res: any, name: string) {
  let fileName: any = null;
  if (res.headers) {
    fileName = res.headers["content-disposition"]
      ? decodeURIComponent(
          res.headers["content-disposition"].split(";")[1].split("=")[1]
        )
      : name;
  }
  const blob = new Blob([res.data], { type: res.data.type });
  const downloadElement = document.createElement("a");
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  if (fileName) {
    downloadElement.download = fileName;
  }
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement); //移除元素；防止连续点击创建多个a标签
  window.URL.revokeObjectURL(href);
}

// 全局处理选择的数据
export function publicDealOptionsLabel(options: any) {
  const { data, isMultiple, value } = options;
  if (!value) return "";
  if (isMultiple) {
    const labels: any = data
      .filter((ele: any) =>
        isArray(value)
          ? value.includes(ele.value)
          : value.split(",").includes(ele.value)
      )
      .map((ele: any) => ele.label);
    return labels.join(",");
  } else {
    const item: any = data.find((ele: any) => ele.value === value);
    return item ? item.label : "";
  }
}

export const formatRouter = (routes, newRoutes: any = []) => {
  routes.map((item: any) => {
    if (item.children && item.children.length)
      formatRouter(item.children, newRoutes);
    newRoutes.push(item);
  });
  return newRoutes;
};

// 跳转其他页面
export function routeToOtherPage(medicalCard) {
  const url: any = `http://194.1.14.210:8011/cdr/login/loginInpatientIntegrated.html?domainId=01&inpatientType=01&patientId=${medicalCard}&sceneType=05&userId=1357`;
  window.open(url);
}

export function replaceObjectKey(rKeys: any[], obj) {
  rKeys.forEach((item: any) => {
    const replaceKey = item[0];
    const targetKey: any = item[1];
    obj[replaceKey] = obj[targetKey];
    delete obj[targetKey];
  });
  return obj;
}

// 数组转化为树形结构
export function arrayToTree(arr: any[], parentId: any, props?: any) {
  if (!arr.length) return [];
  const id: any = props && props.id ? props.id : "id";
  const parentIdKey: any =
    props && props.parentId ? props.parentId : "parentId";
  const childrenKey: any =
    props && props.children ? props.children : "children";
  const tree: any[] = [];
  for (let i = 0; i < arr.length; i++) {
    const node: any = arr[i];
    if (node[parentIdKey] === parentId) {
      const children = arrayToTree(arr, node[id], props);
      if (children.length > 0) {
        node[childrenKey] = children;
      }
      tree.push(node);
    }
  }
  return tree;
}

export function findNewObj(obj: any, path: string, value: any) {
  console.log(obj, path, value);
  if (!path.includes(".")) {
    obj[path] = value;
  } else {
    const paths = path.split(".");
    const key = paths[0];
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      obj[key] = {};
    }
    const nPaths = paths.filter((ele) => ele !== key).join(".");
    findNewObj(obj[key], nPaths, value);
  }
}

export function handleSpecialCharacters(jsonStr) {
  let obj = {};
  if (jsonStr && Object.prototype.toString.call(jsonStr) == "[object String]") {
    jsonStr = jsonStr.replace(/\r/g, "\\r");
    // jsonStr = jsonStr.replace(/\n/g, "\\n");
    // jsonStr = jsonStr.replace(/\t/g, "\\t");
    jsonStr = jsonStr.replace(/\\/g, "\\\\");
    jsonStr = jsonStr.replace(/\'/g, "&#39;");
    // jsonStr = jsonStr.replace(/ /g, "&nbsp;");
    // jsonStr = jsonStr.replace(/</g, "$lt;");
    // jsonStr = jsonStr.replace(/>/g, "$gt;");
    obj = JSON.parse(jsonStr);
  }
  return obj;
}

export function publicExport(options: any) {
  const { data, fileName, type } = options;
  const blob = new Blob([data], { type: type });
  const downloadElement = document.createElement("a");
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  if (fileName) {
    downloadElement.download = fileName;
  }
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement); //移除元素；防止连续点击创建多个a标签
  window.URL.revokeObjectURL(href);
}

export function publicDealRouteData(routes: any[], parent?: any) {
  const result: any[] = [];
  for (const route of routes) {
    const data: any = {
      ...route,
      path: `${parent ? parent.path : ""}${
        route.path.substr(0, 1) === "/" ? "" : "/"
      }${route.path}`
    };
    if (route.children) {
      data.children = publicDealRouteData(route.children, route);
    }
    result.push(data);
  }
  return result;
}

export function treeToArray(trees: any, props?: any) {
  const childrenKey: any =
    props && props.children ? props.children : "children";
  const result: any[] = [];
  trees.forEach((ele) => {
    result.push(ele);
    if (ele[childrenKey] && ele[childrenKey].length) {
      result.push(...treeToArray(ele[childrenKey], props));
    }
  });
  return result;
}

export function acceptFile(e: any) {
  const allowHook = {
    video: ".mp4, .ogv, .ogg, .webm",
    audio: ".wav, .mp3, .ogg, .acc, .webm, .amr",
    file: "doc,.docx,.xlsx,.xls,.pdf",
    excel: "xlsx,.xls",
    img: ".jpg, .jpeg, .png, .gif"
  };
  if (e) {
    return allowHook[e];
  }
  let srt = null;
  for (const k in allowHook) {
    srt += allowHook[k];
  }
  return srt;
}

export function base64ToFile(urlData: string, fileName: string) {
  const arr: any = urlData.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bytes = atob(arr[1]);
  let n = bytes.length;
  const ia = new Uint8Array(n);
  while (n--) {
    ia[n] = bytes.charCodeAt(n);
  }
  return new File([ia], fileName, { type: mime });
}

export function jsonToFormData(json: any) {
  const keys = Object.keys(json);
  const formData = new FormData();
  keys.forEach((key) => {
    formData.append(key, json[key]);
  });
  return formData;
}
export function replaceSrc(htmlString: string, newSrc: string) {
  if (!newSrc) return htmlString;
  // 创建一个正则表达式，用于匹配 src 属性的值
  const regex = /src="([^"]*)"/g;
  // 使用 replace 函数替换匹配到的 src 属性值
  return htmlString.replace(regex, 'src="' + newSrc + '"');
}

export function stopPropagation() {
  const e = (window as any).event; //这里是因为除了IE有event其他浏览器没有所以要做兼容
  if (e && !e.stopPropagation) {
    // 这是IE浏览器
    e.cancelBubble = true;
  } else if (e && e.stopPropagation) {
    //这是其他浏览器
    e.stopPropagation(); //阻止冒泡事件
  }
}
