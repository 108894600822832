import request from "@/utils/request";
import ipList from "@/utils/ipConfig";
export function getConfigListApi(params?: any) {
  return request({
    url: ipList.system.config.list,
    method: "get",
    params
  });
}

export function insetConfigApi(query: any) {
  return request({
    url: ipList.system.config.crudConfig,
    method: "post",
    data: query
  });
}

export function updateConfigApi(query: any) {
  return request({
    url: ipList.system.config.crudConfig,
    method: "put",
    data: query
  });
}

export function deleteConfigApi(id: any) {
  return request({
    url: `${ipList.system.config.crudConfig}/${id}`,
    method: "DELETE"
  });
}

export function exportConfigApi(query: any) {
  return request({
    url: ipList.system.config.export,
    method: "post",
    responseType: "blob",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: query
  });
}
