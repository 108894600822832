import { useDesign } from "@/hooks/web/useDesign";
import { Component, Vue } from "vue-property-decorator";
import { AppMain } from "@/Layout/components";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("parent-view");
@Component({
  name: "ParentView"
})
export default class ParentView extends Vue {
  render() {
    return <AppMain></AppMain>;
  }
}
