import request from "@/utils/request";

export function getCoachListApi(params?: any) {
  return request({
    url: "/business/coach/list",
    method: "get",
    params
  });
}

export function getAllCoachListApi() {
  return request({
    url: "/business/coach/allList",
    method: "get"
  });
}

/**
 * 新增教练信息
 */
export function insertCoachApi(data: any) {
  return request({
    url: `/business/coach/insert`,
    method: "post",
    data
  });
}

/**
 * 修改教练信息
 */
export function updateCoachApi(data: any) {
  return request({
    url: `/business/coach/update`,
    method: "post",
    data
  });
}

/**
 * 修改教练状态
 */
export function changeCoachStatusApi(data: any) {
  return request({
    url: `/business/coach/changeStatus`,
    method: "put",
    data
  });
}

export function deleteCoachListApi(ids: any) {
  return request({
    url: `/business/coach/remove/${ids}`,
    method: "get"
  });
}

export function coachExportApi(data: any) {
  return request({
    url: `/business/coach/export`,
    method: "post",
    responseType: "blob",
    data
  });
}
