import request from "@/utils/request";

export function getCourseListApi(params?: any) {
  return request({
    url: "/business/course/list",
    method: "get",
    params
  });
}

export function getCourseListByTimeRangeApi(params?: any) {
  return request({
    url: "/business/course/listByTimeRange",
    method: "get",
    params
  });
}

export function getCourseUserListApi(params?: any) {
  return request({
    url: "/business/course/userList",
    method: "get",
    params
  });
}

export function getAllCourseListApi() {
  return request({
    url: "/business/course/allList",
    method: "get"
  });
}

/**
 * 新增教练信息
 */
export function insertCourseApi(data: any) {
  return request({
    url: `/business/course/insert`,
    method: "post",
    data
  });
}

/**
 * 修改教练信息
 */
export function updateCourseApi(data: any) {
  return request({
    url: `/business/course/update`,
    method: "post",
    data
  });
}

/**
 * 修改场馆状态
 */
export function changeCourseStatusApi(data: any) {
  return request({
    url: `/business/course/changeStatus`,
    method: "put",
    data
  });
}

export function copyCourseListApi(data: any) {
  return request({
    url: `/business/course/copyList`,
    method: "post",
    data
  });
}

export function deleteCourseListApi(ids: any) {
  return request({
    url: `/business/course/remove/${ids}`,
    method: "delete"
  });
}

export function getCourseDetailApi(id: any) {
  return request({
    url: `/business/course/detail/${id}`,
    method: "get"
  });
}

export function courseExportApi(data: any) {
  return request({
    url: `/business/course/export`,
    method: "post",
    responseType: "blob",
    data
  });
}
