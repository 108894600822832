import { useDesign } from "@/hooks/web/useDesign";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Tag } from "element-ui";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("tag");
@Component({
  name: "SlTag"
})
export default class SlTag extends mixins(Tag) {
  @Prop({
    type: [String, Number],
    default: "标签"
  })
  readonly value: any;

  get getProps() {
    const attrs = { ...this.$attrs, ...this.$props };
    return { ...attrs };
  }
  render() {
    return <Tag {...{ props: this.getProps }}>{this.value}</Tag>;
  }
}
