import { Component, Vue } from "vue-property-decorator";
import { SettingsModule } from "@/store/modules/settings";
import { SlThemePicker } from "@/components";
import { Switch } from "element-ui";
import "./index.scss";

@Component({
  name: "Settings"
})
export default class Settings extends Vue {
  get fixedHeader() {
    return SettingsModule.fixedHeader;
  }

  set fixedHeader(value) {
    SettingsModule.ChangeSetting({ key: "fixedHeader", value });
  }

  get showTagsView() {
    return SettingsModule.showTagsView;
  }

  set showTagsView(value) {
    SettingsModule.ChangeSetting({ key: "showTagsView", value });
  }

  get showSidebarLogo() {
    return SettingsModule.showSidebarLogo;
  }

  set showSidebarLogo(value) {
    SettingsModule.ChangeSetting({ key: "showSidebarLogo", value });
  }

  get sidebarTextTheme() {
    return SettingsModule.sidebarTextTheme;
  }

  set sidebarTextTheme(value) {
    SettingsModule.ChangeSetting({ key: "sidebarTextTheme", value });
  }

  private themeChange(value: string) {
    SettingsModule.ChangeSetting({ key: "theme", value });
  }

  render() {
    return (
      <div class="drawer-container">
        <div>
          <h3 class="drawer-title">系统布局配置</h3>

          <div class="drawer-item">
            <span>主题色</span>
            <SlThemePicker
              style="float: right; height: 26px; margin: -3px 8px 0 0"
              on-change={this.themeChange}
            />
          </div>

          <div class="drawer-item">
            显示Tags—View
            <Switch v-model={this.showTagsView} class="drawer-switch" />
          </div>

          <div class="drawer-item">
            显示侧边栏logo
            <Switch v-model={this.showSidebarLogo} class="drawer-switch" />
          </div>

          <div class="drawer-item">
            固定Header
            <Switch v-model={this.fixedHeader} class="drawer-switch" />
          </div>

          <div class="drawer-item">
            侧边栏文字主题色
            <Switch v-model={this.sidebarTextTheme} class="drawer-switch" />
          </div>
        </div>
      </div>
    );
  }
}
