export default {
  extension: {
    widgetLabel: {
      card: "卡片",
      alert: "提示"
    },

    setting: {
      cardFolded: "是否收起",
      cardShowFold: "显示折叠按钮",
      cardWidth: "卡片宽度",
      cardShadow: "显示阴影",

      alertTitle: "标题",
      alertType: "类型",
      description: "辅助性文字",
      closable: "是否可关闭",
      closeText: "关闭按钮文字",
      center: "文字居中",
      showIcon: "显示图标",
      effect: "显示效果"
    }
  }
};
