import { componentMap } from "@/components/SlForm/src/componentMap";
import {
  OptionGroup,
  Radio,
  RadioButton,
  Option,
  Checkbox,
  CheckboxGroup,
  CheckboxButton
} from "element-ui";
import { isBoolean, isObject } from "@/utils/is";
import { getSlot } from "@/utils/tsxHelper";
import { SlTable } from "@/components";
/**
 * 表格渲染方法
 * @param options 渲染时传入的属性，具体属性可查看TableProps
 */
export function useTable(options: TableProps) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const that: any = this as any;
  const h = that.$createElement;
  const className = options.class
    ? options.class
    : `${options.prefixCls ? options.prefixCls : "sl-content"}-table`;
  const headerData: any[] = options.tableHeader;
  const tableStyle: any = {};
  if (options.height) {
    tableStyle["height"] = options.height;
  }
  const scopedSlots: any = {
    default: (data: any) => {
      const Com = data.header.component
        ? componentMap[data.header.component]
        : null;

      const propStr: any = data.header.prop || data.header.field;
      const renderCom = () => {
        if (data.header.componentProps && data.header.componentProps.on) {
          const handles = Object.keys(data.header.componentProps.on);
          handles.forEach((handle: any) => {
            data.header.componentProps.on[handle] = (value: any) =>
              tableCallback({
                type: propStr,
                data: isObject(value)
                  ? { ...value, row: data.row, rowIndex: data.rowIndex }
                  : { value, row: data.row, rowIndex: data.rowIndex }
              });
          });
        }
        const slotsMap: any = {};
        if (
          !["SelectV2", "Cascader"].includes(
            data.header?.component as string
          ) &&
          data.header?.componentProps?.options
        ) {
          slotsMap.default = () => renderOptions(data.header);
        }
        const renderFormItemContentSlots = () => {
          const slots = Object.keys(slotsMap);
          if (!slots.length) return null;
          return slots.map((zItem: any) => {
            return <template slot={zItem} />;
          });
        };
        if (data.header?.component === "Progress") {
          data.header.componentProps["percentage"] = data.row[propStr];
        }
        if (["Image", "Avatar"].includes(data.header?.component)) {
          data.header.componentProps["src"] = data.row[propStr];
        }
        if (data.header?.component === "ButtonGroup") {
          data.header.componentProps["clickProps"] = {
            row: data.row
          };
        }
        return (
          <Com
            v-model={data.row[propStr]}
            {...{ props: data.header.componentProps }}
            on={{ ...data.header.componentProps?.on }}
            scopedSlots={slotsMap}
          >
            {renderFormItemContentSlots()}
          </Com>
        );
      };
      return options.slots && options.slots[propStr]
        ? getSlot(options.slots, propStr, {
            ...data,
            header: { ...data.header, prop: propStr }
          })
        : Com
        ? renderCom()
        : data.row[propStr];
    }
  };
  const parentDefaultStyle = {
    height: "100%"
  };
  const tableRef = options.ref ? options.ref : "tableRef";
  const parentRef: any = options.ref
    ? `${options.ref}Parent`
    : "tableRefParent";
  // 渲染options
  const renderOptions = (item: FormSchema) => {
    switch (item.component) {
      case "Select":
        return renderSelectOptions(item);
      case "Radio":
      case "RadioButton":
        return renderRadioOptions(item);
      case "CheckboxGroup":
      case "CheckboxButton":
        return renderCheckboxOptions(item);
      default:
        break;
    }
  };
  const renderCheckboxOptions = (item: FormSchema) => {
    // 如果有别名，就取别名
    const labelAlias = item?.componentProps?.optionsAlias?.labelField;
    const valueAlias = item?.componentProps?.optionsAlias?.valueField;
    const Com = item.component === "CheckboxGroup" ? Checkbox : CheckboxButton;
    return item?.componentProps?.options?.map((option) => {
      return (
        <Com label={option[labelAlias || "value"]}>
          {option[valueAlias || "label"]}
        </Com>
      );
    });
  };

  const renderRadioOptions = (item: FormSchema) => {
    // 如果有别名，就取别名
    const labelAlias = item?.componentProps?.optionsAlias?.labelField;
    const valueAlias = item?.componentProps?.optionsAlias?.valueField;
    const Com = item.component === "Radio" ? Radio : RadioButton;
    return item?.componentProps?.options?.map((option) => {
      return (
        <Com label={option[labelAlias || "value"]}>
          {option[valueAlias || "label"]}
        </Com>
      );
    });
  };

  const renderSelectOptions = (item: FormSchema) => {
    // 如果有别名，就取别名
    const labelAlias = item?.componentProps?.optionsAlias?.labelField;
    return item?.componentProps?.options?.map((option) => {
      if (option?.options?.length) {
        return (
          <OptionGroup label={option[labelAlias || "label"]}>
            {option?.options?.map((v) => {
              return renderSelectOptionItem(item, v);
            })}
          </OptionGroup>
        );
      } else {
        return renderSelectOptionItem(item, option);
      }
    });
  };

  const getTableHeight = (ref?: any) => {
    const parentRef: any = ref ? ref : "contentRef";
    const parent: any = that.$refs[parentRef];
    if (!parent) return "0px";
    let diffHeight: any = 0;
    const childNodes: any = parent ? parent.childNodes : [];
    console.log("开始", diffHeight);
    childNodes.forEach((node: any) => {
      if (!node.className.includes(className)) {
        const nodeComputedStyle: any = getComputedStyle(node, null);
        const domRealHeight =
          Number(nodeComputedStyle.height.replace("px", "")) +
          Number(nodeComputedStyle.marginBottom.replace("px", "")) +
          Number(nodeComputedStyle.marginTop.replace("px", ""));
        diffHeight += domRealHeight;
        console.log(node.className, domRealHeight, node);
      }
    });
    console.log("结束", diffHeight);
    const height = `calc(100% - ${diffHeight}px)`;
    console.log(height);
    return height;
  };

  const renderSelectOptionItem = (
    item: FormSchema,
    option: ComponentOptions
  ) => {
    // 如果有别名，就取别名
    const labelAlias = item?.componentProps?.optionsAlias?.labelField;
    const valueAlias = item?.componentProps?.optionsAlias?.valueField;
    const attrList = {
      label: option[labelAlias || "label"],
      value: option[valueAlias || "value"],
      key: option[valueAlias || "value"]
    };
    return (
      <Option {...{ attrs: attrList }}>
        {() => {
          item?.componentProps?.optionsSlot
            ? getSlot(options.slots, `${item.field}-option`, {
                item: option
              })
            : undefined;
        }}
      </Option>
    );
  };
  const tableCallback: Function = options.callback
    ? options.callback
    : that.onTableCallback;
  const objectSpanMethod = (optionValue: any) => {
    const { row, column, rowIndex, columnIndex, headerData, item } =
      optionValue;
    if (!options.spanMethods) return;
    const spanMethods: any = options.spanMethods;
    for (let i = 0; i < spanMethods.length; i++) {
      //划分出需合并的每一个区域（spanArr[i]）
      if (
        columnIndex >= spanMethods[i].columnIndex &&
        columnIndex <=
          spanMethods[i].columnIndex + spanMethods[i].colspan - 1 &&
        rowIndex >= spanMethods[i].rowIndex &&
        rowIndex <= spanMethods[i].rowIndex + spanMethods[i].rowspan - 1
      ) {
        // 保留展示的单元格，合并单元格都为向右与向下延伸
        if (
          columnIndex === spanMethods[i].columnIndex &&
          rowIndex === spanMethods[i].rowIndex
        ) {
          return {
            rowspan: spanMethods[i].rowspan,
            colspan: spanMethods[i].colspan
          };
        } else {
          //删除冗余单元格
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    }
  };
  return (
    <div
      v-loading={options.isLoading}
      class={className}
      style={{
        ...parentDefaultStyle,
        ...options.style
      }}
      ref={parentRef}
    >
      <SlTable
        tableData={options.tableData}
        tableHeader={options.tableHeader}
        tableProps={{
          ...options.tableProps,
          "span-method": (optionValue: any) =>
            objectSpanMethod(
              Object.assign(optionValue, { headerData, options })
            )
        }}
        ref={tableRef}
        isPagination={options.isPagination}
        isShowCheck={options.isShowCheck}
        paginationLayout={options.paginationLayout}
        queryList={options.queryList}
        rowKey={options.rowKey}
        total={options.total}
        on-callback={tableCallback}
        on-selection-change={(data: any) =>
          tableCallback({ type: "checkbox", data })
        }
        on-row-click={(row: any, column: any) =>
          tableCallback({ type: "rowClick", data: { row, column } })
        }
        scopedSlots={scopedSlots}
        disabled={options.disabled}
        style={{ ...tableStyle }}
      />
    </div>
  );
}
