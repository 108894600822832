import type { Component } from "vue";
import {
  Cascader,
  CheckboxGroup,
  ColorPicker,
  DatePicker,
  Input,
  InputNumber,
  RadioGroup,
  Rate,
  Select,
  Slider,
  Switch,
  TimePicker,
  TimeSelect,
  Transfer,
  Autocomplete,
  Divider,
  Checkbox,
  Progress,
  Avatar,
  Image
} from "element-ui";

import { SlInputPassword } from "@/components/SlInputPassword";
import { SlUpload } from "@/components/SlUpload";
import { SlTreeSelect } from "@/components/SlTreeSelect";
import { SlEditor } from "@/components/SlEditor";
import { SlWangEditor } from "@/components/SlWangEditor";
import { SlTree } from "@/components/SlTree";
import { SlButtonGroup } from "@/components/SlButtonGroup";
import { SlTag } from "@/components/SlTag";
import { SlTable } from "@/components/SlTable";
import { SlTabs } from "@/components/SlTabs";
import { SlTimeSelect } from "@/components/SlTimeSelect";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const componentMap: Recordable<Component, ComponentName> = {
  Radio: RadioGroup,
  Checkbox: Checkbox,
  CheckboxGroup: CheckboxGroup,
  CheckboxButton: CheckboxGroup,
  Input: Input,
  Autocomplete: Autocomplete,
  InputNumber: InputNumber,
  Select: Select,
  Cascader: Cascader,
  Switch: Switch,
  Slider: Slider,
  Upload: SlUpload,
  TimePicker: TimePicker,
  DatePicker: DatePicker,
  Rate: Rate,
  ColorPicker: ColorPicker,
  Transfer: Transfer,
  Divider: Divider,
  Editor: SlEditor,
  WangEditor: SlWangEditor,
  TimeSelect: SlTimeSelect,
  RadioButton: RadioGroup,
  InputPassword: SlInputPassword,
  TreeSelect: SlTreeSelect,
  Tree: SlTree,
  Table: SlTable,
  Tabs: SlTabs,
  ButtonGroup: SlButtonGroup,
  Tag: SlTag,
  Progress: Progress,
  Avatar: Avatar,
  Image: Image
};

export { componentMap };
