import request from "@/utils/request";
import ipList from "@/utils/ipConfig";

export function getRoleListApi(params?: any) {
  return request({
    url: ipList.system.role.list,
    method: "get",
    params
  });
}

export function getAllRoleListApi() {
  return request({
    url: ipList.system.role.allList,
    method: "get"
  });
}

export function getRoleMenuListApi(id: any) {
  return request({
    url: `${ipList.system.role.roleMenu}/${id}`,
    method: "get"
  });
}

export function inertRoleApi(data: any) {
  return request({
    url: ipList.system.role.insert,
    method: "post",
    data
  });
}

export function updateRoleApi(data: any) {
  return request({
    url: `${ipList.system.role.update}`,
    method: "put",
    data
  });
}

export function updateRoleMenuApi(params?: any) {
  return request({
    url: ipList.system.role.roleMenu,
    method: "post",
    params
  });
}

export function deleteRoleApi(id: any) {
  return request({
    url: `${ipList.system.role.delete}/${id}`,
    method: "delete"
  });
}
// 修改角色状态
export function changeStatusApi(data: any) {
  return request({
    url: `/system/role/changeStatus`,
    method: "put",
    data
  });
}

// 获取角色详情
export function getRoleDetail(id?: any) {
  return request({
    url: `/system/user/${id ? id : ""}`,
    method: "get"
  });
}

// 获取角色菜单信息
export function getRoleMenuTreeSelectApi(id?: any) {
  return request({
    url: `/system/menu/roleMenuTreeselect/${id ? id : ""}`,
    method: "get"
  });
}

// 获取角色数据权限信息
export function getRoleDeptTreeSelectApi(id?: any) {
  return request({
    url: `/system/role/deptTree/${id ? id : ""}`,
    method: "get"
  });
}

export function updateRoleDataScopeApi(data: any) {
  return request({
    url: `/system/role/dataScope`,
    method: "put",
    data
  });
}

// 角色导出
export function roleExportApi(data: any) {
  return request({
    url: `/system/role/export`,
    method: "post",
    responseType: "blob",
    data
  });
}
