import request from "@/utils/request";

export function getOrdersListApi(params?: any) {
  return request({
    url: "/business/courseOrders/list",
    method: "get",
    params
  });
}

export function cancelOrdersApi(params?: any) {
  return request({
    url: "/business/courseOrders/cancelOrders",
    method: "get",
    params
  });
}
