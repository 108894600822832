import { Vue, Component, Watch } from "vue-property-decorator";
import config from "@/config/config.json";
import {
  filterEmptyField,
  publicDealTableData,
  publicDelete,
  publicDownFile,
  replaceObjectKey
} from "@/utils";
import {
  Button,
  Dialog,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Input,
  Message,
  Switch,
  TabPane,
  Tabs,
  Tag,
  Tree
} from "element-ui";
import {
  getJobGropsApi,
  getJobStatusApi,
  getJobListApi,
  insetJobApi,
  updateJobApi,
  deleteJobApi,
  exportJobApi,
  runJobApi,
  changeStatusApi
} from "@/api/monitor/job";
import { useComProps } from "@/utils/useComProps";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";
import VCronTab from "../compoents/cronTab/index.vue";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("monitor-job-list");
@Component({
  name: "MonitorJobList"
})
export default class MonitorJobList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
    dialogFormRef: any;
  };
  private comJobProps: any = useComProps.call(this, { prefixCls });
  private dataStore: any = {
    isLoading: false,
    formList: {
      cronExpression: ""
    },
    showDialog: false,
    tempcronExpression: "",
    treeIsLoading: false,
    title: "定时任务列表",
    statusList: [],
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNum: 1
    },
    total: 0,
    tableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: []
  };
  // @Watch("dataStore.tempcronExpression", { immediate: true })
  // onChangetempcronExpression=debounce((val)=>{
  //   this.$nextTick(()=>{
  // this.$refs.dialogFormRef.setValues({ cronExpression: val})
  //   })
  // },200)
  private inputHandle(val) {
    this.$refs.dialogFormRef.setValues({ cronExpression: val });
  }
  private crontabFill(val) {
    this.dataStore.tempcronExpression = val;
    this.$refs.dialogFormRef.setValues({ cronExpression: val });
    this.dataStore.showDialog = false;
  }
  private closeDialuge(options: any) {
    this.dataStore.showDialog = false;
  }
  private handleShowCron(row: any) {
    // this.comJobProps.openDialogPage(this,{
    //   width: "720px",
    //   title: "Cron表达式生成器",
    //   showConfirmButton: true,
    //   showCancelButton:true,
    //   cancelButtonText:"重置",
    //   confirmButtonText: "确定",
    //   contentSlots:()=>{
    //     return <VCronTab  expression={row?.cronExpression||""} on-fill={this.crontabFill}></VCronTab>
    //   },
    //   onConfirm: (callback: any) => this.closeDialuge({callback})
    // })
    this.dataStore.showDialog = true;
  }
  private composeValue(val, row) {
    return {
      command: val,
      row: row
    };
  }
  private handleCommand(command: any) {
    switch (command.command) {
      case "0":
        //立即执行
        publicDelete({
          message: `是否执行一次任务编号【${command.row.jobId}】？`,
          title: "确认信息",
          callback: () => {
            runJobApi({
              jobId: command.row.jobId,
              jobGroup: command.row.jobGroup
            }).then((res: any) => {
              Message.success("操作成功");
              this.getTableData();
            });
          }
        });

        break;
      case "1":
        //任务详情
        this.comJobProps.openDialogPage(this, {
          width: "650px",
          title: "任务详情",
          showConfirmButton: false,
          cancelButtonText: "取消",
          formList: command.row,
          // schema: config.monitor.job.dialogDetailSchema,
          contentSlots: () => {
            return this.comJobProps.renderForm({
              ref: "dialogDetailFormRef",
              schema: config.monitor.job.dialogDetailSchema,
              formList: command.row,
              slots: {
                jobGroup: () => {
                  const items = config.monitor.job.filterSchema;
                  const bItem: any = items.find(
                    (ele: any) => ele.field === "jobGroup"
                  );
                  const sItem = bItem.componentProps.options.find(
                    (ele: any) => ele.value === command.row.jobGroup
                  );
                  return sItem.label;
                },
                status: () => {
                  const items = config.monitor.job.filterSchema;
                  const bItem: any = items.find(
                    (ele: any) => ele.field === "status"
                  );
                  const sItem = bItem.componentProps.options.find(
                    (ele: any) => ele.value === command.row.status
                  );
                  return sItem.label;
                },
                concurrent: () => {
                  const items = config.monitor.job.dialogDetailSchema;
                  const bItem: any = items.find(
                    (ele: any) => ele.field === "concurrent"
                  );
                  const sItem = bItem.componentProps.options.find(
                    (ele: any) => ele.value === command.row.concurrent
                  );
                  return sItem.label;
                },
                misfirePolicy: () => {
                  const items = config.monitor.job.dialogDetailSchema;
                  const bItem: any = items.find(
                    (ele: any) => ele.field === "misfirePolicy"
                  );
                  const sItem = bItem.componentProps.options.find(
                    (ele: any) => ele.value === command.row.misfirePolicy
                  );
                  return sItem.label;
                }
              }
            });
          },
          onCancel: (callback: any) => callback()
        });

        break;
      case "2":
        //调度日志
        this.$router.push({
          path: `job-log/index/?id=${command.row.jobId}`
        });
        break;
      default:
        break;
    }
  }
  private handleNodeClick(node: any) {}

  // 操作定时任务信息
  private handleJob(options: any) {
    const { callback, row, title } = options;
    const elRef: any = this.comJobProps.getFormElRef(this, "dialogFormRef");
    this.comJobProps.checkFormRequire(elRef, () => {
      const params: any = this.comJobProps.getFormValue(this, "dialogFormRef");
      const api: any = row ? updateJobApi : insetJobApi;
      if (row && row.jobId) {
        params["jobId"] = row.jobId;
      }
      api(title && row ? row : params).then((res: any) => {
        Message.success(
          `${row ? "编辑" : "添加"}定时任务${title ? title : ""}成功`
        );
        this.getTableData();
        callback && callback();
      });
    });
  }

  // 遍历结构树
  private deepDealTreeData(data: any[], parentId: any) {
    return data.map((ele: any) => {
      ele.label = ele.title;
      if (!ele.parentId && ele.parentId !== 0) {
        ele.parentId = parentId;
      }
      if (ele.children) {
        this.deepDealTreeData(ele.children, ele.id);
      }
      return ele;
    });
  }

  private deepScreenAllParent(data: any[], id: any, result: any[]) {
    if (!id) return;
    data.forEach((item, index) => {
      if (item.id === id) {
        if (!result.includes(item.id)) {
          result.unshift(item.id);
        }
        this.deepScreenAllParent(
          this.dataStore.treeData,
          item.parentId,
          result
        );
      } else {
        if (item.children) {
          this.deepScreenAllParent(item.children, id, result);
        }
      }
    });
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      catLogList: () => {
        this.$router.push({
          path: `job-log/index`
        });
      },
      export: () => {
        const params: any = this.getParamsList();
        const filterParams = filterEmptyField(params);
        // const filterParams = replaceObjectKey(
        //   [
        //     ["pageNum", "current"],
        //     ["pageSize", "size"]
        //   ],
        //   filterEmptyField(params)
        // );
        exportJobApi(filterParams).then((res) => {
          if (res) {
            publicDownFile(res, "定时任务列表");
            Message.success("导出成功！");
          }
        });
      },
      refresh: () => {
        this.comJobProps.clearFormList(this, "filterRef");
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      create: () => {
        this.comJobProps.openDialogPage(this, {
          width: "650px",
          title: "新增定时任务",
          confirmButtonText: "立即添加",
          contentSlots: () => {
            const slots: any = {
              "invokeTarget-label-suffix": () => {
                return this.comJobProps.renderIconToolTip({
                  content: `提示：Bean调用示例ryTask.ryParams('ry')Class类调用示例：com.ruoyi.quartz.task.RyTask.ryParams('ry')参数说明：支持字符串，布尔类型，长整型，浮点型，整型`,
                  icon: "el-icon-question"
                });
              }
            };
            return (
              <div class={"box-wrap"}>
                {this.comJobProps.renderForm({
                  ref: "dialogFormRef",
                  schema: config.monitor.job.dialogSchema,
                  formList: this.dataStore.formList,
                  slots: {
                    cronExpression: () => {
                      return (
                        <Input
                          placeholder="请输入cron执行表达式"
                          v-model={this.dataStore.tempcronExpression}
                          on-input={(val) => this.inputHandle(val)}
                        >
                          <div slot="append">
                            <Button
                              type="primary"
                              on-click={this.handleShowCron.bind(this, row)}
                            >
                              生成表达式
                              <i class="el-icon-time el-icon--right"></i>
                            </Button>
                          </div>
                        </Input>
                      );
                    },
                    ...slots
                  }
                })}
              </div>
            );
          },
          onConfirm: (callback: any) => this.handleJob({ callback })
        });
        this.$nextTick(() => {
          this.dataStore.tempcronExpression = "";
          // this.comJobProps.setFormSchema(this, "dialogRef", [
          //   {
          //     field: "status",
          //     path: "componentProps.options",
          //     value: this.dataStore.statusList
          //   }
          // ])
        });
      },
      deleteBatch: () => {
        if (this.dataStore.selectData.length) {
          const names: any[] = this.dataStore.selectData.map(
            (ele: any) => ele.name
          );
          // publicDelete({
          //   message: `是否删除定时任务【${names.join(",")}】？`,
          //   title: "确认信息",
          //   callback: () => {
          const ids: any = [];
          this.dataStore.selectData.forEach((ele: any) => {
            ids.push(ele.jobId);
          });
          //     this.deleteDataList(ids, names);
          //   }
          // });
          this.comJobProps.openDialogPage(this, {
            width: "586px",
            title: `删除`,
            titleSlots: {
              prefix: () => {
                return (
                  <i
                    class={"el-icon-warning"}
                    style={{
                      marginRight: "18px",
                      color: "#E34D59",
                      fontSize: "24px"
                    }}
                  ></i>
                );
              }
            },
            contentSlots: () => {
              return <div>{` 是否确认删除公告定时任务【${ids}】？`}</div>;
            },
            onConfirm: (callback) => {
              deleteJobApi(ids.join(",")).then((res: any) => {
                Message.success(`删除成功`);
                callback && callback();
                this.getTableData();
              });
            }
          });
        } else {
          Message.warning("请先选择要删除的数据");
        }
      },
      editRow: () => {
        this.comJobProps.openDialogPage(this, {
          width: "650px",
          title: "编辑定时任务",
          contentSlots: () => {
            const slots: any = {
              "invokeTarget-label-suffix": () => {
                return this.comJobProps.renderIconToolTip({
                  content: `提示：Bean调用示例ryTask.ryParams('ry')Class类调用示例：com.ruoyi.quartz.task.RyTask.ryParams('ry')参数说明：支持字符串，布尔类型，长整型，浮点型，整型`,
                  icon: "el-icon-question"
                });
              }
            };
            return (
              <div class={"box-wrap"}>
                {this.comJobProps.renderForm({
                  ref: "dialogFormRef",
                  schema: config.monitor.job.dialogSchema,
                  formList: row,
                  slots: {
                    cronExpression: () => {
                      return (
                        <Input
                          placeholder="请输入cron执行表达式"
                          v-model={this.dataStore.tempcronExpression}
                          on-input={(val) => this.inputHandle(val)}
                        >
                          <div slot="append">
                            <Button
                              type="primary"
                              on-click={this.handleShowCron.bind(this, row)}
                            >
                              生成表达式
                              <i class="el-icon-time el-icon--right"></i>
                            </Button>
                          </div>
                        </Input>
                      );
                    },
                    ...slots
                  }
                })}
              </div>
            );
          },
          onConfirm: (callback: any) => this.handleJob({ callback, row })
        });
        this.$nextTick(() => {
          this.dataStore.tempcronExpression = row.cronExpression;
          // this.comJobProps.setFormSchema(this, "dialogFormRef", [
          //   {
          //     field: "status",
          //     path: "componentProps.options",
          //     value: this.dataStore.statusList
          //   }
          // ]);
        });
      },
      deleteRow: () => {
        // publicDelete({
        //   message: `是否删除定时任务【${row.name}】？`,
        //   title: "确认信息",
        //   callback: () => {
        //     this.deleteDataList([row.id], [row.name]);
        //   }
        // });
        this.comJobProps.openDialogPage(this, {
          width: "586px",
          title: `删除`,
          titleSlots: {
            prefix: () => {
              return (
                <i
                  class={"el-icon-warning"}
                  style={{
                    marginRight: "18px",
                    color: "#E34D59",
                    fontSize: "24px"
                  }}
                ></i>
              );
            }
          },
          contentSlots: () => {
            return <div>{` 是否删除定时任务编号为【${row.jobId}】？`}</div>;
          },
          onConfirm: (callback) => {
            this.deleteDataList(row.jobId, callback);
          }
        });
      }
    };
    obj[item.prop] && obj[item.prop]();
  }
  private getParamsList() {
    const formList: any = this.comJobProps.getFormValue(this, "filterRef");
    const params: any = {
      ...formList,
      ...this.dataStore.queryList,
      "params[beginTime]": formList?.createTime?.[0],
      "params[endTime]": formList?.createTime?.[1]
    };
    delete params.createTime;
    return filterEmptyField(params);
  }
  // 删除数据
  private deleteDataList(id: any, callback) {
    const params: any = id;
    deleteJobApi(params).then(() => {
      Message.success(`删除定时任务【${id}】成功`);
      callback && callback();
      this.getTableData();
    });
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNum = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }
  private getJobGropsList() {
    getJobGropsApi().then((res: any) => {
      const data = res || [];
      this.dataStore.gropsList = data.map((ele: any) => {
        return {
          label: ele.dictLabel,
          value: ele.dictValue
        };
      });
      config.monitor.job.filterSchema[1].componentProps.options =
        this.dataStore.gropsList;
      this.$nextTick(() => {
        this.comJobProps.setFormSchema(this, "dialogRef", [
          {
            field: "jobGroup",
            path: "componentProps.options",
            value: this.dataStore.gropsList
          }
        ]);
        this.$forceUpdate();
      });
      this.$forceUpdate();
    });
  }
  private getStausList() {
    getJobStatusApi().then((res: any) => {
      const data = res || [];
      this.dataStore.statusList = data.map((ele: any) => {
        return {
          label: ele.dictLabel,
          value: ele.dictValue
        };
      });
      config.monitor.job.filterSchema[2].componentProps.options =
        this.dataStore.statusList;
      this.$nextTick(() => {
        this.comJobProps.setFormSchema(this, "dialogRef", [
          {
            field: "status",
            path: "componentProps.options",
            value: this.dataStore.statusList
          }
        ]);
        this.$forceUpdate();
      });
      this.$forceUpdate();
    });
  }
  private getTableData() {
    this.dataStore.isLoading = true;
    const filterList: any = this.comJobProps.getFormValue(this, "filterRef");
    const params: any = this.getParamsList();
    getJobListApi(params)
      .then((res: any) => {
        if (res) {
          this.dataStore.tableData = publicDealTableData(
            res.rows,
            this.dataStore.queryList,
            { current: "pageNum", size: "pageSize" }
          );
          this.dataStore.total = res.total;
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }
  private changeStatus(row: any) {
    publicDelete({
      message: `确认要"${row.status == "0" ? "启用" : "暂停"}""${
        row.jobName
      }"【${row.jobId}】？`,
      title: "确认信息",
      callback: () => {
        changeStatusApi({
          jobId: row.jobId,
          status: row.status //
        }).then((res: any) => {
          Message.success("操作成功");
          this.getTableData();
        });
      }
    });
    this.getTableData();
  }
  mounted() {
    this.comJobProps.dealContentTableHeight(this, ["header", "filter"]);
    this.getTableData();
    this.getStausList();
    this.getJobGropsList();
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        <Dialog
          title="Cron表达式生成器"
          visible={this.dataStore.showDialog}
          append-to-body
          destroy-on-close
          class="scrollbar"
          on-close={this.closeDialuge}
        >
          <VCronTab
            expression={this.dataStore.tempcronExpression}
            on-hide={this.closeDialuge}
            on-fill={this.crontabFill}
          ></VCronTab>
        </Dialog>
        <div class={`${prefixCls}-header`}>{this.dataStore.title}</div>
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.comJobProps.renderForm({
              ref: "filterRef",
              schema: config.monitor.job.filterSchema,
              slots: {
                handle: (data) => {
                  return (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {this.comJobProps.renderButtons({
                        buttons: data.componentProps.buttons
                      })}
                    </div>
                  );
                },
                tableHandle: (data) => {
                  return (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {this.comJobProps.renderButtons({
                        buttons: data.componentProps.buttons
                      })}
                    </div>
                  );
                }
              }
            })}
          </div>
          {this.comJobProps.renderTable({
            tableData: this.dataStore.tableData,
            tableHeader: config.monitor.job.tableHeader,
            isShowCheck: true,
            queryList: this.dataStore.queryList,
            total: this.dataStore.total,
            isLoading: this.dataStore.isLoading,
            paginationLayout: "sizes, prev, pager, next, slot, jumper",
            style: {
              height: this.dataStore.tableHeight
            },
            slots: {
              status: ({ row }) => {
                return (
                  <Switch
                    active-value={"0"}
                    inactive-value={"1"}
                    v-model={row.status}
                    on-change={this.changeStatus.bind(this, row)}
                  />
                );
              },
              jobGroup: ({ row }) => {
                const items = config.monitor.job.filterSchema;
                const bItem: any = items.find(
                  (ele: any) => ele.field === "jobGroup"
                );
                const sItem = bItem.componentProps.options.find(
                  (ele: any) => ele.value === row.jobGroup
                );
                return sItem.label;
              },
              handle: ({ row }) => {
                return (
                  <div style={{ display: "flex" }}>
                    {this.comJobProps.renderButtons({
                      buttons: config.monitor.job.tableHeaderButtons.buttons,
                      row: row
                    })}
                    <Dropdown
                      on-command={this.handleCommand}
                      style={{
                        lineHeight: "40px",
                        marginLeft: "10px",
                        color: "#1890ff"
                      }}
                    >
                      <span class="el-dropdown-link">
                        更多<i class="el-icon-d-arrow-right el-icon--left"></i>
                      </span>
                      <DropdownMenu slot="dropdown">
                        {config.monitor.job.tableHeaderButtons.dropdownitems.map(
                          (ele) => {
                            return (
                              <DropdownItem
                                command={this.composeValue(ele.value, row)}
                              >
                                {ele.name}
                              </DropdownItem>
                            );
                          }
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                );
              }
            },
            ref: "tableRef"
          })}
        </div>
      </div>
    );
  }
}
