import { useDesign } from "@/hooks/web/useDesign";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SvgIcon } from "@/components";
import { InputNumber, Option, Select } from "element-ui";
const prefixCls = useDesign().getPrefixCls("crontab-week");
@Component({
  name: "CrontabWeek"
})
export default class CrontabWeek extends Vue {
  @Prop({
    type: Function
  })
  readonly check: any;

  @Prop({
    type: Object
  })
  readonly cron: any;
  private weekList = [
    {
      key: 2,
      value: "星期一"
    },
    {
      key: 3,
      value: "星期二"
    },
    {
      key: 4,
      value: "星期三"
    },
    {
      key: 5,
      value: "星期四"
    },
    {
      key: 6,
      value: "星期五"
    },
    {
      key: 7,
      value: "星期六"
    },
    {
      key: 1,
      value: "星期日"
    }
  ];
  private radioValue: any = 2;
  private weekday: any = 2;
  private cycle01: any = 2;
  private cycle02: any = 3;
  private average01: any = 1;
  private average02: any = 2;
  private checkboxList: any = [];
  private checkNum: any = this.$options?.propsData?.["check"];
  @Watch("radioValue", { immediate: true })
  radioValueChange() {
    this.radioChange();
  }
  @Watch("cycleTotal", { immediate: true })
  cycleTotalChange() {
    this.cycleChange();
  }
  @Watch("averageTotal", { immediate: true })
  averageTotalChange() {
    this.averageChange();
  }
  @Watch("weekdayCheck", { immediate: true })
  weekdayCheckChange() {
    this.weekdayChange();
  }
  @Watch("checkboxString", { immediate: true })
  checkboxStringChange() {
    this.checkboxChange();
  }
  // 单选按钮值变化时
  private radioChange() {
    if (this.radioValue !== 2 && this.cron.day !== "?") {
      this.$emit("update", "day", "?", "week");
    }
    switch (this.radioValue) {
      case 1:
        this.$emit("update", "week", "*");
        break;
      case 2:
        this.$emit("update", "week", "?");
        break;
      case 3:
        this.$emit("update", "week", this.cycleTotal);
        break;
      case 4:
        this.$emit("update", "week", this.averageTotal);
        break;
      case 5:
        this.$emit("update", "week", this.weekdayCheck + "L");
        break;
      case 6:
        this.$emit("update", "week", this.checkboxString);
        break;
    }
  }
  // 周期两个值变化时
  private cycleChange() {
    if (this.radioValue == "3") {
      this.$emit("update", "week", this.cycleTotal);
    }
  }
  // 平均两个值变化时
  private averageChange() {
    if (this.radioValue == "4") {
      this.$emit("update", "week", this.averageTotal);
    }
  }
  // 最近工作日值变化时
  private weekdayChange() {
    if (this.radioValue == "5") {
      this.$emit("update", "week", this.weekday + "L");
    }
  }
  // checkbox值变化时
  private checkboxChange() {
    if (this.radioValue == "6") {
      this.$emit("update", "week", this.checkboxString);
    }
  }
  get cycleTotal() {
    this.cycle01 = this.checkNum(this.cycle01, 1, 7);
    this.cycle02 = this.checkNum(this.cycle02, 1, 7);
    return this.cycle01 + "-" + this.cycle02;
  }
  get averageTotal() {
    this.average01 = this.checkNum(this.average01, 1, 4);
    this.average02 = this.checkNum(this.average02, 1, 7);
    return this.average02 + "#" + this.average01;
  }
  get weekdayCheck() {
    this.weekday = this.checkNum(this.weekday, 1, 7);
    return this.weekday;
  }
  get checkboxString() {
    const str = this.checkboxList.join();
    return str == "" ? "*" : str;
  }
  render() {
    return (
      <div class={prefixCls}>
        <el-form size="small">
          <el-form-item>
            <el-radio v-model={this.radioValue} label={1}>
              周，允许的通配符[, - * ? / L #]
            </el-radio>
          </el-form-item>
          <el-form-item>
            <el-radio v-model={this.radioValue} label={2}>
              不指定
            </el-radio>
          </el-form-item>
          <el-form-item>
            <el-radio v-model={this.radioValue} label={3}>
              周期从星期
              <Select
                style={{ marginLeft: "8px" }}
                clearable
                v-model={this.cycle01}
              >
                {this.weekList.map((w: any, index: any) => {
                  return (
                    <Option
                      key={index}
                      value={w.key}
                      label={w.value}
                      disabled={w.key === 1}
                    >
                      {w.value}
                    </Option>
                  );
                })}
              </Select>
              -
              <Select
                style={{ marginLeft: "8px" }}
                clearable
                v-model={this.cycle02}
              >
                {this.weekList.map((w: any, index: any) => {
                  return (
                    <Option
                      key={index}
                      value={w.key}
                      label={w.value}
                      disabled={w.key < this.cycle01 && w.key !== 1}
                    >
                      {w.value}
                    </Option>
                  );
                })}
              </Select>
            </el-radio>
          </el-form-item>
          <el-form-item>
            <el-radio v-model={this.radioValue} label={4}>
              第
              <InputNumber
                v-model={this.average01}
                min={1}
                max={4}
              ></InputNumber>
              周的星期
              <Select
                style={{ marginLeft: "8px" }}
                clearable
                v-model={this.average02}
              >
                {this.weekList.map((w: any, index: any) => {
                  return (
                    <Option key={index} value={w.key} label={w.value}>
                      {w.value}
                    </Option>
                  );
                })}
              </Select>
            </el-radio>
          </el-form-item>
          <el-form-item>
            <el-radio v-model={this.radioValue} label={5}>
              本月最后一个星期
              <Select
                style={{ marginLeft: "8px" }}
                clearable
                v-model={this.weekday}
              >
                {this.weekList.map((w: any, index: any) => {
                  return (
                    <Option key={index} value={w.key} label={w.value}>
                      {w.value}
                    </Option>
                  );
                })}
              </Select>
            </el-radio>
          </el-form-item>
          <el-form-item>
            <el-radio v-model={this.radioValue} label={6}>
              指定
              <Select
                multiple={true}
                style={{ width: "100%", marginLeft: "8px" }}
                placeholder="可多选"
                v-model={this.checkboxList}
              >
                {this.weekList.map((e: any, index) => {
                  return (
                    <Option key={index} value={e.key} label={e.value}>
                      {e.value}
                    </Option>
                  );
                })}
              </Select>
            </el-radio>
          </el-form-item>
        </el-form>
      </div>
    );
  }
}
