import { useDesign } from "@/hooks/web/useDesign";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Tree, Checkbox } from "element-ui";

import "./index.scss";
import { isString } from "@/utils/is";
const { getPrefixCls } = useDesign();

const prefixCls = getPrefixCls("tree-wrapper");
@Component({
  name: "SlTree"
})
export default class SlTree extends Vue {
  $refs!: {
    treeRef: any;
  };
  @Prop({
    type: [String, Number, Array, Object]
  })
  value: any;
  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  treeProps: any;
  @Prop({
    type: Boolean,
    default: true
  })
  showTool: any;
  @Prop({
    type: [Number, String],
    default: "400px"
  })
  height: any;
  private dataStore: any = {
    expanded: true,
    allCheck: false,
    checkStrictly: true,
    treeProps: {},
    defaultCheckedKeys: [],
    checkNodeKeys: []
  };

  @Emit("input")
  onInput(item: any) {
    return item;
  }

  @Watch("dataStore.expanded")
  onChangeExpanded(val: any) {
    const nodes: any = this.$refs.treeRef.store.nodesMap;
    for (const i in nodes) {
      nodes[i].expanded = val;
    }
  }

  @Watch("dataStore.allCheck")
  onChangeCheck(val: any) {
    const nodes: any = this.$refs.treeRef.store.nodesMap;
    for (const i in nodes) {
      nodes[i].checked = val;
    }
    this.dealInputList();
  }
  get getTreeProps() {
    const attrs: any = this.$attrs;
    return {
      defaultExpandAll: this.dataStore.expanded,
      "check-strictly": !this.dataStore.checkStrictly,
      "expand-on-click-node": false,
      "highlight-current": true,
      ...attrs.treeProps,
      ...this.$props.treeProps
    };
  }

  private setValue(val: any) {
    if (this.showTool) {
      this.dataStore.checkStrictly = val.checkStrictly;
    }
    this.$nextTick(() => {
      this.$refs.treeRef.setCheckedKeys(this.showTool ? val.keys : val);
    });
  }

  private onChange(value: any, type: string) {
    this.dataStore[type] = value;
    if (type === "checkStrictly") {
      this.dealInputList();
    }
  }

  private onNodeClick(node: any) {
    this.$emit("node-click", node);
  }

  private onCheckChange(data: any, options: any) {
    this.dealInputList();
  }

  // 统一处理双向绑定数据
  private dealInputList() {
    const checkedKeys = this.$refs.treeRef.getCheckedKeys();
    const halfCheckedKeys = this.$refs.treeRef.getHalfCheckedKeys();
    const keys = this.dataStore.checkStrictly
      ? [...checkedKeys, ...halfCheckedKeys]
      : [...checkedKeys];
    const params: any = this.showTool
      ? {
          checkStrictly: this.dataStore.checkStrictly,
          keys: keys
        }
      : keys;
    this.onInput(params);
  }

  private mounted() {
    if (this.value) {
      this.setValue(this.value);
    }
  }
  render() {
    const renderTool = () => {
      return (
        <div class={`${prefixCls}-header`}>
          <Checkbox
            v-model={this.dataStore.expanded}
            onChange={(value: any) => this.onChange(value, "expanded")}
          >
            展开/折叠
          </Checkbox>
          <Checkbox
            v-model={this.dataStore.allCheck}
            onChange={(value: any) => this.onChange(value, "allCheck")}
          >
            全选/全不选
          </Checkbox>
          <Checkbox
            v-model={this.dataStore.checkStrictly}
            onChange={(value: any) => this.onChange(value, "checkStrictly")}
          >
            父子联动
          </Checkbox>
        </div>
      );
    };
    return (
      <div class={prefixCls}>
        {this.showTool ? renderTool() : null}
        <div
          class={`${prefixCls}-content`}
          style={{
            height: this.height
              ? isString(this.height)
                ? this.height
                : `${this.height}px`
              : "400px"
          }}
        >
          <Tree
            ref={"treeRef"}
            {...{ props: this.getTreeProps }}
            on={{
              "node-click": this.onNodeClick,
              "check-change": this.onCheckChange
            }}
            class={`${prefixCls}-content`}
          ></Tree>
        </div>
      </div>
    );
  }
}
