import { Component, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/modules/app";
import { UserModule } from "@/store/modules/user";
import { Dropdown, DropdownMenu, DropdownItem, Tooltip } from "element-ui";
import {
  SlBreadcrumb,
  SlErrorLog,
  SlSizeSelect,
  SlHamburger
} from "@/components";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";

const prefixCls = useDesign().getPrefixCls("navbar");
@Component({
  name: "Navbar"
})
export default class Navbar extends Vue {
  private userInfo: any = undefined;
  private username = localStorage.getItem("username") || "";
  private created() {
    this.userInfo = this.$store.state.user.userInfo;
  }
  get sidebar() {
    return AppModule.sidebar;
  }

  get device() {
    return AppModule.device.toString();
  }

  get avatar() {
    return UserModule.avatar;
  }

  private toggleSideBar() {
    AppModule.ToggleSideBar(false);
  }

  private async logout() {
    await UserModule.LogOut();
    this.$router.push(`/login`).catch((err) => {
      console.warn(err);
    });
  }
  render() {
    const renderMobile = () => {
      return this.device === "mobile" ? (
        <div>
          <SlErrorLog class="errLog-container right-menu-item hover-effect" />
          <Tooltip content="布局大小" effect="dark" placement="bottom">
            <SlSizeSelect class="right-menu-item hover-effect" />
          </Tooltip>
        </div>
      ) : null;
    };
    return (
      <div class={`${prefixCls}`}>
        <SlHamburger
          is-active={this.sidebar.opened}
          on-toggle-click={this.toggleSideBar}
        ></SlHamburger>
        <SlBreadcrumb id="breadcrumb-container" class="breadcrumb-container" />
        <div class="right-menu">
          {renderMobile()}
          <Dropdown
            class="avatar-container right-menu-item hover-effect"
            trigger="click"
          >
            <div class="avatar-wrapper">
              <img
                src={require("@/assets/images/avator-boy.png")}
                class="user-avatar"
              />
              <div class="avatar-wrapper-user">
                <span class="avatar-wrapper-user-name">
                  {this.userInfo.nickName}
                </span>
                <span
                  class={["avatar-wrapper-user-username"]}
                  style="color: #222"
                >
                  {this.username}
                </span>
              </div>
              <i class="el-icon-caret-bottom" />
            </div>
            <DropdownMenu slot="dropdown">
              <router-link to="/">
                <DropdownItem> 首页 </DropdownItem>
              </router-link>
              <DropdownItem divided>
                <span style="display: block" on-click={this.logout}>
                  退出登录
                </span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    );
  }
}
