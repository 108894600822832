import { DatePicker, Input, Option, Select } from "element-ui";
import { SlButton } from "@/components";

export const useTableHandle = (options: any) => {
  const { prefixCls, vm } = options;
  const h = vm.$createElement;
  const renderButton = (options?: any) => {
    const { status, row, buttons, header, data } = options;
    if (!buttons || !buttons.length) return null;
    return buttons.map((item: any, index: number) => {
      return (item.screen && item.screen.includes(status)) || !item.screen ? (
        <SlButton
          key={index}
          type={item.type}
          icon={item.icon}
          prefixIcon={item.prefixIcon}
          suffixIcon={item.suffixIcon}
          plain={item.plain}
          style={item.style}
          title={item.label}
          on-click={vm.onClickMethod.bind(vm, {
            item,
            row,
            header,
            data
          })}
        ></SlButton>
      ) : null;
    });
  };
  const renderInput = (item: any, data: any) => {
    const key: any = item.row[item.header.prop];
    const placeholder: any =
      item.row[key] && item.row[key].placeholder
        ? item.row[key].placeholder
        : `请输入${item.header.label}`;
    return (
      <Input
        v-model={item.row[item.header.prop]}
        placeholder={placeholder}
        on-input={(value: any) => vm.onChangeInput({ value, item, key })}
        {...{ attrs: item.row[key]?.componentProps }}
      />
    );
  };
  const renderOption = (options: any) => {
    return options?.map((item: any) => {
      return <Option label={item.label} value={item.value} />;
    });
  };
  const renderSelect = (item: any, data: any) => {
    const key: any = item.row[item.header.prop];
    const placeholder: any =
      item.row[item.row[item.header.prop]] &&
      item.row[item.row[item.header.prop]].placeholder
        ? item.row[item.row[item.header.prop]].placeholder
        : `请输入${item.header.label}`;
    const formList: any = data.handleData ? item.row : vm.dataStore.formList;
    const keyValue: any = data.handleData ? item.header.prop : key;
    return (
      <Select
        v-model={formList[keyValue]}
        placeholder={placeholder}
        on-change={(value: any) => vm.onChangeSelect({ value, item })}
      >
        {renderOption(
          vm.optionList[item.row[item.header.prop]]
            ? vm.optionList[item.row[item.header.prop]]
            : item.header.componentProps.options
        )}
      </Select>
    );
  };
  const renderDatePicker = (item: any, data: any) => {
    const formList: any = data.handleData ? item.row : vm.dataStore.formList;
    const keyValue: any = item.header.prop;
    return (
      <DatePicker
        v-model={formList[keyValue]}
        on-change={vm.onChangeDate}
        value-format="yyyy-MM-dd"
        picker-options={
          item.header.componentProps && item.header.componentProps.pickerOptions
            ? vm.pickerOptions
            : {}
        }
        {...item.header.componentProps}
        type="date"
        placeholder="选择日期"
      />
    );
  };
  const renderHandle = (item: any, data: any) => {
    return renderButton({
      buttons: item.header.buttons,
      row: item.row,
      header: item.header,
      data: data,
      status: item.row.status
    });
  };
  return {
    renderButton,
    renderInput,
    renderSelect,
    renderDatePicker,
    renderHandle
  };
};
