import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { useDesign } from "@/hooks/web/useDesign";
import { TimeSelect } from "element-ui";
import { isArray } from "@/utils/is";
import "./index.scss";
const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("time-select");
@Component({
  name: "SlTimeSelect"
})
export default class SlTimeSelect extends Vue {
  @Prop({
    type: [String, Number, Array]
  })
  value: any;
  @Prop({
    type: Boolean,
    default: false
  })
  readonly isRange: any;

  @Prop({
    type: Object,
    default: () => {
      return {
        start: {},
        end: {}
      };
    }
  })
  readonly timeProps: any;

  @Watch("value", { immediate: true })
  onChangeModel(value: any) {
    if (value) {
      if (this.isRange) {
        const values = value.split(",");
        this.dataStore.timeList.start = values[0];
        this.dataStore.timeList.end = values[1];
      } else {
        this.dataStore.timeList.start = value;
      }
    }
  }

  @Emit("input")
  onChangeModelValue(val: any) {
    return val;
  }

  @Emit("change")
  onChangeValue(val: any) {
    return val;
  }

  private dataStore: any = {
    timeList: {
      start: "",
      end: ""
    }
  };

  get getStartTimeProps(): any {
    const timeProps = { ...this.timeProps.start };
    const optionsStr: any = "picker-options" || "pickerOptions";
    delete timeProps[optionsStr];
    return timeProps;
  }

  get getEndTimeProps(): any {
    const timeProps = { ...this.timeProps.end };
    const optionsStr: any = "picker-options" || "pickerOptions";
    delete timeProps[optionsStr];
    return timeProps;
  }

  private getValues(value: any) {
    const obj = {
      start: "",
      end: ""
    };
    if (this.isRange) {
      const values = value.split(",");
      obj.start = values[0];
      obj.end = values[1];
    } else {
      obj.start = value;
    }
    return obj;
  }

  private onChangeMethod() {
    const result: any = this.isRange
      ? [this.dataStore.timeList.start, this.dataStore.timeList.end].join(",")
      : this.dataStore.timeList.start;
    console.log(this.dataStore.timeList.end, "ppppppp");
    this.onChangeModelValue(result);
    this.onChangeValue(result);
  }

  render() {
    return (
      <div class={prefixCls}>
        <TimeSelect
          v-model={this.dataStore.timeList.start}
          {...{ props: this.getStartTimeProps }}
          picker-options={{
            ...this.timeProps.start["picker-options" || "pickerOptions"],
            maxTime: this.getValues(this.value).end
          }}
          on-change={() => this.onChangeMethod()}
        ></TimeSelect>
        {this.isRange ? <div class={`${prefixCls}-separator`}>-</div> : null}
        {this.isRange ? (
          <TimeSelect
            v-model={this.dataStore.timeList.end}
            {...{ props: this.getEndTimeProps }}
            picker-options={{
              ...this.timeProps.end["picker-options" || "pickerOptions"],
              minTime: this.getValues(this.value).start
            }}
            on-change={() => this.onChangeMethod()}
          ></TimeSelect>
        ) : null}
      </div>
    );
  }
}
