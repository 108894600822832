import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { useDesign } from "@/hooks/web/useDesign";
import { Upload, Message, Image, Loading } from "element-ui";
import { uploadFileApi } from "@/api/upload";
import { getImageInfo } from "@/utils";
import "./index.scss";
import { isArray, isString } from "@/utils/is";
import { SvgIcon } from "@/components";

const { getPrefixCls } = useDesign();

const prefixCls = getPrefixCls("upload-card");
@Component({
  name: "SlUpload"
})
export default class SlUpload extends Vue {
  $refs!: {
    uploadRef: any;
  };
  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  readonly resultProps: any;

  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  readonly httpParams: any;
  private dataStore: any = {
    dialogImageUrl: "",
    dialogVisible: false,
    disabled: false,
    fileList: [],
    uploadList: [],
    timer: null,
    uploadLoading: false,
    resultFiles: []
  };

  @Emit("input")
  onInput(item: any) {
    return item;
  }

  @Watch("dataStore.resultFiles", { immediate: true })
  onChangeModel(val: any) {
    this.onInput(val ? val.join(",") : "");
  }

  get getProps(): any {
    const whiteArr: any[] = ["strength", "model"];
    const obj: any = {};
    for (const key in this.$props) {
      if (!whiteArr.includes(key)) {
        obj[key] = this.$props[key];
      }
    }
    const params: any = Object.assign(this.$attrs, obj);
    // if (params.action && !params.action.includes("http")) {
    //   params.action = `${process.env.VUE_APP_BASE_API}${params.action}`;
    // }
    if (params.value) {
      this.dealFileList(params.value);
    }
    // if (!params['auto-upload']) {
    //   params['http-request'] = this.submitUpload
    // }
    params["on-change"] = (file: any, fileList: any) =>
      this.handleChangeFile({ file, fileList });
    params["on-success"] = (response: any, file: any, fileList: any) =>
      this.handleSuccess({ response, file, fileList });
    return params;
  }

  // 过滤重复提交文件
  private filterRepetition(arr: any[]) {
    const names: any[] = [];
    const newArr: any[] = [];
    for (const item of arr) {
      if (!names.includes(item.name)) {
        names.push(item.name);
        newArr.push(item);
      }
    }
    return newArr;
  }
  // 防抖
  private debounceMethod(fn: Function, waits: number) {
    if (this.dataStore.timer) {
      clearTimeout(this.dataStore.timer);
      this.dataStore.timer = null;
    }
    this.dataStore.timer = setTimeout(() => {
      // eslint-disable-next-line prefer-rest-params
      fn.apply(this, arguments);
    }, waits);
  }
  // 获取选择的图片
  private handleChangeFile(options: any) {
    const { file, fileList } = options;
    const { limit } = this.getProps;
    if (limit && this.dataStore.fileList.length === limit) {
      this.$refs.uploadRef.clearFiles();
      return Message.error("超出上传数量");
    }
    const fArr: any[] = this.filterRepetition(fileList);
    this.dataStore.uploadList = fArr;
    if (!this.getProps["auto-upload"]) {
      this.submitUpload();
      // this.debounceMethod(this.submitUpload, 500);
    }
  }

  // 自定义上传,覆盖自动上传
  private submitUpload() {
    if (!this.dataStore.uploadList.length)
      return Message.error("请选择图片上传");
    if (this.dataStore.uploadLoading) return Message.error("正在上传请稍后");
    const loadingInstance = Loading.service({
      body: true,
      text: "正在上传请稍后",
      lock: true
    });
    this.dataStore.uploadLoading = true;
    const formData = new FormData();
    this.dataStore.uploadList.forEach((file: any) => {
      formData.append(
        this.getProps.name ? this.getProps.name : "file",
        file.raw
      );
    });
    const httpParamsArr = Object.keys(this.httpParams);
    if (httpParamsArr.length) {
      httpParamsArr.forEach((ele) => {
        formData.append(ele, this.httpParams[ele]);
      });
    }
    uploadFileApi(formData, this.getProps.action)
      .then((res: any) => {
        if (res) {
          this.dealResultFiles(
            isArray(res)
              ? res
              : isString(res)
              ? res.split(",")
              : [res[this.resultProps.url]]
          );
        }
        this.clearFileList();
        this.dataStore.uploadLoading = false;
        loadingInstance.close();
      })
      .catch(() => {
        this.dataStore.uploadLoading = false;
        this.clearFileList();
        loadingInstance.close();
      });
  }

  // 清空数据
  private clearFileList() {
    if (this.$refs.uploadRef) {
      this.$refs.uploadRef.clearFiles();
    }
  }
  // 处理结果数据
  private dealResultFiles(urls: any[]) {
    urls.forEach((url: any) => {
      if (!this.dataStore.resultFiles.includes(url)) {
        this.dataStore.resultFiles.push(url);
      }
    });
    this.onCallback();
  }

  // 触发change
  private onCallback() {
    this.$emit("change", this.dataStore.resultFiles);
  }
  // 处理图片地址数据
  private dealFileList(url: any) {
    const urls: any[] = isArray(url) ? url : url.split(",");
    if (this.dataStore.fileList.length === urls.length) return;
    this.dataStore.resultFiles = JSON.parse(JSON.stringify(urls));
    urls.forEach((ele: any) => {
      const isExist: any = this.dataStore.fileList.find((zle: any) =>
        zle.url.includes(ele)
      );
      if (!isExist) {
        this.dataStore.fileList.push({
          url: `${
            ele.includes(process.env.VUE_APP_BASE_API)
              ? ""
              : process.env.VUE_APP_BASE_API
          }${process.env.VUE_APP_IMAGE_SUFFIX}${
            ele.slice(0, 1) === "/" ? "" : "/"
          }${ele}`
        });
      }
    });
  }

  private handleSuccess(options: any) {
    const { response, file, fileList } = options;
  }

  private async handlePictureCardPreview(file: any) {
    const imageInfo: any = await getImageInfo(file.url);
    this.$myMessageBox({
      top: "50px",
      width: `${imageInfo.width + 40}px`,
      showConfirmButton: false,
      slots: {
        title: () => {
          return "图片预览";
        },
        content: () => {
          return (
            <div style={{ display: "flex" }}>
              <img
                style={{ width: "auto", height: "100%" }}
                src={file.url}
                alt=""
              />
            </div>
          );
        }
      }
    });
  }
  private handleDownload(file: any) {}
  private handleRemove(file: any) {}

  private deleteItem(value: any) {
    const rIndex = this.dataStore.resultFiles.find(
      (ele: any) =>
        `${
          value.includes(process.env.VUE_APP_BASE_API)
            ? ""
            : process.env.VUE_APP_BASE_API
        }${process.env.VUE_APP_IMAGE_SUFFIX}/${ele}` === value
    );
    const fIndex: any = this.dataStore.fileList.findIndex(
      (ele) => ele.url === value
    );
    this.dataStore.resultFiles.splice(rIndex, 1);
    this.dataStore.fileList.splice(fIndex, 1);
  }

  private onClickDel(item: any) {
    this.deleteItem(item.url);
  }
  mounted() {}

  render() {
    const scopedSlots: any = {
      default: () => {
        return !this.getProps.drag ? (
          <i class="el-icon-plus"></i>
        ) : (
          <div>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </div>
        );
      },
      file: ({ file }: any) => {
        return (
          <div class={`${prefixCls}-content`}>
            <img class="el-upload-list__item-thumbnail" src={file.url} alt="" />
            <span
              style={{ width: "80px", height: "80px" }}
              class="el-upload-list__item-actions"
            >
              <span
                class="el-upload-list__item-preview"
                on-click={this.handlePictureCardPreview.bind(this, file)}
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              {/*{!this.dataStore.disabled ? (*/}
              {/*  <span*/}
              {/*    class="el-upload-list__item-delete"*/}
              {/*    on-click={this.handleDownload.bind(this, file)}*/}
              {/*  >*/}
              {/*    <i class="el-icon-download"></i>*/}
              {/*  </span>*/}
              {/*) : null}*/}
              {!this.dataStore.disabled ? (
                <span
                  class="el-upload-list__item-delete"
                  on-click={this.handleRemove.bind(this, file)}
                >
                  <i class="el-icon-delete"></i>
                </span>
              ) : null}
            </span>
          </div>
        );
      }
    };
    const renderImageList = () => {
      return this.dataStore.fileList.map((file: any, index: number) => {
        return (
          <div
            class={`${prefixCls}-item`}
            style={{
              marginRight: "10px",
              height: "80px",
              borderRadius: "0.375rem"
            }}
          >
            <Image
              style="width: 80px; height: 80px"
              fit={"cover"}
              src={file.url}
              preview-src-list={this.dataStore.fileList.map(
                (ele: any) => ele.url
              )}
            ></Image>
            <SvgIcon
              iconClass={"el-icon-error"}
              onClick={this.onClickDel.bind(this, file)}
            ></SvgIcon>
          </div>
        );
      });
    };
    const renderFileList = () => {
      return (
        <ul class={`${prefixCls}-list`}>
          {this.dataStore.fileList.map((ele: any) => {
            const arr = ele.url.split("/");
            return (
              <li class={`${prefixCls}-list-item`}>
                <div class={"name"}>
                  <i class={"el-icon-document"}></i>
                  {arr[arr.length - 1]}
                </div>
                <i
                  class="el-icon-close"
                  onClick={this.onClickDel.bind(this, ele)}
                ></i>
                {/*<i class="el-icon-close-tip">按 delete 键可删除</i>*/}
              </li>
            );
          })}
        </ul>
      );
    };
    return (
      <div class={prefixCls}>
        {this.dataStore.fileList.length && !this.getProps["show-file-list"]
          ? renderImageList()
          : null}
        <Upload
          class={`${prefixCls}-upload`}
          {...{ props: this.getProps }}
          disabled={
            this.getProps.limit &&
            this.getProps.limit <= this.dataStore.fileList.length
          }
          ref={"uploadRef"}
          scopedSlots={scopedSlots}
        >
          {scopedSlots.default()}
          {this.getProps.tip ? (
            <div slot={"tip"}>{this.getProps.tip}</div>
          ) : null}
        </Upload>
        {this.getProps.drag && this.getProps["show-file-list"]
          ? renderFileList()
          : null}
      </div>
    );
  }
}
