import CryptoJS from "crypto-js";

const CryptoJs: any = require("crypto-js");
const defaultKey = "zWGt1Pft4kx1X2gL"; // 默认的key
const defaultIv = "vw3Io3HRa7vlgsBG"; // 默认的key 偏移量
const mode = "CBC";
const pad = "Pkcs7";
/**
 * 加密方法
 * @param: str 需要加密的字符
 * @param: key 密钥
 * @param: iv 密钥偏移量
 */
function encrypt(str: string, key?: string, iv?: string) {
  const keyStr = key ? encParse(key) : encParse(defaultKey);
  const ivStr = iv ? encParse(iv) : encParse(defaultIv);

  const encryptedStr = CryptoJs.AES.encrypt(str, keyStr, {
    iv: ivStr,
    mode: CryptoJs.mode[mode],
    padding: CryptoJs.pad[pad]
  });

  // 直接toString()是base64格式的字符串
  // ciphertext.toString() 是128位的字符串
  return encryptedStr.toString();
}
/**
 * 解密方法
 * @param str
 * @param key
 * @param iv
 */
function decrypt(str: string, key?: string, iv?: string) {
  str = (str + "").replace(/\n*$/g, "").replace(/\n/g, "");
  const keyStr = key ? encParse(key) : encParse(defaultKey);
  const ivStr = iv ? encParse(iv) : encParse(defaultIv);

  // 判断str是否为base64,如果不是就要转base64，是了就不能再转
  const flag = isBase64(str);
  // if (!flag) {
  //   // 转为base64之前要先转16进制
  //   str = CryptoJs.enc.Hex.parse(str);
  //   // 只有base64格式的字符才能被解密
  //   str = CryptoJs.enc.Base64.stringify(str);
  // }

  const encryptedStr = CryptoJs.AES.decrypt(str, keyStr, {
    iv: ivStr,
    mode: CryptoJs.mode[mode],
    padding: CryptoJs.pad[pad]
  });
  return encryptedStr.toString(CryptoJs.enc.Utf8);
}
/**
 * 处理密钥字符格式
 * @param key
 */
function encParse(key: string) {
  // return CryptoJs.enc.Utf8.parse(key);
  return CryptoJs.enc.Latin1.parse(key);
}
/**
 * 使用MD5 hash字符串
 * @param str
 * @param times
 */
function md5(str: string, times = 1) {
  for (let i = 0; i < times; i++) {
    str = CryptoJs.MD5(str).toString();
  }
  return str;
}

// md5加密
function Md5(str: any) {
  return CryptoJS.MD5(str).toString();
}
/**
 * 判断是否是Base64格式的字符串
 */
function isBase64(str: string) {
  const reg =
    /^(([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=))|(([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{2}==))$/;
  return reg.test(str);
}

export default {
  decrypt,
  encrypt,
  md5,
  Md5
};
