import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Message } from "element-ui";
import MyMessageBox from "@/components/SlMessageBox";
import i18n from "@/plugins/i18n";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";
import { useComProps } from "@/utils/useComProps";
import config from "@/config";
import { SlCodeEdit } from "@/components";
import { handleSpecialCharacters, publicExport } from "@/utils";

const prefixCls: any = useDesign().getPrefixCls("tools-panel");
@Component({
  name: "ToolsPanel"
})
export default class ToolsPanel extends mixins(i18n) {
  @Prop(Object) readonly designer: any;
  private dataStore: any = {
    jsonValue: "",
    importTemplate: ""
  };
  private comProps: any = useComProps.call(this, {
    prefixCls
  });

  private checkModel() {
    MyMessageBox({
      title: "预览效果",
      width: "1200px",
      customClass: "dialog-wrapper",
      top: "50px",
      slots: {
        content: () => {
          const schema: any = this.designer.getFormContent().widgetList;
          return this.comProps.renderForm({
            schema: schema,
            rowGutter: this.designer.formConfig.rowGutter
          });
        }
      }
    });
  }

  // 处理需要的json
  private dealNeedJson(readonly?: any) {
    const json: any = this.designer.getFormContent();
    json.widgetList = json.widgetList.map((ele: any) => {
      const obj: any = { ...ele };
      delete obj.id;
      return obj;
    });
    return JSON.stringify(json);
  }

  private checkJson(readonly?: any) {
    MyMessageBox({
      title: `${readonly ? "预览" : "编辑"}Json数据`,
      width: "800px",
      top: "50px",
      slots: {
        content: () => {
          const json = this.dealNeedJson();
          return (
            <SlCodeEdit
              v-model={json}
              mode={"json"}
              ref={"ecEditor"}
            ></SlCodeEdit>
          );
        }
      },
      onConfirm: (callback: Function) => this.importJsonCallback(callback)
    });
  }

  private exportJson() {
    const json: any = this.dealNeedJson(true);
    publicExport({
      data: json,
      fileName: "config.json",
      type: "text/json"
    });
  }

  private onBtnCallback(options: any) {
    console.log(options, "我是点击");
  }

  private importJsonCallback(callback?: Function) {
    const jsonValue: any = handleSpecialCharacters(
      this.dataStore.importTemplate
    );
    this.designer.loadFormJson(jsonValue);
    Message.success(this.i18nt("designer.hint.importJsonSuccess"));
    this.designer.emitHistoryChange();
    this.designer.emitEvent("form-json-imported", []);
    callback && callback();
  }

  private importJson() {
    this.dataStore.importTemplate = JSON.stringify(
      this.designer.getImportTemplate(),
      null,
      "  "
    );
    MyMessageBox({
      title: `导入Json数据`,
      width: "800px",
      top: "50px",
      slots: {
        content: () => {
          return (
            <SlCodeEdit
              v-model={this.dataStore.importTemplate}
              mode={"json"}
              readonly={false}
              ref={"ecEditEditor"}
            ></SlCodeEdit>
          );
        }
      },
      onConfirm: (callback: Function) => this.importJsonCallback(callback)
    });
    // this.designer.clearDesigner();
  }

  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      clear: () => {
        this.designer.clearDesigner();
      },
      check: () => this.checkModel(),
      checkJson: () => this.checkJson(true),
      editJson: () => this.checkJson(),
      importJson: () => this.importJson(),
      exportJson: () => this.exportJson()
    };
    obj[item.prop] && obj[item.prop]();
  }
  render() {
    return (
      <div class={prefixCls}>
        <div class={`${prefixCls}-left`}></div>
        <div class={`${prefixCls}-right`}>
          {this.comProps.renderButtons({
            buttons: config.toolsPanel.rightButtons
          })}
        </div>
      </div>
    );
  }
}
