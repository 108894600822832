import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import {
  filterEmptyField,
  publicDealTableData,
  publicDelete,
  publicDownFile
} from "@/utils";
import { Message, Tree } from "element-ui";
import {
  getRoleListApi,
  getRoleMenuListApi,
  inertRoleApi,
  updateRoleApi,
  deleteRoleApi,
  updateRoleMenuApi
} from "@/api/system/role";
import {
  getGenListApi,
  previewDataListApi,
  importDataApi,
  deleteGenListApi,
  batchGenCodeApi,
  syncGenListApi,
  saveImportTableApi
} from "@/api/tool/gen";
import { SlCodeEdit, SlTabs, SvgIcon } from "@/components";
import { useComProps } from "@/utils/useComProps";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";
import hljs from "highlight.js/lib/highlight";
import "highlight.js/styles/github-gist.css";
import { TagsViewModule } from "@/store/modules/tags-view";

hljs.registerLanguage("java", require("highlight.js/lib/languages/java"));
hljs.registerLanguage("xml", require("highlight.js/lib/languages/xml"));
hljs.registerLanguage("html", require("highlight.js/lib/languages/xml"));
hljs.registerLanguage("vue", require("highlight.js/lib/languages/xml"));
hljs.registerLanguage(
  "javascript",
  require("highlight.js/lib/languages/javascript")
);
hljs.registerLanguage("sql", require("highlight.js/lib/languages/sql"));

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("tool-gen-list");
@Component({
  name: "ToolGenList"
})
export default class ToolGenList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  private comRoleProps: any = useComProps.call(this, { prefixCls });
  private dataStore: any = {
    isLoading: false,
    treeIsLoading: false,
    title: "角色列表",
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNum: 1
    },
    total: 0,
    tableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: [],
    previewList: {
      select: "",
      active: 0,
      code: ""
    },
    importIsLoading: false,
    importQueryList: {
      pageSize: 30,
      pageNum: 1
    },
    importData: [],
    importTotal: 0,
    importSelectData: []
  };

  private handleNodeClick(node: any) {}

  // 操作角色信息
  private handleRole(options: any) {
    const { callback, row, title } = options;
    const elRef: any = this.comRoleProps.getFormElRef(this, "dialogRef");
    this.comRoleProps.checkFormRequire(elRef, () => {
      const params: any = this.comRoleProps.getFormValue(this, "dialogRef");
      const api: any = row ? updateRoleApi : inertRoleApi;
      if (row && row.id) {
        params["id"] = row.id;
      }
      api(title && row ? row : params).then((res: any) => {
        Message.success(
          `${row ? "编辑" : "添加"}角色${title ? title : ""}成功`
        );
        this.getTableData();
        callback && callback();
      });
    });
  }

  private onChangeTabs(options: any, tabs: any) {
    const { index, type } = options;
    const obj: any = {
      changeTab: () => {
        const item = tabs[index];
        this.dataStore.previewList.select = item.value;
        this.dataStore.previewList.code = item.code;
        this.dataStore.previewList.active = index;
      }
    };
    obj[type] && obj[type]();
  }

  private async previewHandle(row: any) {
    const result: any = await previewDataListApi(row.tableId);
    const tabs = Object.keys(result).map((ele) => {
      const keys = ele.split("/");
      const names: any = keys[keys.length - 1].split(".");
      names.splice(names.length - 1, 1);
      return {
        label: names.join("."),
        value: result[ele],
        code: keys[1]
      };
    });
    this.onChangeTabs({ type: "changeTab", index: 0 }, tabs);
    this.comRoleProps.openDialogPage(this, {
      title: "预览",
      top: "50px",
      width: "1536px",
      showCancelButton: false,
      confirmButtonText: "关闭",
      contentSlots: () => {
        return (
          <div class={`${prefixCls}-dialog`}>
            <SlTabs
              v-model={this.dataStore.previewList.active}
              tabData={tabs}
              onCallback={(index: number) => this.onChangeTabs(index, tabs)}
            ></SlTabs>
            <div class={`${prefixCls}-dialog-content`}>
              <div
                v-clipboard={{
                  type: "copy",
                  content: this.dataStore.previewList.select
                }}
                class={`${prefixCls}-dialog-content-copy`}
                onClick={() => {
                  this.$message.success(`复制成功`);
                }}
              >
                <SvgIcon
                  style={{ marginRight: "5px" }}
                  icon-class={"icon-copy"}
                ></SvgIcon>
                复制
              </div>
              <pre>
                <code
                  class={"hljs"}
                  domProps-innerHTML={this.highlightedCode(
                    this.dataStore.previewList
                  )}
                ></code>
              </pre>
            </div>
          </div>
        );
      }
    });
  }

  private highlightedCode(options: any) {
    const { code, select } = options;
    const result = hljs.highlight(code, select || "", true);
    return result.value || "&nbsp;";
  }

  // 删除方法
  private deleteHandle(ids: any[], names: any[]) {
    publicDelete({
      message: `是否删除表【${names.join(",")}】？`,
      title: "确认信息",
      callback: () => {
        this.deleteDataList(ids, names);
      }
    });
  }

  private getImportData() {
    const formList: any = this.comRoleProps.getFormValue(this, "importRef");
    const params: any = { ...formList, ...this.dataStore.importQueryList };
    importDataApi(filterEmptyField(params)).then((res: any) => {
      if (res) {
        this.dataStore.importData = res.rows;
        this.dataStore.importTotal = res.total;
      }
    });
  }

  // 批量导入表
  private importBatchHandle() {
    this.getImportData();
    this.comRoleProps.openDialogPage(this, {
      title: "导入表",
      width: "800px",
      contentSlots: () => {
        return (
          <div class={`${prefixCls}-dialog`}>
            {this.comRoleProps.renderForm({
              ref: "importRef",
              schema: config.gen.list.importFilterSchema,
              slots: {
                handle: (data: any) => {
                  return this.comRoleProps.renderButtons({
                    buttons: data.componentProps.buttons
                  });
                }
              }
            })}
            {this.comRoleProps.renderTable({
              tableData: this.dataStore.importData,
              tableHeader: config.gen.list.importTableHeader,
              isShowCheck: true,
              queryList: this.dataStore.importQueryList,
              total: this.dataStore.importTotal,
              isLoading: this.dataStore.importIsLoading,
              paginationLayout: "sizes, prev, pager, next, slot, jumper",
              callback: this.onImportTableCallback,
              style: {
                height: "500px"
              },
              ref: "tableRef"
            })}
          </div>
        );
      },
      onConfirm: (cb: Function) => {
        if (this.dataStore.importSelectData.length) {
          const keys = this.dataStore.importSelectData.map(
            (ele: any) => ele.tableName
          );
          saveImportTableApi(keys.join(",")).then((res) => {
            Message.success(`导入表【${keys.join(",")}】成功`);
            this.getTableData();
            cb && cb();
          });
        } else {
          Message.error("请选择要导入的表");
        }
      }
    });
  }

  private onImportTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.importQueryList.pageNum = item.value;
        this.getImportData();
      },
      size: () => {
        this.dataStore.importQueryList.pageNum = 1;
        this.dataStore.importQueryList.pageSize = item.value;
        this.getImportData();
      },
      checkbox: () => {
        this.dataStore.importSelectData = JSON.parse(JSON.stringify(item.data));
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      refresh: () => {
        this.comRoleProps.clearFormList(this, "filterRef");
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      importSearch: () => {
        this.dataStore.importQueryList.pageNum = 1;
        this.getImportData();
      },
      importRefresh: () => {
        this.comRoleProps.clearFormList(this, "importRef");
        this.dataStore.importQueryList.pageNum = 1;
        this.getImportData();
      },
      batchCreate: () => {
        if (this.dataStore.selectData.length) {
          const tableNames = this.dataStore.selectData.map(
            (ele) => ele.tableName
          );
          batchGenCodeApi({ tables: tableNames.join(",") }).then((res) => {
            Message.success(`生成表【${tableNames.join(",")}】成功`);
            publicDownFile(res);
          });
        } else {
          Message.warning("请选择要生成的数据");
        }
      },
      importBatch: () => this.importBatchHandle(),
      deleteBatch: () => {
        if (this.dataStore.selectData.length) {
          const ids: any = [];
          const names: any[] = [];
          this.dataStore.selectData.forEach((ele: any) => {
            ids.push(ele.tableId);
            names.push(ele.tableName);
          });
          this.deleteHandle(ids, names);
        } else {
          Message.warning("请先选择要删除的数据");
        }
      },
      previewRow: () => this.previewHandle(row),
      editRow: () => {
        const params = {
          pageNum: this.dataStore.queryList.pageNum,
          tableId: row.tableId
        };
        const path: any = `/tool/gen-edit?tableId=${row.tableId}&pageNum=${this.dataStore.queryList.pageNum}`;
        const obj = {
          path: `/tool/gen-edit`,
          meta: { title: `修改[${row.tableName}]生成配置` }
        };
        TagsViewModule.addView(obj);
        this.$router.push({
          path: path,
          params: params
        });
      },
      deleteRow: () => this.deleteHandle([row.tableId], [row.tableName]),
      syncRow: () => {
        publicDelete({
          title: `系统提示`,
          message: `确认要强制同步"${row.tableName}"表结构吗？`,
          callback: () => {
            syncGenListApi(row.tableName).then((res) => {
              Message.success(`强制同步"${row.tableName}"表结构成功`);
              this.getTableData();
            });
            // this.deleteDataList(ids, names);
          }
        });
      },
      createCode: () => {
        batchGenCodeApi({ tables: row.tableName }).then((res) => {
          Message.success(`生成表【${row.tableName}】成功`);
          publicDownFile(res);
        });
      }
    };
    obj[item.prop] && obj[item.prop]();
  }

  // 删除数据
  private deleteDataList(ids: any[], names: any[]) {
    const api: any = deleteGenListApi;
    const params: any = ids.join(",");
    api(params).then(() => {
      Message.success(`删除表【${names.join(",")}】成功`);
      this.getTableData();
    });
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNum = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }

  private getTableData() {
    this.dataStore.isLoading = true;
    const filterList: any = this.comRoleProps.getFormValue(this, "filterRef");
    const params: any = { ...filterList, ...this.dataStore.queryList };
    if (filterList.createTimes) {
      params["params[beginTime]"] = filterList.createTimes[0];
      params["params[endTime]"] = filterList.createTimes[1];
      delete params.createTimes;
    }
    getGenListApi(filterEmptyField(params))
      .then((res: any) => {
        if (res) {
          this.dataStore.tableData = publicDealTableData(
            res.rows,
            this.dataStore.queryList,
            { current: "pageNum", size: "pageSize" }
          );
          this.dataStore.total = res.total;
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }

  mounted() {
    this.comRoleProps.dealContentTableHeight(this, ["header", "filter"]);
    this.getTableData();
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        {/*<div class={`${prefixCls}-header`}>{this.dataStore.title}</div>*/}
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.comRoleProps.renderForm({
              ref: "filterRef",
              schema: config.gen.list.filterSchema,
              slots: {
                handle: (data: any) => {
                  return this.comRoleProps.renderButtons({
                    buttons: data.componentProps.buttons
                  });
                },
                tableHandle: (data: any) => {
                  return this.comRoleProps.renderButtons({
                    buttons: data.componentProps.buttons
                  });
                }
              }
            })}
          </div>
          {this.comRoleProps.renderTable({
            tableData: this.dataStore.tableData,
            tableHeader: config.gen.list.tableHeader,
            isShowCheck: true,
            queryList: this.dataStore.queryList,
            total: this.dataStore.total,
            isLoading: this.dataStore.isLoading,
            paginationLayout: "sizes, prev, pager, next, slot, jumper",
            style: {
              height: this.dataStore.tableHeight
            },
            ref: "tableRef"
          })}
        </div>
      </div>
    );
  }
}
