import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { getToken } from "@/utils/cookies";
import "./index.scss";
import { useDesign } from "@/hooks/web/useDesign";
const prefixCls = useDesign().getPrefixCls("editor");
@Component({
  name: "SlEditor"
})
export default class SlEditor extends Vue {
  @Prop({
    type: String,
    default: ""
  })
  readonly value: any;
  @Prop({
    type: Object,
    default: (val) => {
      return {};
    }
  })
  readonly uEditorConfig: any;

  @Prop({
    type: Boolean,
    default: false
  })
  readonly isReal: any;

  @Watch("value", { immediate: true })
  onChangeValue(val: any) {
    if (val && this.isReal) {
      this.setContentText(val);
    }
  }
  private dataStore: any = {
    instance: null,
    ready: false,
    defaultConfig: {
      zIndex: 3000,
      toolbars: [
        [
          "fullscreen",
          "|",
          "undo",
          "redo",
          "|",
          "bold",
          "italic",
          "underline",
          "fontborder",
          "strikethrough",
          "superscript",
          "subscript",
          "removeformat",
          "formatmatch",
          "autotypeset",
          "blockquote",
          "pasteplain",
          "|",
          "forecolor",
          "backcolor",
          "insertorderedlist",
          "insertunorderedlist",
          "selectall",
          "cleardoc",
          "|",
          "rowspacingtop",
          "rowspacingbottom",
          "lineheight",
          "|",
          "customstyle",
          "paragraph",
          "fontfamily",
          "fontsize",
          "|",
          "directionalityltr",
          "directionalityrtl",
          "indent",
          "|",
          "justifyleft",
          "justifycenter",
          "justifyright",
          "justifyjustify",
          "|",
          "touppercase",
          "tolowercase",
          "|",
          "link",
          "unlink",
          "anchor",
          "|",
          "imagenone",
          "imageleft",
          "imageright",
          "imagecenter",
          "|",
          "insertimage",
          "scrawl",
          "insertvideo",
          "music",
          "attachment",
          "pagebreak",
          "template",
          "background",
          "|",
          "horizontal",
          "date",
          "time",
          "spechars",
          "wordimage",
          "|",
          "inserttable",
          "deletetable",
          "insertparagraphbeforetable",
          "insertrow",
          "deleterow",
          "insertcol",
          "deletecol",
          "mergecells",
          "mergeright",
          "mergedown",
          "splittocells",
          "splittorows",
          "splittocols",
          "charts",
          "|",
          "print",
          "preview",
          "searchreplace"
        ]
      ],
      initialFrameWidth: "100%",
      initialFrameHeight: 360,
      elementPathEnabled: false, //是否启用元素路径，默认是true显示
      wordCount: false, //是否开启字数统计
      token: getToken(),
      serverUrl: `${process.env.VUE_APP_BASE_API}/richText/config`
    }
  };

  @Emit("input")
  onInput(item: any) {
    return item;
  }
  get randomId() {
    return `editor_${Math.random() * 100000000000000000}`;
  }
  private setContentText(val: any) {
    this.dataStore.instance.setContent(val);
  }

  private initEditor() {
    this.$nextTick(() => {
      this.dataStore.instance = this.$ue.getEditor(this.randomId, {
        ...this.dataStore.defaultConfig,
        ...this.uEditorConfig
      });
      this.dataStore.instance.addListener("ready", () => {
        this.dataStore.ready = true;
        if (this.$props.value) {
          this.setContentText(this.$props.value);
        }
      });
      this.dataStore.instance.addListener("contentChange", () => {
        const html: any = this.dataStore.instance.getContent();
        this.onInput(html);
      });
    });
  }
  mounted() {
    this.initEditor();
  }
  render() {
    return (
      <div class={prefixCls} style={{ lineHeight: "20px" }}>
        <script id={this.randomId} type={"text/plain"}></script>
      </div>
    );
  }
}
