import IdCard from "@/utils/idCard";

export const isValidUsername = (str: string) => {
  const reg = /^[A-Za-z0-9_@.]{5,10}$/;
  return reg.test(str);
};

export const isExternal = (path: string) =>
  /^(https?:|mailto:|tel:|http:)/.test(path);

export const isArray = (arg: any) => {
  if (typeof Array.isArray === "undefined") {
    return Object.prototype.toString.call(arg) === "[object Array]";
  }
  return Array.isArray(arg);
};

export const isValidURL = (url: string) => {
  const reg =
    /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
};

// 校验身份证
export const isIdCard = (card: string) => IdCard.identityCodeValid(card);

// 校验手机号
export const isPhone = (phone: string) => {
  const regExp = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
  return regExp.test(phone);
};

// 正则修改输入内容只能为数字且只有一位小数点
export const inputNumber = (value: any) => {
  return (
    value
      .replace(/\D*(\d*)(\.?)(\d{0,3})\d*/, "$1$2$3") // 只能输入数字、小数点限制3位
      .replace(/^0+(\d)/, "$1") // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      .replace(/^\./, "0.") // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
      .match(/^\d*(\.?\d{0,3})/g)[0] || ""
  );
};

export const inputEmail = (value: any) => {
  return (
    value
      .replace(/\D*(\d*)(\.?)(\d{0,3})\d*/, "$1$2$3") // 只能输入数字、小数点限制3位
      .replace(/^0+(\d)/, "$1") // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      .replace(/^\./, "0.") // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
      .match(/^\d*(\.?\d{0,3})/g)[0] || ""
  );
};

export const inputPhone = (value: any) => {
  return (
    value
      .replace(/\D*(\d*)(\.?)(\d{0,3})\d*/, "$1$2$3") // 只能输入数字、小数点限制3位
      .replace(/^0+(\d)/, "$1") // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      .replace(/^\./, "0.") // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
      .match(/^\d*(\.?\d{0,3})/g)[0] || ""
  );
};
