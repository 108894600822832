import { useDesign } from "@/hooks/web/useDesign";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Collapse, CollapseItem, Button } from "element-ui";
import {
  cascaderFields,
  specialPrivateFields
} from "@/views/Tool/Build/components/WidgetPanel/widgetConfig";
import { componentMap } from "@/components/SlForm/src/componentMap";
import MyMessageBox from "@/components/SlMessageBox";
import { mixins } from "vue-class-component";
import config from "@/config";
import i18n from "@/plugins/i18n";
import {
  SlTabs,
  SlScrollPane,
  SlCodeEdit,
  SlOptionsConfig,
  SlForm
} from "@/components";

import "./index.scss";
import { useComProps } from "@/utils/useComProps";
import {
  is,
  isArray,
  isBoolean,
  isFunction,
  isNumber,
  isObject,
  isString
} from "@/utils/is";
import { initModel } from "@/components/SlForm/src/helper";
import { useTable } from "@/hooks/web/useTable";
import { generateId } from "@/utils/utils";
import { findNewObj } from "@/utils";
const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("setting-panel");

@Component({
  name: "SettingPanel"
})
export default class SettingPanel extends mixins(i18n) {
  $refs!: {
    formRef: any;
    ecEditor: any;
  };
  @Prop(Object) readonly designer: any;
  @Prop(Object) readonly selectedWidget: any;
  private comProps: any = useComProps.call(this, {
    prefixCls
  });
  private dataStore: any = {
    jsonName: "",
    activeIndex: 0,
    tabData: [
      {
        label: "属性配置",
        icon: "el-icon-set-up"
      },
      {
        label: "表单配置",
        icon: "el-icon-c-scale-to-original"
      }
    ],
    activeNames: ["1", "2", "3"],
    collapseData: [
      {
        title: "公共属性",
        name: "1",
        field: "public"
      },
      {
        title: "私有属性",
        name: "2",
        field: "private"
      },
      {
        title: "表单属性",
        name: "3",
        field: "formItem"
      }
    ],
    labelSlotList: {
      field: {
        suffix: () => {
          return this.comProps.renderExplain({
            props: {
              content: "超出预警线后，将以微信方式通知到管理员"
            },
            style: { marginLeft: "10px", color: "#017CFF", cursor: "pointer" }
          });
        }
      }
    },
    privateDefault: [],
    tableHeader: []
  };

  private onInput(options: any) {
    const { item, value, keyValue, path } = options;
    if (keyValue === "formConfig") {
      const obj: any = {
        ...this.designer.formConfig
      };
      obj[item.field] = isNumber(this.designer.formConfig[item.field])
        ? Number(value)
        : value;
      this.designer.upgradeFormConfig(obj);
    } else {
      let params: any = {};
      if (["screenValue", "relationValue"].includes(item.field)) {
        const nPath: any = item.field === "screenValue" ? "screen" : "relation";
        const nList = this.selectedWidget[nPath]
          ? { ...this.selectedWidget[nPath] }
          : {};
        if (!Object.keys(nList).length) {
          return;
        }
        nList["value"] = value;
        params = {
          id: keyValue,
          field: nPath,
          value: nList
        };
      } else {
        params = {
          id: keyValue,
          field: item.field,
          path: path ? `${path}.${item.field}` : item.field,
          value
        };
      }
      this.designer.updateSelected(params);
    }
  }

  private onChange(options: any) {
    const { item, value, keyValue, path, type } = options;
    console.log(options, "kkkkkkkk");
    if (type && type === "column") {
      const index = this.dataStore.tableHeader.findIndex(
        (ele: any) => ele.field === keyValue
      );
      if (path) {
        findNewObj(
          this.dataStore.tableHeader[index],
          `${path}.${item.field}`,
          value
        );
      } else {
        if (item.field === "multiple") {
          this.dataStore.tableHeader[index]["value"] = [];
        }
        if (!this.dataStore.tableHeader[index]["componentProps"]) {
          this.dataStore.tableHeader[index]["componentProps"] = {};
        }
        this.dataStore.tableHeader[index]["componentProps"][item.field] = value;
      }
    } else {
      let params: any = {};
      if (item.field === "multiple") {
        this.designer.updateSelected({
          id: keyValue,
          field: "value",
          value: value ? [] : ""
        });
      }
      if (["screenKey", "relationKey"].includes(item.field)) {
        const nPath: any = item.field === "screenKey" ? "screen" : "relation";
        const nList = this.selectedWidget[nPath]
          ? { ...this.selectedWidget[nPath] }
          : {};
        if (!Object.keys(nList).length) {
          nList["value"] = "";
        }
        nList["key"] = value;
        params = {
          id: keyValue,
          field: nPath,
          value: nList
        };
      } else {
        params = {
          id: keyValue,
          field: item.field,
          path: path ? `${path}.${item.field}` : item.field,
          value
        };
      }
      this.designer.updateSelected(params);
    }
  }

  // 处理自定义组件
  private dealCustomComponent(data: any[], component: any, item: any) {
    const hasSpecial: any = specialPrivateFields[component.name];
    return hasSpecial ? data.concat(hasSpecial) : data;
  }

  // 处理elementui组件
  private dalElementComponent(data: any[], component: any, item: any) {
    const oItem: any = component.props || component.options.props;
    const nItem: any = { ...oItem, ...this.dealMixinsProps(component).props };
    const keys: any = Object.keys(nItem);
    keys.forEach((ele: any) => {
      if (!isArray(nItem[ele].type)) {
        if (nItem[ele].type && nItem[ele].type.name) {
          const isString = nItem[ele].type.name === "String";
          const isBoolean = nItem[ele].type.name === "Boolean";
          const isNumber = nItem[ele].type.name === "Number";
          const label: any = this.i18nt(`designer.setting.${ele}`);
          const hasSpecial: any =
            specialPrivateFields[component.name] &&
            specialPrivateFields[component.name].find(
              (zle: any) => zle.field === ele
            );
          const defaultValue: any = isBoolean
            ? !!nItem[ele].type.default
            : nItem[ele].default
            ? nItem[ele].default
            : "";
          if (hasSpecial) {
            hasSpecial["value"] = defaultValue;
            hasSpecial["label"] = !label.includes(".")
              ? label
              : hasSpecial["label"];
            data.push(hasSpecial);
          } else {
            if (!label.includes(".")) {
              const formItem: any = {
                field: ele,
                label: label,
                component: ele.includes("Color")
                  ? "ColorPicker"
                  : isString
                  ? "Input"
                  : isBoolean
                  ? "Switch"
                  : isNumber
                  ? "InputNumber"
                  : "",
                value: defaultValue,
                componentProps: {
                  on: isString
                    ? {
                        input: true
                      }
                    : {
                        change: true
                      }
                },
                colProps: {
                  xs: 24,
                  sm: 24,
                  md: 24,
                  lg: 24,
                  xl: 24
                },
                formItemProps: {
                  style: {
                    marginBottom: "4px"
                  }
                }
              };
              data.push(formItem);
            }
          }
        }
      } else {
        // console.log(nItem[ele], "jsjsjdjhjsah");
      }
    });
    const initFormSchema: any = config.schemaList.private.map((ele: any) => {
      return {
        ...ele,
        ...{
          label:
            ele.field === "style"
              ? "组件样式"
              : ele.label
              ? this.i18nt(`designer.setting.${ele.label}`)
              : ""
        }
      };
    });
    if (item.component === "Checkbox") {
      data.push({
        field: "label",
        label: "勾选文本",
        component: "Input",
        componentProps: {
          on: {
            input: true
          }
        },
        colProps: {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24,
          xl: 24
        },
        formItemProps: {
          style: {
            marginBottom: "4px"
          }
        }
      });
    }
    if (item.component === "Cascader") {
      data = data.concat(cascaderFields);
    }
    return data.concat(initFormSchema);
  }

  // 处理mixins里面的props
  private dealMixinsProps(item) {
    if (!item.mixins || !item.mixins.length)
      return {
        hasProps: false,
        props: {}
      };
    const mixins = item.mixins;
    let propList: any = {};
    mixins.forEach((ele: any) => {
      propList = { ...propList, ...ele.props };
    });
    if (item.name === "ElInput") {
      propList["maxlength"] = {
        type: String
      };
    }
    return {
      hasProps: Object.keys(propList).length,
      props: propList
    };
  }

  private dealPrivateSchema(item: any) {
    if (!item || !item.field) return [];
    const defaultComponent: any = {
      Radio: "RadioGroup"
    };
    const component: any = componentMap[item.component]
      ? componentMap[item.component]
      : componentMap[defaultComponent[item.component]];
    let defaultFormSchema: any[] = [];
    if (!component) return defaultFormSchema;
    if (
      component.props ||
      (component.options && component.options.props) ||
      this.dealMixinsProps(component).hasProps
    ) {
      defaultFormSchema = this.dalElementComponent(
        defaultFormSchema,
        component,
        item
      );
    } else {
      defaultFormSchema = this.dealCustomComponent(
        defaultFormSchema,
        component,
        item
      );
    }
    return defaultFormSchema;
  }

  // 处理col数据
  private editColProps(options: any) {
    const colKeys: any[] = Object.keys(this.selectedWidget.colProps);
    MyMessageBox({
      title: `添加${options.label}数据`,
      slots: {
        content: () => {
          return this.comProps.renderForm({
            schema: config.colPropsSchema,
            formList: {
              colType: colKeys.includes("xs") ? "col" : "span",
              ...this.selectedWidget.colProps
            },
            ref: "colFormRef"
          });
        }
      },
      onConfirm: (callback: Function) => {
        const result: any = this.comProps.getFormValue(this, "colFormRef");
        const nResult: any = {};
        const colData: any[] = ["xs", "sm", "md", "lg", "xl"];
        Object.keys(result).forEach((ele: any) => {
          if (ele !== "colType") {
            if (result.colType === "col" && colData.includes(ele)) {
              nResult[ele] = result[ele];
            }
            if (result.colType === "span" && !colData.includes(ele)) {
              nResult[ele] = result[ele];
            }
          }
        });
        this.designer.updateSelected({
          id: this.selectedWidget.id,
          field: options.field,
          value: nResult,
          path: "colProps"
        });
        callback && callback();
      }
    });
  }

  private editGlobalFunctions(options?: any, cb?: any) {
    if (!options[options.path]) {
      options[options.path] = {};
    }
    const defaultValue: any = JSON.stringify(
      cb
        ? options[options.path][options.oField]
        : options.path
        ? this.selectedWidget[options.path][options.field]
        : this.selectedWidget[options.field]
    );
    this.dataStore.jsonName = defaultValue ? defaultValue : "";
    MyMessageBox({
      title: `添加${options.label}数据`,
      slots: {
        content: () => {
          return (
            <SlCodeEdit
              v-model={this.dataStore.jsonName}
              mode={"json"}
              readonly={false}
              ref={"ecEditor"}
            ></SlCodeEdit>
          );
        }
      },
      onConfirm: (callback: Function) => {
        if (cb) {
          cb(JSON.parse(this.dataStore.jsonName));
        } else {
          this.designer.updateSelected({
            id: this.selectedWidget.id,
            field: options.field,
            value: JSON.parse(this.dataStore.jsonName),
            path: options.path
              ? `${options.path}.${options.field}`
              : options.field
          });
        }
        callback && callback();
      }
    });
  }

  // 动态修改编辑列配置
  private autoChangeConfig(configs: any, field: string, value: any) {
    const index = this.dataStore.tableHeader.findIndex(
      (zle: any) => zle.field === configs.field
    );
    if (!this.dataStore.tableHeader[index]["componentProps"]) {
      this.dataStore.tableHeader[index]["componentProps"] = {};
    }
    this.dataStore.tableHeader[index]["componentProps"][field] = value;
  }

  private openColumnConfigDialog(schema: any[], configs: any) {
    this.comProps.openDialogPage(this, {
      title: `编辑列配置`,
      width: "900px",
      customClass: `${prefixCls}-dialog`,
      contentSlots: () => {
        return this.comProps.renderForm({
          schema: schema,
          formList: configs.componentProps
            ? configs.componentProps.props
              ? { ...configs.componentProps, ...configs.componentProps.props }
              : configs.componentProps
            : {},
          keyValue: configs.field,
          type: "column",
          slots: {
            style: (options: any) =>
              this.renderJsonButton(
                { path: "componentProps", ...configs, oField: options.field },
                (value: any) => {
                  this.autoChangeConfig(configs, "style", value);
                }
              ),
            optionConfig: (options: any) => {
              return [
                "CheckboxGroup",
                "Select",
                "Cascader",
                "Radio",
                "RadioButton"
              ].includes(configs.component)
                ? this.renderAllOptions(
                    {
                      ...configs,
                      callback: (value: any) => {
                        this.autoChangeConfig(configs, "options", value);
                      }
                    },
                    true
                  )
                : null;
            },
            dataConfig: (options: any) => {
              return ["Transfer"].includes(configs.component)
                ? this.renderAllOptions(options)
                : null;
            }
          }
        });
      }
    });
  }

  private onDialogTableCallback(cbOpt: any) {
    const obj: any = {
      component: () => {
        const index = this.dataStore.tableHeader.findIndex(
          (zle: any) => zle.field === cbOpt.data.row.field
        );
        this.dataStore.tableHeader[index].componentProps = {};
        if (
          this.dataStore.tableHeader[index] &&
          ["Cascader"].includes(this.dataStore.tableHeader[index].component) &&
          !this.dataStore.tableHeader[index].componentProps.options
        ) {
          this.dataStore.tableHeader[index].componentProps.options = [
            {
              label: "测试一",
              value: "1",
              children: [
                {
                  label: "测试二",
                  value: "5"
                }
              ]
            },
            {
              label: "测试二",
              value: "2"
            },
            {
              label: "测试三",
              value: "3"
            }
          ];
        }
        if (["Select"].includes(this.dataStore.tableHeader[index].component)) {
          this.dataStore.tableHeader[index].componentProps["options"] = [
            { label: "选项1", value: 1 }
          ];
        }
        console.log(cbOpt, ";;;;;;;");
      },
      handle: () => {
        const childObj: any = {
          editConfig: () => {
            const nOptions = this.dataStore.tableHeader[cbOpt.data.rowIndex];
            const nSchema = JSON.parse(
              JSON.stringify(this.dealPrivateSchema(nOptions))
            );
            const schema = nSchema.map((ele) => {
              const colPropKeys = Object.keys(ele.colProps);
              colPropKeys.forEach((zle: any) => {
                if (!["optionConfig"].includes(ele.field)) {
                  ele["colProps"][zle] = 8;
                }
                ele["formItemProps"]["style"] = {
                  marginBottom: "20px"
                };
              });
              return ele;
            });
            this.openColumnConfigDialog(
              schema,
              this.dataStore.tableHeader[cbOpt.data.rowIndex]
            );
            console.log(nSchema, "llll");
          },
          delete: () => {
            this.dataStore.tableHeader.splice(cbOpt.data.rowIndex, 1);
          }
        };
        childObj[cbOpt.data.item.prop] && childObj[cbOpt.data.item.prop]();
      }
    };
    console.log(cbOpt, "ppppp");
    obj[cbOpt.type] && obj[cbOpt.type]();
  }

  private editTableColumn(options: any) {
    const defaultValue: any = JSON.stringify(
      options.path
        ? this.selectedWidget[options.path][options.field]
        : this.selectedWidget[options.field]
    );
    this.dataStore.tableHeader = JSON.parse(defaultValue);
    console.log("lllll", this.dataStore.tableHeader);
    this.comProps.openDialogPage(this, {
      title: `编辑列`,
      width: "1400px",
      customClass: `${prefixCls}-dialog`,
      contentSlots: () => {
        return (
          <div class={`${prefixCls}-dialog-body`}>
            <Button
              type={"primary"}
              icon={"el-icon-plus"}
              plain
              round
              on-click={() => {
                const num = generateId();
                const obj: any = {
                  label: `列信息-${num}`,
                  field: `column_${num}`,
                  component: "Text",
                  align: "center",
                  "show-overflow-tooltip": true,
                  componentProps: {}
                };
                this.dataStore.tableHeader.push(obj);
              }}
            >
              添加
            </Button>
            {useTable.call(this, {
              prefixCls: prefixCls,
              tableData: this.dataStore.tableHeader,
              tableHeader: config.allSchema[options.field],
              isPagination: false,
              style: {
                height: "400px"
              },
              callback: this.onDialogTableCallback
            })}
          </div>
        );
      },
      onConfirm: (callback: Function) => {
        this.designer.updateSelected({
          id: this.selectedWidget.id,
          field: options.field,
          value: this.dataStore.tableHeader,
          path: options.path
            ? `${options.path}.${options.field}`
            : options.field
        });
        const list: any = {};
        this.dataStore.tableHeader.forEach((ele: any) => {
          const isNumber = ["Rate"].includes(ele.component);
          const isMultiple =
            (["Select"].includes(ele.component) &&
              ele.componentProps.multiple) ||
            (["Cascader"].includes(ele.component) &&
              ele.componentProps.props &&
              ele.componentProps.props.multiple);
          list[ele.field] = isNumber ? 0 : isMultiple ? [] : "";
        });
        console.log(list, "kkkskkkkk", this.dataStore.tableHeader);
        this.designer.updateSelected({
          id: this.selectedWidget.id,
          field: options.field,
          value: [list],
          path: `${options.path}.tableData`
        });
        callback && callback();
      }
    });
  }

  private onChangeTab(options) {
    const { type, index } = options;
    const obj = {
      changeTab: () => {
        console.log(index, this.dataStore.activeIndex);
        this.dataStore.activeIndex = index;
      }
    };
    obj[type] && obj[type]();
  }

  // 判断是否显示
  private isShowRelation(item, ele) {
    if (!item) return false;
    const hasField = ["relationKey", "relationValue"].includes(ele.field);
    if (!hasField) return true;
    return hasField && ["DatePicker"].includes(item.component);
  }

  private renderAllOptions(options: any, isOther?: any) {
    const nItem: any = this.selectedWidget;
    const isMultiple: any =
      (nItem.componentProps && nItem.componentProps.multiple) ||
      ["CheckboxGroup"].includes(nItem.component);
    const defaultProp: any = {
      value: isMultiple ? [] : ""
    };
    const selectedWidget: any = isOther ? options : this.selectedWidget;
    return (
      <SlOptionsConfig
        designer={this.designer}
        selectedWidget={{ ...defaultProp, ...selectedWidget }}
        defaultProps={options?.componentProps?.defaultProps}
        optionName={options?.componentProps?.optionName}
        optionPath={options?.componentProps?.optionPath}
        callback={options.callback}
      ></SlOptionsConfig>
    );
  }

  private renderJsonButton(options: any, callback?: any) {
    return (
      <Button
        type={"info"}
        icon={"el-icon-edit"}
        plain
        round
        on-click={() =>
          this.editGlobalFunctions(
            {
              ...options
            },
            callback
          )
        }
      >
        {this.i18nt("designer.setting.addEventHandler")}
      </Button>
    );
  }

  render() {
    const renderTableHeader = (options: any) => {
      return (
        <Button
          type={"info"}
          icon={"el-icon-edit"}
          plain
          round
          on-click={() =>
            this.editTableColumn({
              ...options
            })
          }
        >
          {this.i18nt("designer.setting.addTableColumn")}
        </Button>
      );
    };

    const renderHandleButton = (options) => {
      return (
        <Button
          type={"info"}
          icon={"el-icon-edit"}
          plain
          on-click={() =>
            this.editColProps({
              ...options
            })
          }
        >
          {this.i18nt("designer.setting.handleColProps")}
        </Button>
      );
    };
    const renderPublicModel = () => {
      const nItem: any = this.selectedWidget;
      const isArr: any =
        nItem &&
        [
          "Transfer",
          "Cascader",
          "Select",
          "Divider",
          "CheckboxGroup",
          "Checkbox",
          "ButtonGroup"
        ].includes(nItem.component);
      const data: any[] = config.schemaList.public
        .filter((ele: any) =>
          ["relationKey", "relationValue"].includes(ele.field)
            ? this.isShowRelation(nItem, ele)
            : ele.field !== "value" ||
              !nItem ||
              (ele.field === "value" && !isArr)
        )
        .map((ele: any) => {
          const componentProps: any = ele.componentProps || {};
          if (["screenKey", "relationKey"].includes(ele.field) && nItem) {
            componentProps["options"] = this.designer.widgetList
              .filter((zle) => nItem.field !== zle.field)
              .map((zle: any) => {
                return {
                  label: zle.label,
                  value: zle.field
                };
              });
          }
          return {
            ...ele,
            ...{
              label: this.i18nt(`property.public.${ele.label}`),
              componentProps
            }
          };
        });
      const initFormList = initModel(data, {});
      const formList: any = {
        ...initFormList,
        ...this.selectedWidget,
        ...{
          value: nItem && nItem.value ? nItem.value : "",
          formType: nItem && nItem.formType ? nItem.formType : "edit"
        }
      };
      formList["screenKey"] = nItem && nItem.screen ? nItem.screen.key : "";
      formList["screenValue"] = nItem && nItem.screen ? nItem.screen.value : "";
      formList["relationKey"] =
        nItem && nItem.relation ? nItem.relation.key : "";
      formList["relationValue"] =
        nItem && nItem.relation ? nItem.relation.value : "";
      return this.comProps.renderForm({
        schema: data,
        formList: formList,
        disabled: !this.selectedWidget,
        ref: "formRef",
        slots: {
          colProps: (options: any) => renderHandleButton({ ...options })
        }
      });
    };
    const renderPrivateModel = () => {
      const data = this.dealPrivateSchema(this.selectedWidget);
      const initFormList = initModel(data, {});
      let formList: any = {
        ...initFormList,
        id: this.selectedWidget && this.selectedWidget.id,
        ...this.selectedWidget?.componentProps
      };
      if (this.selectedWidget?.componentProps?.props) {
        formList = {
          ...formList,
          ...this.selectedWidget?.componentProps?.props
        };
      }
      if (formList["placeholder"] && isFunction(formList["placeholder"])) {
        formList["placeholder"] = "";
      }
      console.log(formList, "jjhgggggggg", this.selectedWidget, data);
      return this.selectedWidget
        ? this.comProps.renderForm({
            schema: data,
            ref: "privateFormRef",
            path: "componentProps",
            formList: formList,
            slots: {
              style: (options: any) =>
                this.renderJsonButton({ path: "componentProps", ...options }),
              optionConfig: (options: any) => {
                return [
                  "CheckboxGroup",
                  "Select",
                  "Cascader",
                  "Radio",
                  "RadioButton"
                ].includes(this.selectedWidget.component)
                  ? this.renderAllOptions(options)
                  : null;
              },
              dataConfig: (options) => {
                return ["Transfer"].includes(this.selectedWidget.component)
                  ? this.renderAllOptions(options)
                  : null;
              },
              tableHeader: (options: any) =>
                renderTableHeader({
                  path: "componentProps",
                  ...options
                }),
              buttons: (options: any) =>
                this.renderJsonButton({ path: "componentProps", ...options })
            }
          })
        : null;
    };

    const renderFormModel = (schema: any[]) => {
      const data: any[] = schema.map((ele: any) => {
        const componentProps: any = ele.componentProps || {};
        return {
          ...ele,
          ...{
            label: this.i18nt(`designer.setting.${ele.label}`)
              ? this.i18nt(`designer.setting.${ele.label}`)
              : ele.label,
            componentProps
          }
        };
      });
      const initFormList = initModel(data, {});
      const formList: any = {
        ...{
          id: this.selectedWidget && this.selectedWidget.id,
          labelPosition: "left",
          required: false
        },
        ...initFormList,
        ...this.selectedWidget?.formItemProps
      };
      return this.selectedWidget
        ? this.comProps.renderForm({
            schema: data,
            ref: "formItemRef",
            path: "formItemProps",
            formList: formList,
            slots: {
              style: (options: any) =>
                this.renderJsonButton({ ...options, path: "formItemProps" }),
              labelStyle: (options: any) =>
                this.renderJsonButton({ ...options, path: "formItemProps" }),
              labelSlots: (options: any) =>
                this.renderJsonButton({ ...options, path: "formItemProps" }),
              rules: (options: any) =>
                this.renderJsonButton({ ...options, path: "formItemProps" }),
              prompt: (options: any) =>
                this.renderJsonButton({ ...options, path: "formItemProps" })
            }
          })
        : null;
    };
    const renderModel = (item: any) => {
      const modelObj: any = {
        "1": renderPublicModel,
        "2": renderPrivateModel,
        "3": () => {
          return renderFormModel(config.schemaList.formItem);
        }
      };
      return modelObj[item.name] ? modelObj[item.name]() : null;
    };
    return (
      <div class={`${prefixCls}`}>
        <SlTabs
          v-model={this.dataStore.activeIndex}
          tabData={this.dataStore.tabData}
          onCallback={this.onChangeTab}
        ></SlTabs>
        <div class={`${prefixCls}-content`}>
          {this.dataStore.activeIndex === 0 ? (
            <Collapse
              v-model={this.dataStore.activeNames}
              class={`${prefixCls}-content-collapse`}
            >
              {this.dataStore.collapseData.map((item: any) => {
                return (
                  <CollapseItem name={item.name} title={item.title}>
                    {renderModel(item)}
                  </CollapseItem>
                );
              })}
            </Collapse>
          ) : (
            <div style={{ padding: "10px 5px" }}>
              {this.comProps.renderForm({
                schema: config.schemaList.formWrapper.map((ele: any) => {
                  const componentProps: any = ele.componentProps || {};
                  return {
                    ...ele,
                    ...{
                      label: this.i18nt(`designer.setting.${ele.label}`)
                        ? this.i18nt(`designer.setting.${ele.label}`)
                        : ele.label,
                      componentProps
                    }
                  };
                }),
                ref: "formWrapperRef",
                formList: this.designer.formConfig,
                keyValue: "formConfig"
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}
