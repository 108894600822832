import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import {
  filterEmptyField,
  publicDealFileUrl,
  publicDelete,
  publicDownFile
} from "@/utils";
import {
  getCustomListApi,
  updateCustomListApi,
  updateCustomAuthApi,
  deleteCustomByIdsApi,
  downloadCustomModuleApi,
  importCustomApi,
  insertCustomListApi
} from "@/api/custom";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";
import UseRenderCom from "@/hooks/useRenderCom";
import { Checkbox, Image, Message, Upload } from "element-ui";
import { getToken } from "@/utils/cookies";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("custom-list");
@Component({
  name: "CustomList"
})
export default class CustomList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };

  get useRenderCom() {
    return UseRenderCom.call(this);
  }

  // private useRenderCom = UseRenderCom.call(this);
  private dataStore: any = {
    isLoading: false,
    treeIsLoading: false,
    title: "客户列表",
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNum: 1
    },
    total: 0,
    tableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: [],
    formData: null,
    updateSupport: false
  };

  private handleExport() {
    const filterList: any = this.useRenderCom.getFormValue("filterRef");
    const params: any = {
      ...filterList,
      ...this.dataStore.queryList
    };
  }

  private changeAdmin(item: any) {
    const params: any = JSON.parse(JSON.stringify(item));
    params["isAdmin"] = item.isAdmin === "1" ? "0" : "1";
    publicDelete({
      title: "提示",
      message: `是否${params.isAdmin === "1" ? "设置" : "取消"}【${
        params.nickName
      }】为管理员`,
      callback: () => {
        updateCustomAuthApi({
          userId: params.userId,
          isAdmin: params.isAdmin
        }).then((res) => {
          this.$message.success(
            `${params.isAdmin === "1" ? "设置" : "取消"}【${
              params.nickName
            }】为管理员成功`
          );
          this.getTableData();
        });
      }
    });
  }

  private handleSuccess() {}

  private uploadCustoms(file: any) {
    this.dataStore.formData = new FormData();
    this.dataStore.formData.append("file", file.file);
  }

  private handleDownLoad() {
    downloadCustomModuleApi().then((res: any) => {
      if (res) {
        publicDownFile(res);
      }
    });
  }

  private handleImportCustom(cb: Function) {
    importCustomApi(this.dataStore.formData, this.dataStore.updateSupport).then(
      (res: any) => {
        Message({
          type: "success",
          message: res.result,
          dangerouslyUseHTMLString: true
        });
        this.getTableData();
        cb && cb();
      }
    );
  }

  private importCustomHandle() {
    this.useRenderCom.renderDialog({
      width: "400px",
      title: "客户导入",
      contentSlots: () => {
        return (
          <Upload
            class="upload-demo"
            drag
            action=""
            headers={{
              Authorization: getToken()
            }}
            http-request={this.uploadCustoms}
            props={{
              "on-success": this.handleSuccess
            }}
            multiple={false}
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div
              class="el-upload__tip"
              slot="tip"
              style={{ textAlign: "center" }}
            >
              <Checkbox v-model={this.dataStore.updateSupport}>
                是否更新已经存在的客户数据
              </Checkbox>
              <div>
                仅允许导入xls、xlsx格式文件。
                <span
                  onClick={this.handleDownLoad}
                  style="color:#1890ff;cursor:pointer"
                >
                  点击下载模板
                </span>
              </div>
            </div>
          </Upload>
        );
      },
      onConfirm: (callback: Function) => {
        this.handleImportCustom(callback);
        callback();
      }
    });
  }

  private handleTable(type: any, row: any) {
    const isEdit = type === "edit";
    this.useRenderCom.renderDialog({
      title: `${isEdit ? "编辑" : "新增"}客户信息`,
      width: "800px",
      schema: config.custom.dialogSchema,
      formList: { ...row },
      onConfirm: (cb: Function) => {
        this.useRenderCom.checkFormRequire("dialogRef", (formList: any) => {
          const params: any = { ...formList };
          const api = isEdit ? updateCustomListApi : insertCustomListApi;
          if (isEdit) {
            params["userId"] = row.userId;
          }
          params["userName"] = formList["mobile"];
          api(params).then(() => {
            Message.success(`${isEdit ? "编辑" : "新增"}客户信息成功`);
            this.getTableData();
            cb && cb();
          });
        });
      }
    });
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      refresh: () => {
        this.useRenderCom.clearFormList("filterRef");
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      exportCustom: () => {},
      exportRole: () => this.handleExport(),
      createRow: () => this.handleTable("create", {}),
      editRow: () => this.handleTable("edit", row),
      deleteRow: () => {
        publicDelete({
          title: "提示",
          message: `确定删除客户【${row.nickName}】？`,
          callback: () => {
            deleteCustomByIdsApi(row.userId).then((res: any) => {
              this.$message.success(`删除客户【${row.nickName}】成功`);
              this.getTableData();
            });
          }
        });
        console.log(row, ";;;;;;;");
      },
      setAdmin: () => this.changeAdmin(row),
      cancelAdmin: () => this.changeAdmin(row),
      importCustom: () => this.importCustomHandle()
    };
    obj[item.prop] && obj[item.prop]();
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNum = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      handle: () => {
        this.onClickMethod({ item: item.data.item, row: item.data.row });
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }

  // 处理表格数据
  private dealTableData(data: any[]) {
    return data.map((ele) => {
      ele["status"] = ele.status === "0";
      // ele["roleSorts"] = 2;
      return ele;
    });
  }

  private getTableData() {
    this.dataStore.isLoading = true;
    const filterList: any = this.useRenderCom.getFormValue("filterRef");
    const params: any = { ...filterList, ...this.dataStore.queryList };
    getCustomListApi(filterEmptyField(params))
      .then((res: any) => {
        if (res) {
          this.dataStore.tableData = this.dealTableData(res.rows);
          this.dataStore.total = res.total;
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }

  mounted() {
    this.$nextTick(() => {
      this.useRenderCom.dealContentTableHeight();
    });
    this.getTableData();
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.useRenderCom.renderForm({
              ref: "filterRef",
              schema: config.custom.filterSchema,
              slots: {
                handle: (data: any) => {
                  return this.useRenderCom.renderButtons({
                    buttons: data.componentProps.buttons,
                    style: data.componentProps.style
                  });
                }
              }
            })}
          </div>
          {this.useRenderCom.renderTable({
            prefixCls: prefixCls,
            tableData: this.dataStore.tableData,
            tableHeader: config.custom.tableHeader,
            isShowCheck: false,
            queryList: this.dataStore.queryList,
            total: this.dataStore.total,
            isLoading: this.dataStore.isLoading,
            paginationLayout: "sizes, prev, pager, next, slot, jumper",
            style: {
              height: this.dataStore.tableHeight
            },
            status: "isAdmin",
            ref: "tableRef",
            slots: {
              isAdmin: ({ row }) => {
                return row.isAdmin === "1" ? "是" : "否";
              },
              avatar: ({ row, header }) => {
                if (!row.avatar)
                  return (
                    <Image
                      style={{ width: "80px", height: "80px" }}
                      fit={"cover"}
                      src={require("@/assets/images/coach_default.png")}
                    ></Image>
                  );
                const images = row.avatar
                  ? row.avatar
                      .split(",")
                      .map((ele: any) =>
                        publicDealFileUrl({ image: ele }, "image")
                      )
                  : [];
                return images.map((image: any) => {
                  return (
                    <Image
                      style={{ width: "80px", height: "80px" }}
                      src={image}
                      fit={"cover"}
                      preview-src-list={images}
                    ></Image>
                  );
                });
              }
            }
          })}
        </div>
      </div>
    );
  }
}
