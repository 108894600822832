import { Component, Vue, Prop } from "vue-property-decorator";
import QRCode from "qrcode";
import { base64ToFile } from "@/utils";
@Component({
  name: "SlQRCode"
})
export default class SlQRCode extends Vue {
  $refs!: {
    qrcodeCanvas: any;
  };
  @Prop({
    type: String
  })
  readonly value: any;
  @Prop({
    type: Number,
    default: 100
  })
  readonly size: any;

  @Prop({
    type: String,
    default: ""
  })
  readonly text: any;

  @Prop({
    type: String,
    default: "#000"
  })
  readonly textColor: any;

  @Prop({
    type: Number,
    default: 16
  })
  readonly textSize: any;

  @Prop({
    type: String,
    default: ""
  })
  readonly image: any;

  @Prop({
    type: Number,
    default: 10
  })
  readonly imageWidth: any;

  @Prop({
    type: Number,
    default: 10
  })
  readonly imageHeight: any;
  private async generate(value?: any) {
    try {
      const canvas: any = this.$refs.qrcodeCanvas;
      const url = await QRCode.toDataURL(value ? value : this.value, {
        width: this.size,
        margin: 2
      });
      const img = new Image();
      img.src = url;
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = this.text
          ? img.height + this.textSize + 10
          : img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        if (this.text) {
          ctx.font = `${this.textSize}px sans-serif`;
          ctx.fillStyle = this.textColor;
          ctx.textAlign = "center";
          ctx.fillText(this.text, img.width / 2, img.height + this.textSize);
        }
        const image = new Image();
        image.src = this.image;
        image.onload = () => {
          ctx.drawImage(
            image,
            img.width / 2 - this.imageWidth / 2,
            img.height / 2 - this.imageHeight / 2,
            this.imageWidth,
            this.imageHeight
          );
        };
      };
    } catch (err) {
      console.error(err);
    }
  }

  private printCode(codeName) {
    this.$refs.qrcodeCanvas.toBlob(function (blob) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${codeName}.png`;
      link.click();
    });
  }

  private async saveFile(cb: Function) {
    const base64 = this.$refs.qrcodeCanvas.toDataURL("image/png");
    cb && cb(base64ToFile(base64, "code"));
  }

  mounted() {
    this.generate();
  }
  render() {
    return (
      <div>
        <canvas ref="qrcodeCanvas"></canvas>
      </div>
    );
  }
}
