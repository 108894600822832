import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
  Model
} from "vue-property-decorator";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";
import { isString } from "@/utils/is";

const prefixCls = useDesign().getPrefixCls("tab-button");
@Component({
  name: "SlTabButton"
})
export default class SlTabButton extends Vue {
  @Model("change", { type: [Number, String] }) readonly activeIndex!: any;
  // @Prop({
  //   type: [Number, String],
  //   default: 0
  // })
  // readonly value: any;

  @Prop({
    type: Array,
    default: () => {
      return [];
    }
  })
  readonly tabData: any;

  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  readonly activeStyle: any;

  @Prop({
    type: [Number, String],
    default: 10
  })
  readonly gutter: any;

  // @Emit('input')
  // onInput(val: any) {
  //   console.log(val, '3636363636363');
  //   return val;
  // }
  //
  // @Watch('dataStore.activeIndex', { immediate: true })
  // onChangeActive(val: any) {
  //   this.onInput(val);
  // }
  //
  // @Watch('value')
  // onModel(val: any) {
  //   this.dataStore.activeIndex = val;
  // }

  private dataStore: any = {
    activeIndex: 0
  };

  private onClickTab(item: any) {
    this.inputHandle(item.value);
    this.$emit("callback", item.value);
    // this.dataStore.activeIndex = item.value;
  }

  public inputHandle(val: any): void {
    this.$emit("change", val); // 后面会讲到@Emit,此处就先使用this.$emit代替
  }

  render() {
    const renderContent = () => {
      return this.tabData.map((item: any) => {
        return (
          <div
            class={[
              `${prefixCls}-item`,
              { "active-item": this.activeIndex === item.value }
            ]}
            style={
              this.activeIndex === item.value
                ? Object.assign(
                    {
                      marginRight: isString(this.gutter)
                        ? this.gutter
                        : `${this.gutter}px`
                    },
                    this.activeStyle
                  )
                : {
                    marginRight: isString(this.gutter)
                      ? this.gutter
                      : `${this.gutter}px`
                  }
            }
            on-click={this.onClickTab.bind(this, item)}
          >
            {item.title}
          </div>
        );
      });
    };
    return <div class={`${prefixCls}`}>{renderContent()}</div>;
  }
}
