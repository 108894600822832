import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import {
  filterEmptyField,
  publicDealFileUrl,
  publicDelete,
  publicDownFile
} from "@/utils";
import { Image, Message } from "element-ui";
import {
  getMemberCardListApi,
  insertMemberCardApi,
  updateMemberCardApi,
  deleteMemberCardListApi,
  changeMemberCardStatusApi,
  memberCardExportApi
} from "@/api/memberCard";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";
import UseRenderCom from "@/hooks/useRenderCom";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("member-card-list");
@Component({
  name: "MemberCardList"
})
export default class MemberCardList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  get useRenderCom() {
    return UseRenderCom.call(this);
  }
  private dataStore: any = {
    isLoading: false,
    treeIsLoading: false,
    title: "会员卡列表",
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNum: 1
    },
    total: 0,
    tableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: [],
    dictData: []
  };

  private handleNodeClick(node: any) {}

  // 操作会员卡信息
  private handleRole(options: any) {
    const { callback, row, title } = options;
    this.useRenderCom.checkFormRequire("dialogRef", (formList: any) => {
      const params: any = { ...formList };
      const api: any = row ? updateMemberCardApi : insertMemberCardApi;
      if (row && row.id) {
        params["id"] = row.id;
      }
      api(title && row ? row : params).then((res: any) => {
        Message.success(
          `${row ? "编辑" : "添加"}会员卡${title ? title : ""}成功`
        );
        this.getTableData();
        callback && callback();
      });
    });
  }

  private async handleDialog(type: any, row?: any) {
    this.useRenderCom.renderDialog({
      width: "700px",
      title: `${type === "insert" ? "新增" : "编辑"}会员卡`,
      schema: config.memberCard.dialogSchema,
      formList: row
        ? {
            ...row,
            status: row.status ? "0" : "1"
          }
        : {},
      onConfirm: (callback: any) => this.handleRole({ callback, row })
    });
    setTimeout(() => {
      this.useRenderCom.setFormSchema("dialogRef", [
        {
          field: "type",
          path: "componentProps.options",
          value: this.dataStore.dictData
        }
      ]);
    });
  }

  private handleExport() {
    const filterList: any = this.useRenderCom.getFormValue("filterRef");
    const params: any = {
      ...filterList,
      ...this.dataStore.queryList
    };
    memberCardExportApi(filterEmptyField(params)).then((res: any) => {
      publicDownFile(res, "会员卡列表");
      Message.success("导出数据成功");
    });
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      refresh: () => {
        this.useRenderCom.clearFormList("filterRef");
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      createRow: () => this.handleDialog("insert"),
      deleteBatch: () => {
        if (this.dataStore.selectData.length) {
          const names: any[] = this.dataStore.selectData.map(
            (ele: any) => ele.name
          );
          publicDelete({
            message: `是否删除会员卡【${names.join(",")}】？`,
            title: "确认信息",
            callback: () => {
              const ids: any = [];
              const names: any[] = [];
              this.dataStore.selectData.forEach((ele: any) => {
                ids.push(ele.id);
                names.push(ele.name);
              });
              this.deleteDataList(ids, names);
            }
          });
        } else {
          Message.warning("请先选择要删除的数据");
        }
      },
      editRow: () => this.handleDialog("update", row),
      deleteRow: () => {
        publicDelete({
          message: `是否删除会员卡【${row.name}】？`,
          title: "确认信息",
          callback: () => {
            this.deleteDataList([row.id], [row.name]);
          }
        });
      },
      exportRole: () => this.handleExport()
    };
    obj[item.prop] && obj[item.prop]();
  }

  // 删除数据
  private deleteDataList(ids: any[], names: any[]) {
    const api: any = deleteMemberCardListApi;
    const params: any = ids.join(",");
    api(params).then(() => {
      Message.success(`删除会员卡【${names.join(",")}】成功`);
      this.getTableData();
    });
  }

  private onChangeStatus(value: any, item: any) {
    this.useRenderCom.renderDialog({
      width: "586px",
      title: `会员卡${value ? "启用" : "停用"}`,
      titleSlots: {
        prefix: () => {
          return (
            <i
              class={"el-icon-warning"}
              style={{
                marginRight: "18px",
                color: "#F88B3D",
                fontSize: "24px"
              }}
            ></i>
          );
        }
      },
      contentSlots: () => {
        return (
          <div style={{ marginTop: "40px", marginBottom: "40px" }}>
            您确定{value ? "启用" : "停用"}【{item.title}
            】该会员卡吗？
          </div>
        );
      },
      onConfirm: (callback: Function) => {
        changeMemberCardStatusApi({
          status: value ? "0" : "1",
          id: item.id
        }).then((res) => {
          Message.success(
            `${value ? "启用" : "停用"}会员卡【${item.title}】成功`
          );
          this.getTableData();
          callback && callback();
        });
      },
      onCloseDialog: (callback: Function) => {
        this.getTableData();
        callback && callback();
      }
    });
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNum = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
      },
      status: () => {
        this.onChangeStatus(item.data.value, item.data.row);
      },
      handle: () => {
        this.onClickMethod({ item: item.data.item, row: item.data.row });
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }

  // 处理表格数据
  private dealTableData(data: any[]) {
    return data.map((ele) => {
      ele["status"] = ele.status === "0";
      return ele;
    });
  }

  private getTableData() {
    this.dataStore.isLoading = true;
    const filterList: any = this.useRenderCom.getFormValue("filterRef");
    const params: any = { ...filterList, ...this.dataStore.queryList };
    getMemberCardListApi(filterEmptyField(params))
      .then((res: any) => {
        if (res) {
          this.dataStore.tableData = this.dealTableData(res.rows);
          this.dataStore.total = res.total;
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }

  async mounted() {
    this.$nextTick(() => {
      this.useRenderCom.dealContentTableHeight();
    });
    this.getTableData();
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.useRenderCom.renderForm({
              ref: "filterRef",
              schema: config.memberCard.filterSchema,
              slots: {
                handle: (data: any) => {
                  return this.useRenderCom.renderButtons({
                    buttons: data.componentProps.buttons
                  });
                },
                tableHandle: (data: any) => {
                  return this.useRenderCom.renderButtons({
                    buttons: data.componentProps.buttons
                  });
                }
              }
            })}
          </div>
          {this.useRenderCom.renderTable({
            prefixCls: prefixCls,
            tableData: this.dataStore.tableData,
            tableHeader: config.memberCard.tableHeader,
            isShowCheck: true,
            queryList: this.dataStore.queryList,
            total: this.dataStore.total,
            isLoading: this.dataStore.isLoading,
            paginationLayout: "sizes, prev, pager, next, slot, jumper",
            style: {
              height: this.dataStore.tableHeight
            },
            ref: "tableRef",
            slots: {
              image: ({ row, header }) => {
                if (!row.image)
                  return (
                    <Image
                      style={{ width: "80px", height: "80px" }}
                      fit={"cover"}
                      src={require("@/assets/images/coach_default.png")}
                    ></Image>
                  );
                const images = row.image
                  ? row.image
                      .split(",")
                      .map((ele: any) =>
                        publicDealFileUrl({ image: ele }, "image")
                      )
                  : [];
                return images.map((image: any) => {
                  return (
                    <Image
                      style={{ width: "80px", height: "80px" }}
                      src={image}
                      fit={"cover"}
                      preview-src-list={images}
                    ></Image>
                  );
                });
              },
              type: ({ row, header }) => {
                const item: any = this.dataStore.dictData.find(
                  (zle: any) => zle.value === row.type
                );
                return item ? item.label : "";
              }
            }
          })}
        </div>
      </div>
    );
  }
}
