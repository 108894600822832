import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";
import { SvgIcon } from "@/components";
import { isString } from "@/utils/is";

const { getPrefixCls } = useDesign();

const prefixCls = getPrefixCls("tabs");
@Component({
  name: "SlTabs"
})
export default class SlTabs extends Vue {
  $refs!: {
    tabsRef: any;
  };

  @Prop({
    type: Number,
    default: 0
  })
  readonly value: any;

  @Prop({
    type: Array,
    default: () => {
      return [];
    }
  })
  readonly tabData: any;

  @Prop({
    type: [String, Number],
    default: "auto"
  })
  readonly height: any;

  @Prop({
    type: [String, Number],
    default: "4px"
  })
  readonly activeLineSize: any;

  @Prop({
    type: Boolean,
    default: true
  })
  readonly border: any;

  @Prop({
    type: [Number, String],
    default: 0
  })
  readonly offsetBottom: any;

  @Prop({ type: Number }) readonly model: any;

  @Prop({
    type: String,
    default: "#357AF6"
  })
  readonly activeLineColor: any;

  @Prop({
    type: String,
    default: "rgba(0, 0, 0, 0.9)"
  })
  readonly activeTextColor: any;

  @Prop(Function) readonly customChange: any;

  get tabDom(): any {
    return this.$refs.tabsRef;
  }

  @Watch("tabDom", { immediate: true })
  onWatchTabDom(val: any) {}

  @Emit("callback")
  callback(item: any) {
    return item;
  }

  @Emit("input")
  onInput(item: any) {
    return item;
  }

  @Watch("tabData", { immediate: true })
  onGetDom(val: any) {}

  @Watch("value", { immediate: true })
  onChangeModel(val: any, oldVal: any) {
    if (val || val === 0) {
      this.dataStore.activeIndex = val;
      if (val !== oldVal) {
        this.onCallback(val);
      }
    }
  }

  private dataStore: any = {
    activeIndex: 0,
    checkedPosition: 0,
    lineWidth: 0
  };

  private onCallback(index: any) {
    this.dataStore.activeIndex = index;
    this.calculationPosition();
    this.callback({
      type: "changeTab",
      index: this.dataStore.activeIndex
    });
  }

  private onClickTab(index: number) {
    if (this.customChange) {
      this.customChange(index, this.onCallback);
    } else {
      this.onCallback(index);
      this.onInput(index);
    }
  }

  // 获取选中后的line位置
  private calculationPosition() {
    if (!this.$refs || !this.$refs.tabsRef) return;
    const tabs: any = this.$refs.tabsRef.childNodes[0];
    const tab: any = tabs.childNodes;
    this.dataStore.lineWidth = `${
      tab[this.dataStore.activeIndex].offsetWidth
    }px`;
    this.dataStore.checkedPosition = `${
      tab[this.dataStore.activeIndex].offsetLeft
    }px`;
  }

  mounted() {
    this.calculationPosition();
  }

  render() {
    const renderTabList = () => {
      return this.tabData.map((item: any, index: number) => {
        return (
          <div
            class={[
              `${prefixCls}-wrap-tab`,
              this.dataStore.activeIndex === index ? "active-tab" : ""
            ]}
            style={{
              color:
                this.dataStore.activeIndex === index
                  ? this.activeTextColor
                  : "rgba(0, 0, 0, 0.65)"
            }}
            on-click={this.onClickTab.bind(this, index)}
          >
            {item.icon ? (
              <SvgIcon
                iconClass={item.icon}
                className={item.className}
                style={{ marginRight: "10px" }}
              ></SvgIcon>
            ) : null}
            {item.label}
          </div>
        );
      });
    };
    return (
      <div
        class={`${prefixCls}`}
        ref="tabsRef"
        style={{
          height: isString(this.height) ? this.height : `${this.height}px`
        }}
      >
        <div class={[`${prefixCls}-wrap`, this.border ? "tabs-border" : ""]}>
          {renderTabList()}
        </div>
        <div
          class={`${prefixCls}-line`}
          style={{
            background: this.activeLineColor,
            height:
              typeof this.activeLineSize === "string"
                ? this.activeLineSize
                : `${this.activeLineSize}px`,
            left: this.dataStore.checkedPosition,
            width: this.dataStore.lineWidth,
            bottom:
              typeof this.offsetBottom === "string"
                ? this.offsetBottom
                : `${this.offsetBottom}px`
          }}
        ></div>
      </div>
    );
  }
}
