import { useDesign } from "@/hooks/web/useDesign";
import { Component, Prop, Vue } from "vue-property-decorator";
import { SlButton } from "@/components";

const prefixCls = useDesign().getPrefixCls("button-group");
@Component({
  name: "SlButtonGroup"
})
export default class SlButtonGroup extends Vue {
  @Prop({
    type: Array,
    default: () => {
      return [];
    }
  })
  readonly buttons: any;

  @Prop({
    type: [String, Boolean, Number],
    default: ""
  })
  readonly status: any;

  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  readonly clickProps: any;

  @Prop({
    type: Function
  })
  readonly callback: any;

  private onClickButton(event: any, options: any) {
    event.stopPropagation();
    this.$emit("callback", options);
  }
  render() {
    return (
      <div class={`${prefixCls}-buttons`}>
        {this.buttons.map((item: any, index: number) => {
          const getProps = () => {
            const obj: any = {};
            const keys: any = Object.keys(item);
            keys.forEach((ele: any) => {
              if (!["style"].includes(ele)) {
                obj[ele] = item[ele];
              }
            });
            return obj;
          };
          return !item.screen ||
            (item.screen &&
              item.screen.includes(this.clickProps.row[this.status])) ? (
            <SlButton
              key={index}
              {...{
                attrs: getProps()
              }}
              customStyle={item?.style}
              title={item.label}
              on-click={(e: any) =>
                this.onClickButton(e, {
                  item,
                  ...this.clickProps
                })
              }
            ></SlButton>
          ) : null;
        })}
      </div>
    );
  }
}
