import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import { arrayToTree, filterEmptyField, publicDelete } from "@/utils";
import { Message, Tree } from "element-ui";
import { PermissionModule } from "@/store/modules/permission";
import { useComProps } from "@/utils/useComProps";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";
import { SvgIcon } from "@/components";
import {
  deleteMenuListApi,
  getMenuListApi,
  insertMenuListApi,
  updateMenuListApi
} from "@/api/system/menu";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("system-menu");
@Component({
  name: "SystemMenuList"
})
export default class SystemMenuList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  private comRoleProps: any = useComProps.call(this, { prefixCls });
  private dataStore: any = {
    isLoading: false,
    treeIsLoading: false,
    title: "菜单列表",
    tableHeight: 0,
    queryList: {
      pageSize: 10,
      pageNum: 1
    },
    total: 0,
    tableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: []
  };
  // 操作菜单信息
  private handleMenu(options: any) {
    const { callback, row, title } = options;
    const elRef: any = this.comRoleProps.getFormElRef(this, "dialogRef");
    this.comRoleProps.checkFormRequire(elRef, () => {
      const params: any = this.comRoleProps.getFormValue(this, "dialogRef");
      if (row && row.menuId) {
        params["menuId"] = row.menuId;
      }
      const api = row && row.menuId ? updateMenuListApi : insertMenuListApi;
      api(params).then((res: any) => {
        Message.success(
          `${row ? "编辑" : "添加"}菜单${title ? title : ""}成功`
        );
        this.getTableData();
        this.reloadPage();
        callback && callback();
      });
    });
  }

  // 操作弹窗
  private handleDialog(type: any, row: any) {
    config.menu.dialogSchema[0].componentProps.treeProps.data =
      this.dataStore.treeData;
    this.comRoleProps.openDialogPage(this, {
      width: "650px",
      title: `${type === "insert" ? "新增" : "编辑"}菜单`,
      confirmButtonText: "确定",
      schema: config.menu.dialogSchema,
      formList: row,
      onConfirm: (callback: any) =>
        this.handleMenu({
          callback,
          row
        })
    });
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      refresh: () => {
        this.comRoleProps.clearFormList(this, "filterRef");
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      createRow: () => this.handleDialog("insert", {}),
      insertChild: () => this.handleDialog("insert", { parentId: row.menuId }),
      editRow: () => this.handleDialog("update", row),
      deleteRow: () => {
        publicDelete({
          message: `是否删除菜单【${row.menuName}】？`,
          title: "删除菜单",
          callback: () => {
            this.deleteDataList([row.menuId], [row.menuName]);
          }
        });
      }
    };
    obj[item.prop] && obj[item.prop]();
  }

  // 删除数据
  private deleteDataList(ids: any[], names: any[]) {
    const api: any = deleteMenuListApi;
    const params: any = ids.join(",");
    api(params)
      .then(() => {
        Message.success(`删除菜单【${names.join(",")}】成功`);
        this.getTableData();
        this.reloadPage();
      })
      .catch(() => {});
  }

  // 操作完菜单数据后刷新页面
  private reloadPage() {
    PermissionModule.ReloadRoutes();
    // location.reload();
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNum = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }

  // 获取结构树数据
  private getTreeData(data: any[]) {
    this.dataStore.treeData = [
      {
        menuName: "主类目",
        menuId: 0,
        parentId: null,
        children: data
      }
    ];
  }

  private getTableData() {
    this.dataStore.isLoading = true;
    const filterList: any = this.comRoleProps.getFormValue(this, "filterRef");
    const params: any = { ...filterList };
    getMenuListApi(filterEmptyField(params))
      .then((res: any) => {
        if (res) {
          this.dataStore.tableData = arrayToTree(res, res[0].parentId, {
            id: "menuId"
          });
          this.getTreeData(this.dataStore.tableData);
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }

  mounted() {
    this.comRoleProps.dealContentTableHeight(this, ["header", "filter"]);
    this.getTableData();
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        {/* <div class={`${prefixCls}-header`}>{this.dataStore.title}</div> */}
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.comRoleProps.renderForm({
              ref: "filterRef",
              schema: config.menu.filterSchema,
              slots: {
                handle: () => {
                  return this.comRoleProps.renderButtons({
                    buttons: config.menu.filterHandleData
                  });
                },
                tableHandle: () => {
                  return (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {this.comRoleProps.renderButtons({
                        buttons: config.menu.tableHeaderHandleData
                      })}
                    </div>
                  );
                }
              }
            })}
          </div>
          {this.comRoleProps.renderTable({
            tableData: this.dataStore.tableData,
            tableHeader: config.menu.tableHeader,
            isShowCheck: false,
            isPagination: false,
            isLoading: this.dataStore.isLoading,
            tableProps: {
              rowKey: "menuId"
            },
            style: {
              height: this.dataStore.tableHeight
            },
            slots: {
              component: ({ row }) => {
                return row.component ? row.component : "/Layout/Layout";
              },
              menuType: (data) => {
                const obj = {
                  M: "目录",
                  C: "菜单",
                  F: "按钮"
                };
                return obj[data.row.menuType];
              },
              icon: (data) => {
                return data.row.icon ? (
                  <SvgIcon icon-class={data.row.icon}></SvgIcon>
                ) : null;
              },
              status: (data) => {
                const obj = {
                  "0": {
                    border: "1px solid #d1e9ff",
                    background: "#e8f4ff",
                    color: "#1890ff"
                  },
                  "1": {
                    border: "1px solid #ffdbdb",
                    background: "#ffeded",
                    color: "#ff4949"
                  }
                };
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      style={{
                        ...obj[data.row.status],
                        width: "46px",
                        padding: "5px 0",
                        borderRadius: "4px",
                        margin: "0px"
                      }}
                    >
                      {data.row.status === "0" ? "正常" : "停用"}
                    </div>
                  </div>
                );
              }
            },
            ref: "tableRef"
          })}
        </div>
      </div>
    );
  }
}
