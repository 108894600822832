import request from "@/utils/request";

// 部门列表查询
export function getDeptListApi(params?: any) {
  return request({
    url: "/system/dept/list",
    method: "get",
    params
  });
}

// 部门树查询
export function getDeptTreeApi() {
  return request({
    url: "/system/user/deptTree",
    method: "get"
  });
}
// 添加部门列表
export function insertDeptListApi(data: any) {
  return request({
    url: "/system/dept",
    method: "post",
    data
  });
}

// 编辑部门列表
export function updateDeptListApi(data: any) {
  return request({
    url: "/system/dept",
    method: "put",
    data
  });
}

// 删除部门列表
export function deleteDeptListApi(data: any) {
  return request({
    url: `/system/dept/${data.id}`,
    method: "delete"
  });
}
//服务监控
export function getOperateApi() {
  return request({
    url: "/monitor/server",
    method: "get"
  });
}
//操作日志
export function getOperatesApi(params: any) {
  console.log(params, "-----params");
  return request({
    url: "/monitor/operlog/list",
    method: "get",
    params
  });
}
//删除操作日志内容
export function deleteOperates(data: any) {
  return request({
    url: `/monitor/operlog/${data.id}`,
    method: "delete"
  });
}
//清空
export function deleteEvery() {
  return request({
    url: `/monitor/operlog/clean`,
    method: "delete"
  });
}
//导出
export function ExportDeleteEvery(query: any) {
  return request({
    url: "/monitor/operlog/export",
    method: "post",
    responseType: "blob",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: query
  });
}
