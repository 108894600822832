import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import {
  Form,
  FormItem,
  Row,
  Col,
  OptionGroup,
  Option,
  Radio,
  RadioButton,
  Checkbox,
  CheckboxButton,
  Tooltip
} from "element-ui";
import { getSlot } from "@/utils/tsxHelper";
import { componentMap } from "./componentMap";
import { inputNumber } from "@/utils/validate";
import {
  setGridProp,
  setComponentProps,
  setItemComponentSlots,
  setTextPlaceholder,
  initModel
} from "./helper";
import { useDesign } from "@/hooks/web/useDesign";
import { findIndex } from "@/utils";
import { set } from "lodash-es";
import { FormProps } from "./types";
import { isString, isArray, isUnDef } from "@/utils/is";
import { SlFormItemWrapper } from "@/components/SlFormItemWrapper";
import Draggable from "vuedraggable";
import "./index.scss";

const { getPrefixCls } = useDesign();

const prefixCls = getPrefixCls("form");

@Component({
  name: "SlForm"
})
export default class SlForm extends Vue {
  $refs!: {
    formRef: any;
  };
  @Prop(Object) readonly designer: any;
  @Prop({
    type: Array,
    default: () => []
  })
  readonly schema: any;

  // 是否需要栅格布局
  @Prop({
    type: Boolean,
    default: true
  })
  readonly isCol: Boolean | undefined;

  // 表单数据对象
  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  readonly model: any;

  // 表单数据对象
  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  readonly value: any;

  // 是否自动设置placeholder
  @Prop({
    type: Boolean,
    default: true
  })
  readonly autoSetPlaceholder: any;

  // 是否自动设置placeholder
  @Prop({
    type: Boolean,
    default: false
  })
  readonly disabled: any;

  @Prop({
    type: Number,
    default: 20
  })
  readonly rowGutter: any;

  // 是否自定义内容
  @Prop({
    type: Boolean,
    default: false
  })
  readonly isCustom: any;

  // 表单label宽度
  @Prop({
    type: [String, Number],
    default: "auto"
  })
  readonly labelWidth: any;

  @Prop({
    type: [String],
    default: "left"
  })
  readonly labelPosition: any;

  // 表单类型
  @Prop({
    type: String,
    default: "edit"
  })
  readonly formType: any;

  private dataStore: any = {
    formModel: {},
    checkValueStyle: {
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      height: "100%"
    },
    labelPosition: "left"
  };

  private mergeProps: any = {};

  get formData(): any {
    return this.schema;
  }

  get newFormModel(): any {
    return JSON.stringify(this.dataStore.formModel);
  }

  @Emit("input")
  onInput(item: any) {
    return item;
  }

  @Watch("formData", { immediate: true })
  onChange(nVal: any[]) {
    if (nVal.length) {
      this.dataStore.formModel = Object.assign(
        initModel(
          nVal,
          Object.assign(this.dataStore.formModel, this.model, this.value)
        ),
        this.model
      );
    }
  }

  @Watch("newFormModel", { immediate: true })
  onChangeModel(val: any) {
    this.onInput(JSON.parse(val));
  }

  get getProps(): any {
    const propsObj = { ...this.$props, ...this.mergeProps };
    return propsObj;
  }

  private defaultComStyle: any = {
    width: "100%"
  };

  // 检验number
  private onInputMethod(value: any) {}

  private getPlaceholder = (item: any) => {
    return `请${item.type === "input" ? "输入" : "选择"}${item.label}`;
  };

  // 对表单赋值
  private setValues(data: Recordable = {}) {
    this.dataStore.formModel = Object.assign(this.dataStore.formModel, data);
  }

  private setProps(props: FormProps = {}) {
    this.mergeProps = Object.assign(this.mergeProps, props);
  }

  private delSchema(field: string) {
    const { schema } = this.getProps;
    const index = findIndex(schema, (v: FormSchema) => v.field === field);
    if (index > -1) {
      schema.splice(index, 1);
    }
  }

  private addSchema(formSchema: FormSchema, index?: number) {
    const { schema } = this.getProps;
    if (index !== void 0) {
      schema.splice(index, 0, formSchema);
      return;
    }
    schema.push(formSchema);
  }

  private setSchema(schemaProps: FormSetPropsType[]) {
    const { schema } = this.getProps;
    for (const v of schema) {
      for (const item of schemaProps) {
        if (v.field === item.field) {
          set(v, item.path, item.value);
        }
      }
    }
    this.$forceUpdate();
  }

  private getFormModel() {
    const { schema = [] } = this.getProps;
    const result: any = {};
    const keys: any[] = Object.keys(this.dataStore.formModel);
    keys.map((ele: any) => {
      const item: any = schema.find((zle: any) => zle.field === ele);
      if (
        item &&
        item.formType !== "check" &&
        item.component &&
        !["Divider"].includes(item.component) &&
        !item.componentProps.disabled
      ) {
        result[ele] = this.dataStore.formModel[ele];
      }
    });
    return result;
  }

  private getElFormRef() {
    return this.$refs.formRef;
  }

  private onChangeInput(options: any) {
    const { value, item, type } = options;
    this.dataStore.formModel[item.field] = inputNumber(value);
  }

  private onDragAdd(evt) {
    const newIndex = evt.newIndex;
    if (this.designer.widgetList[newIndex]) {
      this.designer.setSelected(this.designer.widgetList[newIndex]);
    }
    this.designer.emitHistoryChange();
    this.designer.emitEvent("field-selected", null);
  }

  private onDragUpdate() {
    this.designer.emitHistoryChange();
  }

  private onDragEnd(evt) {
    this.designer.isMove = false;
  }

  private checkMove(evt) {
    this.designer.isMove = true;
    return this.designer.checkWidgetMove(evt);
  }

  private isInput(item: any) {
    return item.component === "Input" && item.componentProps?.inputType;
  }

  private isInputNumber(item: any) {
    return this.isInput(item) && item.componentProps?.inputType === "number";
  }

  private isInputEmail(item: any) {
    return this.isInput(item) && item.componentProps?.inputType === "email";
  }

  private isInputPhone(item: any) {
    return this.isInput(item) && item.componentProps?.inputType === "phone";
  }

  private checkMobile = (rules: any, value: any, cb: Function) => {
    const regMobile =
      /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
    if (regMobile.test(value)) {
      return cb();
    }
    cb(new Error("请输入合法的手机号"));
  };

  render() {
    // 过滤传入Form组件的属性
    const getFormBindValue = () => {
      // 避免在标签上出现多余的属性
      const delKeys = [
        "schema",
        "isCol",
        "autoSetPlaceholder",
        "isCustom",
        "model",
        "value",
        "formType"
      ];
      const props = { ...this.$props, ...this.$attrs };
      for (const key in props) {
        if (delKeys.indexOf(key) !== -1) {
          delete props[key];
        }
      }
      return props;
    };

    // 渲染options
    const renderOptions = (item: FormSchema) => {
      switch (item.component) {
        case "Select":
          return renderSelectOptions(item);
        case "Radio":
        case "RadioButton":
          return renderRadioOptions(item);
        case "CheckboxGroup":
        case "CheckboxButton":
          return renderCheckboxOptions(item);
        default:
          break;
      }
    };
    const renderCheckboxOptions = (item: FormSchema) => {
      // 如果有别名，就取别名
      const labelAlias = item?.componentProps?.optionsAlias?.labelField;
      const valueAlias = item?.componentProps?.optionsAlias?.valueField;
      const Com =
        item.component === "CheckboxGroup" ? Checkbox : CheckboxButton;
      return item?.componentProps?.options?.map((option) => {
        return (
          <Com label={option[labelAlias || "value"]} disabled={this.disabled}>
            {option[valueAlias || "label"]}
          </Com>
        );
      });
    };

    const renderRadioOptions = (item: FormSchema) => {
      // 如果有别名，就取别名
      const labelAlias = item?.componentProps?.optionsAlias?.labelField;
      const valueAlias = item?.componentProps?.optionsAlias?.valueField;
      const Com = item.component === "Radio" ? Radio : RadioButton;
      return item?.componentProps?.options?.map((option) => {
        return (
          <Com label={option[labelAlias || "value"]} disabled={this.disabled}>
            {option[valueAlias || "label"]}
          </Com>
        );
      });
    };

    const renderSelectOptions = (item: FormSchema) => {
      // 如果有别名，就取别名
      const labelAlias = item?.componentProps?.optionsAlias?.labelField;
      return item?.componentProps?.options?.map((option) => {
        if (option?.options?.length) {
          return (
            <OptionGroup label={option[labelAlias || "label"]}>
              {option?.options?.map((v) => {
                return renderSelectOptionItem(item, v);
              })}
            </OptionGroup>
          );
        } else {
          return renderSelectOptionItem(item, option);
        }
      });
    };

    // 渲染 select option item
    const renderSelectOptionItem = (
      item: FormSchema,
      option: ComponentOptions
    ) => {
      // 如果有别名，就取别名
      const labelAlias = item?.componentProps?.optionsAlias?.labelField;
      const valueAlias = item?.componentProps?.optionsAlias?.valueField;
      const attrList = {
        label: option[labelAlias || "label"],
        value: option[valueAlias || "value"],
        key: option[valueAlias || "value"]
      };
      return (
        <Option {...{ attrs: attrList }}>
          {() => {
            item?.componentProps?.optionsSlot
              ? getSlot(this.$scopedSlots, `${item.field}-option`, {
                  item: option
                })
              : undefined;
          }}
        </Option>
      );
    };

    const renderFormDivider = (item: any) => {
      const Com = componentMap.Divider;
      const renderFormDividerComDom = () => {
        return item.componentProps.type === "dashed" ? (
          <div
            key={item.id ? item.id : item.field}
            style={item.componentProps.style}
          />
        ) : (
          <Com
            key={item.id ? item.id : item.field}
            {...{
              attrs: { contentPosition: "left", ...item.componentProps }
            }}
          >
            {item?.label}
          </Com>
        );
      };
      const renderFormDividerCom = () => {
        return this.designer && Object.keys(this.designer).length ? (
          <SlFormItemWrapper
            key={item.field}
            designer={this.designer}
            formItem={item}
            parentList={this.designer.widgetList}
            scopedSlots={{
              default: () => renderFormDividerComDom()
            }}
          ></SlFormItemWrapper>
        ) : (
          renderFormDividerComDom()
        );
      };
      return item.colProps ? (
        <Col
          key={item.id ? item.id : item.field}
          {...{ attrs: setGridProp(item.colProps) }}
        >
          {renderFormDividerCom()}
        </Col>
      ) : (
        renderFormDividerCom()
      );
    };

    const isShowForm = (item: any) => {
      if (!item.screen) return true;
      if (isUnDef(this.dataStore.formModel[item.screen.key])) return false;
      const isShow = isArray(item.screen.value)
        ? item.screen.value.includes(this.dataStore.formModel[item.screen.key])
        : isArray(this.dataStore.formModel[item.screen.key])
        ? this.dataStore.formModel[item.screen.key].includes(item.screen.value)
        : isString(this.dataStore.formModel[item.screen.key]) &&
          this.dataStore.formModel[item.screen.key].includes(",")
        ? this.dataStore.formModel[item.screen.key]
            .split(",")
            .includes(item.screen.value)
        : item.screen.value === this.dataStore.formModel[item.screen.key];
      // if (!isShow) {
      //   this.dataStore.formModel[item.field] =
      //     item.componentProps && item.componentProps.multiple ? [] : "";
      // }
      return isShow;
    };

    const renderFormItemContent = (
      item: any,
      slotsMap: any,
      notRenderOptions: any[]
    ) => {
      const Com = componentMap[item.component as string];
      const { autoSetPlaceholder } = this.getProps;
      const placeholder = autoSetPlaceholder && setTextPlaceholder(item);
      const componentAttrs = Object.assign(
        placeholder,
        setComponentProps(item),
        {
          disabled: item.componentProps?.disabled
            ? item.componentProps.disabled
            : this.disabled
        }
      );
      const renderFormItemContentSlots = () => {
        const slots = Object.keys(slotsMap);
        if (!slots.length) return null;
        return slots.map((zItem: any) => {
          return <i slot={zItem} />;
        });
      };
      const defaultStyle = ["Select", "Datepicker", "Cascader"].includes(
        item.component
      )
        ? { width: "100%" }
        : {};
      return item.formType && item.formType === "check" ? (
        renderCheckDom(item)
      ) : this.$slots[item.field] ? (
        getSlot(this.$scopedSlots, item.field, { item })
      ) : (
        <Com
          key={item.field}
          v-model={this.dataStore.formModel[item.field]}
          {...{ attrs: componentAttrs }}
          disabled={
            item.componentProps.disabled
              ? item.componentProps.disabled
              : this.disabled
          }
          style={{ ...defaultStyle, ...item.componentProps?.style }}
          scopedSlots={slotsMap}
        >
          {renderFormItemContentSlots()}
        </Com>
      );
    };
    const renderCheckDom = (item: any) => {
      const style = Object.assign(
        JSON.parse(JSON.stringify(this.dataStore.checkValueStyle)),
        item.componentProps?.style
      );
      const Com = componentMap[item.component as string];
      const realValue: any = this.dataStore.formModel[item.field]
        ? this.dataStore.formModel[item.field]
        : item.value;
      const isDisabled = (item: any) => {
        const dom: any = this.$refs[`${item.field}Ref`];
        if (!realValue || !dom) return true;
        return !(
          dom.clientWidth < dom.scrollWidth &&
          dom.clientHeight === dom.scrollHeight
        );
      };
      const renderCheckDomCom = () => {
        return this.$slots[item.field] || this.$scopedSlots[item.field] ? (
          getSlot(this.$scopedSlots, item.field, { item })
        ) : (
          <Tooltip
            effect={"dark"}
            content={String(realValue)}
            disabled={isDisabled(item)}
          >
            <span ref={`${item.field}Ref`} style={style}>
              {realValue}
              {item.formItemProps?.prompt?.value && realValue ? (
                <span
                  domProps-innerHTML={item.formItemProps?.prompt?.value}
                  style={item.formItemProps?.prompt?.style}
                />
              ) : null}
            </span>
          </Tooltip>
        );
      };
      return !item.component || item.component !== "Image" ? (
        renderCheckDomCom()
      ) : (
        <Com
          props={{
            model: this.dataStore.formModel[item.field] || item.value
          }}
          {...{ attrs: Object.assign(item.componentProps) }}
        />
      );
    };

    const renderOtherFormItemContent = (
      item: any,
      slotsMap: any,
      notRenderOptions: any[],
      styleList: any
    ) => {
      const Com = componentMap[item.component as string];
      const { autoSetPlaceholder } = this.getProps;
      const placeholder = autoSetPlaceholder && setTextPlaceholder(item);
      const componentAttrs = Object.assign(
        placeholder,
        setComponentProps(item),
        {
          disabled:
            item.componentProps && item.componentProps?.disabled
              ? item.componentProps.disabled
              : this.disabled
        }
      );
      const isCheck = item.formType && item.formType === "check";
      const isPrompt =
        item.formItemProps?.prompt?.value &&
        ["left", "right", "bottom"].includes(
          item.formItemProps?.prompt?.direction
        );
      const renderContentCom = () => {
        const renderContentComSlots = () => {
          const slots = Object.keys(slotsMap);
          if (!slots.length) return null;
          return slots.map((zItem: any) => {
            return <i slot={zItem}></i>;
          });
        };
        const defaultStyle = ["Select", "Datepicker", "Cascader"].includes(
          item.component
        )
          ? { width: "100%" }
          : {};
        return (
          <Com
            v-model={this.dataStore.formModel[item.field]}
            key={item.field}
            {...{ attrs: componentAttrs }}
            style={{ ...defaultStyle, ...item.componentProps?.style }}
            on={{ ...item.componentProps?.on }}
            disabled={
              item.componentProps && item.componentProps.disabled
                ? item.componentProps.disabled
                : this.disabled
            }
            scopedSlots={slotsMap}
          >
            {renderContentComSlots()}
          </Com>
        );
      };
      const renderContentPrompt = () => {
        return (
          <span
            domProps-innerHTML={item.formItemProps?.prompt?.value}
            style={item.formItemProps?.prompt?.style}
          />
        );
      };
      return this.$slots[item.field] ? (
        getSlot(this.$scopedSlots, item.field, { item })
      ) : isCheck ? (
        renderCheckDom(item)
      ) : isPrompt ? (
        <div style={styleList}>
          {["left"].includes(item.formItemProps?.prompt?.direction)
            ? renderContentPrompt()
            : null}
          {renderContentCom()}
          {["right", "bottom"].includes(item.formItemProps?.prompt?.direction)
            ? renderContentPrompt()
            : null}
        </div>
      ) : (
        renderContentCom()
      );
    };

    const renderFormItem = (item: FormSchema) => {
      const { formType } = this.getProps;
      // 单独给只有options属性的组件做判断
      const notRenderOptions = ["SelectV2", "Cascader", "Transfer"];
      const slotsMap: Recordable = {
        ...setItemComponentSlots(
          this.$scopedSlots,
          item?.componentProps?.slots,
          item.field
        )
      };
      const styleList: any = {
        display: "flex"
      };
      const isTopPrompt =
        item.formItemProps?.prompt?.value &&
        item.formItemProps?.prompt?.direction === "top";
      if (item.formItemProps?.prompt?.direction === "bottom") {
        styleList["flex-wrap"] = "wrap";
      }
      if (
        !["SelectV2", "Cascader"].includes(item?.component as string) &&
        item?.componentProps?.options
      ) {
        slotsMap.default = () => renderOptions(item);
      }
      const attrs = item?.formItemProps
        ? JSON.parse(JSON.stringify(item?.formItemProps))
        : {};
      delete attrs?.style;
      const renderFormItemCom = () => {
        const renderFormItemComLabel = () => {
          return item.label ? (
            <div class={"custom-label"} style={item.formItemProps?.labelStyle}>
              {this.$scopedSlots[`${item.field}-label-prefix`]
                ? getSlot(this.$scopedSlots, `${item.field}-label-prefix`, item)
                : null}
              {item.formItemProps?.hideLabel ? "" : item.label}
              {this.$scopedSlots[`${item.field}-label-suffix`]
                ? getSlot(this.$scopedSlots, `${item.field}-label-suffix`, item)
                : null}
            </div>
          ) : null;
        };
        const slots: any = {
          default: () => {
            return this.$scopedSlots[item.field]
              ? getSlot(this.$scopedSlots, item.field, item)
              : formType === "edit"
              ? isTopPrompt
                ? renderFormItemContent(item, slotsMap, notRenderOptions)
                : renderOtherFormItemContent(
                    item,
                    slotsMap,
                    notRenderOptions,
                    styleList
                  )
              : renderCheckDom(item);
          },
          label: () => renderFormItemComLabel()
        };
        return (
          <FormItem
            key={item.field}
            {...{ attrs: attrs }}
            class={[
              `${prefixCls}-item`,
              item.formItemProps?.labelPosition
                ? `${prefixCls}_${item.formItemProps?.labelPosition}`
                : `${prefixCls}_left`
            ]}
            prop={item.field}
            label={item.label || ""}
            style={item.formItemProps?.style}
            scopedSlots={slots}
          ></FormItem>
        );
      };
      const renderFormItemWrapper = () => {
        return isTopPrompt ? (
          <div key={item.field}>
            <div
              domProps-innerHTML={item.formItemProps?.prompt?.value}
              style={item.formItemProps?.prompt?.style}
            />
            {renderFormItemCom()}
          </div>
        ) : (
          renderFormItemCom()
        );
      };
      return this.designer && Object.keys(this.designer).length ? (
        <SlFormItemWrapper
          key={item.field}
          designer={this.designer}
          formItem={item}
          parentList={this.designer.widgetList}
          scopedSlots={{
            default: () => renderFormItemWrapper()
          }}
        ></SlFormItemWrapper>
      ) : (
        renderFormItemWrapper()
      );

      // return isTopPrompt ? (
      //   <div>
      //     <div
      //       domProps-innerHTML={item.formItemProps?.prompt?.value}
      //       style={item.formItemProps?.prompt?.style}
      //     />
      //     {renderFormItemCom()}
      //   </div>
      // ) : (
      //   renderFormItemCom()
      // );
    };

    // 是否要渲染el-col
    const renderFormItemWrap = () => {
      // hidden属性表示隐藏，不做渲染
      const { schema = [], isCol } = this.getProps;
      const renderFormItemWrapCom = () => {
        return schema
          .filter((v: any) => !v.hidden)
          .filter((v: any) => isShowForm(v))
          .map((item: any) => {
            // 如果是 Divider 组件，需要自己占用一行
            const isDivider: any = item.component === "Divider";
            const Com = componentMap.Divider;
            const isInputNumber = this.isInputNumber(item);
            const isInputEmail = this.isInputEmail(item);
            const isInputPhone = this.isInputPhone(item);
            const isDateRelation =
              item.component === "DatePicker" && item.relation;
            const timeJudge =
              item.component === "DatePicker" && item.componentProps?.judge;
            if (isInputNumber) {
              item.componentProps["on"] = Object.assign(
                item.componentProps.on ? item.componentProps.on : {},
                {
                  input: (value: any) =>
                    this.onChangeInput({
                      value,
                      item,
                      type: item.componentProps?.inputType
                    })
                }
              );
            }
            if (isInputPhone) {
              if (item.formItemProps.rules) {
                item.formItemProps.rules.push({
                  pattern: /^1[3456789]\d{9}$/,
                  trigger: ["change", "blur"],
                  message: `请输入正确的${item.label
                    .replace("：", "")
                    .replace(/[0-9]、/g, "")}`
                });
              } else {
                item.formItemProps["rules"] = [
                  {
                    pattern: /^1[3456789]\d{9}$/,
                    trigger: "blur",
                    message: `请输入正确的${item.label
                      .replace("：", "")
                      .replace(/[0-9]、/g, "")}`
                  }
                ];
              }
            }
            if (isDateRelation) {
              // 处理需要关联的时间数据
              item.componentProps.pickerOptions = {
                disabledDate: (time: any) => {
                  const judgeTime = this.dataStore.formModel[item.relation.key]
                    ? new Date(
                        this.dataStore.formModel[item.relation.key]
                      ).getTime()
                    : 0;
                  return this.dataStore.formModel[item.relation.key]
                    ? item.relation.value === ">"
                      ? time.getTime() < judgeTime
                      : time.getTime() > judgeTime
                    : time.getTime() < judgeTime;
                }
              };
            }
            if (timeJudge) {
              item.componentProps.pickerOptions = {
                ...item.componentProps?.pickerOptions,
                disabledDate: (time: any) => {
                  return item.componentProps?.judge === ">"
                    ? time.getTime() > Date.now()
                    : time.getTime() < Date.now();
                }
              };
            }
            const renderFormItemWrapComDom = () => {
              return isDivider ? (
                renderFormDivider(item)
              ) : isCol ? (
                // 如果需要栅格，需要包裹 ElCol
                <Col
                  key={item.id ? item.id : item.field}
                  {...{ attrs: setGridProp(item.colProps) }}
                >
                  {renderFormItem(item)}
                </Col>
              ) : (
                renderFormItem(item)
              );
            };
            return renderFormItemWrapComDom();
          });
      };
      return this.designer ? (
        <Draggable
          list={this.designer.widgetList}
          group={"dragGroup"}
          ghostClass={"ghost"}
          animation={300}
          handle={".drag-handler"}
          on={{
            add: this.onDragAdd,
            update: this.onDragUpdate,
            end: this.onDragEnd
          }}
          move={this.checkMove}
        >
          <transition-group
            name={"fade"}
            tag={"div"}
            class={`${prefixCls}-container`}
          >
            {renderFormItemWrapCom()}
          </transition-group>
        </Draggable>
      ) : (
        renderFormItemWrapCom()
      );
    };

    // 渲染包裹标签，是否使用栅格布局
    const renderWrap = () => {
      const { isCol }: any = this.getProps;
      const content = isCol ? (
        <Row gutter={this.rowGutter}>{renderFormItemWrap()}</Row>
      ) : (
        renderFormItemWrap()
      );
      return content;
    };
    return (
      <Form
        ref="formRef"
        {...{ attrs: getFormBindValue() }}
        props={{
          model: this.$props.isCustom
            ? this.$props.model
            : this.dataStore.formModel
        }}
        {...{
          nativeOn: {
            submit: (e: any) => {
              e.preventDefault();
              e.stopPropagation();
            }
          }
        }}
        class={[`${prefixCls}`, `${prefixCls}--label-${this.labelPosition}`]}
      >
        {this.$props.isCustom
          ? getSlot(this.$scopedSlots, "default")
          : renderWrap()}
      </Form>
    );
  }
}
