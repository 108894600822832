import { SlButton } from "@/components";

export function useButtons(options: ButtonsProps) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const that: any = this as any;
  const h = that.$createElement;
  const { status, row, buttons, style, rowIndex } = options;
  if (!buttons || !buttons.length) return null;
  const buttonCallBack: Function = options.callback
    ? options.callback
    : that.onClickMethod;
  return (
    <div class={`${options?.prefixCls || "use"}-buttons`} style={style}>
      {buttons.map((item: any, index: number) => {
        const getProps = () => {
          const obj: any = {};
          const keys: any = Object.keys(item);
          keys.forEach((ele: any) => {
            if (!["style"].includes(ele)) {
              obj[ele] = item[ele];
            }
          });
          return obj;
        };
        return (item.screen && item.screen.includes(status)) || !item.screen ? (
          <SlButton
            key={index}
            {...{
              attrs: getProps()
            }}
            customStyle={item?.style}
            title={item.label}
            on-click={buttonCallBack.bind(that, {
              item,
              row,
              rowIndex
            })}
          ></SlButton>
        ) : null;
      })}
    </div>
  );
}
