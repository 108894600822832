import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import i18n from "@/plugins/i18n";
import { useDesign } from "@/hooks/web/useDesign";
import { getSlot } from "@/utils/tsxHelper";
import "./index.scss";
import { SvgIcon } from "@/components";

const prefixCls = useDesign().getPrefixCls("field-wrapper");
@Component({
  name: "SlFormItemWrapper"
})
export default class SlFormItemWrapper extends mixins(i18n) {
  @Prop(Object) readonly designer: any;
  @Prop(Object) readonly formItem: any;
  @Prop(Object) readonly parentWidget: any;
  @Prop(Array) readonly parentList: any;

  private selectField(event) {
    if (this.designer) {
      this.designer.setSelected(this.formItem);
      this.designer.emitEvent("field-selected", this.parentWidget); //发送选中组件的父组件对象
    }
    // event.
  }

  private selectParentWidget() {
    if (this.parentWidget) {
      this.designer.setSelected(this.parentWidget);
    } else {
      this.designer.clearSelected();
      console.log(this.designer, "------");
    }
  }

  private moveUpWidget() {
    const index = this.parentList.findIndex(
      (ele: any) => ele.field === this.formItem.field
    );
    this.designer.moveUpWidget(this.parentList, index);
    this.designer.emitHistoryChange();
  }

  private moveDownWidget() {
    const index = this.parentList.findIndex(
      (ele: any) => ele.field === this.formItem.field
    );
    this.designer.moveDownWidget(this.parentList, index);
    this.designer.emitHistoryChange();
  }

  private removeFieldWidget() {
    const index = this.parentList.findIndex(
      (ele: any) => ele.field === this.formItem.field
    );
    if (this.parentList) {
      let nextSelected = null;
      if (this.parentList.length === 1) {
        if (this.parentWidget) {
          nextSelected = this.parentWidget;
        }
      } else if (this.parentList.length === 1 + index) {
        nextSelected = this.parentList[index - 1];
      } else {
        nextSelected = this.parentList[index + 1];
      }

      this.$nextTick(() => {
        this.parentList.splice(index, 1);
        //if (!!nextSelected) {
        this.designer.setSelected(nextSelected);
        //}

        this.designer.emitHistoryChange();
      });
    }
  }
  render() {
    console.log(this.formItem);
    const renderAction = () => {
      return this.designer.selectedId === this.formItem.field &&
        !this.designer.isMove ? (
        <div class={`${prefixCls}-action`}>
          <i class="el-icon-back" on-click={this.selectParentWidget}></i>
          {this.parentList && this.parentList.length ? (
            <i class="el-icon-top" on-click={this.moveUpWidget}></i>
          ) : null}
          {this.parentList && this.parentList.length ? (
            <i class="el-icon-bottom" on-click={this.moveDownWidget}></i>
          ) : null}
          <i class="el-icon-delete" on-click={this.removeFieldWidget}></i>
        </div>
      ) : null;
    };
    const renderHandler = () => {
      return this.designer.selectedId === this.formItem.field &&
        !this.designer.isMove ? (
        <div class={[`${prefixCls}-handler`, "drag-handler"]}>
          <i class="el-icon-rank"></i>
          <i>{this.formItem.label ? this.formItem.label : "勾选框"}</i>
          <SvgIcon
            iconClass={`icon-password-${
              this.formItem.hidden ? "close" : "open"
            }`}
          ></SvgIcon>
        </div>
      ) : null;
    };
    return (
      <div
        class={[
          prefixCls,
          {
            "design-time-bottom-margin": !!this.designer,
            selected:
              this.designer.selectedId === this.formItem.field &&
              !this.designer.isMove
          }
        ]}
        on-click={this.selectField}
      >
        {this.$scopedSlots.default
          ? getSlot(this.$scopedSlots, "default")
          : null}
        {renderAction()}
        {renderHandler()}
      </div>
    );
  }
}
