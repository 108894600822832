import { Tooltip } from "element-ui";
import { SvgIcon } from "@/components";

export function useIconTooltip(options: any) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const that: any = this as any;
  const h = that.$createElement;
  const { props, style, icon } = options;
  const defaultProps: any = {
    placement: "top",
    effect: "dark"
  };
  const nProps: any = { ...defaultProps, ...props };
  return (
    <Tooltip {...{ attrs: nProps }}>
      <SvgIcon style={style} iconClass={icon ? icon : "icon-explain"}></SvgIcon>
    </Tooltip>
  );
}
