import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Input } from "element-ui";
import { useDesign } from "@/hooks/web/useDesign";
import "@/styles/index.scss";
import "./index.scss";
import { getSlot } from "@/utils/tsxHelper";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("input-password");

@Component({
  name: "InputPassword"
})
export default class InputPassword extends Vue {
  $refs!: {
    inputRef: any;
  };
  @Prop({
    type: Boolean,
    default: false
  })
  readonly strength: any;

  @Prop({
    type: Array,
    default: () => {
      return [6, 16];
    }
  })
  readonly limitLength: any;

  @Prop({
    type: String
  })
  readonly value: any;

  @Emit("input")
  onChangeModelValue(val: any) {
    return val;
  }

  get getIconName(): any {
    return `icon-password-${this.textType === "password" ? "close" : "open"}`;
  }

  get getPasswordStrength(): any {
    return this.checkPasswordLevel(this.dataStore.inputValue);
  }

  get getProps(): any {
    const whiteArr: any[] = ["strength", "model"];
    const obj: any = {};
    for (const key in this.$props) {
      if (!whiteArr.includes(key)) {
        obj[key] = this.$props[key];
      }
    }
    const result = { ...this.$attrs, ...obj };
    return result;
  }

  private dataStore: any = {
    inputValue: "",
    tipData: [
      {
        label: "含有数字",
        prop: "hasNumber"
      },
      {
        label: "含有小写字母",
        prop: "hasLowerLetter"
      },
      {
        label: "含有大写字母",
        prop: "hasUpperLetter"
      },
      {
        label: "含有特殊字符",
        prop: "hasSpecialSymbols"
      },
      {
        label: `{}位字符及以上`,
        prop: "isRequiredNumber"
      }
    ],
    iconWidth: 0
  };

  private checkPasswordLevel(value) {
    //验证密码强度
    const obj: any = {
      hasNumber: () => /[0-9]/.test(value),
      hasLowerLetter: () => /[a-z]/.test(value),
      hasUpperLetter: () => /[A-Z]/.test(value),
      hasSpecialSymbols: () => /[^0-9a-zA-Z_]/.test(value),
      isRequiredNumber: () =>
        this.limitLength[0] === this.limitLength[1]
          ? value.length >= this.limitLength[0]
          : value.length >= this.limitLength[0] &&
            value.length <= this.limitLength[1]
    };
    const result: any = {
      score: -1
    };
    Object.keys(obj).forEach((key) => {
      const judge = value && obj[key]();
      if (judge) {
        result.score++;
      }
      result[key] = judge;
    });
    return result;
  }
  @Watch("value", { immediate: true })
  onChangeModel(val: any) {
    this.dataStore.inputValue = val;
  }

  @Watch("dataStore.inputValue", { immediate: true })
  onChangeValue(val: any) {
    this.onChangeModelValue(val);
  }

  private textType = "password";

  private changeTextType() {
    this.textType = this.textType === "password" ? "text" : "password";
  }

  mounted() {
    this.$nextTick(() => {
      if (this.$refs.inputRef) {
        const node = this.$refs.inputRef.$el.children[0];
        const styleList = getComputedStyle(node, null);
        this.dataStore.iconWidth = styleList.paddingLeft;
      }
    });
  }

  render() {
    const renderIcon = () => {
      return this.$scopedSlots.prefix ? (
        <div
          style={{ width: this.dataStore.iconWidth }}
          class={`${prefixCls}-prefix-icon`}
        >
          {getSlot(this.$scopedSlots, "prefix")}
        </div>
      ) : null;
    };
    // 渲染输入框
    const renderInput = () => {
      const scopedSlots = {
        suffix: () => {
          return (
            <i
              class={["iconfont", this.getIconName]}
              on-click={this.changeTextType}
            ></i>
          );
        }
      };
      return (
        <Input
          ref={"inputRef"}
          class={`${prefixCls}__input`}
          v-model={this.dataStore.inputValue}
          {...{ attrs: this.getProps }}
          type={this.textType}
          scopedSlots={scopedSlots}
        ></Input>
      );
    };
    // 渲染密码强度
    const renderStrength = () => {
      return this.strength ? (
        <div class={[`${prefixCls}__bar`]}>
          <div
            class={`${prefixCls}__bar--fill`}
            data-score={this.getPasswordStrength.score}
          ></div>
        </div>
      ) : null;
    };

    const renderTip = () => {
      return this.strength ? (
        <div class={`${prefixCls}-tip`}>
          {this.dataStore.tipData.map((ele) => {
            return (
              <div
                class={[
                  `${prefixCls}-tip-item`,
                  {
                    "is-active": this.getPasswordStrength[ele.prop],
                    "is-error":
                      this.dataStore.inputValue &&
                      !this.getPasswordStrength[ele.prop]
                  }
                ]}
              >
                ·{" "}
                {ele.prop === "isRequiredNumber"
                  ? this.limitLength[0] === this.limitLength[1]
                    ? ele.label.replace("{}", this.limitLength[0])
                    : `${this.limitLength[0]} ~ ${this.limitLength[1]}字符之间`
                  : ele.label}
              </div>
            );
          })}
        </div>
      ) : null;
    };
    return (
      <div class={[prefixCls]}>
        {renderIcon()}
        {renderInput()}
        {renderStrength()}
        {renderTip()}
      </div>
    );
  }
}
