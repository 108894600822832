import { useDesign } from "@/hooks/web/useDesign";
import { Component, Vue } from "vue-property-decorator";
import "./index.scss";
const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("empty-page");

@Component({
  name: "EmptyPageIndex"
})
export default class EmptyPageIndex extends Vue {
  get routeList() {
    const meta: any = this.$route.meta;
    return meta.title;
  }
  render() {
    return (
      <div class={prefixCls}>
        <div class={`${prefixCls}-content`}>
          {this.routeList}模块正在开发中，请等候...
        </div>
      </div>
    );
  }
}
