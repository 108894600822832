import { Component, Vue } from "vue-property-decorator";
import errGif from "@/assets/401-images/401.gif";
import { Button, Row, Col, Dialog } from "element-ui";
import "./401.scss";

@Component({
  name: "Page401"
})
export default class Page401 extends Vue {
  private errGif = errGif + "?" + +new Date();
  private ewizardClap =
    "https://wpimg.wallstcn.com/007ef517-bafd-4066-aae4-6883632d9646";
  private dialogVisible = false;

  private back() {
    if (this.$route.query.noGoBack) {
      this.$router.push({ path: "/dashboard" }).catch((err) => {
        console.warn(err);
      });
    } else {
      this.$router.go(-1);
    }
  }
  render() {
    return (
      <div class="errPage-container">
        <Button icon="el-icon-arrow-left" class="back-btn" on-click={this.back}>
          返回
        </Button>
        <Row>
          <Col span={12}>
            <h1 class="text-jumbo text-ginormous">Oops!</h1>
            gif来源
            <a href="https://zh.airbnb.com/" target="_blank">
              airbnb
            </a>{" "}
            页面
            <h2>你没有权限去该页面</h2>
            <h6>如有不满请联系你领导</h6>
            <ul class="list-unstyled">
              <li>或者你可以去:</li>
              <li class="link-type">
                <router-link to="/dashboard"> 回首页 </router-link>
              </li>
              <li class="link-type">
                <a href="https://github.com/armour">随便看看</a>
              </li>
              <li>
                <a href="#" on-click={(this.dialogVisible = true)}>
                  点我看图
                </a>
              </li>
            </ul>
          </Col>
          <Col span={12}>
            <img
              src={this.errGif}
              class="some-gif"
              width="313"
              height="428"
              alt="Girl has dropped her ice cream."
            />
          </Col>
        </Row>
        <Dialog
          visible={this.dialogVisible}
          on={{
            "update:visible": (val: any) => (this.dialogVisible = val)
          }}
          title="随便看"
        >
          <img src={this.ewizardClap} class="some-img" />
        </Dialog>
      </div>
    );
  }
}
