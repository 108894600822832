import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import {
  filterEmptyField,
  formatTime,
  publicDealTableData,
  publicDelete,
  publicDownFile
} from "@/utils";
import { Message, Tag, Tree } from "element-ui";
import {
  getLoginLogListApi,
  exportLoginLogApi,
  cleanLoginApi,
  unlockLoginApi,
  getLoginstatusApi,
  deleteLoginLogApi
} from "@/api/system/loginLog";
import { useComProps } from "@/utils/useComProps";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("system-logLogin-list");
@Component({
  name: "SystemLogLoginList"
})
export default class SystemLogLoginList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  private comLoginLogProps: any = useComProps.call(this, { prefixCls });
  private dataStore: any = {
    isLoading: false,
    treeIsLoading: false,
    title: "角色列表",
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNum: 1
    },
    total: 0,
    tableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: []
  };

  // 遍历结构树
  private deepDealTreeData(data: any[], parentId: any) {
    return data.map((ele: any) => {
      ele.label = ele.title;
      if (!ele.parentId && ele.parentId !== 0) {
        ele.parentId = parentId;
      }
      if (ele.children) {
        this.deepDealTreeData(ele.children, ele.id);
      }
      return ele;
    });
  }

  private deepScreenAllParent(data: any[], id: any, result: any[]) {
    if (!id) return;
    data.forEach((item, index) => {
      if (item.id === id) {
        if (!result.includes(item.id)) {
          result.unshift(item.id);
        }
        this.deepScreenAllParent(
          this.dataStore.treeData,
          item.parentId,
          result
        );
      } else {
        if (item.children) {
          this.deepScreenAllParent(item.children, id, result);
        }
      }
    });
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      export: () => {
        const params: any = this.getParamsList();
        const filterParams = filterEmptyField(params);
        exportLoginLogApi(filterParams).then((res) => {
          if (res) {
            publicDownFile(res, "登录日志列表");
            Message.success("导出成功！");
          }
        });
      },
      deleteBatch: () => {
        if (this.dataStore.selectData.length) {
          const names: any[] = this.dataStore.selectData.map(
            (ele: any) => ele.name
          );
          const ids: any = [];
          this.dataStore.selectData.forEach((ele: any) => {
            ids.push(ele.infoId);
          });
          this.comLoginLogProps.openDialogPage(this, {
            width: "586px",
            title: `删除`,
            titleSlots: {
              prefix: () => {
                return (
                  <i
                    class={"el-icon-warning"}
                    style={{
                      marginRight: "18px",
                      color: "#E34D59",
                      fontSize: "24px"
                    }}
                  ></i>
                );
              }
            },
            contentSlots: () => {
              return <div>{` 是否确认删除访问编号【${ids}】？`}</div>;
            },
            onConfirm: (callback) => {
              deleteLoginLogApi(ids.join(",")).then((res: any) => {
                Message.success(`删除成功`);
                callback && callback();
                this.getTableData();
              });
            }
          });
        } else {
          Message.warning("请先选择要删除的数据");
        }
      },
      clean: () => {
        publicDelete({
          message: `是否确认清空所有登录日志项？`,
          title: "确认信息",
          callback: () => {
            cleanLoginApi().then((res: any) => {
              Message.success("操作成功");
              this.getTableData();
            });
          }
        });
      },
      unlock: () => {
        if (this.dataStore.selectData.length) {
          if (this.dataStore.selectData.length > 1) {
            Message.error("每次只能单选一项进行解锁");
            return;
          }
          publicDelete({
            message: `是否确认解锁用户【${this.dataStore.selectData[0].userName}】？`,
            title: "确认信息",
            callback: () => {
              unlockLoginApi(this.dataStore.selectData[0].userName).then(
                (res: any) => {
                  Message.success("操作成功");
                  this.getTableData();
                }
              );
            }
          });
        } else {
          Message.warning("请先选择要解锁的数据");
        }
      },
      refresh: () => {
        this.comLoginLogProps.clearFormList(this, "filterRef");
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      deleteRow: () => {
        // publicDelete({
        //   message: `是否强退定时任务【${row.name}】？`,
        //   title: "确认信息",
        //   callback: () => {
        //     this.deleteDataList([row.id], [row.name]);
        //   }
        // });
        this.comLoginLogProps.openDialogPage(this, {
          width: "586px",
          title: `强退`,
          titleSlots: {
            prefix: () => {
              return (
                <i
                  class={"el-icon-warning"}
                  style={{
                    marginRight: "18px",
                    color: "#E34D59",
                    fontSize: "24px"
                  }}
                ></i>
              );
            }
          },
          contentSlots: () => {
            return <div>{` 是否强退名称为【${row.userName}】的用户？`}</div>;
          },
          onConfirm: (callback) => {
            this.deleteDataList(row.tokenId, callback);
          }
        });
      }
    };
    obj[item.prop] && obj[item.prop]();
  }

  // 强退数据
  private deleteDataList(id: any, callback) {
    const params: any = id;
    cleanLoginApi(params).then(() => {
      Message.success(`强退用户成功`);
      callback && callback();
      this.getTableData();
    });
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNum = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }
  private getParamsList() {
    const formList: any = this.comLoginLogProps.getFormValue(this, "filterRef");
    const params: any = {
      ...formList,
      ...this.dataStore.queryList,
      "params[beginTime]": formList?.loginTime?.[0]
        ? `${formList?.loginTime?.[0]} 00:00:00`
        : void 0,
      "params[endTime]": formList?.loginTime?.[1]
        ? `${formList?.loginTime?.[1]} 23:59:59`
        : void 0
    };
    delete params.loginTime;
    return filterEmptyField(params);
  }
  private getTableData() {
    this.dataStore.isLoading = true;
    const params: any = this.getParamsList();
    getLoginLogListApi(filterEmptyField(params))
      .then((res: any) => {
        if (res) {
          this.dataStore.tableData = publicDealTableData(
            res.rows,
            this.dataStore.queryList,
            { current: "pageNum", size: "pageSize" }
          );
          this.dataStore.total = res.total;
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }

  mounted() {
    this.comLoginLogProps.dealContentTableHeight(this, ["header", "filter"]);
    this.getTableData();
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.comLoginLogProps.renderForm({
              ref: "filterRef",
              schema: config.LoginLogPage.filterSchema,
              slots: {
                handle: (data) => {
                  return (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {this.comLoginLogProps.renderButtons({
                        buttons: data.componentProps.buttons
                      })}
                    </div>
                  );
                },
                tableHandle: (data) => {
                  return (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {this.comLoginLogProps.renderButtons({
                        buttons: data.componentProps.buttons
                      })}
                    </div>
                  );
                }
              }
            })}
          </div>
          {this.comLoginLogProps.renderTable({
            tableData: this.dataStore.tableData,
            tableHeader: config.LoginLogPage.tableHeader,
            isShowCheck: true,
            queryList: this.dataStore.queryList,
            total: this.dataStore.total,
            isLoading: this.dataStore.isLoading,
            paginationLayout: "sizes, prev, pager, next, slot, jumper",
            style: {
              height: this.dataStore.tableHeight
            },
            slots: {
              loginTime: ({ row }) => {
                return formatTime(row.loginTime, "yyyy-MM-dd HH:mm:ss");
              },
              status: ({ row }) => {
                const items = config.LoginLogPage.filterSchema;
                const bItem: any = items.find(
                  (ele: any) => ele.field === "status"
                );
                const sItem = bItem.componentProps.options.find(
                  (ele: any) => ele.value === row.status
                );
                return (
                  <Tag type={row.status === "1" ? "danger" : "success"}>
                    {sItem.label}
                  </Tag>
                );
              }
            },
            ref: "tableRef"
          })}
        </div>
      </div>
    );
  }
}
