import request from "@/utils/request";
import ipList from "@/utils/ipConfig";

export function getMenuTreeSelectApi() {
  return request({
    url: ipList.system.menu.treeSelect,
    method: "get"
  });
}
// 获取菜单列表信息
export function getMenuListApi(params) {
  return request({
    url: "/system/menu/list",
    method: "get",
    params
  });
}

// 新增菜单列表信息
export function insertMenuListApi(data) {
  return request({
    url: "/system/menu",
    method: "post",
    data
  });
}

// 编辑菜单列表信息
export function updateMenuListApi(data) {
  return request({
    url: "/system/menu",
    method: "put",
    data
  });
}

// 删除菜单列表信息
export function deleteMenuListApi(id) {
  return request({
    url: `/system/menu/${id}`,
    method: "delete"
  });
}

// 获取角色菜单信息
export function getRoleMenuIdsApi(params) {
  return request({
    url: "/system/menu/getRoleMenuList",
    method: "get",
    params
  });
}
