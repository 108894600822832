import { Component, Prop, Vue } from "vue-property-decorator";
import { isExternal } from "@/utils/validate";
import { useDesign } from "@/hooks/web/useDesign";
import { getSlot } from "@/utils/tsxHelper";

const prefixCls = useDesign().getPrefixCls("sidebar-item-link");
@Component({
  name: "SidebarItemLink"
})
export default class SidebarItemLink extends Vue {
  @Prop({ required: true }) private to!: string;

  private isExternal = isExternal;
  render() {
    const renderContent = () => {
      const slotMap: any = {};
      slotMap.default = this.$scopedSlots.default
        ? getSlot(this.$scopedSlots, "default")
        : null;
      return isExternal(this.to) ? (
        <a href={this.to} target="_blank" rel="noopener">
          {slotMap.default}
        </a>
      ) : (
        <router-link to={this.to}>{slotMap.default}</router-link>
      );
    };
    return <div class={`${prefixCls}`}>{renderContent()}</div>;
  }
}
