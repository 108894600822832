import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { useDesign } from "@/hooks/web/useDesign";
import { Button } from "element-ui";
import { SvgIcon } from "@/components/SvgIcon";
import "./index.scss";

const prefixCls = useDesign().getPrefixCls("button");
@Component({
  name: "SlButton"
})
export default class SlButton extends Vue {
  @Prop({
    type: String,
    default: ""
  })
  readonly prefixIcon: any;

  @Prop({
    type: String,
    default: ""
  })
  readonly suffixIcon: any;

  @Prop({
    type: String,
    default: ""
  })
  readonly title: any;

  @Prop({
    type: String,
    default: ""
  })
  readonly className: any;

  @Prop(Object) readonly customStyle: any;

  get getAttrs(): any {
    const obj: any = {};
    const keys: any = Object.keys(this.$attrs);
    keys.forEach((ele: any) => {
      if (!["style", "label", "prop"].includes(ele)) {
        obj[ele] = this.$attrs[ele];
      }
    });
    return obj;
  }
  get getStyle(): any {
    const attrs: any = this.$attrs;
    return attrs.style ? { ...attrs.style } : {};
  }

  @Emit("click")
  onClick(item?: any) {
    return item;
  }

  private onClickButton(e: any) {
    this.onClick(e);
  }

  render() {
    const renderIcon = (icon: any) => {
      return icon ? (
        <SvgIcon
          iconClass={icon}
          className={this.className}
          style={{
            marginRight: this.suffixIcon ? "0" : "4px",
            marginLeft: this.suffixIcon ? "4px" : "0"
          }}
        ></SvgIcon>
      ) : null;
    };
    return (
      <Button
        props={{ ...this.getAttrs }}
        class={this.prefixIcon || this.suffixIcon ? `${prefixCls}` : ""}
        style={this.customStyle}
        on-click={(e: any) => this.onClickButton(e)}
      >
        {renderIcon(this.prefixIcon)}
        {this.title}
        {renderIcon(this.suffixIcon)}
      </Button>
    );
  }
}
