import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Dialog, Button } from "element-ui";
import { useDesign } from "@/hooks/web/useDesign";
import { isFunction } from "@/utils/is";
import { getSlot } from "@/utils/tsxHelper";
import "./index.scss";

const prefixCls = useDesign().getPrefixCls("messagebox");

@Component({
  name: "SlMessageBox"
})
export default class SlMessageBox extends Vue {
  @Prop({
    type: Boolean,
    default: false
  })
  readonly visible: any;

  @Prop({
    type: Function,
    default: () => {}
  })
  readonly close: any;

  @Watch("visible", { immediate: true })
  onChangeVisible(val: any) {
    this.showDialog = val;
  }

  @Watch("showDialog", { immediate: true })
  onChangeDialog(val: any) {
    if (!val) {
      this.close();
    }
  }

  showDialog = false;
  type = "info";
  title = "提示";
  align = "right";
  showClose = true;
  showCancelButton = false;
  showConfirmButton = true;
  cancelButtonText = "取消";
  confirmButtonText = "确定";
  cancelButtonStyle = {};
  confirmButtonStyle = {};
  dangerouslyUseHTMLString = false;
  customClass = "";
  dialogCustomClass = "";
  width = "30%";
  top = "15vh";
  onCloseDialog: any = null;
  content = "";
  onCancel: any = null;
  onConfirm: any = null;
  closeOnClickModal: any = true;
  fullscreen: any = false;
  destroyOnClose = false;

  buttonData: any = [
    {
      label: this.cancelButtonText,
      prop: "cancel",
      style: this.cancelButtonStyle
    },
    {
      label: this.confirmButtonText,
      prop: "nowAdd",
      type: "primary",
      style: this.confirmButtonStyle
    }
  ];

  private onClickMethod(item: any) {
    const obj: any = {
      cancel: () => {
        this.onCancel && isFunction(this.onCancel)
          ? this.onCancel(this.closeDialog)
          : this.onCloseDialog && isFunction(this.onCloseDialog)
          ? this.onCloseDialog(this.closeDialog)
          : (this.showDialog = false);
      },
      confirm: () => {
        this.onConfirm && isFunction(this.onConfirm)
          ? this.onConfirm(this.closeDialog)
          : (this.showDialog = false);
      }
    };
    if (!obj[item.prop]) return;
    obj[item.prop]();
  }

  private handleAfterLeave() {
    (this.$el as any).parentNode.removeChild(this.$el);
  }

  private closeDialog() {
    this.showDialog = false;
  }

  render() {
    const renderButton = (buttons: any[], status?: any) => {
      return buttons.map((item: any, index: number) => {
        return (item.screen && item.screen.includes(status)) || !item.screen ? (
          <Button
            key={index}
            type={item.type}
            icon={item.icon}
            plain={item.plain}
            style={item.style}
            on-click={this.onClickMethod.bind(this, item)}
          >
            {item.label}
          </Button>
        ) : null;
      });
    };
    const renderDom = (field: any) => {
      return this.$slots[field] || this.$scopedSlots[field] ? (
        getSlot(this.$scopedSlots, field)
      ) : field === "footer" ? (
        renderButton(this.buttonData)
      ) : (
        <div
          class={`${prefixCls}-wrap-${field}`}
          domProps-InnerHTML={(this as any)[field]}
        ></div>
      );
    };
    const renderDialog = () => {
      const sArr = Object.keys(this.$scopedSlots);
      const scopedSlots: any = {};
      sArr.forEach((ele: any) => {
        if (ele !== "content") {
          scopedSlots[ele] = () => renderDom(ele);
        }
      });
      return (
        <Dialog
          v-elDragDialog
          title={this.title}
          visible={this.showDialog}
          width={this.width}
          top={this.top}
          on-close={
            this.onCloseDialog
              ? this.onCloseDialog.bind(this, this.closeDialog)
              : this.closeDialog
          }
          show-close={this.showClose}
          close-on-click-modal={this.closeOnClickModal}
          fullscreen={this.fullscreen}
          class={[`${prefixCls}-dialog`, this.dialogCustomClass]}
          custom-class={this.customClass}
          destroy-on-close={this.destroyOnClose}
          scopedSlots={scopedSlots}
        >
          {renderDom("content")}
          <span slot="footer">{renderButton(this.buttonData)}</span>
        </Dialog>
      );
    };
    return (
      <transition
        name="message-fade"
        mode="out-in"
        on-after-leave={this.handleAfterLeave}
      >
        {renderDialog()}
      </transition>
    );
  }
}
