import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { Tree, Select, Option, Checkbox } from "element-ui";
import { screenListForTree } from "@/utils";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";
import { isArray } from "@/utils/is";
const { getPrefixCls } = useDesign();

const prefixCls = getPrefixCls("tree-select");
@Component({
  name: "SlTreeSelect"
})
export default class SlTreeSelect extends Vue {
  $refs!: {
    treeRef: any;
    selectRef: any;
  };
  @Prop({
    type: [String, Number, Array]
  })
  value: any;

  @Prop({
    type: Object,
    default: () => {
      return {
        data: [],
        defaultExpandAll: true,
        nodeKey: "id",
        props: {
          label: "label",
          children: "children"
        }
      };
    }
  })
  readonly treeProps: any;
  @Emit("input")
  onChangeModelValue(val: any) {
    return val;
  }

  @Emit("change")
  onChangeValue(val: any) {
    return val;
  }
  @Watch("value", { immediate: true })
  onChangeModel(val: any) {
    this.dataStore.realValue = isArray(val) ? Array.from(new Set(val)) : val;
    if (this.treeProps.data.length) {
      this.changeLabel(this.dataStore.realValue);
    }
  }

  @Watch("dataStore.expanded")
  onChangeExpanded(val: any) {
    const nodes: any = this.$refs.treeRef.store.nodesMap;
    for (const i in nodes) {
      nodes[i].expanded = val;
    }
  }

  @Watch("dataStore.allCheck")
  onChangeCheck(val: any) {
    this.changeTreeCheckStatus(val);
    this.dealInputList();
  }

  private dataStore: any = {
    realValue: [],
    selectValue: "",
    inputValue: "",
    optionValue: "",
    expanded: true,
    allCheck: false,
    checkStrictly: true
  };
  get getSelectProps(): any {
    const whiteArr: any[] = ["strength", "model", "treeProps", "value"];
    const params: any = {
      filterable: true,
      "collapse-tags": true,
      ...this.$attrs,
      ...this.$props
    };
    const obj: any = {};
    for (const key in params) {
      if (!whiteArr.includes(key)) {
        obj[key] = params[key];
      }
    }
    return obj;
  }
  get getTreeProps() {
    const attrs: any = this.$attrs;
    const result: any = {
      showCheckbox: !!attrs.multiple,
      defaultExpandAll: true,
      "check-on-click-node": !!attrs.multiple,
      "check-strictly": !this.dataStore.checkStrictly,
      "expand-on-click-node": false,
      "highlight-current": !attrs.multiple,
      ...attrs.treeProps,
      ...this.$props.treeProps
    };
    if (attrs.multiple) {
      result["default-checked-keys"] = isArray(this.dataStore.realValue)
        ? this.dataStore.realValue
        : [this.dataStore.realValue];
    } else {
      result["currentNodeKey"] = this.dataStore.realValue;
    }
    //currentNodeKey
    return result;
  }

  get getNodeLabel() {
    const { props } = this.$props.treeProps;
    const label: any = props && props.label ? props.label : "label";
    return label;
  }

  get getNodeKey() {
    const key =
      this.$props.treeProps["node-key"] ||
      this.$props.treeProps["nodeKey"] ||
      "id";
    return key;
  }

  // 改变勾选状态
  private changeTreeCheckStatus(status: boolean) {
    const nodes: any = this.$refs.treeRef.store.nodesMap;
    for (const i in nodes) {
      nodes[i].checked = status;
      if (!status) {
        nodes[i].halfChecked = status;
      }
    }
  }

  private changeLabel(value: any) {
    const { data, props } = this.$props.treeProps;
    const label: any = this.getNodeLabel;
    const key = this.getNodeKey;
    const results = screenListForTree(
      data,
      isArray(value) ? value : [value],
      [],
      {
        ...props,
        key: key
      }
    );
    const isMultiple = this.getSelectProps.multiple;
    if (results.length) {
      this.dataStore.inputValue = isMultiple
        ? results.map((ele: any) => ele)
        : results[0];
      this.dataStore.selectValue = isMultiple
        ? results.map((ele: any) => ele[label])
        : results[0][label];
    } else {
      this.dataStore.inputValue = isMultiple ? [] : "";
      this.dataStore.selectValue = isMultiple ? [] : "";
    }
  }
  private onClearable() {
    if (this.getSelectProps["multiple"]) {
      this.dataStore.inputValue = [];
      this.dataStore.realValue = [];
      this.dataStore.allCheck = false;
      this.$refs.treeRef.setCheckedNodes([]);
    } else {
      this.$refs.treeRef.setCurrentKey(null);
    }
  }

  private onVisibleChange(visible: any) {
    if (visible) {
      setTimeout(() => {
        const elements: any = document.getElementsByClassName(prefixCls);
        const eleArr = Array.from(elements);
        const parent: any = eleArr.find((ele: any) => {
          return ele.style.display !== "none";
        });
        const node: any =
          !this.getSelectProps.multiple && this.dataStore.realValue
            ? Array.from(
                this.$refs.treeRef.$el.getElementsByClassName("is-current")
              )[0]
            : null;
        const dropdown = parent.getElementsByClassName(
          "el-select-dropdown__wrap"
        )[0];
        if (dropdown) {
          dropdown.scrollTop =
            this.dataStore.realValue && node && !this.getSelectProps.multiple
              ? node.offsetTop - 30
              : 0;
        }
      }, 0);
    }
  }
  private onRemoveTag(value: any) {
    const label: any = this.getNodeLabel;
    const nodeKey: any = this.getNodeKey;
    const checkedNodes = this.$refs.treeRef.getCheckedNodes();
    const checkNode: any = checkedNodes.find(
      (ele: any) => ele[label] === value
    );
    this.$refs.treeRef.setChecked(checkNode[nodeKey], false, true);
    this.dealInputList();
  }

  // 搜索功能
  private onFilterMethod(value: any) {
    this.$refs.treeRef.filter(value);
  }

  private onFilterNodeMethod(value: string, data: any) {
    if (!value) return true;
    return data.label.indexOf(value) !== -1;
  }

  private onNodeClick(item: any) {
    const nodeKey: any = this.getNodeKey;
    if (this.getSelectProps.multiple) return;
    this.onChangeValue(item[nodeKey]);
    this.onChangeModelValue(item[nodeKey]);
    this.$refs.selectRef.visible = false;
  }

  private dealInputList() {
    const checkedKeys = this.$refs.treeRef.getCheckedKeys();
    const halfCheckedKeys = this.$refs.treeRef.getHalfCheckedKeys();
    const keys = this.dataStore.checkStrictly
      ? [...checkedKeys]
      : [...checkedKeys];
    const realKeys = Array.from(new Set(keys));
    this.onChangeValue(realKeys);
    this.onChangeModelValue(realKeys);
  }

  private onCheckChange(data: any, options: any) {
    this.dealInputList();
  }

  private onChangeBox(value: any, type: string) {}

  mounted() {
    // if (this.treeProps.data.length) {
    //   this.changeLabel(this.value);
    // }
  }

  render() {
    const labelStr: any = this.getNodeLabel;
    const label =
      isArray(this.dataStore.inputValue) && this.dataStore.inputValue.length
        ? this.dataStore.inputValue[0][labelStr]
        : "";
    const value =
      isArray(this.dataStore.realValue) && this.dataStore.realValue.length
        ? this.dataStore.realValue[0]
        : "";
    const renderTool = () => {
      return (
        <div class={`${prefixCls}-header`}>
          <Checkbox
            v-model={this.dataStore.expanded}
            onChange={(value: any) => this.onChangeBox(value, "expanded")}
          >
            展开/折叠
          </Checkbox>
          <Checkbox
            v-model={this.dataStore.allCheck}
            onChange={(value: any) => this.onChangeBox(value, "allCheck")}
          >
            全选/全不选
          </Checkbox>
          {/*<Checkbox*/}
          {/*  v-model={this.dataStore.checkStrictly}*/}
          {/*  onChange={(value: any) => this.onChangeBox(value, "checkStrictly")}*/}
          {/*>*/}
          {/*  父子联动*/}
          {/*</Checkbox>*/}
        </div>
      );
    };
    return (
      <Select
        v-model={this.dataStore.selectValue}
        placeholder="请选择"
        {...{ props: this.getSelectProps }}
        on-clear={this.onClearable}
        on-visible-change={this.onVisibleChange}
        on-remove-tag={this.onRemoveTag}
        filter-method={this.onFilterMethod}
        value-key={"id"}
        popper-class={prefixCls}
        ref={"selectRef"}
      >
        {this.getSelectProps["multiple"] ? renderTool() : null}
        <Option
          disabled={true}
          key={value}
          value={value}
          label={label}
          style={{ height: "auto" }}
        >
          <Tree
            ref={"treeRef"}
            {...{ props: this.getTreeProps }}
            filter-node-method={this.onFilterNodeMethod}
            on={{
              "node-click": this.onNodeClick,
              check: this.onCheckChange
            }}
            class={`${prefixCls}-content`}
          ></Tree>
        </Option>
      </Select>
    );
  }
}
