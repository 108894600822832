import { useDesign } from "@/hooks/web/useDesign";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SvgIcon } from "@/components";
import { InputNumber, Option, Select } from "element-ui";
const prefixCls = useDesign().getPrefixCls("crontab-day");
@Component({
  name: "CrontabDay"
})
export default class CrontabDay extends Vue {
  @Prop({
    type: Function
  })
  readonly check: any;

  @Prop({
    type: Object
  })
  readonly cron: any;
  private daysArr = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 19, 30, 31
  ];
  private radioValue: any = 1;
  private workday: any = 1;
  private cycle01: any = 1;
  private cycle02: any = 2;
  private average01: any = 1;
  private average02: any = 1;
  private checkboxList: any = [];
  private checkNum: any = this.$options?.propsData?.["check"];
  @Watch("radioValue", { immediate: true })
  radioChangeChange() {
    this.radioChange();
  }
  @Watch("cycleTotal", { immediate: true })
  cycleTotalChange() {
    this.cycleChange();
  }
  @Watch("averageTotal", { immediate: true })
  averageTotalChange() {
    this.averageChange();
  }
  @Watch("workdayCheck", { immediate: true })
  workdayCheckChange() {
    this.workdayChange();
  }
  @Watch("checkboxString", { immediate: true })
  checkboxStringChange() {
    this.checkboxChange();
  }
  mounted() {
    // const _options: any = this.$options;
    // console.log('xxxxx');
    // this.checkNum= _options.propsData.check
  }
  // 单选按钮值变化时
  private radioChange() {
    ("day rachange");
    if (this.radioValue !== 2 && this.cron.week !== "?") {
      this.$emit("update", "week", "?", "day");
    }

    switch (this.radioValue) {
      case 1:
        this.$emit("update", "day", "*");
        break;
      case 2:
        this.$emit("update", "day", "?");
        break;
      case 3:
        this.$emit("update", "day", this.cycleTotal);
        break;
      case 4:
        this.$emit("update", "day", this.averageTotal);
        break;
      case 5:
        this.$emit("update", "day", this.workday + "W");
        break;
      case 6:
        this.$emit("update", "day", "L");
        break;
      case 7:
        this.$emit("update", "day", this.checkboxString);
        break;
    }
    ("day rachange end");
  }
  // 周期两个值变化时
  private cycleChange() {
    if (this.radioValue == "3") {
      this.$emit("update", "day", this.cycleTotal);
    }
  }
  // 平均两个值变化时
  private averageChange() {
    if (this.radioValue == "4") {
      this.$emit("update", "day", this.averageTotal);
    }
  }
  // 最近工作日值变化时
  private workdayChange() {
    if (this.radioValue == "5") {
      this.$emit("update", "day", this.workdayCheck + "W");
    }
  }
  // checkbox值变化时
  private checkboxChange() {
    if (this.radioValue == "7") {
      this.$emit("update", "day", this.checkboxString);
    }
  }
  get cycleTotal() {
    const cycle01 = this.checkNum(this.cycle01, 1, 30);
    const cycle02 = this.checkNum(
      this.cycle02,
      cycle01 ? cycle01 + 1 : 2,
      31,
      31
    );
    return cycle01 + "-" + cycle02;
  }
  get averageTotal() {
    const average01 = this.checkNum(this.average01, 1, 30);
    const average02 = this.checkNum(this.average02, 1, 31 - average01 || 0);
    console.log(average01, "....average01");

    return average01 + "/" + average02;
  }
  get workdayCheck() {
    const workday = this.checkNum(this.workday, 1, 31);
    return workday;
  }
  get checkboxString() {
    const str = this.checkboxList.join();
    return str == "" ? "*" : str;
  }
  render() {
    return (
      <div class={prefixCls}>
        <el-form size="small">
          <el-form-item>
            <el-radio v-model={this.radioValue} label={1}>
              日，允许的通配符[, - * ? / L W]
            </el-radio>
          </el-form-item>
          <el-form-item>
            <el-radio v-model={this.radioValue} label={2}>
              不指定
            </el-radio>
          </el-form-item>
          <el-form-item>
            <el-radio v-model={this.radioValue} label={3}>
              周期从
              <InputNumber
                v-model={this.cycle01}
                min={1}
                max={30}
              ></InputNumber>
              -
              <InputNumber
                v-model={this.cycle02}
                min={this.cycle01 ? this.cycle01 + 1 : 2}
                max={31}
              ></InputNumber>
              日
            </el-radio>
          </el-form-item>
          <el-form-item>
            <el-radio v-model={this.radioValue} label={4}>
              从
              <InputNumber
                v-model={this.average01}
                min={1}
                max={30}
              ></InputNumber>
              号开始，
              <InputNumber
                v-model={this.average02}
                min={1}
                max={31 - this.average01 || 1}
              ></InputNumber>
              日执行一次
            </el-radio>
          </el-form-item>
          <el-form-item>
            <el-radio v-model={this.radioValue} label={5}>
              每月
              <InputNumber
                v-model={this.average01}
                min={1}
                max={31}
              ></InputNumber>
              号最近的那个工作日
            </el-radio>
          </el-form-item>
          <el-form-item>
            <el-radio v-model={this.radioValue} label={6}>
              本月最后一天
            </el-radio>
          </el-form-item>
          <el-form-item>
            <el-radio v-model={this.radioValue} label={7}>
              指定
              <Select
                multiple={true}
                style={{ width: "100%", marginLeft: "8px" }}
                placeholder="可多选"
                v-model={this.checkboxList}
              >
                {this.daysArr.map((e: any) => {
                  return <Option value={e}>{e}</Option>;
                })}
              </Select>
            </el-radio>
          </el-form-item>
        </el-form>
      </div>
    );
  }
}
