import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from "vuex-module-decorators";
import { getToken, setToken, removeToken } from "@/utils/cookies";
import router, { resetRouter } from "@/router";
import { PermissionModule } from "./permission";
import { TagsViewModule } from "./tags-view";
import {
  loginApi,
  logoutApi,
  getUserInfoApi,
  loginByAccountApi
} from "@/api/system/login";
import store from "@/store";

const config = require("@/config/config.json");

export interface IUserState {
  token: string;
  name: string | null;
  avatar: string;
  introduction: string;
  roles: string[];
  email: string;
}

@Module({ dynamic: true, store, name: "user" })
export default class User extends VuexModule implements IUserState {
  public token = getToken() || "";
  public name = "";
  public avatar = "";
  public introduction = "";
  public roles: string[] = [];
  public email = "";
  public userInfo: any = {};

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token;
  }

  @Mutation
  private SET_UserInfo(userInfo: Object) {
    this.userInfo = userInfo;
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name;
  }

  @Mutation
  private REMOVE_NAME() {
    this.name = "";
  }

  @Mutation
  private SET_AVATAR(avatar: string) {
    this.avatar = avatar;
  }

  @Mutation
  private SET_INTRODUCTION(introduction: string) {
    this.introduction = introduction;
  }

  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles;
  }

  @Mutation
  private SET_EMAIL(email: string) {
    this.email = email;
  }

  @Action({ rawError: true })
  public setLoginMsg(options: any) {
    const { userResult, userInfo } = options;
    setToken(userResult.token);
    this.SET_TOKEN(userResult.token);
    this.SET_UserInfo(userResult.user);
    console.log(userResult);
    localStorage.setItem("username", userInfo.username);
  }

  @Action({ rawError: true })
  public async Login(userInfo: { username: string; password: string }) {
    const userResult: any = await loginApi(userInfo);
    if (userResult) {
      this.setLoginMsg({ userResult, userInfo });
    }
    return userResult;
  }

  @Action({ rawError: true })
  public async LoginByAccount(account: any) {
    localStorage.clear();
    const userResult: any = await loginByAccountApi({ username: account });
    // const userResult: any = await loginApi(userInfo);
    if (userResult) {
      setToken(userResult.token);
      this.SET_TOKEN(userResult.token);
      this.SET_UserInfo(userResult.user);
      localStorage.setItem("username", account);
      await this.GetUserInfo();
      resetRouter();
      // Generate dynamic accessible routes based on roles
      await PermissionModule.GenerateRoutes(UserModule.userInfo.id);
      // Add generated routes
      PermissionModule.dynamicRoutes.forEach((route) => {
        router.addRoute(route);
      });
    }
    return userResult;
  }

  @Action
  public ResetToken() {
    removeToken();
    this.SET_TOKEN("");
    this.SET_ROLES([]);
    this.SET_NAME("");
  }

  @Action
  public async GetUserInfo() {
    if (this.token === "") {
      throw Error("token 未定义");
    }
    const userInfo: any = await getUserInfoApi();
    // const userInfo: any = {
    //   name: "11111"
    // };
    if (userInfo) {
      this.SET_NAME(userInfo.user.nickName);
      this.SET_UserInfo(userInfo.user);
    }
  }

  @Action
  public async ChangeRoles() {
    await this.GetUserInfo();
    resetRouter();
    // Generate dynamic accessible routes based on roles
    await PermissionModule.GenerateRoutes(UserModule.userInfo.id);
    // Add generated routes
    PermissionModule.dynamicRoutes.forEach((route) => {
      router.addRoute(route);
    });
    // Reset visited views and cached views
    TagsViewModule.delAllViews();
  }

  @Action
  public async LogOut() {
    if (this.token === "") {
      throw Error("LogOut: token is undefined!");
    }
    // await logoutApi();
    this.ClearLoginMsg();
  }

  @Action
  public ClearLoginMsg() {
    removeToken();
    resetRouter();
    this.REMOVE_NAME();
    TagsViewModule.delAllViews();
    this.SET_TOKEN("");
    this.SET_ROLES([]);
    localStorage.clear();
  }
}

export const UserModule = getModule(User);
