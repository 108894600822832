import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import { publicDealTableData, publicDelete } from "@/utils";
import { Col, Message, Row, Tree } from "element-ui";

import { useComProps } from "@/utils/useComProps";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";
import {
  clearCacheAllApi,
  clearCacheKeyApi,
  clearCacheNameApi,
  getCachesNamesApi,
  getCachesTokensApi,
  getCachesValuesApi
} from "@/api/monitor/cachesList";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("system-role");
@Component({
  name: "SystemRoleList"
})
export default class SystemRoleList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  private comCacheListProps: any = useComProps.call(this, { prefixCls });
  private dataStore: any = {
    isLoading: false,
    treeIsLoading: false,
    title: "角色列表",
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNumber: 1
    },
    total: 0,
    tableData: [{ status: 1 }],
    tableDatas: [{}],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: []
  };

  private handleNodeClick(node: any) {}

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNumber = 1;
      },
      deleTokensRow: () => {
        clearCacheKeyApi(row.name).then((res: any) => {
          Message.success(`清理缓存键名${row.name}成功`);
          getCachesValuesApi(row.name).then((res: any) => {
            this.comCacheListProps.setFormValues(this, "getValuesFormRef", {
              ...res
            });
          });
        });
      },
      deleNameRow: () => {
        clearCacheKeyApi(row.cacheName).then((res: any) => {
          Message.success(`清理缓存名称${row.cacheName}成功`);
          getCachesTokensApi(row.name).then((res: any) => {
            this.dataStore.tableData2 = res;
            this.comCacheListProps.dealContentTableHeight(
              this,
              ["header", "filter"],
              { tableRef: "tableRef2" }
            );
          });
        });
        // this.comCacheListProps.openDialogPage(this, {
        //   width: "586px",
        //   title: `删除`,
        //   titleSlots: {
        //     prefix: () => {
        //       return (
        //         <i
        //           class={"el-icon-warning"}
        //           style={{
        //             marginRight: "18px",
        //             color: "#E34D59",
        //             fontSize: "24px"
        //           }}
        //         ></i>
        //       );
        //     }
        //   },
        //   contentSlots: () => {
        //     return <div>{` 是否删除字典编号为【${row.dictId}】？`}</div>;
        //   },
        //   onConfirm: (callback) => {
        //     this.deleteDataList(row.cachekey, callback,);
        //   }
        // });
      }
    };
    obj[item.prop] && obj[item.prop]();
  }
  private refreshHandle(num) {
    switch (num) {
      case 1:
        Message.success("刷新缓存名称成功");
        this.dataStore.tokenName = void 0;
        this.getTableData();
        //清空上次选中值
        this.dataStore.tableData2 = [];
        this.comCacheListProps.clearFormList(this, "getValuesFormRef");
        break;
      case 2:
        Message.success("刷新键名成功");
        getCachesTokensApi(this.dataStore.tokenName).then((res: any) => {
          this.dataStore.tableData2 = res;
        });
        break;
      case 3:
        this.dataStore.tokenName = void 0;
        clearCacheAllApi().then((res: any) => {
          Message.success("清理全部成功");
          this.getTableData();
        });
        break;

      default:
        break;
    }
  }
  private handleRole(options: any) {
    console.log(options, "-----optionsoptionsoptions");
  }
  private getTableData() {
    this.dataStore.isLoading = true;
    getCachesNamesApi()
      .then((res: any) => {
        if (res) {
          this.dataStore.tableData = res;
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }
  mounted() {
    this.comCacheListProps.dealContentTableHeight(this, ["header", "filter"]);
    this.comCacheListProps.dealContentTableHeight(this, ["header", "filter"], {
      tableRef: "tableRef2"
    });
    this.getTableData();
  }
  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNum = item.value;
        // this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        // this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
      },
      rowClick: () => {
        const kyes = Object.keys(item.data.row) || [];
        const api = kyes.includes("cacheName")
          ? getCachesTokensApi
          : getCachesValuesApi;
        this.dataStore.isLoading = true;
        api(
          kyes.includes("cacheName")
            ? item.data.row.cacheName
            : item.data.row.name
        )
          .then((res: any) => {
            if (res) {
              if (kyes.includes("cacheName")) {
                this.dataStore.tokenName = item.data.row.name;
                this.dataStore.tableData2 = res;
                this.comCacheListProps.dealContentTableHeight(
                  this,
                  ["header", "filter"],
                  { tableRef: "tableRef2" }
                );
              } else {
                this.comCacheListProps.setFormValues(this, "getValuesFormRef", {
                  ...res
                });
              }
            }
            this.dataStore.isLoading = false;
          })
          .catch(() => {
            this.dataStore.isLoading = false;
          });
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }
  render() {
    return (
      <div class={`${prefixCls}`}>
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <Row gutter={20} style={{ height: "100%" }}>
            <Col
              span={8}
              class={`${prefixCls}-content-main`}
              style={{ height: "100%" }}
            >
              <div
                class={`${prefixCls}-content-l-top`}
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  fontSize: "18px"
                }}
              >
                <div>缓存列表</div>
                <div
                  on-click={this.refreshHandle.bind(this, 1)}
                  style={"color:#1890ff;fontSize:18px;margin-left:auto"}
                  class={"el-icon-refresh-left"}
                ></div>
              </div>
              {this.comCacheListProps.renderTable({
                tableData: this.dataStore.tableData,
                tableHeader: config.cacheList.tableHeader,
                tableProps: config.cacheList.tableProps,
                isLoading: this.dataStore.isLoading,
                isPagination: false,
                style: {
                  height: this.dataStore.tableHeight
                },
                ref: "tableRef"
              })}
            </Col>
            <Col
              span={8}
              class={`${prefixCls}-content-main`}
              style={{ height: "100%" }}
            >
              <div
                class={`${prefixCls}-content-l-top`}
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  fontSize: "18px"
                }}
              >
                <div>键名列表</div>
                <div
                  on-click={this.refreshHandle.bind(this, 2)}
                  style={"color:#1890ff;fontSize:18px;margin-left:auto"}
                  class={"el-icon-refresh-left"}
                ></div>
              </div>
              {this.comCacheListProps.renderTable({
                tableData: this.dataStore.tableData2,
                tableHeader: config.cacheLists.tableHeader,
                tableProps: config.cacheList.tableProps,
                isLoading: this.dataStore.isLoading,
                isPagination: false,
                style: {
                  height: this.dataStore.tableHeight
                },
                ref: "tableRef2"
              })}
            </Col>
            <Col span={8} class={`${prefixCls}-content-main`}>
              <div
                class={`${prefixCls}-content-l-top`}
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  fontSize: "18px"
                }}
              >
                <div>缓存内容</div>
                <div
                  on-click={this.refreshHandle.bind(this, 3)}
                  style={"color:#1890ff;fontSize:14px;margin-left:auto"}
                >
                  清理全部
                </div>
              </div>
              {
                // monitor.getValuesForm
                this.comCacheListProps.renderForm({
                  ref: "getValuesFormRef",
                  schema: config.monitor.getValuesForm,
                  formList: this.dataStore.formList
                })
              }
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
