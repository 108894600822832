import request from "@/utils/request";

export function getMemberListApi(params?: any) {
  return request({
    url: "/business/member/list",
    method: "get",
    params
  });
}

/**
 * 新增场馆信息
 */
export function insertMemberApi(data: any) {
  return request({
    url: `/business/member/insert`,
    method: "post",
    data
  });
}

/**
 * 修改使用次数
 */
export function changeUseNumApi(data: any) {
  return request({
    url: `/business/member/changeUseNum`,
    method: "post",
    data
  });
}

/**
 * 修改使用状态
 */
export function changeStatusApi(data: any) {
  return request({
    url: `/business/member/changeStatus`,
    method: "post",
    data
  });
}

/**
 * 修改场馆信息
 */
export function updateMemberApi(data: any) {
  return request({
    url: `/business/member/update`,
    method: "post",
    data
  });
}

export function deleteMemberListApi(ids: any) {
  return request({
    url: `/business/member/remove/${ids}`,
    method: "get"
  });
}

export function memberExportApi(data: any) {
  return request({
    url: `/business/member/export`,
    method: "post",
    responseType: "blob",
    data
  });
}
