import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import {
  filterEmptyField,
  publicDealTableData,
  publicDelete,
  publicDownFile,
  replaceObjectKey
} from "@/utils";
import { Message, Tag, Tree } from "element-ui";
import {
  getConfigListApi,
  insetConfigApi,
  updateConfigApi,
  deleteConfigApi,
  exportConfigApi
} from "@/api/system/config";
import { useComProps } from "@/utils/useComProps";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("system-config");
@Component({
  name: "SystemConfigList"
})
export default class SystemConfigList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  private comRoleProps: any = useComProps.call(this, { prefixCls });
  private dataStore: any = {
    isLoading: false,
    treeIsLoading: false,
    title: "参数设置",
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNum: 1
    },
    total: 0,
    tableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: []
  };

  private handleNodeClick(node: any) {}

  // 操作参数信息
  private handleRole(options: any) {
    console.log(options, "-----optionsoptionsoptions");
    const { callback, row, title } = options;
    const elRef: any = this.comRoleProps.getFormElRef(this, "dialogRef");
    this.comRoleProps.checkFormRequire(elRef, () => {
      const params: any = this.comRoleProps.getFormValue(this, "dialogRef");
      const api: any = row ? updateConfigApi : insetConfigApi;
      if (row && row.configId) {
        params["configId"] = row.configId;
      }
      api(title && row ? row : params).then((res: any) => {
        Message.success(
          `${row ? "编辑" : "添加"}参数${title ? title : ""}成功`
        );
        this.getTableData();
        callback && callback();
      });
    });
  }

  // 遍历结构树
  private deepDealTreeData(data: any[], parentId: any) {
    return data.map((ele: any) => {
      ele.label = ele.title;
      if (!ele.parentId && ele.parentId !== 0) {
        ele.parentId = parentId;
      }
      if (ele.children) {
        this.deepDealTreeData(ele.children, ele.id);
      }
      return ele;
    });
  }

  private deepScreenAllParent(data: any[], id: any, result: any[]) {
    if (!id) return;
    data.forEach((item, index) => {
      if (item.id === id) {
        if (!result.includes(item.id)) {
          result.unshift(item.id);
        }
        this.deepScreenAllParent(
          this.dataStore.treeData,
          item.parentId,
          result
        );
      } else {
        if (item.children) {
          this.deepScreenAllParent(item.children, id, result);
        }
      }
    });
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      export: () => {
        const params: any = this.getParamsList();
        const filterParams = filterEmptyField(params);
        exportConfigApi(filterParams).then((res) => {
          if (res) {
            publicDownFile(res, "参数设置列表");
            Message.success("导出成功！");
          }
        });
      },
      refresh: () => {
        this.comRoleProps.clearFormList(this, "filterRef");
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      create: () => {
        this.comRoleProps.openDialogPage(this, {
          width: "650px",
          title: "新增参数",
          confirmButtonText: "立即添加",
          schema: config.configPage.dialogSchema,
          contentSlots: {},
          onConfirm: (callback: any) => this.handleRole({ callback })
        });
      },
      deleteBatch: () => {
        if (this.dataStore.selectData.length) {
          const names: any[] = this.dataStore.selectData.map(
            (ele: any) => ele.name
          );
          // publicDelete({
          //   message: `是否删除通知【${names.join(",")}】？`,
          //   title: "确认信息",
          //   callback: () => {
          const ids: any = [];
          this.dataStore.selectData.forEach((ele: any) => {
            ids.push(ele.configId);
          });
          //     this.deleteDataList(ids, names);
          //   }
          // });
          this.comRoleProps.openDialogPage(this, {
            width: "586px",
            title: `删除`,
            titleSlots: {
              prefix: () => {
                return (
                  <i
                    class={"el-icon-warning"}
                    style={{
                      marginRight: "18px",
                      color: "#E34D59",
                      fontSize: "24px"
                    }}
                  ></i>
                );
              }
            },
            contentSlots: () => {
              return <div>{` 是否确认删除参数编号【${ids}】？`}</div>;
            },
            onConfirm: (callback) => {
              deleteConfigApi(ids.join(",")).then((res: any) => {
                Message.success(`删除成功`);
                callback && callback();
                this.getTableData();
              });
            }
          });
        } else {
          Message.warning("请先选择要删除的数据");
        }
      },
      editRow: () => {
        this.comRoleProps.openDialogPage(this, {
          width: "650px",
          title: "编辑参数",
          schema: config.configPage.dialogSchema,
          formList: row,
          onConfirm: (callback: any) => this.handleRole({ callback, row })
        });
      },
      deleteRow: () => {
        // publicDelete({
        //   message: `是否删除通知【${row.name}】？`,
        //   title: "确认信息",
        //   callback: () => {
        //     this.deleteDataList([row.id], [row.name]);
        //   }
        // });
        this.comRoleProps.openDialogPage(this, {
          width: "586px",
          title: `删除`,
          titleSlots: {
            prefix: () => {
              return (
                <i
                  class={"el-icon-warning"}
                  style={{
                    marginRight: "18px",
                    color: "#E34D59",
                    fontSize: "24px"
                  }}
                ></i>
              );
            }
          },
          contentSlots: () => {
            return <div>{` 是否删除通知编号为【${row.configId}】？`}</div>;
          },
          onConfirm: (callback) => {
            this.deleteDataList(row.configId, callback);
          }
        });
      }
    };
    obj[item.prop] && obj[item.prop]();
  }

  private getParamsList() {
    const formList: any = this.comRoleProps.getFormValue(this, "filterRef");
    const params: any = {
      ...formList,
      ...this.dataStore.queryList,
      "params[beginTime]": formList?.createTime?.[0],
      "params[endTime]": formList?.createTime?.[1]
    };
    delete params.createTime;
    return filterEmptyField(params);
  }
  // 删除数据
  private deleteDataList(id: any, callback) {
    const params: any = id;
    deleteConfigApi(params).then(() => {
      Message.success(`删除通知【${id}】成功`);
      callback && callback();
      this.getTableData();
    });
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNum = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }

  private getTableData() {
    this.dataStore.isLoading = true;
    const filterList: any = this.comRoleProps.getFormValue(this, "filterRef");
    const params: any = this.getParamsList();
    getConfigListApi(params)
      .then((res: any) => {
        if (res) {
          this.dataStore.tableData = publicDealTableData(
            res.rows,
            this.dataStore.queryList,
            { current: "pageNum", size: "pageSize" }
          );
          this.dataStore.total = res.total;
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }

  mounted() {
    this.comRoleProps.dealContentTableHeight(this, ["header", "filter"]);
    this.getTableData();
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        <div class={`${prefixCls}-header`}>{this.dataStore.title}</div>
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.comRoleProps.renderForm({
              ref: "filterRef",
              schema: config.configPage.filterSchema,
              slots: {
                handle: (data) => {
                  return (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {this.comRoleProps.renderButtons({
                        buttons: data.componentProps.buttons
                      })}
                    </div>
                  );
                },
                tableHandle: (data) => {
                  return (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {this.comRoleProps.renderButtons({
                        buttons: data.componentProps.buttons
                      })}
                    </div>
                  );
                }
              }
            })}
          </div>
          {this.comRoleProps.renderTable({
            tableData: this.dataStore.tableData,
            tableHeader: config.configPage.tableHeader,
            isShowCheck: true,
            queryList: this.dataStore.queryList,
            total: this.dataStore.total,
            isLoading: this.dataStore.isLoading,
            paginationLayout: "sizes, prev, pager, next, slot, jumper",
            style: {
              height: this.dataStore.tableHeight
            },
            slots: {
              configType: ({ row }) => {
                const items = config.configPage.filterSchema;
                const bItem: any = items.find(
                  (ele: any) => ele.field === "configType"
                );
                const sItem = bItem.componentProps.options.find(
                  (ele: any) => ele.value === row.configType
                );
                return (
                  <Tag type={row.configType === "N" ? "danger" : ""}>
                    {sItem.label}
                  </Tag>
                );
              }
            },
            ref: "tableRef"
          })}
        </div>
      </div>
    );
  }
}
