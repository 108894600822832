import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { useDesign } from "@/hooks/web/useDesign";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { IEditorConfig } from "@wangeditor/editor";
import "./index.scss";
import { uploadFileApi } from "@/api/upload";

const prefixCls = useDesign().getPrefixCls("wang-editor");
@Component({
  name: "SlWangEditor"
})
export default class SlWangEditor extends Vue {
  @Prop({
    type: String,
    default: ""
  })
  readonly value: any;
  @Prop({
    type: Object,
    default: (val) => {
      return {};
    }
  })
  readonly uEditorConfig: any;

  @Prop({
    type: Boolean,
    default: false
  })
  readonly isReal: any;

  @Watch("value", { immediate: true })
  onChangeValue(val: any, oldVal: any) {
    if (val && val !== oldVal) {
      this.setContentText(val);
    }
  }
  private editorConfig: Partial<IEditorConfig> = {
    placeholder: "请输入内容...",
    MENU_CONF: {
      uploadImage: {
        // 小于该值就插入 base64 格式（而不上传），默认为 0
        base64LimitSize: 5 * 1024, // 5kb
        // 单个文件的最大体积限制，默认为 2M
        // maxFileSize: 1 * 1024 * 1024, // 1M
        // // 最多可上传几个文件，默认为 100
        // maxNumberOfFiles: 5,
        // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
        allowedFileTypes: ["image/*"],
        // 自定义上传
        async customUpload(file, insertFn) {
          // 文件上传
          const formData = new FormData();
          formData.append("file", file);
          const result: any = await uploadFileApi(formData);
          // 插入到富文本编辑器中，主意这里的三个参数都是必填的，要不然控制台报错：typeError: Cannot read properties of undefined (reading 'replace')
          insertFn(result.url, result.newFilename, result.newFilename);
        }
      }
    }
  };
  private dataStore: any = {
    editor: null,
    ready: false,
    editorValue: "",
    toolbarConfig: {},
    editorConfig: {
      placeholder: "请输入内容...",
      MENU_CONF: {}
    },
    mode: "default"
  };

  @Emit("input")
  onInput(item: any) {
    return item;
  }
  get randomId() {
    return `editor_${Math.random() * 100000000000000000}`;
  }
  private setContentText(val: any) {
    this.dataStore.editorValue = val;
  }

  private onCreated(editor: any) {
    this.dataStore.editor = Object.seal(editor);
  }

  private onChange(editor: any) {
    const html = editor.getHtml();
    this.onInput(html);
  }

  private initEditor() {}

  beforeDestroy() {
    const editor = this.dataStore.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  }
  mounted() {
    this.initEditor();
  }
  render() {
    return (
      <div class={prefixCls}>
        <Toolbar
          editor={this.dataStore.editor}
          defaultConfig={this.dataStore.toolbarConfig}
          mode={this.dataStore.mode}
          style="border: 1px solid #ccc"
        ></Toolbar>
        <Editor
          v-model={this.dataStore.editorValue}
          defaultConfig={this.editorConfig}
          mode={this.dataStore.mode}
          on-onCreated={this.onCreated}
          on-onChange={this.onChange}
          style={this.$attrs.style}
        ></Editor>
      </div>
    );
  }
}
