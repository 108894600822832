import { Vue, Component, Prop } from "vue-property-decorator";
import "./index.scss";
import { stopPropagation } from "@/utils";

@Component({
  name: "SvgIcon"
})
export default class SvgIcon extends Vue {
  @Prop({
    type: String,
    required: true
  })
  readonly iconClass: any;
  @Prop({
    type: String,
    default: ""
  })
  readonly className: any;

  get iconName(): any {
    return `#icon-${this.iconClass}`;
  }
  get svgClass(): any {
    if (this.className) {
      return "svg-icon " + this.className;
    } else {
      return "svg-icon";
    }
  }

  get iconClassName(): any {
    const icon: any = this.iconClass.includes("s-icon") ? "s-icon" : "iconfont";
    if (this.className) {
      return `${icon} ${this.className}`;
    } else {
      return icon;
    }
  }

  private onCallback() {
    stopPropagation();
    this.$emit("click");
  }

  render() {
    const renderIcon = () => {
      return this.iconClass.includes("icon-") ? (
        <i
          class={[
            this.iconClass.includes("el-icon") ? "" : this.iconClassName,
            this.iconClass
          ]}
          onClick={this.onCallback}
        ></i>
      ) : (
        <svg onClick={this.onCallback} class={this.svgClass} aria-hidden="true">
          <use xlinkHref={this.iconName}></use>
        </svg>
      );
    };
    return renderIcon();
  }
}
