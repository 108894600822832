class IdCard {
  // eslint-disable-next-line camelcase
  private aIdentityCode_City: any = {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江 ",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北 ",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏 ",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门",
    91: "国外"
  };

  isCardNo(card: string) {
    // 检查号码是否符合规范，包括长度，类型
    const reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/; // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
    if (!reg.test(card)) {
      return false;
    }
    return true;
  }

  checkProvince(card: string) {
    // 取身份证前两位，校验省份
    const province = card.substr(0, 2);
    if (this.aIdentityCode_City[province] === undefined) {
      return false;
    }
    return true;
  }

  checkBirthday(card: any) {
    if (!this.isCardNo(card)) return false;
    // 检查生日是否正确，15位以'19'年份来进行补齐。
    const len = card.length;
    const reg: RegExp =
      len === 15
        ? /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/
        : /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/;
    // eslint-disable-next-line camelcase
    const card_arr: any[] = card.match(reg);
    // 身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
    // eslint-disable-next-line prefer-const
    let year = card_arr[2];
    const month = card_arr[3];
    const day = card_arr[4];
    const birthday = new Date(
      `${len === 15 ? "19" : ""}${year}/${month}/${day}`
    );
    return this.verifyBirthday(
      `${len === 15 ? "19" : ""}${year}`,
      month,
      day,
      birthday
    );
  }

  verifyBirthday(year: any, month: any, day: any, birthday: any) {
    // 校验日期 ，15位以'19'年份来进行补齐。
    const now = new Date();
    // eslint-disable-next-line camelcase
    const now_year = now.getFullYear();
    // 年月日是否合理
    if (
      birthday.getFullYear() === Number(year) &&
      birthday.getMonth() + 1 === Number(month) &&
      birthday.getDate() === Number(day)
    ) {
      // 判断年份的范围（3岁到150岁之间)
      // eslint-disable-next-line camelcase
      const time = now_year - Number(year);
      if (time >= 3 && time <= 150) {
        return true;
      }
      return false;
    }
    return false;
  }

  checkParity(card: any) {
    // 校验位的检测
    const nCard = this.changeFifteenToEighteen(card); // 15位转18位
    const len = nCard.length;
    let cardTemp = 0;
    let i;
    let valnum;
    if (len === 18) {
      const arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      const arrCh = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];
      for (i = 0; i < 17; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cardTemp += nCard.substr(i, 1) * arrInt[i];
      }
      valnum = arrCh[cardTemp % 11];
      if (valnum === nCard.substr(17, 1)) {
        return true;
      }
      return false;
    }
    return false;
  }

  changeFifteenToEighteen(card: any) {
    // 15位转18位身份证号
    if (card.length === 15) {
      const arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      const arrCh = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];
      let cardTemp = 0;
      let i;
      card = card.substr(0, 6) + "19" + card.substr(6, card.length - 6);
      for (i = 0; i < 17; i++) {
        cardTemp += card.substr(i, 1) * arrInt[i];
      }
      card += arrCh[cardTemp % 11];
      return card;
    }
    return card;
  }

  identityCodeValid(card: string) {
    //   身份证号码检验主入口
    console.log(`长度校验：${this.isCardNo(card)}`);
    console.log(`省份校验：${this.checkProvince(card)}`);
    console.log(`出生年月校验：${this.checkBirthday(card)}`);
    const judge =
      this.isCardNo(card) &&
      this.checkProvince(card) &&
      this.checkBirthday(card);
    return judge;
  }
}

export default new IdCard();
