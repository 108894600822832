import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import {
  filterEmptyField,
  publicDealTableData,
  publicDelete,
  publicDownFile,
  replaceObjectKey
} from "@/utils";
import { Message, Tag, Tree } from "element-ui";
import {
  getPostListApi,
  insetPostApi,
  updatePostApi,
  deletePostApi,
  exportPostApi
} from "@/api/system/post";
import { useComProps } from "@/utils/useComProps";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";
import { getDictStatusListApi } from "@/api/system/dict";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("system-post");
@Component({
  name: "SystemPostList"
})
export default class SystemPostList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  private comPostProps: any = useComProps.call(this, { prefixCls });
  private dataStore: any = {
    isLoading: false,
    treeIsLoading: false,
    title: "岗位设置",
    statusList: [],
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNum: 1
    },
    total: 0,
    tableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: []
  };

  private handleNodeClick(node: any) {}

  // 操作岗位信息
  private handleRole(options: any) {
    const { callback, row, title } = options;
    const elRef: any = this.comPostProps.getFormElRef(this, "dialogRef");
    this.comPostProps.checkFormRequire(elRef, () => {
      const params: any = this.comPostProps.getFormValue(this, "dialogRef");
      const api: any = row ? updatePostApi : insetPostApi;
      if (row && row.postId) {
        params["postId"] = row.postId;
      }
      api(title && row ? row : params).then((res: any) => {
        Message.success(
          `${row ? "编辑" : "添加"}岗位${title ? title : ""}成功`
        );
        this.getTableData();
        callback && callback();
      });
    });
  }

  // 遍历结构树
  private deepDealTreeData(data: any[], parentId: any) {
    return data.map((ele: any) => {
      ele.label = ele.title;
      if (!ele.parentId && ele.parentId !== 0) {
        ele.parentId = parentId;
      }
      if (ele.children) {
        this.deepDealTreeData(ele.children, ele.id);
      }
      return ele;
    });
  }

  private deepScreenAllParent(data: any[], id: any, result: any[]) {
    if (!id) return;
    data.forEach((item, index) => {
      if (item.id === id) {
        if (!result.includes(item.id)) {
          result.unshift(item.id);
        }
        this.deepScreenAllParent(
          this.dataStore.treeData,
          item.parentId,
          result
        );
      } else {
        if (item.children) {
          this.deepScreenAllParent(item.children, id, result);
        }
      }
    });
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      export: () => {
        const params: any = this.getParamsList();
        const filterParams = filterEmptyField(params);
        // const filterParams = replaceObjectKey(
        //   [
        //     ["pageNum", "current"],
        //     ["pageSize", "size"]
        //   ],
        //   filterEmptyField(params)
        // );
        exportPostApi(filterParams).then((res) => {
          if (res) {
            publicDownFile(res, "岗位列表");
            Message.success("导出成功！");
          }
        });
      },
      refresh: () => {
        this.comPostProps.clearFormList(this, "filterRef");
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      create: () => {
        this.comPostProps.openDialogPage(this, {
          width: "650px",
          title: "新增岗位",
          confirmButtonText: "立即添加",
          schema: config.postPage.dialogSchema,
          onConfirm: (callback: any) => this.handleRole({ callback })
        });
        this.$nextTick(() => {
          this.comPostProps.setFormSchema(this, "dialogRef", [
            {
              field: "status",
              path: "componentProps.options",
              value: this.dataStore.statusList
            }
          ]);
        });
      },
      deleteBatch: () => {
        if (this.dataStore.selectData.length) {
          const names: any[] = this.dataStore.selectData.map(
            (ele: any) => ele.name
          );
          // publicDelete({
          //   message: `是否删除岗位【${names.join(",")}】？`,
          //   title: "确认信息",
          //   callback: () => {
          const ids: any = [];
          this.dataStore.selectData.forEach((ele: any) => {
            ids.push(ele.postId);
          });
          //     this.deleteDataList(ids, names);
          //   }
          // });
          this.comPostProps.openDialogPage(this, {
            width: "586px",
            title: `删除`,
            titleSlots: {
              prefix: () => {
                return (
                  <i
                    class={"el-icon-warning"}
                    style={{
                      marginRight: "18px",
                      color: "#E34D59",
                      fontSize: "24px"
                    }}
                  ></i>
                );
              }
            },
            contentSlots: () => {
              return <div>{` 是否确认删除公告岗位【${ids}】？`}</div>;
            },
            onConfirm: (callback) => {
              deletePostApi(ids.join(",")).then((res: any) => {
                Message.success(`删除成功`);
                callback && callback();
                this.getTableData();
              });
            }
          });
        } else {
          Message.warning("请先选择要删除的数据");
        }
      },
      editRow: () => {
        this.comPostProps.openDialogPage(this, {
          width: "650px",
          title: "编辑岗位",
          schema: config.postPage.dialogSchema,
          formList: row,
          onConfirm: (callback: any) => this.handleRole({ callback, row })
        });
        this.$nextTick(() => {
          this.comPostProps.setFormSchema(this, "dialogRef", [
            {
              field: "status",
              path: "componentProps.options",
              value: this.dataStore.statusList
            }
          ]);
        });
      },
      deleteRow: () => {
        // publicDelete({
        //   message: `是否删除岗位【${row.name}】？`,
        //   title: "确认信息",
        //   callback: () => {
        //     this.deleteDataList([row.id], [row.name]);
        //   }
        // });
        this.comPostProps.openDialogPage(this, {
          width: "586px",
          title: `删除`,
          titleSlots: {
            prefix: () => {
              return (
                <i
                  class={"el-icon-warning"}
                  style={{
                    marginRight: "18px",
                    color: "#E34D59",
                    fontSize: "24px"
                  }}
                ></i>
              );
            }
          },
          contentSlots: () => {
            return <div>{` 是否删除岗位编号为【${row.postId}】？`}</div>;
          },
          onConfirm: (callback) => {
            this.deleteDataList(row.postId, callback);
          }
        });
      }
    };
    obj[item.prop] && obj[item.prop]();
  }
  private getParamsList() {
    const formList: any = this.comPostProps.getFormValue(this, "filterRef");
    const params: any = {
      ...formList,
      ...this.dataStore.queryList,
      "params[beginTime]": formList?.createTime?.[0],
      "params[endTime]": formList?.createTime?.[1]
    };
    delete params.createTime;
    return filterEmptyField(params);
  }
  // 删除数据
  private deleteDataList(id: any, callback) {
    const params: any = id;
    deletePostApi(params).then(() => {
      Message.success(`删除岗位【${id}】成功`);
      callback && callback();
      this.getTableData();
    });
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNum = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }
  private getStausList() {
    getDictStatusListApi().then((res: any) => {
      const data = res || [];
      this.dataStore.statusList = data.map((ele: any) => {
        return {
          label: ele.dictLabel,
          value: ele.dictValue
        };
      });
      config.postPage.filterSchema[2].componentProps.options =
        this.dataStore.statusList;
      this.$nextTick(() => {
        this.comPostProps.setFormSchema(this, "dialogRef", [
          {
            field: "status",
            path: "componentProps.options",
            value: this.dataStore.statusList
          }
        ]);
        this.$forceUpdate();
      });
      this.$forceUpdate();
    });
  }
  private getTableData() {
    this.dataStore.isLoading = true;
    const filterList: any = this.comPostProps.getFormValue(this, "filterRef");
    const params: any = this.getParamsList();
    getPostListApi(params)
      .then((res: any) => {
        if (res) {
          this.dataStore.tableData = publicDealTableData(
            res.rows,
            this.dataStore.queryList,
            { current: "pageNum", size: "pageSize" }
          );
          this.dataStore.total = res.total;
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }

  mounted() {
    this.comPostProps.dealContentTableHeight(this, ["header", "filter"]);
    this.getTableData();
    this.getStausList();
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        <div class={`${prefixCls}-header`}>{this.dataStore.title}</div>
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.comPostProps.renderForm({
              ref: "filterRef",
              schema: config.postPage.filterSchema,
              slots: {
                handle: (data) => {
                  return (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {this.comPostProps.renderButtons({
                        buttons: data.componentProps.buttons
                      })}
                    </div>
                  );
                },
                tableHandle: (data) => {
                  return (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {this.comPostProps.renderButtons({
                        buttons: data.componentProps.buttons
                      })}
                    </div>
                  );
                }
              }
            })}
          </div>
          {this.comPostProps.renderTable({
            tableData: this.dataStore.tableData,
            tableHeader: config.postPage.tableHeader,
            isShowCheck: true,
            queryList: this.dataStore.queryList,
            total: this.dataStore.total,
            isLoading: this.dataStore.isLoading,
            paginationLayout: "sizes, prev, pager, next, slot, jumper",
            style: {
              height: this.dataStore.tableHeight
            },
            slots: {
              status: ({ row }) => {
                const sItem = this.dataStore.statusList.find(
                  (ele: any) => ele.value === row.status
                );
                return (
                  <Tag type={row?.status === "1" ? "danger" : ""}>
                    {sItem?.label}
                  </Tag>
                );
              }
            },
            ref: "tableRef"
          })}
        </div>
      </div>
    );
  }
}
