import request from "@/utils/request";
import ipList from "@/utils/ipConfig";
export function getPostListApi(params?: any) {
  return request({
    url: ipList.system.post.list,
    method: "get",
    params
  });
}

export function insetPostApi(query: any) {
  return request({
    url: ipList.system.post.crudPost,
    method: "post",
    data: query
  });
}

export function updatePostApi(query: any) {
  return request({
    url: ipList.system.post.crudPost,
    method: "put",
    data: query
  });
}

export function deletePostApi(id: any) {
  return request({
    url: `${ipList.system.post.crudPost}/${id}`,
    method: "DELETE"
  });
}

export function exportPostApi(query: any) {
  return request({
    url: ipList.system.post.export,
    method: "post",
    responseType: "blob",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: query
  });
}
