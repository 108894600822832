import request from "@/utils/request";
import ipList from "@/utils/ipConfig";

export function getNoticeListApi(params?: any) {
  return request({
    url: ipList.system.notice.list,
    method: "get",
    params
  });
}

export function insetNoticeApi(query: any) {
  return request({
    url: ipList.system.notice.notice,
    method: "post",
    data: query
  });
}

export function updateNoticeApi(query: any) {
  return request({
    url: ipList.system.notice.notice,
    method: "put",
    data: query
  });
}

export function deleteNoticeApi(id: any) {
  return request({
    url: `${ipList.system.notice.notice}/${id}`,
    method: "DELETE"
  });
}
