import { useDesign } from "@/hooks/web/useDesign";
import { Component, Vue } from "vue-property-decorator";
import { Container, Aside } from "element-ui";
import WidgetPanel from "@/views/Tool/Build/components/WidgetPanel/Index";
import FormWidget from "@/views/Tool/Build/components/FormWidget/Index";
import SettingPanel from "@/views/Tool/Build/components/SettingPanel/Index";
import ToolsPanel from "@/views/Tool/Build/components/ToolsPanel/Index";
import { createDesigner } from "@/utils/designer";
import "./index.scss";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("system-tools-build");

@Component({
  name: "SystemToolsBuildIndex"
})
export default class SystemToolsBuildIndex extends Vue {
  private dataStore: any = {
    designer: createDesigner(this)
  };
  render() {
    const renderContentHeader = () => {
      return <ToolsPanel designer={this.dataStore.designer}></ToolsPanel>;
    };
    return (
      <div class={prefixCls}>
        <Container>
          <Aside class={`${prefixCls}-left`}>
            <WidgetPanel designer={this.dataStore.designer}></WidgetPanel>
          </Aside>
          <Container class={`${prefixCls}-content`}>
            <Aside>
              {renderContentHeader()}
              <FormWidget
                style={{ height: "calc(100% - 50px)", margin: "10px 10px 0" }}
                designer={this.dataStore.designer}
              ></FormWidget>
            </Aside>
          </Container>
          <Aside class={`${prefixCls}-right`}>
            <SettingPanel
              designer={this.dataStore.designer}
              selectedWidget={this.dataStore.designer.selectedWidget}
            ></SettingPanel>
          </Aside>
        </Container>
      </div>
    );
  }
}
