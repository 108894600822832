import request from "@/utils/request";

export function getVenueListApi(params?: any) {
  return request({
    url: "/business/venue/list",
    method: "get",
    params
  });
}

export function getAllVenueListApi() {
  return request({
    url: "/business/venue/allList",
    method: "get"
  });
}

/**
 * 新增场馆信息
 */
export function insertVenueApi(data: any) {
  return request({
    url: `/business/venue/insert`,
    method: "post",
    data
  });
}

/**
 * 修改场馆信息
 */
export function updateVenueApi(data: any) {
  return request({
    url: `/business/venue/update`,
    method: "post",
    data
  });
}

/**
 * 修改场馆状态
 */
export function changeVenueStatusApi(data: any) {
  return request({
    url: `/business/venue/changeStatus`,
    method: "put",
    data
  });
}

export function deleteVenueListApi(ids: any) {
  return request({
    url: `/business/venue/remove/${ids}`,
    method: "get"
  });
}

export function venueExportApi(data: any) {
  return request({
    url: `/business/venue/export`,
    method: "post",
    responseType: "blob",
    data
  });
}
