import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import {
  publicDealTableData,
  publicDelete,
  filterEmptyField,
  publicDownFile
} from "@/utils";
import { Message, Tree } from "element-ui";
import {
  getOperatesApi,
  deleteOperates,
  deleteEvery,
  ExportDeleteEvery
} from "@/api/system/dept";

import { useComProps } from "@/utils/useComProps";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("system-role");
@Component({
  name: "SystemRoleList"
})
export default class SystemRoleList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  private comRoleProps: any = useComProps.call(this, { prefixCls });
  private dataStore: any = {
    isLoading: false,
    treeIsLoading: false,
    title: "角色列表",
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNumber: 1
    },
    total: 0,
    tableData: [{ status: 1 }],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: []
  };
  private handleNodeClick(node: any) {}
  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      export: () => {
        const params: any = this.getParamsList();
        const filterParams = filterEmptyField(params);
        ExportDeleteEvery(filterParams).then((res) => {
          if (res) {
            publicDownFile(res, "登录日志列表");
            Message.success("导出成功！");
          }
        });
      },
      clean: () => {
        publicDelete({
          message: `你确定要清空数据吗`,
          title: "确认信息",
          callback: () => {
            deleteEvery().then((res: any) => {
              this.$message({
                type: "success",
                message: "清空成功"
              });
              this.getTableData();
            });
          }
        });
      },
      deleteBatch: () => {
        console.log(this.dataStore.selectData, "----this.dataStore.selectData");
        const id = this.dataStore.selectData
          .map((v) => {
            return v.operId;
          })
          .join(",");
        if (id) {
          publicDelete({
            message: `你确定要删除吗`,
            title: "确认信息",
            callback: () => {
              deleteOperates({ id }).then((res: any) => {
                this.$message({
                  type: "success",
                  message: "删除成功"
                });
                this.getTableData();
              });
            }
          });
        }
      },
      refresh: () => {
        this.comRoleProps.clearFormList(this, "filterRef");
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      search: (row) => {
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      getDetail: () => {
        this.comRoleProps.openDialogPage(this, {
          width: "650px",
          title: "操作日志详细",
          schema: config.operationLog.dialogSchema,
          contentSlots: () => {
            return (
              <div class={"moudle_data"}>
                <div class={"moudle_data-l"}>
                  操作模块:<span>{row.title}</span>
                </div>
                <div class={"moudle_data-l"}>
                  请求地址:<span>{row.jsonResult}</span>
                </div>
                <div class={"moudle_data-l"}>
                  登录信息:
                  <span>
                    {row.admin}/{row.operIp}/{row.operLocation}
                  </span>
                </div>
                <div class={"moudle_data-l"}>
                  请求方式:<span>{row.requestMethod}</span>
                </div>
                <div class={"moudle_data-l"}>
                  操作方法:
                  <span>{row.method}</span>
                </div>
                <div class={"moudle_data-l"}>
                  请求参数:<span> {row.operParam}</span>
                </div>
                <div class={"moudle_data-l"}>
                  返回参数:<span>{row.jsonResult}</span>
                </div>
              </div>
            );
          },
          onConfirm: (callback: any) => this.handleRole({ callback })
        });
      }
    };
    obj[item.prop] && obj[item.prop]();
  }

  private handleRole(options: any) {
    const { callback, row, title } = options;
    callback();
  }

  // private getTableData() {
  //   this.dataStore.isLoading = true;
  //   const filterList: any = this.comRoleProps.getFormValue(this, "filterRef");
  //   const params: any = { ...filterList, ...this.dataStore.queryList };
  //   getRoleListApi(params)
  //     .then((res: any) => {
  //       if (res) {
  //         this.dataStore.tableData = publicDealTableData(
  //           res.records,
  //           this.dataStore.queryList,
  //           { current: "pageNumber", size: "pageSize" }
  //         );
  //         this.dataStore.total = res.total;
  //       }
  //       this.dataStore.isLoading = false;
  //     })
  //     .catch(() => {
  //       this.dataStore.isLoading = false;
  //     });
  // }

  mounted() {
    this.comRoleProps.dealContentTableHeight(this, ["header", "filter"]);
    this.getTableData();
  }
  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        console.log(item, "------itemitem");
        this.dataStore.queryList.pageNum = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
        console.log(this.dataStore.selectData, "----this.dataStore.selectData");
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }

  private getParamsList() {
    const formList: any = this.comRoleProps.getFormValue(this, "filterRef");
    const params: any = {
      ...formList,
      ...this.dataStore.queryList,
      "params[beginTime]": formList?.createTime?.[0]
        ? `${formList?.createTime?.[0]} 00:00:00`
        : void 0,
      "params[endTime]": formList?.createTime?.[1]
        ? `${formList?.createTime?.[1]} 23:59:59`
        : void 0
    };
    delete params.createTime;
    console.log(params, "------formListshuju");
    // const params: any = {
    //   ...formList,
    //   ...this.dataStore.queryList,
    //   "params[beginTime]":formList?.loginTime?.[0]?`${formList?.loginTime?.[0]} 00:00:00`:void 0,
    //   "params[endTime]": formList?.loginTime?.[1]?`${formList?.loginTime?.[1]} 23:59:59`:void 0
    // };
    // delete params.loginTime;
    // return filterEmptyField(params);
    return params;
  }
  private getTableData() {
    const params: any = this.getParamsList();
    //  console.log(filterEmptyField(params),'-----filterEmptyField(params)')
    getOperatesApi(params).then((res: any) => {
      this.dataStore.tableData = res.rows;
      this.dataStore.total = res.total;
    });
  }
  render() {
    return (
      <div class={`${prefixCls}`}>
        {/* <div class={`${prefixCls}-header`}>{this.dataStore.title}</div> */}
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.comRoleProps.renderForm({
              ref: "filterRef",
              schema: config.operationLog.filterSchema,
              slots: {
                handle: ({ componentProps }) => {
                  return this.comRoleProps.renderButtons({
                    buttons: componentProps.buttons
                  });
                },
                tableHandle: (data) => {
                  return (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {this.comRoleProps.renderButtons({
                        buttons: data.componentProps.buttons
                      })}
                    </div>
                  );
                }
              }
            })}
          </div>
          {this.comRoleProps.renderTable({
            tableData: this.dataStore.tableData,
            tableHeader: config.operationLog.tableHeader,
            isShowCheck: true,
            queryList: this.dataStore.queryList,
            total: this.dataStore.total,
            isLoading: this.dataStore.isLoading,
            paginationLayout: "sizes, prev, pager, next, slot, jumper",
            style: {
              height: this.dataStore.tableHeight
            },
            slots: {
              status: (data) => {
                const obj = {
                  "0": {
                    border: "1px solid #e6f8f7",
                    background: "#e6f8f7",
                    color: "#00B4AF"
                  },
                  "1": {
                    border: "1px solid #ffdbdb",
                    background: "#ffeded",
                    color: "#ff4949"
                  }
                };

                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      style={{
                        ...obj[data.row.status],
                        width: "46px",
                        padding: "5px 0",
                        borderRadius: "4px",
                        margin: "0px"
                      }}
                    >
                      {data.row.status == "0" ? "成功" : "停用"}
                    </div>
                  </div>
                );
              },
              businessType: (data) => {
                const statusType = {
                  "1": "新增",
                  "2": "修改",
                  "3": "删除",
                  "4": "授权",
                  "5": "导出",
                  "6": "导入",
                  "7": "强退",
                  "8": "生成代码"
                };
                return <div>{statusType[data.row.businessType]}</div>;
              }
            },
            ref: "tableRef"
          })}
        </div>
      </div>
    );
  }
}
