import { useDesign } from "@/hooks/web/useDesign";
import { Component, Vue } from "vue-property-decorator";
import { SlTabs } from "@/components";
import { queryGenDetailApi, saveGenDetailApi } from "@/api/tool/gen";
import { getOptionSelectApi } from "@/api/system/dict";
import { getMenuTreeSelectApi } from "@/api/system/menu";
import "./index.scss";
import config from "@/config";
import { useComProps } from "@/utils/useComProps";
import { Message, Dropdown, DropdownMenu, DropdownItem } from "element-ui";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("tool-gen-edit");
@Component({
  name: "ToolGenEdit"
})
export default class ToolGenEdit extends Vue {
  $refs!: {
    contentRef: any;
    tableRef: any;
  };
  private comProps: any = useComProps.call(this, {
    prefixCls
  });
  private dataStore: any = {
    activeIndex: 1,
    tabData: config.gen.edit.tabData,
    fieldMessageList: {
      tableData: []
    },
    tableHeight: 0,
    fields: ["isInsert", "isEdit", "isList", "isQuery", "isRequired"],
    paramsFields: ["parentMenuId", "treeCode", "treeParentCode", "treeName"],
    dictOptions: [],
    deptData: [],
    formList: {},
    tables: []
  };

  private dealParams(list: any) {
    const result: any = {};
    this.dataStore.paramsFields.forEach((ele: any) => {
      result[ele] = list[ele];
    });
    return result;
  }

  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj = {
      submit: () => {
        const formList = this.comProps.getFormValue(this, "formRef");
        if (formList) {
          this.dataStore.formList = { ...this.dataStore.formList, ...formList };
        }
        const params: any = this.dealParams(this.dataStore.formList);
        const nParams: any = {
          ...this.dataStore.formList,
          params
        };
        console.log(nParams);
        // saveGenDetailApi(nParams).then(() => {
        //   Message.success(`保存表数据成功`);
        //   this.$router.back();
        // });
      },
      back: () => {
        this.$router.back();
      }
    };
    obj[item.prop] && obj[item.prop]();
  }

  // 动态转变值
  private autoChangeValue(list: any, fields: any[], isBoolean?: boolean) {
    const result: any = {};
    fields.forEach((field: string) => {
      if (isBoolean) {
        result[field] = list[field] === "1";
      } else {
        result[field] = list[field] ? "1" : "0";
      }
    });
    return result;
  }

  // 处理表格数据
  private dealTableData(data: any[]) {
    return data.map((ele) => {
      const result: any = this.autoChangeValue(
        ele,
        this.dataStore.fields,
        true
      );
      ele["dictType"] =
        ele.dictType || ele.dictType === "0" ? Number(ele.dictType) : "";
      ele["status"] = 1;
      return { ...ele, ...result };
    });
  }

  private onChange(options: any) {
    const { item, value } = options;
    console.log("kkkkkkkkk", value);
    if (item.field === "subTableName") {
      this.comProps.setFormValues(this, "formRef", {
        subTableFkName: ""
      });
      const index = this.dataStore.tables.findIndex(
        (ele: any) => ele.tableName === value
      );
      if (index >= 0) {
        this.comProps.setFormSchema(this, "formRef", [
          {
            field: "subTableFkName",
            path: "componentProps.options",
            value: this.dataStore.tables[index].columns.map((ele) => {
              return {
                label: `${ele.columnName}：${ele.columnComment}`,
                value: ele.columnName
              };
            })
          }
        ]);
      }
      console.log(value, this.dataStore.tables);
      // const nOptions = this.
    }
  }

  private initData(tableId: any) {
    queryGenDetailApi(tableId).then((res: any) => {
      this.dataStore.fieldMessageList.tableData = this.dealTableData(res.rows);
      this.dataStore.formList = {
        ...res.info,
        parentMenuId: Number(res.info.parentMenuId)
      };
      this.dataStore.tables = res.tables;
    });
    getOptionSelectApi().then((res: any) => {
      if (res) {
        this.dataStore.dictOptions = res.map((ele: any) => {
          return {
            ...ele,
            label: ele.dictName,
            value: ele.dictId
          };
        });
        config.gen.edit.tabData[1].tableHeader[
          config.gen.edit.tabData[1].tableHeader.length - 1
        ].componentProps.options = this.dataStore.dictOptions;
      }
    });
    getMenuTreeSelectApi().then((res) => {
      if (res) {
        this.dataStore.deptData = res;
      }
    });
  }

  private onCallback(options: any) {
    const { type, index } = options;
    const obj: any = {
      changeTab: () => {
        if (index === 2) {
          this.dataStore.tabData[2].schema[5].componentProps.treeProps.data =
            this.dataStore.deptData;
        }
      }
    };
    obj[type] && obj[type]();
  }

  // 切换到生成信息页面的操作
  private handleCreateMessage() {
    this.dataStore.tabData[2].schema[5].componentProps.treeProps.data =
      this.dataStore.deptData;
    const options = this.dataStore.formList.columns.map((ele) => {
      return {
        label: `${ele.columnName}：${ele.columnComment}`,
        value: ele.columnName
      };
    });
    setTimeout(() => {
      this.comProps.setFormSchema(this, "formRef", [
        {
          field: "treeCode",
          path: "componentProps.options",
          value: options
        },
        {
          field: "treeParentCode",
          path: "componentProps.options",
          value: options
        },
        {
          field: "treeName",
          path: "componentProps.options",
          value: options
        },
        {
          field: "subTableName",
          path: "componentProps.options",
          value: this.dataStore.tables.map((ele) => {
            return {
              label: `${ele.tableName}：${ele.tableComment}`,
              value: ele.tableName
            };
          })
        }
      ]);
    }, 300);
  }

  private customChange(index: number, cb: Function) {
    const formList = this.comProps.getFormValue(this, "formRef");
    if (formList) {
      this.dataStore.formList = { ...this.dataStore.formList, ...formList };
    }
    if (index === 2) {
      this.handleCreateMessage();
    }
    this.dataStore.activeIndex = index;
    setTimeout(() => {
      this.comProps.setFormValues(this, "formRef", this.dataStore.formList);
    }, 500);
  }
  private mounted() {
    const tableId = this.$route.query && this.$route.query.tableId;
    if (tableId) {
      this.initData(tableId);
      this.$nextTick(() => {
        const contentRef: any = this.$refs.contentRef;
        this.dataStore.tableHeight = `${contentRef.offsetHeight}px`;
        if (this.$refs.tableRef) {
          this.$refs.tableRef.reloadTableHeader();
        }
      });
    }
  }
  render() {
    const renderFieldMessage = (item: any) => {
      return (
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          {this.comProps.renderAutoincrementTable({
            tableData: JSON.parse(
              JSON.stringify(this.dataStore.fieldMessageList.tableData)
            ),
            tableHeader: item.tableHeader,
            ref: "tableRef",
            style: {
              height: this.dataStore.tableHeight
            },
            callback: (options: any) => {
              if (Object.keys(options.item).includes("rowIndex")) {
                this.dataStore.fieldMessageList.tableData.splice(
                  options.item.rowIndex,
                  1,
                  options.row
                );
                this.dataStore.formList.columns =
                  this.dataStore.fieldMessageList.tableData.map((ele) => {
                    const otherList: any = this.autoChangeValue(
                      ele,
                      this.dataStore.fields
                    );
                    return { ...ele, ...otherList };
                  });
              }
            }
          })}
        </div>
      );
    };
    const renderFormContent = (item: any) => {
      const slots: any = {
        "genPath-append": () => {
          return (
            <Dropdown split-button>
              最近路径快速选择
              <DropdownMenu
                onClick={() => {
                  console.log("000000");
                }}
              >
                <DropdownItem>恢复默认的生成基础路径</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          );
        }
      };
      if (item.iconData) {
        item.iconData.forEach((ele) => {
          slots[`${ele.field}-${ele.iconType}`] = () => {
            return this.comProps.renderIconToolTip({
              content: ele.content,
              icon: ele.icon
            });
          };
        });
      }
      return this.comProps.renderForm({
        schema: item.schema,
        formList: this.dataStore.formList,
        ref: "formRef",
        style: {
          paddingTop: "30px"
        },
        slots: slots
      });
    };
    const renderContent = () => {
      const renderApi = {
        baseMessage: renderFormContent,
        fieldMessage: renderFieldMessage,
        createMessage: renderFormContent
      };
      const item = this.dataStore.tabData[this.dataStore.activeIndex];
      return renderApi[item.prop] ? renderApi[item.prop](item) : null;
    };
    return (
      <div class={`${prefixCls}`}>
        <SlTabs
          v-model={this.dataStore.activeIndex}
          tabData={this.dataStore.tabData}
          customChange={this.customChange}
        ></SlTabs>
        {renderContent()}
        {this.comProps.renderButtons({
          buttons: config.gen.edit.buttons,
          style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "15px"
          }
        })}
      </div>
    );
  }
}
