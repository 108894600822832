import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { addClass, removeClass } from "@/utils";
import { SettingsModule } from "@/store/modules/settings";
import { useDesign } from "@/hooks/web/useDesign";
import { getSlot } from "@/utils/tsxHelper";
import "./index.scss";

const prefixCls = useDesign().getPrefixCls("right-panel");
@Component({
  name: "RightPanel"
})
export default class RightPanel extends Vue {
  @Prop({ default: false }) private clickNotClose!: boolean;
  @Prop({ default: 250 }) private buttonTop!: number;

  private dataStore: any = {
    show: false
  };

  get theme() {
    return SettingsModule.theme;
  }

  @Watch("dataStore.show", { immediate: true })
  private onShowChange(value: boolean) {
    // if (value && !this.clickNotClose) {
    //   this.addEventClick();
    // }
    if (value) {
      addClass(document.body, "showRightPanel");
    } else {
      removeClass(document.body, "showRightPanel");
    }
  }

  mounted() {
    this.insertToBody();
  }

  beforeDestroy() {
    const elx = this.$refs.rightPanel as Element;
    elx.remove();
  }

  private addEventClick() {
    window.addEventListener("click", this.closeSidebar);
  }

  private closeSidebar(ev: MouseEvent) {
    const parent = (ev.target as HTMLElement).closest(".rightPanel");
    if (!parent) {
      this.dataStore.show = false;
      window.removeEventListener("click", this.closeSidebar);
    }
  }

  private insertToBody() {
    const elx = this.$refs.rightPanel as Element;
    const body = document.querySelector("body");
    if (body) {
      body.insertBefore(elx, body.firstChild);
    }
  }

  private changeClick() {
    this.dataStore.show = !this.dataStore.show;
  }

  render() {
    const slotMap: any = {};
    slotMap.default = this.$scopedSlots.default
      ? getSlot(this.$scopedSlots, "default")
      : null;
    return (
      <div
        ref="rightPanel"
        class={[`${prefixCls}`, { show: this.dataStore.show }]}
      >
        <div class={`${prefixCls}-background`} />
        <div class={`${prefixCls}-container`}>
          <div
            class="handle-button"
            style={{
              top: this.buttonTop + "px",
              "background-color": this.theme
            }}
            onClick={this.changeClick}
          >
            <i
              class={this.dataStore.show ? "el-icon-close" : "el-icon-setting"}
            />
          </div>
          <div class={`${prefixCls}-container-items`}>{slotMap.default}</div>
        </div>
      </div>
    );
  }
}
