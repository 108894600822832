import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import { RouteConfig } from "vue-router";
import router, { constantRoutes, resetRouter } from "@/router";
import store from "@/store";
import { isExternal } from "@/utils/validate";
import config from "@/config";
import { getRouters } from "@/api/system/login";
const ViewDir = require("@/router/view-dir");

const modules = require.context("@/views", true, /\.tsx$/);
const vueModules = require.context("@/views", true, /\.vue$/);

const hasPermission = (roles: string[], route: RouteConfig) => {
  if (route.meta && route.meta.roles) {
    return roles.some((role) => route.meta?.roles.includes(role));
  } else {
    return true;
  }
};
const needReplaceComponents: any[] = [];

const needReplacePaths: any[] = [];

// 处理需要替换的component
const dealReplaceStr = (component: any, needPlaceData: any[]) => {
  let nComponent: any = "";
  needPlaceData.forEach((ele: any) => {
    const oldCom: any = ele[0];
    const nCom: any = ele[1];
    if (component.includes(oldCom)) {
      nComponent = component.replace(oldCom, nCom);
    }
  });
  return nComponent ? nComponent : component;
};

const judgeChildHasRouter = (redirect, child) => {
  const item = child.filter((ele: any) => ele.path === redirect);
  return !!item.length;
};

const ajaxRoutes = (routes: any[]) => {
  const res: any[] = [];
  for (const route of routes) {
    const component: any = dealReplaceStr(
      route.component,
      needReplaceComponents
    );
    const path: any = dealReplaceStr(route.path, needReplacePaths);
    const noCache: any =
      route.noCache && route.noCache !== 0 && ["1", 1].includes(route.noCache);
    const data: any = {
      name: route.name,
      path: path,
      meta: { ...route.meta, hidden: route.hidden }
    };
    if (route.redirect && route.children) {
      const hasRedirect = judgeChildHasRouter(route.redirect, route.children);
      data["redirect"] = hasRedirect
        ? route.redirect
        : `${route.path}/${route.children[0].path}`;
    } else {
      data["redirect"] = null;
    }
    if (["Layout"].includes(component)) {
      const com = ViewDir.default || ViewDir;
      data.component = com[component];
    } else {
      if (isExternal(path)) {
        data.component = null;
      } else {
        const prefix = component.substr(0, 1) === "/" ? "" : "/";
        const item = `.${prefix}${component}.tsx`;
        const itemindex = `.${prefix}${component}/Index.tsx`;
        const itemIndex = `.${prefix}${component}/Index.tsx`;
        const itemVue = `.${prefix}${component}.vue`;
        const com = modules.keys().includes(item)
          ? modules(item)
          : modules.keys().includes(itemIndex)
          ? modules(itemIndex)
          : modules.keys().includes(itemIndex)
          ? modules(itemindex)
          : vueModules.keys().includes(itemVue)
          ? vueModules(itemVue)
          : vueModules(`.${prefix}${component}/index.vue`);
        data.component = com.default || com;
      }
    }
    if (!data.redirect) delete data.redirect;
    if (route.children) {
      data.children = ajaxRoutes(route.children);
    }
    res.push(data);
  }
  return res;
};

export const filterAsyncRoutes = (routes: RouteConfig[], roles: string[]) => {
  const res: RouteConfig[] = [];
  routes.forEach((route) => {
    const r = { ...route };
    if (hasPermission(roles, r)) {
      if (r.children) {
        r.children = filterAsyncRoutes(r.children, roles);
      }
      res.push(r);
    }
  });
  return res;
};

export const parseAsyncRoutes = (routes: any[]) => {
  let temp: any[] = [];
  temp = routes.map((item: any) => {
    item.redirect || delete item.redirect;
    item.meta = {
      title: item.title,
      hidden: item.hidden === "1",
      icon: item.icon,
      noCache: item.noCache !== "1"
    };
    if (item.children && item.children.length) {
      parseAsyncRoutes(item.children);
    }
    return item;
  });
  temp.push({
    path: "*",
    redirect: "/404",
    meta: { hidden: true }
  });
  return temp;
};

export const getValues = (obj: any, key: string) => {
  return obj[key];
};

export interface IPermissionState {
  routes: RouteConfig[];
  dynamicRoutes: RouteConfig[];
}

@Module({ dynamic: true, store, name: "permission" })
export default class Permission extends VuexModule implements IPermissionState {
  public routes: RouteConfig[] = [];
  public dynamicRoutes: RouteConfig[] = [];

  @Mutation
  private SET_ROUTES(routes: RouteConfig[]) {
    this.routes = constantRoutes.concat(routes);
    this.dynamicRoutes = routes;
  }

  @Action
  public async ReloadRoutes() {
    let accessedRoutes: any = [];
    const res: any = await getRouters();
    accessedRoutes = ajaxRoutes(res);
    if (accessedRoutes.length) {
      accessedRoutes.push({
        path: "/",
        redirect: accessedRoutes[0].path
      });
    }
    this.SET_ROUTES(accessedRoutes);
    resetRouter();
    this.dynamicRoutes.forEach((route) => {
      router.addRoute(route);
    });
  }

  @Action
  public async GenerateRoutes(id: any) {
    let accessedRoutes: any = [];
    const res: any = await getRouters();
    accessedRoutes = ajaxRoutes(res);
    // accessedRoutes = ajaxRoutes(config.routes);
    if (accessedRoutes.length) {
      accessedRoutes.push({
        path: "/",
        redirect: accessedRoutes[0].path
      });
    }
    this.SET_ROUTES(accessedRoutes);
  }
}

export const PermissionModule = getModule(Permission);
