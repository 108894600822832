import { SlCharts } from "@/components";

export function useCharts(options: ChartType) {
  return (
    <SlCharts
      ref={options.ref ? options.ref : "chartRef"}
      options={options.chartOptions}
      height={options.chartHeight}
    />
  );
}
