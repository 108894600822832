import { Vue, Component } from "vue-property-decorator";
import { isArray } from "@/utils/is";

@Component({
  name: "Redirect"
})
export default class Redirect extends Vue {
  created() {
    const { params, query } = this.$route;
    const paramKeys = Object.keys(params);
    const path = isArray(params.path) ? params.path.join("/") : params.path;
    this.$router.replace({
      path: path.startsWith("/") ? path : `/${path}`
    });
    console.log(this.$route, this.$router, "0000000000000000000000");
  }
  render() {
    return <div></div>;
  }
}
