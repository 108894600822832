import { useDesign } from "@/hooks/web/useDesign";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { getSlot } from "@/utils/tsxHelper";
import CalendarUtils from "@/utils/calendarUtils";
import { formatTime } from "@/utils";
import "./index.scss";

const prefixCls = useDesign().getPrefixCls("date-range-picker");
@Component({
  name: "SlDateRangePicker"
})
export default class SlDateRangePicker extends Vue {
  @Prop({
    type: Array,
    default: () => {
      return [];
    }
  })
  readonly value: any;

  @Prop({
    type: String,
    default: "至"
  })
  readonly rangeSeparator: any;

  @Watch("value", { immediate: true })
  onChangeValue(val: any, oldVal: any) {
    if (val && val !== oldVal) {
      this.dateValues = val;
      // this.setContentText(val);
    }
  }

  @Watch("dateValues", { immediate: true })
  onChangeNewValue(val: any) {
    this.onInput(val);
    this.onChangeHandle(this.resultList);
  }

  @Emit("input")
  onInput(item: any) {
    return item;
  }

  @Emit("change")
  onChangeHandle(val: any) {
    return val;
  }

  private dateValues: any = [];

  private resultList: any = {};

  private onChangeDate(type: any) {
    const result: any = CalendarUtils.getNewWeek({
      type,
      startDate: this.dateValues[0],
      endDate: this.dateValues[1],
      formatTime
    });
    this.dateValues = [result.startDate, result.endDate];
    this.resultList = result;
  }

  mounted() {
    if (!this.value || !this.value.length) {
      const result = CalendarUtils.getThisWeek({
        formatTime
      });
      console.log(result, "lsksjhhshshsh");
      this.dateValues = [result.startDate, result.endDate];
      this.resultList = result;
    }
  }
  render() {
    return (
      <div class={prefixCls}>
        {this.$scopedSlots.left || this.$slots.left ? (
          <div class={`${prefixCls}-prev`}>
            {getSlot(this.$scopedSlots || this.$slots, "left")}
          </div>
        ) : (
          <div
            class={`${prefixCls}-prev`}
            onClick={() => this.onChangeDate("prev")}
          >
            <i class={`el-icon-caret-left prev-icon`}></i>上一周
          </div>
        )}
        <div class={`${prefixCls}-date`}>
          <div class={`${prefixCls}-date-start`}>
            <i class={"el-icon-date"}></i>
            <div class={"date"}>{this.dateValues[0]}</div>
          </div>
          <span class={"range-separator"}>{this.rangeSeparator}</span>
          <div class={`${prefixCls}-date-end`}>
            <i class={"el-icon-date"}></i>
            <div class={"date"}>{this.dateValues[1]}</div>
          </div>
        </div>
        {this.$scopedSlots.right || this.$slots.right ? (
          <div class={`${prefixCls}-next`}>
            {getSlot(this.$scopedSlots || this.$slots, "right")}
          </div>
        ) : (
          <div
            class={`${prefixCls}-next`}
            onClick={() => this.onChangeDate("next")}
          >
            下一周
            <i class={`el-icon-caret-right next-icon`}></i>
          </div>
        )}
      </div>
    );
  }
}
