import { useDesign } from "@/hooks/web/useDesign";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SvgIcon } from "@/components";
import { InputNumber, Option, Select } from "element-ui";
const prefixCls = useDesign().getPrefixCls("crontab-second");
@Component({
  name: "CrontabSecond"
})
export default class CrontabSecond extends Vue {
  @Prop({
    type: Function
  })
  readonly check: any;

  @Prop({
    type: String
  })
  readonly radioParent: any;

  private secondArr: any = Array.from({ length: 60 }, (v, k) => k + 1);
  private fullYear: any = 0;

  private radioValue: any = 1;
  private cycle01: any = 1;
  private cycle02: any = 2;
  private average01: any = 0;
  private average02: any = 1;
  private checkboxList: any = [];
  private checkNum: any = this.$options?.propsData?.["check"];
  @Watch("radioValue", { immediate: true })
  radioValueChange() {
    this.radioChange();
  }
  @Watch("cycleTotal", { immediate: true })
  cycleTotalChange() {
    this.cycleChange();
  }
  @Watch("averageTotal", { immediate: true })
  averageTotalChange() {
    this.averageChange();
  }
  @Watch("checkboxString", { immediate: true })
  checkboxStringChange() {
    this.checkboxChange();
  }
  @Watch("radioParent", { immediate: true })
  radioParentChange() {
    // this.checkboxChange();
    this.radioValue = this.radioParent;
  }
  // 单选按钮值变化时
  private radioChange() {
    switch (this.radioValue) {
      case 1:
        this.$emit("update", "second", "*", "second");
        break;
      case 2:
        this.$emit("update", "second", this.cycleTotal);
        break;
      case 3:
        this.$emit("update", "second", this.averageTotal);
        break;
      case 4:
        this.$emit("update", "second", this.checkboxString);
        break;
    }
  }
  // 周期两个值变化时
  private cycleChange() {
    if (this.radioValue == "2") {
      this.$emit("update", "second", this.cycleTotal);
    }
  }
  // 平均两个值变化时
  private averageChange() {
    if (this.radioValue == "3") {
      this.$emit("update", "second", this.averageTotal);
    }
  }

  // checkbox值变化时
  private checkboxChange() {
    if (this.radioValue == "4") {
      this.$emit("update", "second", this.checkboxString);
    }
  }
  get cycleTotal() {
    const average01 = this.checkNum(this.average01, 0, 58);
    const average02 = this.checkNum(this.average02, 1, 59 - average01 || 0);
    return average01 + "/" + average02;
  }
  get averageTotal() {
    const average01 = this.checkNum(this.average01, 0, 58);
    const average02 = this.checkNum(this.average02, 1, 59 - average01 || 0);
    return average01 + "/" + average02;
  }
  get checkboxString() {
    const str = this.checkboxList.join();
    return str == "" ? "*" : str;
  }

  render() {
    return (
      <div class={prefixCls}>
        <el-form size="small">
          <el-form-item>
            <el-radio v-model={this.radioValue} label={1}>
              秒，允许的通配符[, - * /]
            </el-radio>
          </el-form-item>

          <el-form-item>
            <el-radio v-model={this.radioValue} label={2}>
              周期从
              <InputNumber
                v-model={this.cycle01}
                min={0}
                max={58}
              ></InputNumber>
              -
              <InputNumber
                v-model={this.cycle02}
                min={this.cycle01 ? this.cycle01 + 1 : 1}
                max={59}
              ></InputNumber>
              秒
            </el-radio>
          </el-form-item>
          <el-form-item>
            <el-radio v-model={this.radioValue} label={3}>
              从
              <InputNumber
                v-model={this.average01}
                min={0}
                max={58}
              ></InputNumber>
              秒开始，每
              <InputNumber
                v-model={this.average02}
                min={1}
                max={59 - this.average01 || 0}
              ></InputNumber>
              秒执行一次
            </el-radio>
          </el-form-item>
          <el-form-item>
            <el-radio v-model={this.radioValue} label={4}>
              指定
              <Select
                multiple={true}
                placeholder="可多选"
                style={{ marginLeft: "8px" }}
                clearable
                v-model={this.checkboxList}
              >
                {this.secondArr.map((w: any) => {
                  return (
                    <Option value={w - +this.fullYear}>
                      {w + this.fullYear}
                    </Option>
                  );
                })}
              </Select>
            </el-radio>
          </el-form-item>
        </el-form>
      </div>
    );
  }
}
