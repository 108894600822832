import { Component, Prop, Watch } from "vue-property-decorator";
import { useDesign } from "@/hooks/web/useDesign";
import { mixins } from "vue-class-component";
import ace from "ace-builds";
import "ace-builds/src-min-noconflict/theme-sqlserver"; // 新设主题
import "ace-builds/src-min-noconflict/mode-javascript"; // 默认设置的语言模式
import "ace-builds/src-min-noconflict/mode-json"; //
import "ace-builds/src-min-noconflict/mode-css"; //
import "ace-builds/src-min-noconflict/ext-language_tools";
import { ACE_BASE_PATH } from "@/utils/config";
import i18n from "@/plugins/i18n";
import "./index.scss";

const prefixCls = useDesign().getPrefixCls("code-edit");
@Component({
  name: "SlCodeEdit"
})
export default class SlCodeEdit extends mixins(i18n) {
  $refs!: {
    ace: any;
  };
  @Prop({
    type: String,
    required: true
  })
  readonly value: any;

  @Prop({
    type: Boolean,
    default: false
  })
  readonly readonly: any;

  @Prop({
    type: String,
    default: "javascript"
  })
  readonly mode: any;

  //是否开启语法检查，默认开启
  @Prop({
    type: Boolean,
    default: true
  })
  readonly userWorker: any;

  private dataStore: any = {
    aceEditor: null,
    themePath: "ace/theme/sqlserver", // 不导入 webpack-resolver，该模块路径会报错
    modePath: "ace/mode/javascript", // 同上
    codeValue: ""
  };

  private addAutoCompletion(ace) {
    const acData = [
      {
        meta: "VForm API",
        caption: "getWidgetRef",
        value: "getWidgetRef()",
        score: 1
      },
      {
        meta: "VForm API",
        caption: "getFormRef",
        value: "getFormRef()",
        score: 1
      }
      //TODO: 待补充！！
    ];
    const langTools = ace.require("ace/ext/language_tools");
    langTools.addCompleter({
      getCompletions: function (editor, session, pos, prefix, callback) {
        if (prefix.length === 0) {
          return callback(null, []);
        } else {
          return callback(null, acData);
        }
      }
    });
  }

  private setJsonMode() {
    this.dataStore.aceEditor.getSession().setMode("ace/mode/json");
  }

  private setCssMode() {
    this.dataStore.aceEditor.getSession().setMode("ace/mode/css");
  }

  private getEditorAnnotations() {
    return this.dataStore.aceEditor.getSession().getAnnotations();
  }

  mounted() {
    this.dataStore.codeValue = this.value
      ? JSON.stringify(JSON.parse(this.value), null, 2)
      : "";
    // this.dataStore.codeValue = JSON.stringify(JSON.parse(this.value), null, 2);
    ace.config.set("basePath", ACE_BASE_PATH);
    this.addAutoCompletion(ace); //添加自定义代码提示！！

    this.dataStore.aceEditor = ace.edit(this.$refs.ace, {
      maxLines: 50, // 最大行数，超过会自动出现滚动条
      minLines: 5, // 最小行数，还未到最大行数时，编辑器会自动伸缩大小
      fontSize: 12, // 编辑器内字体大小
      theme: this.dataStore.themePath, // 默认设置的主题
      mode: this.dataStore.modePath, // 默认设置的语言模式
      tabSize: 2, // 制表符设置为2个空格大小
      readOnly: this.readonly,
      highlightActiveLine: true,
      value: this.dataStore.codeValue
    });

    this.dataStore.aceEditor.setOptions({
      enableBasicAutocompletion: true,
      enableSnippets: true, // 设置代码片段提示
      enableLiveAutocompletion: true // 设置自动提示
    });

    if (this.mode === "json") {
      this.setJsonMode();
    } else if (this.mode === "css") {
      this.setCssMode();
    }

    if (!this.userWorker) {
      this.dataStore.aceEditor.getSession().setUseWorker(false);
    }

    //编辑时同步数据
    this.dataStore.aceEditor.getSession().on("change", (ev) => {
      //this.$emit('update:value', this.aceEditor.getValue())  // 触发更新事件, 实现.sync双向绑定！！
      this.$emit("input", this.dataStore.aceEditor.getValue());
    });
  }

  render() {
    return (
      <div class={prefixCls}>
        <div class={`${prefixCls}-content`} ref="ace"></div>
      </div>
    );
  }
}
