import request from "@/utils/request";
import ipList from "@/utils/ipConfig";

export function getJobGropsApi(params?: any) {
  return request({
    url: ipList.monitor.job.jobGrops,
    method: "get",
    params
  });
}
export function getJobStatusApi(params?: any) {
  return request({
    url: ipList.monitor.job.jobStatus,
    method: "get",
    params
  });
}
export function getJobListApi(params?: any) {
  return request({
    url: ipList.monitor.job.list,
    method: "get",
    params
  });
}

export function insetJobApi(query: any) {
  return request({
    url: ipList.monitor.job.crudJob,
    method: "post",
    data: query
  });
}

export function changeStatusApi(query: any) {
  return request({
    url: ipList.monitor.job.changeStatus,
    method: "put",
    data: query
  });
}
export function updateJobApi(query: any) {
  return request({
    url: ipList.monitor.job.crudJob,
    method: "put",
    data: query
  });
}

export function deleteJobApi(id: any) {
  return request({
    url: `${ipList.monitor.job.crudJob}/${id}`,
    method: "DELETE"
  });
}

export function exportJobApi(query: any) {
  return request({
    url: ipList.monitor.job.export,
    method: "post",
    responseType: "blob",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: query
  });
}

export function runJobApi(query: any) {
  return request({
    url: ipList.monitor.job.run,
    method: "put",
    data: query
  });
}
