import { useDesign } from "@/hooks/web/useDesign";
import { Component, Vue, Mixins, Prop } from "vue-property-decorator";
import {
  containers,
  basicFields,
  advancedFields,
  customFields
} from "./widgetConfig";
import { Collapse, CollapseItem } from "element-ui";
import i18n from "@/plugins/i18n";
import Draggable from "vuedraggable";
import { SlTabs, SlScrollPane, SvgIcon } from "@/components";
import { mixins } from "vue-class-component";
import "./index.scss";
const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("widget-panel");

@Component({
  name: "WidgetPanel"
})
export default class WidgetPanel extends mixins(i18n) {
  @Prop(Object) readonly designer: any;
  @Prop({
    type: Array,
    default: () => {
      return [];
    }
  })
  readonly bannerData: any;
  private dataStore: any = {
    activeIndex: 0,
    activeNames: ["1", "2", "3", "4"],
    tabData: [
      {
        label: this.i18nt("designer.componentLib"),
        icon: "el-icon-set-up"
      }
      // {
      //   label: this.i18nt("designer.formLib"),
      //   icon: "el-icon-c-scale-to-original"
      // }
    ],
    collapseData: [
      [
        // {
        //   title: "containerTitle",
        //   name: "1",
        //   field: "containers",
        //   data: containers
        //     .map((con) => {
        //       return {
        //         ...con,
        //         displayName: this.i18n2t(
        //           `designer.widgetLabel.${con.type}`,
        //           `extension.widgetLabel.${con.type}`
        //         )
        //       };
        //     })
        //     .filter((con) => {
        //       return !con.internal && !this.isBanned(con.type);
        //     })
        // },
        {
          title: "basicFieldTitle",
          name: "2",
          field: "basicFields",
          data: basicFields
        },
        {
          title: "advancedFieldTitle",
          name: "3",
          field: "advancedFields",
          data: advancedFields
        }
        // {
        //   title: "advancedFieldTitle",
        //   name: "3",
        //   field: "advancedFields",
        //   data: advancedFields
        // },
        // {
        //   title: "customFieldTitle",
        //   name: "4",
        //   field: "customFields",
        //   data: customFields
        // }
      ]
    ]
  };

  private isBanned(wName: any) {
    return this.bannerData.includes(wName);
  }

  private checkMove(evt, item) {
    return this.designer[
      item.name === "1" ? "checkWidgetMove" : "checkFieldMove"
    ](evt);
  }

  private handleClone(origin, item) {
    const nOrigin = JSON.parse(JSON.stringify(origin));
    console.log(nOrigin, "pppppppppp");
    if (nOrigin.label) {
      nOrigin["label"] = this.i18nt(`designer.widgetLabel.${nOrigin.label}`);
    }
    return this.designer[
      item.name === "1" ? "copyNewContainerWidget" : "copyNewFieldWidget"
    ](nOrigin);
  }

  private addFieldByDbClick(item: any) {
    console.log(item, "00000000");
  }

  render() {
    const renderComponentLibrary = () => {
      return (
        <Collapse>
          {this.dataStore.collapseData.map((item: any) => {
            return (
              <CollapseItem
                name={item.name}
                title={this.i18nt(`designer.${item.title}`)}
              ></CollapseItem>
            );
          })}
        </Collapse>
      );
    };
    const renderFormModel = () => {};

    return (
      <div class={`${prefixCls}`}>
        <SlTabs
          v-model={this.dataStore.activeIndex}
          tabData={this.dataStore.tabData}
        ></SlTabs>
        <div class={`${prefixCls}-content`}>
          <Collapse
            v-model={this.dataStore.activeNames}
            class="widget-collapse"
          >
            {this.dataStore.collapseData[this.dataStore.activeIndex].map(
              (item: any) => {
                return (
                  <CollapseItem
                    name={item.name}
                    title={this.i18nt(`designer.${item.title}`)}
                  >
                    <Draggable
                      tag={"ul"}
                      list={item.data}
                      group={{ name: "dragGroup", pull: "clone", put: false }}
                      move={(evt) => this.checkMove(evt, item)}
                      clone={(evt) => this.handleClone(evt, item)}
                      ghost-class="ghost-item"
                      sort={false}
                    >
                      {item.data.map((ele: any) => {
                        return (
                          <li class="field-widget-item" title={ele.displayName}>
                            <SvgIcon
                              icon-class={`icon-${ele.field}`}
                              class-name="color-svg-icon"
                            ></SvgIcon>
                            {ele.component
                              ? this.i18n2t(
                                  `designer.widgetLabel.${
                                    ele.label ? ele.label : ele.field
                                  }`,
                                  `extension.widgetLabel.${
                                    ele.label ? ele.label : ele.field
                                  }`
                                )
                              : "000000"}
                          </li>
                        );
                      })}
                    </Draggable>
                  </CollapseItem>
                );
              }
            )}
          </Collapse>
        </div>
      </div>
    );
  }
}
