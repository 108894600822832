import request from "@/utils/request";

// 部门列表查询
export function getUserListApi(params: any) {
  return request({
    url: "/system/user/list",
    method: "get",
    params
  });
}

// 修改用户状态
export function changeUserStatusApi(data: any) {
  return request({
    url: `/system/user/changeStatus`,
    method: "put",
    data
  });
}

// 新增用户
export function insertUserListApi(data: any) {
  return request({
    url: `/system/user`,
    method: "post",
    data
  });
}

// 编辑用户
export function updateUserListApi(data: any) {
  return request({
    url: `/system/user`,
    method: "put",
    data
  });
}

// 删除用户
export function deleteUserListApi(ids: any) {
  return request({
    url: `/system/user/${ids}`,
    method: "delete"
  });
}

export function downloadUserModuleApi() {
  return request({
    url: `/system/user/importTemplate`,
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    responseType: "blob"
  });
}

export function importUserApi(data: any, updateSupport: boolean) {
  return request({
    url: `system/user/importData?updateSupport=${updateSupport}`,
    method: "post",
    data
  });
}

// 重置用户密码
export function resetUserPswApi(data: any) {
  return request({
    url: `/system/user/resetPwd`,
    method: "put",
    data
  });
}

// 角色导出
export function userExportApi(data: any) {
  return request({
    url: `/system/user/export`,
    method: "post",
    responseType: "blob",
    data
  });
}
