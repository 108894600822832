import { formatTime } from "@/utils/index";

class CalendarUtils {
  /**
   * 1900-2100各年的24节气日期速查表
   * @Array Of Property
   * @return 0x string For splice
   */
  static lTermInfo = [
    "9778397bd097c36b0b6fc9274c91aa",
    "97b6b97bd19801ec9210c965cc920e",
    "97bcf97c3598082c95f8c965cc920f",
    "97bd0b06bdb0722c965ce1cfcc920f",
    "b027097bd097c36b0b6fc9274c91aa",
    "97b6b97bd19801ec9210c965cc920e",
    "97bcf97c359801ec95f8c965cc920f",
    "97bd0b06bdb0722c965ce1cfcc920f",
    "b027097bd097c36b0b6fc9274c91aa",
    "97b6b97bd19801ec9210c965cc920e",
    "97bcf97c359801ec95f8c965cc920f",
    "97bd0b06bdb0722c965ce1cfcc920f",
    "b027097bd097c36b0b6fc9274c91aa",
    "9778397bd19801ec9210c965cc920e",
    "97b6b97bd19801ec95f8c965cc920f",
    "97bd09801d98082c95f8e1cfcc920f",
    "97bd097bd097c36b0b6fc9210c8dc2",
    "9778397bd197c36c9210c9274c91aa",
    "97b6b97bd19801ec95f8c965cc920e",
    "97bd09801d98082c95f8e1cfcc920f",
    "97bd097bd097c36b0b6fc9210c8dc2",
    "9778397bd097c36c9210c9274c91aa",
    "97b6b97bd19801ec95f8c965cc920e",
    "97bcf97c3598082c95f8e1cfcc920f",
    "97bd097bd097c36b0b6fc9210c8dc2",
    "9778397bd097c36c9210c9274c91aa",
    "97b6b97bd19801ec9210c965cc920e",
    "97bcf97c3598082c95f8c965cc920f",
    "97bd097bd097c35b0b6fc920fb0722",
    "9778397bd097c36b0b6fc9274c91aa",
    "97b6b97bd19801ec9210c965cc920e",
    "97bcf97c3598082c95f8c965cc920f",
    "97bd097bd097c35b0b6fc920fb0722",
    "9778397bd097c36b0b6fc9274c91aa",
    "97b6b97bd19801ec9210c965cc920e",
    "97bcf97c359801ec95f8c965cc920f",
    "97bd097bd097c35b0b6fc920fb0722",
    "9778397bd097c36b0b6fc9274c91aa",
    "97b6b97bd19801ec9210c965cc920e",
    "97bcf97c359801ec95f8c965cc920f",
    "97bd097bd097c35b0b6fc920fb0722",
    "9778397bd097c36b0b6fc9274c91aa",
    "97b6b97bd19801ec9210c965cc920e",
    "97bcf97c359801ec95f8c965cc920f",
    "97bd097bd07f595b0b6fc920fb0722",
    "9778397bd097c36b0b6fc9210c8dc2",
    "9778397bd19801ec9210c9274c920e",
    "97b6b97bd19801ec95f8c965cc920f",
    "97bd07f5307f595b0b0bc920fb0722",
    "7f0e397bd097c36b0b6fc9210c8dc2",
    "9778397bd097c36c9210c9274c920e",
    "97b6b97bd19801ec95f8c965cc920f",
    "97bd07f5307f595b0b0bc920fb0722",
    "7f0e397bd097c36b0b6fc9210c8dc2",
    "9778397bd097c36c9210c9274c91aa",
    "97b6b97bd19801ec9210c965cc920e",
    "97bd07f1487f595b0b0bc920fb0722",
    "7f0e397bd097c36b0b6fc9210c8dc2",
    "9778397bd097c36b0b6fc9274c91aa",
    "97b6b97bd19801ec9210c965cc920e",
    "97bcf7f1487f595b0b0bb0b6fb0722",
    "7f0e397bd097c35b0b6fc920fb0722",
    "9778397bd097c36b0b6fc9274c91aa",
    "97b6b97bd19801ec9210c965cc920e",
    "97bcf7f1487f595b0b0bb0b6fb0722",
    "7f0e397bd097c35b0b6fc920fb0722",
    "9778397bd097c36b0b6fc9274c91aa",
    "97b6b97bd19801ec9210c965cc920e",
    "97bcf7f1487f531b0b0bb0b6fb0722",
    "7f0e397bd097c35b0b6fc920fb0722",
    "9778397bd097c36b0b6fc9274c91aa",
    "97b6b97bd19801ec9210c965cc920e",
    "97bcf7f1487f531b0b0bb0b6fb0722",
    "7f0e397bd07f595b0b6fc920fb0722",
    "9778397bd097c36b0b6fc9274c91aa",
    "97b6b97bd19801ec9210c9274c920e",
    "97bcf7f0e47f531b0b0bb0b6fb0722",
    "7f0e397bd07f595b0b0bc920fb0722",
    "9778397bd097c36b0b6fc9210c91aa",
    "97b6b97bd197c36c9210c9274c920e",
    "97bcf7f0e47f531b0b0bb0b6fb0722",
    "7f0e397bd07f595b0b0bc920fb0722",
    "9778397bd097c36b0b6fc9210c8dc2",
    "9778397bd097c36c9210c9274c920e",
    "97b6b7f0e47f531b0723b0b6fb0722",
    "7f0e37f5307f595b0b0bc920fb0722",
    "7f0e397bd097c36b0b6fc9210c8dc2",
    "9778397bd097c36b0b70c9274c91aa",
    "97b6b7f0e47f531b0723b0b6fb0721",
    "7f0e37f1487f595b0b0bb0b6fb0722",
    "7f0e397bd097c35b0b6fc9210c8dc2",
    "9778397bd097c36b0b6fc9274c91aa",
    "97b6b7f0e47f531b0723b0b6fb0721",
    "7f0e27f1487f595b0b0bb0b6fb0722",
    "7f0e397bd097c35b0b6fc920fb0722",
    "9778397bd097c36b0b6fc9274c91aa",
    "97b6b7f0e47f531b0723b0b6fb0721",
    "7f0e27f1487f531b0b0bb0b6fb0722",
    "7f0e397bd097c35b0b6fc920fb0722",
    "9778397bd097c36b0b6fc9274c91aa",
    "97b6b7f0e47f531b0723b0b6fb0721",
    "7f0e27f1487f531b0b0bb0b6fb0722",
    "7f0e397bd097c35b0b6fc920fb0722",
    "9778397bd097c36b0b6fc9274c91aa",
    "97b6b7f0e47f531b0723b0b6fb0721",
    "7f0e27f1487f531b0b0bb0b6fb0722",
    "7f0e397bd07f595b0b0bc920fb0722",
    "9778397bd097c36b0b6fc9274c91aa",
    "97b6b7f0e47f531b0723b0787b0721",
    "7f0e27f0e47f531b0b0bb0b6fb0722",
    "7f0e397bd07f595b0b0bc920fb0722",
    "9778397bd097c36b0b6fc9210c91aa",
    "97b6b7f0e47f149b0723b0787b0721",
    "7f0e27f0e47f531b0723b0b6fb0722",
    "7f0e397bd07f595b0b0bc920fb0722",
    "9778397bd097c36b0b6fc9210c8dc2",
    "977837f0e37f149b0723b0787b0721",
    "7f07e7f0e47f531b0723b0b6fb0722",
    "7f0e37f5307f595b0b0bc920fb0722",
    "7f0e397bd097c35b0b6fc9210c8dc2",
    "977837f0e37f14998082b0787b0721",
    "7f07e7f0e47f531b0723b0b6fb0721",
    "7f0e37f1487f595b0b0bb0b6fb0722",
    "7f0e397bd097c35b0b6fc9210c8dc2",
    "977837f0e37f14998082b0787b06bd",
    "7f07e7f0e47f531b0723b0b6fb0721",
    "7f0e27f1487f531b0b0bb0b6fb0722",
    "7f0e397bd097c35b0b6fc920fb0722",
    "977837f0e37f14998082b0787b06bd",
    "7f07e7f0e47f531b0723b0b6fb0721",
    "7f0e27f1487f531b0b0bb0b6fb0722",
    "7f0e397bd097c35b0b6fc920fb0722",
    "977837f0e37f14998082b0787b06bd",
    "7f07e7f0e47f531b0723b0b6fb0721",
    "7f0e27f1487f531b0b0bb0b6fb0722",
    "7f0e397bd07f595b0b0bc920fb0722",
    "977837f0e37f14998082b0787b06bd",
    "7f07e7f0e47f531b0723b0b6fb0721",
    "7f0e27f1487f531b0b0bb0b6fb0722",
    "7f0e397bd07f595b0b0bc920fb0722",
    "977837f0e37f14998082b0787b06bd",
    "7f07e7f0e47f149b0723b0787b0721",
    "7f0e27f0e47f531b0b0bb0b6fb0722",
    "7f0e397bd07f595b0b0bc920fb0722",
    "977837f0e37f14998082b0723b06bd",
    "7f07e7f0e37f149b0723b0787b0721",
    "7f0e27f0e47f531b0723b0b6fb0722",
    "7f0e397bd07f595b0b0bc920fb0722",
    "977837f0e37f14898082b0723b02d5",
    "7ec967f0e37f14998082b0787b0721",
    "7f07e7f0e47f531b0723b0b6fb0722",
    "7f0e37f1487f595b0b0bb0b6fb0722",
    "7f0e37f0e37f14898082b0723b02d5",
    "7ec967f0e37f14998082b0787b0721",
    "7f07e7f0e47f531b0723b0b6fb0722",
    "7f0e37f1487f531b0b0bb0b6fb0722",
    "7f0e37f0e37f14898082b0723b02d5",
    "7ec967f0e37f14998082b0787b06bd",
    "7f07e7f0e47f531b0723b0b6fb0721",
    "7f0e37f1487f531b0b0bb0b6fb0722",
    "7f0e37f0e37f14898082b072297c35",
    "7ec967f0e37f14998082b0787b06bd",
    "7f07e7f0e47f531b0723b0b6fb0721",
    "7f0e27f1487f531b0b0bb0b6fb0722",
    "7f0e37f0e37f14898082b072297c35",
    "7ec967f0e37f14998082b0787b06bd",
    "7f07e7f0e47f531b0723b0b6fb0721",
    "7f0e27f1487f531b0b0bb0b6fb0722",
    "7f0e37f0e366aa89801eb072297c35",
    "7ec967f0e37f14998082b0787b06bd",
    "7f07e7f0e47f149b0723b0787b0721",
    "7f0e27f1487f531b0b0bb0b6fb0722",
    "7f0e37f0e366aa89801eb072297c35",
    "7ec967f0e37f14998082b0723b06bd",
    "7f07e7f0e47f149b0723b0787b0721",
    "7f0e27f0e47f531b0723b0b6fb0722",
    "7f0e37f0e366aa89801eb072297c35",
    "7ec967f0e37f14998082b0723b06bd",
    "7f07e7f0e37f14998083b0787b0721",
    "7f0e27f0e47f531b0723b0b6fb0722",
    "7f0e37f0e366aa89801eb072297c35",
    "7ec967f0e37f14898082b0723b02d5",
    "7f07e7f0e37f14998082b0787b0721",
    "7f07e7f0e47f531b0723b0b6fb0722",
    "7f0e36665b66aa89801e9808297c35",
    "665f67f0e37f14898082b0723b02d5",
    "7ec967f0e37f14998082b0787b0721",
    "7f07e7f0e47f531b0723b0b6fb0722",
    "7f0e36665b66a449801e9808297c35",
    "665f67f0e37f14898082b0723b02d5",
    "7ec967f0e37f14998082b0787b06bd",
    "7f07e7f0e47f531b0723b0b6fb0721",
    "7f0e36665b66a449801e9808297c35",
    "665f67f0e37f14898082b072297c35",
    "7ec967f0e37f14998082b0787b06bd",
    "7f07e7f0e47f531b0723b0b6fb0721",
    "7f0e26665b66a449801e9808297c35",
    "665f67f0e37f1489801eb072297c35",
    "7ec967f0e37f14998082b0787b06bd",
    "7f07e7f0e47f531b0723b0b6fb0721",
    "7f0e27f1487f531b0b0bb0b6fb0722"
  ];

  /**
   * 农历 1900-2100 的润大小信息表
   * @return Array
   */
  static lunarInfo = [
    0x04bd8,
    0x04ae0,
    0x0a570,
    0x054d5,
    0x0d260,
    0x0d950,
    0x16554,
    0x056a0,
    0x09ad0,
    0x055d2, //1900-1909
    0x04ae0,
    0x0a5b6,
    0x0a4d0,
    0x0d250,
    0x1d255,
    0x0b540,
    0x0d6a0,
    0x0ada2,
    0x095b0,
    0x14977, //1910-1919
    0x04970,
    0x0a4b0,
    0x0b4b5,
    0x06a50,
    0x06d40,
    0x1ab54,
    0x02b60,
    0x09570,
    0x052f2,
    0x04970, //1920-1929
    0x06566,
    0x0d4a0,
    0x0ea50,
    0x06e95,
    0x05ad0,
    0x02b60,
    0x186e3,
    0x092e0,
    0x1c8d7,
    0x0c950, //1930-1939
    0x0d4a0,
    0x1d8a6,
    0x0b550,
    0x056a0,
    0x1a5b4,
    0x025d0,
    0x092d0,
    0x0d2b2,
    0x0a950,
    0x0b557, //1940-1949
    0x06ca0,
    0x0b550,
    0x15355,
    0x04da0,
    0x0a5b0,
    0x14573,
    0x052b0,
    0x0a9a8,
    0x0e950,
    0x06aa0, //1950-1959
    0x0aea6,
    0x0ab50,
    0x04b60,
    0x0aae4,
    0x0a570,
    0x05260,
    0x0f263,
    0x0d950,
    0x05b57,
    0x056a0, //1960-1969
    0x096d0,
    0x04dd5,
    0x04ad0,
    0x0a4d0,
    0x0d4d4,
    0x0d250,
    0x0d558,
    0x0b540,
    0x0b6a0,
    0x195a6, //1970-1979
    0x095b0,
    0x049b0,
    0x0a974,
    0x0a4b0,
    0x0b27a,
    0x06a50,
    0x06d40,
    0x0af46,
    0x0ab60,
    0x09570, //1980-1989
    0x04af5,
    0x04970,
    0x064b0,
    0x074a3,
    0x0ea50,
    0x06b58,
    0x05ac0,
    0x0ab60,
    0x096d5,
    0x092e0, //1990-1999
    0x0c960,
    0x0d954,
    0x0d4a0,
    0x0da50,
    0x07552,
    0x056a0,
    0x0abb7,
    0x025d0,
    0x092d0,
    0x0cab5, //2000-2009
    0x0a950,
    0x0b4a0,
    0x0baa4,
    0x0ad50,
    0x055d9,
    0x04ba0,
    0x0a5b0,
    0x15176,
    0x052b0,
    0x0a930, //2010-2019
    0x07954,
    0x06aa0,
    0x0ad50,
    0x05b52,
    0x04b60,
    0x0a6e6,
    0x0a4e0,
    0x0d260,
    0x0ea65,
    0x0d530, //2020-2029
    0x05aa0,
    0x076a3,
    0x096d0,
    0x04afb,
    0x04ad0,
    0x0a4d0,
    0x1d0b6,
    0x0d250,
    0x0d520,
    0x0dd45, //2030-2039
    0x0b5a0,
    0x056d0,
    0x055b2,
    0x049b0,
    0x0a577,
    0x0a4b0,
    0x0aa50,
    0x1b255,
    0x06d20,
    0x0ada0, //2040-2049
    /**Add By JJonline@JJonline.Cn**/
    0x14b63,
    0x09370,
    0x049f8,
    0x04970,
    0x064b0,
    0x168a6,
    0x0ea50,
    0x06b20,
    0x1a6c4,
    0x0aae0, //2050-2059
    0x092e0,
    0x0d2e3,
    0x0c960,
    0x0d557,
    0x0d4a0,
    0x0da50,
    0x05d55,
    0x056a0,
    0x0a6d0,
    0x055d4, //2060-2069
    0x052d0,
    0x0a9b8,
    0x0a950,
    0x0b4a0,
    0x0b6a6,
    0x0ad50,
    0x055a0,
    0x0aba4,
    0x0a5b0,
    0x052b0, //2070-2079
    0x0b273,
    0x06930,
    0x07337,
    0x06aa0,
    0x0ad50,
    0x14b55,
    0x04b60,
    0x0a570,
    0x054e4,
    0x0d160, //2080-2089
    0x0e968,
    0x0d520,
    0x0daa0,
    0x16aa6,
    0x056d0,
    0x04ae0,
    0x0a9d4,
    0x0a4d0,
    0x0d150,
    0x0f252, //2090-2099
    0x0d520
  ]; //2100;

  /**
   * 24节气速查表
   * @Array Of Property
   * @trans['小寒','大寒','立春','雨水','惊蛰','春分','清明','谷雨','立夏','小满','芒种','夏至','小暑','大暑','立秋','处暑','白露','秋分','寒露','霜降','立冬','小雪','大雪','冬至']
   * @return Cn string
   */
  static lunarTerm = [
    "\u5c0f\u5bd2",
    "\u5927\u5bd2",
    "\u7acb\u6625",
    "\u96e8\u6c34",
    "\u60ca\u86f0",
    "\u6625\u5206",
    "\u6e05\u660e",
    "\u8c37\u96e8",
    "\u7acb\u590f",
    "\u5c0f\u6ee1",
    "\u8292\u79cd",
    "\u590f\u81f3",
    "\u5c0f\u6691",
    "\u5927\u6691",
    "\u7acb\u79cb",
    "\u5904\u6691",
    "\u767d\u9732",
    "\u79cb\u5206",
    "\u5bd2\u9732",
    "\u971c\u964d",
    "\u7acb\u51ac",
    "\u5c0f\u96ea",
    "\u5927\u96ea",
    "\u51ac\u81f3"
  ];

  /**
   * 数字转中文速查表
   * @Array Of Property
   * @trans ['日','一','二','三','四','五','六','七','八','九','十']
   * @return Cn string
   */
  static nStr1 = [
    "\u65e5",
    "\u4e00",
    "\u4e8c",
    "\u4e09",
    "\u56db",
    "\u4e94",
    "\u516d",
    "\u4e03",
    "\u516b",
    "\u4e5d",
    "\u5341"
  ];

  /**
   * 日期转农历称呼速查表
   * @Array Of Property
   * @trans ['初','十','廿','卅']
   * @return Cn string
   */
  static nStr2 = ["\u521d", "\u5341", "\u5eff", "\u5345"];

  /**
   * 月份转农历称呼速查表
   * @Array Of Property
   * @trans ['正','一','二','三','四','五','六','七','八','九','十','冬','腊']
   * @return Cn string
   */
  static nStr3 = [
    "\u6b63",
    "\u4e8c",
    "\u4e09",
    "\u56db",
    "\u4e94",
    "\u516d",
    "\u4e03",
    "\u516b",
    "\u4e5d",
    "\u5341",
    "\u51ac",
    "\u814a"
  ];

  /**
   * 年份数字转中文速查表
   * @Array Of Property
   * @trans ['零','一','二','三','四','五','六','七','八','九','十']
   * @return Cn string
   */
  static nStr4 = [
    "\u96f6",
    "\u4e00",
    "\u4e8c",
    "\u4e09",
    "\u56db",
    "\u4e94",
    "\u516d",
    "\u4e03",
    "\u516b",
    "\u4e5d",
    "\u5341"
  ];

  /**
   * 公历每个月份的天数普通表
   * @Array Of Property
   * @return Number
   */
  static solarMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  /**
   * 天干地支之天干速查表
   * @Array Of Property trans['甲','乙','丙','丁','戊','己','庚','辛','壬','癸']
   * @return Cn string
   */
  static gan = [
    "\u7532",
    "\u4e59",
    "\u4e19",
    "\u4e01",
    "\u620a",
    "\u5df1",
    "\u5e9a",
    "\u8f9b",
    "\u58ec",
    "\u7678"
  ];

  /**
   * 天干地支之地支速查表
   * @Array Of Property
   * @trans['子','丑','寅','卯','辰','巳','午','未','申','酉','戌','亥']
   * @return Cn string
   */
  static zhi = [
    "\u5b50",
    "\u4e11",
    "\u5bc5",
    "\u536f",
    "\u8fb0",
    "\u5df3",
    "\u5348",
    "\u672a",
    "\u7533",
    "\u9149",
    "\u620c",
    "\u4ea5"
  ];
  /**
   * 天干地支之地支速查表<=>生肖
   * @Array Of Property
   * @trans['鼠','牛','虎','兔','龙','蛇','马','羊','猴','鸡','狗','猪']
   * @return Cn string
   */
  static animals = [
    "\u9f20",
    "\u725b",
    "\u864e",
    "\u5154",
    "\u9f99",
    "\u86c7",
    "\u9a6c",
    "\u7f8a",
    "\u7334",
    "\u9e21",
    "\u72d7",
    "\u732a"
  ];
  static holidays: any = {
    "1.1": "元旦节",
    "2.14": "情人节",
    "3.8": "妇女节",
    "3.12": "植树节",
    "4.1": "愚人节",
    "4.22": "地球日",
    "5.1": "劳动节",
    "5.4": "青年节",
    "5.12": "护士节",
    "6.1": "儿童节",
    "7.1": "建党节",
    "8.1": "建军节",
    "9.10": "教师节",
    "10.1": "国庆节",
    "11.1": "万圣节",
    "12.24": "圣诞节"
  };
  // 星座
  static constellations = [
    "白羊座",
    "金牛座",
    "双子座",
    "巨蟹座",
    "狮子座",
    "处女座",
    "天秤座",
    "天蝎座",
    "射手座",
    "摩羯座",
    "水瓶座",
    "双鱼座"
  ];

  /**
   * 返回农历y年闰月的天数 若该年没有闰月则返回0
   * @return Number (0、29、30)
   * @eg:var leapMonthDay = solarLunar.leapDays(1987) ;//leapMonthDay=29
   * @param y
   */
  static leapDays(y: any) {
    if (CalendarUtils.leapMonth(y)) {
      return CalendarUtils.lunarInfo[y - 1900] & 0x10000 ? 30 : 29;
    }
    return 0;
  }

  /**
   * 返回农历y年闰月是哪个月；若y年没有闰月 则返回0
   * @return Number (0-12)
   * @eg:var leapMonth = solarLunar.leapMonth(1987) ;//leapMonth=6
   * @param y
   */
  static leapMonth(y: any) {
    //闰字编码 \u95f0
    return CalendarUtils.lunarInfo[y - 1900] & 0xf;
  }

  /**
   * 返回农历y年一整年的总天数
   * @return Number
   * @eg:var count = solarLunar.lYearDays(1987) ;//count=387
   * @param y
   */
  static lYearDays(y: any) {
    let i,
      sum = 348;
    for (i = 0x8000; i > 0x8; i >>= 1) {
      sum += CalendarUtils.lunarInfo[y - 1900] & i ? 1 : 0;
    }
    return sum + CalendarUtils.leapDays(y);
  }

  /**
   * 返回农历 y 年 m 月（非闰月）的总天数，计算 m 为闰月时的天数请使用 leapDays 方法
   * @return Number (-1、29、30)
   * @eg:var MonthDay = solarLunar.monthDays(1987,9) ;//MonthDay=29
   * @param y
   * @param m
   */
  static monthDays(y: any, m: any) {
    if (m > 12 || m < 1) {
      return -1;
    } //月份参数从1至12，参数错误返回-1
    return CalendarUtils.lunarInfo[y - 1900] & (0x10000 >> m) ? 30 : 29;
  }

  /**
   * 返回公历(!)y年m月的天数
   * @param solar Year
   * @return Number (-1、28、29、30、31)
   * @eg:var solarMonthDay = solarLunar.leapDays(1987) ;//solarMonthDay=30
   */
  static solarDays(y: any, m: any) {
    if (m > 12 || m < 1) {
      return -1;
    } //若参数错误 返回-1
    const ms = m - 1;
    if (ms === 1) {
      // 2月份的闰平规律测算后确认返回28或29
      return (y % 4 === 0 && y % 100 !== 0) || y % 400 === 0 ? 29 : 28;
    } else {
      return CalendarUtils.solarMonth[ms];
    }
  }

  /**
   * 传入offset偏移量返回干支
   * @param offset 相对甲子的偏移量
   * @return Cn string
   */
  static toGanZhi(offset: any) {
    return CalendarUtils.gan[offset % 10] + CalendarUtils.zhi[offset % 12];
  }

  /**
   * 传入公历(!) y 年获得该年第 n 个节气的公历日期
   * @return number Number
   * @eg:var _24 = solarLunar.getTerm(1987,3) ;//_24=4;意即1987年2月4日立春
   * @param y
   * @param n
   */
  static getTerm(y: any, n: any) {
    if (y < 1900 || y > 2100) {
      return -1;
    }
    if (n < 1 || n > 24) {
      return -1;
    }
    const _table = CalendarUtils.lTermInfo[y - 1900];
    const _info = [
      parseInt("0x" + _table.substr(0, 5)).toString(),
      parseInt("0x" + _table.substr(5, 5)).toString(),
      parseInt("0x" + _table.substr(10, 5)).toString(),
      parseInt("0x" + _table.substr(15, 5)).toString(),
      parseInt("0x" + _table.substr(20, 5)).toString(),
      parseInt("0x" + _table.substr(25, 5)).toString()
    ];
    const _calDay = [
      _info[0].substr(0, 1),
      _info[0].substr(1, 2),
      _info[0].substr(3, 1),
      _info[0].substr(4, 2),

      _info[1].substr(0, 1),
      _info[1].substr(1, 2),
      _info[1].substr(3, 1),
      _info[1].substr(4, 2),

      _info[2].substr(0, 1),
      _info[2].substr(1, 2),
      _info[2].substr(3, 1),
      _info[2].substr(4, 2),

      _info[3].substr(0, 1),
      _info[3].substr(1, 2),
      _info[3].substr(3, 1),
      _info[3].substr(4, 2),

      _info[4].substr(0, 1),
      _info[4].substr(1, 2),
      _info[4].substr(3, 1),
      _info[4].substr(4, 2),

      _info[5].substr(0, 1),
      _info[5].substr(1, 2),
      _info[5].substr(3, 1),
      _info[5].substr(4, 2)
    ];
    return parseInt(_calDay[n - 1]);
  }

  /**
   * 传入农历年份数字返回汉语通俗表示法
   * @return string
   * @eg:
   * @param y
   */
  static toChinaYear(y: any) {
    //年 => \u5E74
    const oxxx = parseInt(String(y / 1000));
    const xoxx = parseInt(String((y % 1000) / 100));
    const xxox = parseInt(String((y % 100) / 10));
    const xxxo = y % 10;
    return (
      CalendarUtils.nStr4[oxxx] +
      CalendarUtils.nStr4[xoxx] +
      CalendarUtils.nStr4[xxox] +
      CalendarUtils.nStr4[xxxo] +
      "\u5E74"
    );
  }

  /**
   * 传入农历数字月份返回汉语通俗表示法
   * @return number string
   * @eg:var cnMonth = solarLunar.toChinaMonth(12) ;//cnMonth='腊月'
   * @param m
   */
  static toChinaMonth(m: any) {
    // 月 => \u6708
    if (m > 12 || m < 1) {
      return -1;
    } //若参数错误 返回-1
    let s = CalendarUtils.nStr3[m - 1];
    s += "\u6708"; //加上月字
    return s;
  }

  /**
   * 传入农历日期数字返回汉字表示法
   * @return Cn string
   * @eg:var cnDay = solarLunar.toChinaDay(21) ;//cnMonth='廿一'
   * @param d
   */
  static toChinaDay(d: any) {
    //日 => \u65e5
    let s;
    switch (d) {
      case 10:
        s = "\u521d\u5341";
        break;
      case 20:
        s = "\u4e8c\u5341";
        break;
      case 30:
        s = "\u4e09\u5341";
        break;
      default:
        s = CalendarUtils.nStr2[Math.floor(d / 10)];
        s += CalendarUtils.nStr1[d % 10];
    }
    return s;
  }

  /**
   * 年份转生肖[!仅能大致转换] => 精确划分生肖分界线是“立春”
   * @param y year
   * @return Cn string
   * @eg:var animal = solarLunar.getAnimal(1987) ;//animal='兔'
   * todo 生肖需要精确转换
   */
  static getAnimal(y: any) {
    return CalendarUtils.animals[(y - 4) % 12];
  }

  /**
   * 传入公历年月日获得详细的公历、农历object信息 <=>JSON
   * @return JSON object
   * @eg:console.log(solarLunar.solar2lunar(1987,11,01));
   * @param startY
   * @param startM
   * @param startD
   */
  static solar2lunar(startY: any, startM: any, startD: any) {
    let objDate;
    //参数区间1900.1.31~2100.12.31
    if (startY < 1900 || startY > 2100) {
      return -1;
    } //年份限定、上限
    if (startY === 1900 && startM === 1 && startD < 31) {
      return -1;
    } //下限
    if (!startY) {
      //未传参 获得当天
      objDate = new Date();
    } else {
      objDate = new Date(startY, parseInt(startM) - 1, startD);
    }
    let i;
    let leap = 0;
    let temp = 0;
    //修正ymd参数
    // eslint-disable-next-line prefer-const
    const y = objDate.getFullYear(),
      m = objDate.getMonth() + 1,
      d = objDate.getDate();
    let offset =
      (Date.UTC(objDate.getFullYear(), objDate.getMonth(), objDate.getDate()) -
        Date.UTC(1900, 0, 31)) /
      86400000;
    for (i = 1900; i < 2101 && offset > 0; i++) {
      temp = CalendarUtils.lYearDays(i);
      offset -= temp;
    }
    if (offset < 0) {
      offset += temp;
      i--;
    }

    //是否今天
    // eslint-disable-next-line prefer-const
    let isTodayObj = new Date(),
      isToday = false;
    if (
      isTodayObj.getFullYear() === y &&
      isTodayObj.getMonth() + 1 === m &&
      isTodayObj.getDate() === d
    ) {
      isToday = true;
    }
    //星期几
    // eslint-disable-next-line prefer-const
    let nWeek = objDate.getDay(),
      // eslint-disable-next-line prefer-const
      cWeek = CalendarUtils.nStr1[nWeek];
    if (nWeek === 0) {
      nWeek = 7;
    } //数字表示周几顺应天朝周一开始的惯例
    //农历年
    const year = i;

    leap = CalendarUtils.leapMonth(i); //闰哪个月

    let isLeap = false;

    //效验闰月
    for (i = 1; i < 13 && offset > 0; i++) {
      //闰月
      if (leap > 0 && i === leap + 1 && !isLeap) {
        --i;
        isLeap = true;
        temp = CalendarUtils.leapDays(year); //计算农历闰月天数
      } else {
        temp = CalendarUtils.monthDays(year, i); //计算农历普通月天数
      }
      //解除闰月
      if (isLeap && i === leap + 1) {
        isLeap = false;
      }
      offset -= temp;
    }

    if (offset === 0 && leap > 0 && i === leap + 1)
      if (isLeap) {
        isLeap = false;
      } else {
        isLeap = true;
        --i;
      }
    if (offset < 0) {
      offset += temp;
      --i;
    }
    //农历月
    const month = i;
    //农历日
    const day = offset + 1;

    //天干地支处理
    const sm = m - 1;
    const term3 = CalendarUtils.getTerm(y, 3); //该公历年立春日期
    let gzY = CalendarUtils.toGanZhi(y - 4); //普通按年份计算，下方尚需按立春节气来修正
    const termTimestamp = new Date(y, 1, term3).getTime();
    const dayTimestamp = new Date(y, sm, d).getTime();
    //依据立春日进行修正gzY
    if (dayTimestamp < termTimestamp) {
      gzY = CalendarUtils.toGanZhi(y - 5);
    }

    //月柱 1900年1月小寒以前为 丙子月(60进制12)
    const firstNode = CalendarUtils.getTerm(y, m * 2 - 1); //返回当月「节」为几日开始
    const secondNode = CalendarUtils.getTerm(y, m * 2); //返回当月「节」为几日开始

    //依据12节气修正干支月
    let gzM = CalendarUtils.toGanZhi((y - 1900) * 12 + m + 11);
    if (d >= firstNode) {
      gzM = CalendarUtils.toGanZhi((y - 1900) * 12 + m + 12);
    }

    //传入的日期的节气与否
    let isTerm = false;
    let term = "";
    if (firstNode === d) {
      isTerm = true;
      term = CalendarUtils.lunarTerm[m * 2 - 2];
    }
    if (secondNode === d) {
      isTerm = true;
      term = CalendarUtils.lunarTerm[m * 2 - 1];
    }
    //日柱 当月一日与 1900/1/1 相差天数
    const dayCyclical = Date.UTC(y, sm, 1, 0, 0, 0, 0) / 86400000 + 25567 + 10;
    const gzD = CalendarUtils.toGanZhi(dayCyclical + d - 1);
    return {
      lYear: year,
      lMonth: month,
      lDay: day,
      animal: CalendarUtils.getAnimal(year),
      yearCn: CalendarUtils.toChinaYear(year),
      monthCn:
        (isLeap && leap === month ? "\u95f0" : "") +
        CalendarUtils.toChinaMonth(month),
      dayCn: CalendarUtils.toChinaDay(day),
      cYear: y,
      cMonth: m,
      cDay: d,
      gzYear: gzY,
      gzMonth: gzM,
      gzDay: gzD,
      isToday: isToday,
      isLeap: isLeap,
      nWeek: nWeek,
      ncWeek: "\u661f\u671f" + cWeek,
      isTerm: isTerm,
      term: term,
      constellation: CalendarUtils.constellations[m - 1],
      holiday: CalendarUtils.getHoliday(m, d)
    };
  }

  /**
   * 传入公历年月日以及传入的月份是否闰月获得详细的公历、农历object信息 <=>JSON
   * @param y  lunar year
   * @param m lunar month
   * @param d  lunar day
   * @param isLeapMonth  lunar month is leap or not.
   * @return JSON object
   * @eg:console.log(solarLunar.lunar2solar(1987,9,10));
   */
  static lunar2solar(y: any, m: any, d: any, isLeapMonth: boolean) {
    let i;
    //参数区间1900.1.31~2100.12.1
    //     const leapOffset = 0;
    const leapMonth = CalendarUtils.leapMonth(y);
    // const leapDay = CalendarUtils.leapDays(y);
    if (isLeapMonth && leapMonth !== m) {
      return -1;
    } //传参要求计算该闰月公历 但该年得出的闰月与传参的月份并不同
    if (
      (y === 2100 && m === 12 && d > 1) ||
      (y === 1900 && m === 1 && d < 31)
    ) {
      return -1;
    } //超出了最大极限值
    const day = CalendarUtils.monthDays(y, m);
    if (y < 1900 || y > 2100 || d > day) {
      return -1;
    } //参数合法性效验

    //计算农历的时间差
    let offset = 0;
    for (i = 1900; i < y; i++) {
      offset += CalendarUtils.lYearDays(i);
    }
    let leap = 0,
      isAdd = false;
    for (i = 1; i < m; i++) {
      leap = CalendarUtils.leapMonth(y);
      if (!isAdd) {
        //处理闰月
        if (leap <= i && leap > 0) {
          offset += CalendarUtils.leapDays(y);
          isAdd = true;
        }
      }
      offset += CalendarUtils.monthDays(y, i);
    }
    //转换闰月农历 需补充该年闰月的前一个月的时差
    if (isLeapMonth) {
      offset += day;
    }
    //1900年农历正月一日的公历时间为1900年1月30日0时0分0秒(该时间也是本农历的最开始起始点)
    const stmap = Date.UTC(1900, 1, 30, 0, 0, 0);
    const calObj = new Date((offset + d - 31) * 86400000 + stmap);
    const cY = calObj.getUTCFullYear();
    const cM = calObj.getUTCMonth() + 1;
    const cD = calObj.getUTCDate();

    return CalendarUtils.solar2lunar(cY, cM, cD);
  }

  // 获取指定年份是否是闰年
  static isLeapYear(year: any) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  // 获取指定年份的天数
  static getDaysInYear(year: any) {
    return CalendarUtils.isLeapYear(year) ? 366 : 365;
  }

  // 获取指定月份的天数
  static getDaysInMonth(year: any, month: any) {
    const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (month === 1 && CalendarUtils.isLeapYear(year)) {
      return 29;
    }
    return daysInMonth[month];
  }

  // 获取指定日期的星期（0-6，0 表示周日）
  static getDayOfWeek(year: any, month: any, day: any) {
    const date = new Date(year, month, day);
    return date.getDay();
  }

  // 获取指定日期的假期
  static getHoliday(m: any, d: any) {
    const holidayKey: any = `${m}.${d}`;
    return CalendarUtils.holidays[holidayKey] || "";
  }

  static getTimestamp(date: any) {
    const nowDate = new Date(date);
    return nowDate.getTime();
  }

  public getRangeDates(options: any) {
    const { date, dayNumber, formatTime, fmt = "yyyy-MM-dd" } = options;
    const startTimestamp = CalendarUtils.getTimestamp(date);
    const dates: any[] = [];
    for (let i = 0; i < dayNumber; i++) {
      const date: any = formatTime(startTimestamp + i * 24 * 3600 * 1000, fmt);
      const calendar: any = this.getDayCalendarByDate(date);
      dates.push({
        date: date,
        ...calendar
      });
    }
    return dates;
  }

  public getDayCalendarByDate(date: string) {
    const dates: any[] = date.split("-");
    return this.getDayCalendar(dates[0], dates[1], dates[2]);
  }

  public getDayCalendar(year: any, month: any, day: any) {
    return CalendarUtils.solar2lunar(year, month, day);
  }

  // 获取指定月份的日历
  public getMonthCalendar(year: any, month: any) {
    const totalDays = CalendarUtils.getDaysInMonth(year, month);
    const firstDayOfWeek = CalendarUtils.getDayOfWeek(year, month, 1);
    const calendar: any = [];

    // 补充上个月的日期
    const prevMonth = month === 0 ? 11 : month - 1;
    const prevYear = month === 0 ? year - 1 : year;
    const prevDays = CalendarUtils.getDaysInMonth(prevYear, prevMonth);
    const startDay = prevDays - firstDayOfWeek + 1;
    for (let i = startDay; i <= prevDays; i++) {
      const pDetail: any = CalendarUtils.solar2lunar(
        prevYear,
        prevMonth + 1,
        i
      );
      calendar.push({
        year: prevYear,
        month: prevMonth + 1,
        day: i,
        isCurrentMonth: false,
        ...pDetail
      });
    }

    // 当前月份的日期
    for (let i = 1; i <= totalDays; i++) {
      const detail: any = CalendarUtils.solar2lunar(year, month + 1, i);
      calendar.push({
        year,
        month: month + 1,
        day: i,
        isCurrentMonth: true,
        ...detail
      });
    }

    // 补充下个月的日期
    const nextMonth = month === 11 ? 0 : month + 1;
    const nextYear = month === 11 ? year + 1 : year;
    const nextDays = 42 - calendar.length;
    for (let i = 1; i <= nextDays; i++) {
      const nDetail: any = CalendarUtils.solar2lunar(
        nextYear,
        nextMonth + 1,
        i
      );
      calendar.push({
        year: nextYear,
        month: nextMonth + 1,
        day: i,
        isCurrentMonth: false,
        ...nDetail
      });
    }

    return calendar;
  }

  // 获取本周
  public getThisWeek(options: any) {
    const { dayNumber = 7, formatTime, fmt = "yyyy-MM-dd" } = options;
    const nowDate = new Date(Date.now());
    const timestamp: any = nowDate.getTime();
    const weekDay: any = nowDate.getDay() === 0 ? 7 : nowDate.getDay();
    const startTime: any = timestamp - (weekDay - 1) * 24 * 3600 * 1000;
    const endTime: any = timestamp + (dayNumber - weekDay) * 24 * 3600 * 1000;
    console.log(nowDate, weekDay);
    return {
      startDate: formatTime(startTime, fmt),
      endDate: formatTime(endTime, fmt),
      dates: this.getRangeDates({
        date: formatTime(startTime, fmt),
        formatTime,
        fmt,
        dayNumber
      })
    };
  }

  // 获取新的日期范围
  public getNewWeek(options: any) {
    const {
      startDate,
      endDate,
      formatTime,
      fmt = "yyyy-MM-dd",
      type,
      dayNumber = 7
    } = options;
    const startTimestamp = CalendarUtils.getTimestamp(startDate);
    const endTimestamp: any = CalendarUtils.getTimestamp(endDate);
    const realStartDate =
      type === "prev"
        ? formatTime(startTimestamp - dayNumber * 24 * 3600 * 1000, fmt)
        : formatTime(startTimestamp + dayNumber * 24 * 3600 * 1000, fmt);
    const realEndDate =
      type === "prev"
        ? formatTime(endTimestamp - dayNumber * 24 * 3600 * 1000, fmt)
        : formatTime(endTimestamp + dayNumber * 24 * 3600 * 1000, fmt);
    return {
      startDate: realStartDate,
      endDate: realEndDate,
      dates: this.getRangeDates({
        date: formatTime(realStartDate, fmt),
        formatTime,
        fmt,
        dayNumber
      })
    };
  }
}

const calendarUtils = new CalendarUtils();
export default calendarUtils;
