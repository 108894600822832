import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import {
  filterEmptyField,
  publicDealTableData,
  publicDelete,
  publicDownFile
} from "@/utils";
import { Message, Tree, Input, Switch, Upload, Checkbox } from "element-ui";
import { getRoleDetail } from "@/api/system/role";
import {
  getUserListApi,
  changeUserStatusApi,
  insertUserListApi,
  updateUserListApi,
  deleteUserListApi,
  downloadUserModuleApi,
  importUserApi,
  resetUserPswApi,
  userExportApi
} from "@/api/system/user";
import { getDeptTreeApi } from "@/api/system/dept";
import { useComProps } from "@/utils/useComProps";
import { useDesign } from "@/hooks/web/useDesign";
import tools from "@/utils/tools";
import "./index.scss";
import { getToken } from "@/utils/cookies";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("system-user");
@Component({
  name: "SystemUserList"
})
export default class SystemUserList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  private comRoleProps: any = useComProps.call(this, { prefixCls });
  private dataStore: any = {
    searchTree: "",
    isLoading: false,
    treeIsLoading: false,
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNum: 1
    },
    total: 0,
    tableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: [],
    deptData: [],
    deptIsLoading: false,
    selectNodeId: null,
    formData: null,
    updateSupport: false
  };

  private handleNodeClick(node: any) {
    this.dataStore.selectNodeId = node.id;
    this.getTableData();
  }

  // 操作用户信息
  private handleUser(options: any) {
    const { callback, row, title } = options;
    const elRef: any = this.comRoleProps.getFormElRef(this, "dialogRef");
    this.comRoleProps.checkFormRequire(elRef, () => {
      const params: any = this.comRoleProps.getFormValue(this, "dialogRef");
      const api: any = row ? updateUserListApi : insertUserListApi;
      if (row && row.userId) {
        params["userId"] = row.userId;
        delete params["password"];
      } else {
        params["password"] = tools.Md5(params.password);
      }
      api(title && row ? row : params).then((res: any) => {
        Message.success(
          `${row ? "编辑" : "添加"}用户${title ? title : ""}成功`
        );
        this.getTableData();
        callback && callback();
      });
    });
  }

  // 遍历结构树
  private deepDealTreeData(data: any[], parentId: any) {
    return data.map((ele: any) => {
      ele.label = ele.title;
      if (!ele.parentId && ele.parentId !== 0) {
        ele.parentId = parentId;
      }
      if (ele.children) {
        this.deepDealTreeData(ele.children, ele.id);
      }
      return ele;
    });
  }

  private deepScreenAllParent(data: any[], id: any, result: any[]) {
    if (!id) return;
    data.forEach((item, index) => {
      if (item.id === id) {
        if (!result.includes(item.id)) {
          result.unshift(item.id);
        }
        this.deepScreenAllParent(
          this.dataStore.treeData,
          item.parentId,
          result
        );
      } else {
        if (item.children) {
          this.deepScreenAllParent(item.children, id, result);
        }
      }
    });
  }

  // 处理岗位和角色信息
  private dealOptions(data: any[]) {
    const schema = JSON.parse(JSON.stringify(config.user.dialogSchema));
    data.forEach((ele) => {
      const index = config.user.dialogSchema.findIndex(
        (zle: any) => zle.field === ele.field
      );
      if (ele.field === "deptId") {
        schema[index].componentProps.treeProps.data = ele.data;
      } else {
        schema[index].componentProps.options = ele.data.map((zle: any) => {
          return {
            label: zle[ele.label],
            value: zle[ele.value]
          };
        });
      }
    });
    return schema;
  }

  //
  private async handleDialog(type: any, row?: any) {
    const result: any = await getRoleDetail(row ? row.userId : null);
    const schema = this.dealOptions([
      { field: "deptId", data: this.dataStore.deptData },
      {
        field: "postIds",
        data: result.posts,
        label: "postName",
        value: "postId"
      },
      {
        field: "roleIds",
        data: result.roles,
        label: "roleName",
        value: "roleId"
      }
    ]);
    const formList = row ? { ...row, status: row.status ? "0" : "1" } : {};
    if (this.dataStore.selectNodeId || (row && row.deptId)) {
      formList["deptId"] =
        row && row.deptId ? row.deptId : this.dataStore.selectNodeId;
    }
    if (type === "insert") {
      formList["userType"] = "1";
    } else {
      formList["postIds"] = result.postIds;
      formList["roleIds"] = result.roleIds;
    }
    this.comRoleProps.openDialogPage(this, {
      width: "600px",
      title: `${type === "insert" ? "新增" : "编辑"}用户`,
      schema: schema,
      formList: { ...formList },
      onConfirm: (callback: any) => this.handleUser({ callback, row })
    });
  }

  private uploadUsers(file: any) {
    this.dataStore.formData = new FormData();
    this.dataStore.formData.append("file", file.file);
  }

  private handleSuccess() {}

  private handleDownLoad() {
    downloadUserModuleApi().then((res) => {
      if (res) {
        publicDownFile(res);
      }
    });
  }

  private handleImportUser(cb: Function) {
    importUserApi(this.dataStore.formData, this.dataStore.updateSupport).then(
      (res) => {
        Message.success("导入用户成功");
        this.getTableData();
        cb && cb();
      }
    );
  }

  private importUserHandle() {
    this.comRoleProps.openDialogPage(this, {
      width: "400px",
      title: "用户导入",
      contentSlots: () => {
        return (
          <Upload
            class="upload-demo"
            drag
            action=""
            headers={{
              Authorization: getToken()
            }}
            http-request={this.uploadUsers}
            props={{
              "on-success": this.handleSuccess
            }}
            multiple={false}
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div
              class="el-upload__tip"
              slot="tip"
              style={{ textAlign: "center" }}
            >
              <Checkbox v-model={this.dataStore.updateSupport}>
                是否更新已经存在的用户数据
              </Checkbox>
              <div>
                仅允许导入xls、xlsx格式文件。
                <span
                  onClick={this.handleDownLoad}
                  style="color:#1890ff;cursor:pointer"
                >
                  点击下载模板
                </span>
              </div>
            </div>
          </Upload>
        );
      },
      onConfirm: (callback: Function) => {
        this.handleImportUser(callback);
        callback();
      }
    });
  }

  //
  handleResetPsw(options: any) {
    const { item, row, callback } = options;
    const ElRef = this.comRoleProps.getFormElRef(this, "dialogRef");
    this.comRoleProps.checkFormRequire(ElRef, () => {
      const formList = this.comRoleProps.getFormValue(this, "dialogRef");
      if (formList.password.length < 5) {
        Message.warning("密码需不少于5位");
        return;
      }
      const params = {
        password: tools.Md5(formList.password),
        userId: row.userId
      };
      resetUserPswApi(params)
        .then((res: any) => {
          Message.success("重置密码成功");
          this.getTableData();
          callback && callback();
        })
        .catch((error) => {});
    });
  }

  private handleExport() {
    const filterList: any = this.comRoleProps.getFormValue(this, "filterRef");
    const params: any = {
      ...filterList,
      ...this.dataStore.queryList,
      deptId: this.dataStore.selectNodeId
    };
    userExportApi(filterEmptyField(params)).then((res: any) => {
      publicDownFile(res, "用户列表");
      Message.success("导出数据成功");
    });
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      refresh: () => {
        this.comRoleProps.clearFormList(this, "filterRef");
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      createRow: () => this.handleDialog("insert"),
      deleteBatch: () => {
        if (this.dataStore.selectData.length) {
          const names: any[] = this.dataStore.selectData.map(
            (ele: any) => ele.userName
          );
          publicDelete({
            message: `是否删除用户【${names.join(",")}】？`,
            title: "确认信息",
            callback: () => {
              const ids: any = [];
              const names: any[] = [];
              this.dataStore.selectData.forEach((ele: any) => {
                ids.push(ele.userId);
                names.push(ele.userName);
              });
              this.deleteDataList(ids, names);
            }
          });
        } else {
          Message.warning("请先选择要删除的数据");
        }
      },
      editRow: () => this.handleDialog("update", row),
      deleteRow: () => {
        publicDelete({
          message: `是否删除用户【${row.userName}】？`,
          title: "确认信息",
          callback: () => {
            this.deleteDataList([row.userId], [row.userName]);
          }
        });
      },
      resetPassword: () => {
        config.user.passwordSchema[0].label = `请输入用户名为"${row.userName}"的新密码`;
        this.comRoleProps.openDialogPage(this, {
          width: "500px",
          title: "重置密码",
          confirmButtonText: "确定",
          schema: config.user.passwordSchema,
          onConfirm: (callback: any) => this.handleResetPsw({ callback, row })
        });
      },
      importUser: () => this.importUserHandle(),
      exportUser: () => this.handleExport()
    };
    obj[item.prop] && obj[item.prop]();
  }

  // 删除数据
  private deleteDataList(ids: any[], names: any[]) {
    const api: any = deleteUserListApi;
    const params: any = ids.join(",");
    api(params).then(() => {
      Message.success(`删除用户【${names.join(",")}】成功`);
      this.getTableData();
    });
  }

  private onChangeStatus(value: any, item: any) {
    this.comRoleProps.openDialogPage(this, {
      width: "586px",
      title: `用户${value ? "启用" : "停用"}`,
      titleSlots: {
        prefix: () => {
          return (
            <i
              class={"el-icon-warning"}
              style={{
                marginRight: "18px",
                color: "#F88B3D",
                fontSize: "24px"
              }}
            ></i>
          );
        }
      },
      contentSlots: () => {
        return (
          <div style={{ marginTop: "40px", marginBottom: "40px" }}>
            您确定{value ? "启用" : "停用"}【{item.nickName}
            】该用户吗？
          </div>
        );
      },
      onConfirm: (callback: Function) => {
        changeUserStatusApi({
          status: value ? "0" : "1",
          userId: item.userId
        }).then((res) => {
          Message.success(
            `${value ? "启用" : "停用"}用户【${item.nickName}】成功`
          );
          this.getTableData();
          callback && callback();
        });
      },
      onCloseDialog: (callback: Function) => {
        this.getTableData();
        callback && callback();
      }
    });
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNum = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }

  private dealTableData(data: any[]) {
    return data.map((ele: any) => {
      ele["deptName"] = ele.dept.deptName;
      ele["status"] = ele.status === "0";
      return ele;
    });
  }

  private getTableData() {
    this.dataStore.isLoading = true;
    const filterList: any = this.comRoleProps.getFormValue(this, "filterRef");
    const params: any = { ...filterList, ...this.dataStore.queryList };
    if (this.dataStore.selectNodeId) {
      params["deptId"] = this.dataStore.selectNodeId;
    }
    if (filterList.createTimes) {
      params["params[beginTime]"] = filterList.createTimes[0];
      params["params[endTime]"] = filterList.createTimes[1];
      delete params.createTimes;
    }
    getUserListApi(filterEmptyField(params))
      .then((res: any) => {
        if (res) {
          this.dataStore.tableData = this.dealTableData(res.rows);
          this.dataStore.total = res.total;
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }

  // 获取部门信息
  private getDeptTree() {
    this.dataStore.deptIsLoading = true;
    getDeptTreeApi()
      .then((res: any) => {
        if (res) {
          this.dataStore.deptData = res;
          if (!this.dataStore.selectNodeId && res.length) {
            this.dataStore.selectNodeId = res[0].id;
            this.$nextTick(() => {
              this.$refs.treeRef?.setCurrentKey(this.dataStore.selectNodeId);
            });
            this.getTableData();
            console.log(this.dataStore.selectNodeId);
          }
        }
      })
      .finally(() => {
        this.dataStore.deptIsLoading = false;
      });
  }

  private filterNode(value: any, data: any) {
    if (!value) return true;
    return data.label.includes(value);
  }

  private onChangeTree(value: string) {
    this.$refs.treeRef?.filter(value);
  }

  mounted() {
    this.getDeptTree();
    this.comRoleProps.dealContentTableHeight(this, ["header", "filter"]);
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        <div class={`${prefixCls}-left`}>
          <Input
            v-model={this.dataStore.searchTree}
            prefix-icon="el-icon-search"
            placeholder="请输入内容"
            on-change={this.onChangeTree}
          ></Input>
          <div class={`${prefixCls}-left-tree`}>
            <Tree
              v-loading={this.dataStore.deptIsLoading}
              class={`${prefixCls}-dialog-tree`}
              data={this.dataStore.deptData}
              node-key={"id"}
              default-expand-all={true}
              expand-on-click-node={false}
              on-node-click={this.handleNodeClick}
              filter-node-method={this.filterNode}
              ref={"treeRef"}
            ></Tree>
          </div>
        </div>
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.comRoleProps.renderForm({
              ref: "filterRef",
              schema: config.user.filterSchema,
              slots: {
                handle: (data: any) => {
                  return this.comRoleProps.renderButtons({
                    buttons: data.componentProps.buttons,
                    style: {
                      display: "flex"
                    }
                  });
                }
              }
            })}
          </div>
          {this.comRoleProps.renderTable({
            tableData: this.dataStore.tableData,
            tableHeader: config.user.tableHeader,
            isShowCheck: true,
            queryList: this.dataStore.queryList,
            total: this.dataStore.total,
            isLoading: this.dataStore.isLoading,
            paginationLayout: "sizes, prev, pager, next, slot, jumper",
            style: {
              height: this.dataStore.tableHeight
            },
            ref: "tableRef",
            slots: {
              status: ({ row, header }) => {
                const disabled = row.status === 4;
                return (
                  <Switch
                    v-model={row[header.prop]}
                    disabled={disabled}
                    width={50}
                    active-text={"停用"}
                    inactive-text={"正常"}
                    active-color={"#1677FF"}
                    inactive-color={"#F56C6C"}
                    onChange={(event: any) => this.onChangeStatus(event, row)}
                  ></Switch>
                );
              }
            }
          })}
        </div>
      </div>
    );
  }
}
