import request from "@/utils/request";
import ipList from "@/utils/ipConfig";

export function getOnlineListApi(params?: any) {
  return request({
    url: ipList.monitor.online.list,
    method: "get",
    params
  });
}

export function deleteOnlineApi(id: any) {
  return request({
    url: `${ipList.monitor.online.stopRun}/${id}`,
    method: "DELETE"
  });
}
