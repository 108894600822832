import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import {
  filterEmptyField,
  formatTime,
  publicDealFileUrl,
  publicDelete,
  publicDownFile
} from "@/utils";
import { Image, Message } from "element-ui";
import {
  getMemberListApi,
  insertMemberApi,
  updateMemberApi,
  deleteMemberListApi,
  memberExportApi,
  changeUseNumApi,
  changeStatusApi
} from "@/api/member";
import { getAllCustomListApi, getListByMobileApi } from "@/api/custom";
import { getAllMemberCardListApi } from "@/api/memberCard";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";
import UseRenderCom from "@/hooks/useRenderCom";
import { getDictAllDataApi } from "@/api/system/dict";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("member-list");
@Component({
  name: "MemberList"
})
export default class MemberList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  get useRenderCom() {
    return UseRenderCom.call(this);
  }
  private dataStore: any = {
    isLoading: false,
    treeIsLoading: false,
    title: "卡券列表",
    tableHeight: 0,
    queryList: {
      pageSize: 30,
      pageNum: 1
    },
    total: 0,
    tableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: [],
    dictData: [],
    optionsList: {},
    colorData: [
      {
        background: "#a5dee5",
        color: "#080000"
      },
      {
        background: "#e0f9b5",
        color: "#080000"
      },
      {
        background: "#fefdca",
        color: "#080000"
      }
    ]
  };

  // 操作卡券信息
  private handleRole(options: any) {
    const { callback, row, title } = options;
    this.useRenderCom.checkFormRequire("dialogRef", (formList: any) => {
      const params: any = { ...formList };
      const api: any = row ? updateMemberApi : insertMemberApi;
      const userList: any = this.dataStore.optionsList["userData"].find(
        (zle: any) => zle.value === formList.userId
      );
      if (userList) {
        params["userName"] = userList.nickName;
        params["status"] =
          params.type === "0" ? (params.useStartTime ? "1" : "0") : "1";
      }
      if (params.useStartTime && !params.useStartTime?.includes("T")) {
        params.useStartTime =
          params.type === "0" ? new Date(params.useStartTime) : null;
      }
      if (params.type === "0") {
        const memberCard = this.dataStore.optionsList["memberType"].find(
          (zle: any) => zle.id === Number(params.memberType)
        );
        if (memberCard) {
          params["memberDuration"] = memberCard.duration;
        }
      }
      if (row && row.id) {
        params["id"] = row.id;
      }
      api(title && row ? row : params).then((res: any) => {
        Message.success(
          `${row ? "编辑" : "添加"}卡券${title ? title : ""}成功`
        );
        this.getTableData();
        callback && callback();
      });
    });
  }

  private onRemoteMethod(query: string) {
    if (query !== "") {
      getListByMobileApi({ mobile: query }).then((res: any) => {
        console.log(res, "ppppppp");
      });
      // this.loading = true;
      // setTimeout(() => {
      //   this.loading = false;
      //   this.options = this.list.filter(item => {
      //     return item.label.toLowerCase()
      //         .indexOf(query.toLowerCase()) > -1;
      //   });
      // }, 200);
    } else {
      // this.options = [];
    }
  }

  private async getCustomByMobile() {}

  private async handleDialog(type: any, row?: any) {
    this.useRenderCom.renderDialog({
      width: "700px",
      title: `${type === "insert" ? "新增" : "编辑"}卡券`,
      schema: config.member.dialogSchema,
      formList: row
        ? {
            ...row
          }
        : {
            courseType: "0"
          },
      onConfirm: (callback: any) => this.handleRole({ callback, row })
    });
    const value = row && row.id ? row.type : "0";
    setTimeout(() => {
      this.useRenderCom.setFormSchema("dialogRef", [
        {
          field: "courseType",
          path: "componentProps.options",
          value: this.dataStore.dictData.filter(
            (ele: any) => (value === "0" && ele.value === "0") || value === "1"
          )
        },
        {
          field: "memberType",
          path: "componentProps.options",
          value: this.dataStore.optionsList["memberType"]
        },
        {
          field: "userId",
          path: "componentProps.options",
          value: this.dataStore.optionsList["userData"]
        }
      ]);
    });
  }

  private handleExport() {
    const filterList: any = this.useRenderCom.getFormValue("filterRef");
    const params: any = {
      ...filterList,
      ...this.dataStore.queryList
    };
    memberExportApi(filterEmptyField(params)).then((res: any) => {
      publicDownFile(res, "卡券列表");
      Message.success("导出数据成功");
    });
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      refresh: () => {
        this.useRenderCom.clearFormList("filterRef");
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      createRow: () => this.handleDialog("insert"),
      deleteBatch: () => {
        if (this.dataStore.selectData.length) {
          const names: any[] = this.dataStore.selectData.map(
            (ele: any) => ele.name
          );
          publicDelete({
            message: `是否删除卡券【${names.join(",")}】？`,
            title: "确认信息",
            callback: () => {
              const ids: any = [];
              const names: any[] = [];
              this.dataStore.selectData.forEach((ele: any) => {
                ids.push(ele.id);
                names.push(ele.name);
              });
              this.deleteDataList(ids, names);
            }
          });
        } else {
          Message.warning("请先选择要删除的数据");
        }
      },
      editRow: () => this.handleDialog("update", row),
      deleteRow: () => {
        publicDelete({
          message: `是否删除卡券【${row.name}】？`,
          title: "确认信息",
          callback: () => {
            this.deleteDataList([row.id], [row.name]);
          }
        });
      },
      exportRole: () => this.handleExport(),
      changeUseNum: () => {
        this.useRenderCom.renderDialog({
          title: "修改使用次数",
          width: "400px",
          schema: config.member.useNumSchema,
          formList: {
            useNumber: row.useNumber
          },
          onConfirm: (cb: Function) => {
            this.useRenderCom.checkFormRequire("dialogRef", (formList: any) => {
              const params: any = {
                id: row.id,
                useNumber: formList.useNumber
              };
              changeUseNumApi(params).then((res: any) => {
                this.$message.success("修改使用次数成功");
                this.getTableData();
                cb && cb();
              });
            });
          }
        });
        this.$nextTick(() => {
          this.useRenderCom.setFormSchema("dialogRef", [
            {
              field: "useNumber",
              path: "componentProps.max",
              value: row.orderNumber
            }
          ]);
        });
      }
    };
    obj[item.prop] && obj[item.prop]();
  }

  // 删除数据
  private deleteDataList(ids: any[], names: any[]) {
    const api: any = deleteMemberListApi;
    const params: any = ids.join(",");
    api(params).then(() => {
      Message.success(`删除卡券【${names.join(",")}】成功`);
      this.getTableData();
    });
  }

  private onChange(options: any) {
    const { item, value } = options;
    if (item.field === "type") {
      this.useRenderCom.setFormValues("dialogRef", {
        courseType: "",
        memberType: "",
        memberDuration: "",
        orderNumber: ""
      });
      this.useRenderCom.setFormSchema("dialogRef", [
        {
          field: "courseType",
          path: "componentProps.options",
          value: this.dataStore.dictData.filter(
            (ele: any) => (value === "0" && ele.value === "0") || value === "1"
          )
        }
      ]);
    }
    if (item.field === "memberType") {
      const nItem: any = this.dataStore.optionsList["memberType"].find(
        (ele: any) => ele.value === value
      );
      this.useRenderCom.setFormValues("dialogRef", {
        memberDuration: nItem.duration
      });
    }
    if (item.field === "courseType") {
      console.log(value, "sss");
      const colorList: any = this.dataStore.colorData[Number(value)]
        ? this.dataStore.colorData[Number(value)]
        : this.dataStore.colorData[0];
      this.useRenderCom.setFormValues("dialogRef", {
        background: colorList.background,
        color: colorList.color
      });
    }
    // if (["color", "background"].includes(item.field)) {
    //   console.log(this.useRenderCom.getFormValue("dialogRef"), value);
    // }
  }

  private onChangeStatus(value: any, item: any) {
    if (item.status !== "1") {
      Message.warning(
        item.status === "0"
          ? `当前卡卷未使用无需${value ? "启用" : "停用"}`
          : `卡券已用完，无需${value ? "启用" : "停用"}`
      );
      this.getTableData();
      return;
    }
    this.useRenderCom.renderDialog({
      width: "586px",
      title: `卡卷${value ? "启用" : "停用"}`,
      titleSlots: {
        prefix: () => {
          return (
            <i
              class={"el-icon-warning"}
              style={{
                marginRight: "18px",
                color: "#F88B3D",
                fontSize: "24px"
              }}
            ></i>
          );
        }
      },
      contentSlots: () => {
        return (
          <div style={{ marginTop: "40px", marginBottom: "40px" }}>
            您确定{value ? "启用" : "停用"}【{item.name}
            】该卡券吗？
          </div>
        );
      },
      onConfirm: (callback: Function) => {
        changeStatusApi({
          enable: value ? "0" : "1",
          id: item.id
        }).then((res) => {
          Message.success(`${value ? "启用" : "停用"}卡券【${item.name}】成功`);
          this.getTableData();
          callback && callback();
        });
      },
      onCloseDialog: (callback: Function) => {
        this.getTableData();
        callback && callback();
      }
    });
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNum = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      checkbox: () => {
        this.dataStore.selectData = JSON.parse(JSON.stringify(item.data));
      },
      enable: () => {
        this.onChangeStatus(item.data.value, item.data.row);
      },
      handle: () => {
        this.onClickMethod({ item: item.data.item, row: item.data.row });
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }

  // 处理表格数据
  private dealTableData(data: any[]) {
    return data.map((ele) => {
      if (ele.memberType) {
        ele.useStartTime = formatTime(ele.useStartTime, "yyyy-MM-dd HH:mm:ss");
        ele.useEndTime = formatTime(ele.useEndTime, "yyyy-MM-dd HH:mm:ss");
        ele.useNumber = "";
      }
      ele["enable"] = ele.enable === "0";
      return ele;
    });
  }

  private getTableData() {
    this.dataStore.isLoading = true;
    const filterList: any = this.useRenderCom.getFormValue("filterRef");
    const params: any = { ...filterList, ...this.dataStore.queryList };
    getMemberListApi(filterEmptyField(params))
      .then((res: any) => {
        if (res) {
          this.dataStore.tableData = this.dealTableData(res.rows);
          this.dataStore.total = res.total;
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }

  private async getDictAllData() {
    const res: any = await getDictAllDataApi("course_type");
    this.dataStore.dictData = res.map((ele: any) => {
      return {
        ...ele,
        label: ele.dictLabel,
        value: ele.dictValue
      };
    });
    config.member.filterSchema[2].componentProps.options =
      this.dataStore.dictData;
  }

  private getAllMemberCardList() {
    Promise.all([getAllCustomListApi(), getAllMemberCardListApi()]).then(
      (res: any) => {
        if (res) {
          this.dataStore.optionsList["userData"] = res[0].map((ele: any) => {
            return {
              ...ele,
              label: `${ele.nickName}（${ele.mobile}）`,
              value: ele.userId
            };
          });
          this.dataStore.optionsList["memberType"] = res[1].map((ele: any) => {
            return {
              ...ele,
              label: ele.name,
              value: String(ele.id)
            };
          });
        }
      }
    );
  }

  async mounted() {
    await this.getDictAllData();
    this.getAllMemberCardList();
    this.$nextTick(() => {
      this.useRenderCom.dealContentTableHeight();
    });
    this.getTableData();
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.useRenderCom.renderForm({
              ref: "filterRef",
              schema: config.member.filterSchema,
              slots: {
                handle: (data: any) => {
                  return this.useRenderCom.renderButtons({
                    buttons: data.componentProps.buttons
                  });
                },
                tableHandle: (data: any) => {
                  return this.useRenderCom.renderButtons({
                    buttons: data.componentProps.buttons
                  });
                }
              }
            })}
          </div>
          {this.useRenderCom.renderTable({
            prefixCls: prefixCls,
            tableData: this.dataStore.tableData,
            tableHeader: config.member.tableHeader,
            isShowCheck: true,
            queryList: this.dataStore.queryList,
            total: this.dataStore.total,
            isLoading: this.dataStore.isLoading,
            paginationLayout: "sizes, prev, pager, next, slot, jumper",
            style: {
              height: this.dataStore.tableHeight
            },
            status: "type",
            ref: "tableRef",
            slots: {
              type: ({ row, header }) => {
                return row.type === "0" ? "会员" : "单课";
              },
              courseType: ({ row, header }) => {
                if (!this.dataStore.dictData.length) return "";
                const item: any = this.dataStore.dictData.find(
                  (zle: any) => zle.value === row.courseType
                );
                return item ? item.label : "";
              },
              status: ({ row, header }) => {
                const options: any =
                  config.member.filterSchema[3].componentProps.options;
                const item: any = options.find(
                  (ele: any) => ele.value === row.status
                );
                return item ? item.label : "";
              },
              memberType: ({ row }) => {
                if (
                  !row.memberType ||
                  !this.dataStore.optionsList["memberType"]
                )
                  return "";
                const item: any = this.dataStore.optionsList["memberType"].find(
                  (zle: any) => String(zle.id) === row.memberType
                );
                return item ? item.name : "";
              }
            }
          })}
        </div>
      </div>
    );
  }
}
