import { Component, Prop, Vue } from "vue-property-decorator";
import settings from "@/settings";
import "./SidebarLogo.scss";

@Component({
  name: "SidebarLogo"
})
export default class SidebarLogo extends Vue {
  @Prop({ required: true }) private collapse!: boolean;

  private title = settings.title;
  render() {
    const renderContent = () => {
      return this.collapse ? (
        <router-link key="collapse" class="sidebar-logo-link" to="/">
          <img src="favicon.ico" class="sidebar-logo" />
        </router-link>
      ) : (
        <router-link key="expand" class="sidebar-logo-link" to="/">
          <img src="favicon.ico" class="sidebar-logo" />
          <h1 class="sidebar-title">{this.title}</h1>
        </router-link>
      );
    };
    return (
      <div class={[`sidebar-logo-container`, { collapse: this.collapse }]}>
        <transition name="sidebarLogoFade">{renderContent()}</transition>
      </div>
    );
  }
}
