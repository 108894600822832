import request from "@/utils/request";

export function getAllMemberCardListApi() {
  return request({
    url: "/business/memberCard/allList",
    method: "get"
  });
}
export function getMemberCardListApi(params?: any) {
  return request({
    url: "/business/memberCard/list",
    method: "get",
    params
  });
}

/**
 * 新增场馆信息
 */
export function insertMemberCardApi(data: any) {
  return request({
    url: `/business/memberCard/insert`,
    method: "post",
    data
  });
}

/**
 * 修改场馆信息
 */
export function updateMemberCardApi(data: any) {
  return request({
    url: `/business/memberCard/update`,
    method: "post",
    data
  });
}

/**
 * 修改场馆状态
 */
export function changeMemberCardStatusApi(data: any) {
  return request({
    url: `/business/memberCard/changeStatus`,
    method: "put",
    data
  });
}

export function deleteMemberCardListApi(ids: any) {
  return request({
    url: `/business/memberCard/remove/${ids}`,
    method: "get"
  });
}

export function memberCardExportApi(data: any) {
  return request({
    url: `/business/memberCard/export`,
    method: "post",
    responseType: "blob",
    data
  });
}
