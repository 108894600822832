import { Vue, Component } from "vue-property-decorator";
import { TagsViewModule } from "@/store/modules/tags-view";
@Component({
  name: "App"
})
export default class App extends Vue {
  get cachedViews() {
    return TagsViewModule.cachedViews;
  }
  render() {
    return (
      <div id={"app"}>
        <router-view />
      </div>
    );
  }
}
