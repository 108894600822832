import { getSlot } from "@/utils/tsxHelper";
import { isObject } from "@/utils/is";
import { useForm } from "@/hooks/web/useForm";

export function useDialog(options: DialogProps) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const that: any = this as any;
  const h = that.$createElement;
  const dealLabelSlots = (data: any[], slots?: any) => {
    if (!slots) return data;
    const keys: any[] = Object.keys(slots);
    keys.forEach((item: any) => {
      const index: any = data.findIndex((ele: any) => ele.field === item);
      if (index >= 0) {
        data[index].formItemProps.labelSlots = slots[item];
      }
    });
    return data;
  };
  const schema: any = options.schema
    ? dealLabelSlots(
        JSON.parse(JSON.stringify(options.schema)),
        options.labelSlots
      )
    : [];
  const defaultProps = {
    customClass: options.customClass
      ? options.customClass
      : `${options.prefixCls || "custom"}-dialog`,
    showCancelButton: true,
    closeOnClickModal: false
  };
  that.$myMessageBox({
    ...defaultProps,
    slots: {
      title: () => {
        return options.titleSlots && options.titleSlots.title ? (
          getSlot(options.titleSlots, "title")
        ) : (
          <div
            style={{
              fontSize: "20px",
              fontWeight: 600,
              borderBottom: "1px solid #D8D8D8",
              paddingBottom: "20px",
              display: "flex",
              alignItems: "center"
            }}
          >
            {options.titleSlots && options.titleSlots.prefix ? (
              getSlot(options.titleSlots, "prefix")
            ) : (
              <span
                style={{
                  display: "flex",
                  width: "4px",
                  height: "21px",
                  background: "#268BFC",
                  borderRadius: "3px",
                  marginRight: "16px"
                }}
              ></span>
            )}
            <div>{options.title}</div>
            {options.titleSlots && options.titleSlots.suffix
              ? getSlot(options.titleSlots, "suffix")
              : null}
          </div>
        );
      },
      content: () => {
        return options.contentSlots &&
          !isObject(options.contentSlots) &&
          options.contentSlots
          ? getSlot(options, "contentSlots")
          : useForm.call(that, {
              schema,
              ref: options.ref ? options.ref : "dialogRef",
              formList: options.formList,
              slots: options?.formSlots,
              style: options?.formStyle
            });
      }
    },
    onCloseDialog: (callback: Function) => {
      callback && callback();
    },
    ...options
  });
}
