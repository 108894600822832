import { SlForm } from "@/components";
import { isBoolean } from "@/utils/is";
import { firstToUpper } from "@/utils";

/**
 * 表单hooks渲染方法
 * @param options 渲染时传入的属性，具体属性可查看FormProps
 * @param callback 回调方法，即渲染后表单时触发的方法，非必填
 */
export function useForm(options: FormProps, callback?: Function) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const that: any = this as any;
  const h = that.$createElement;
  const publicBindMethod = (methodOptions: any) => {
    const { schema, list, keyValue, index, scopedSlots, path, type } =
      methodOptions;
    return schema.map((item: any) => {
      if (item.componentProps) {
        if (item.componentProps.on) {
          const keys = Object.keys(item.componentProps.on);
          keys.forEach((ele: any) => {
            if (
              isBoolean(item.componentProps.on[ele]) &&
              that[`on${firstToUpper(ele)}`]
            ) {
              item.componentProps.on[ele] = (value: any) =>
                that[`on${firstToUpper(ele)}`]({
                  value,
                  item,
                  index,
                  list,
                  keyValue,
                  schema,
                  type,
                  path: item.path ? item.path : path
                });
            }
          });
        }
        if (item.componentProps.slots) {
          const sKeys = Object.keys(item.componentProps.slots);
          sKeys.forEach((sKey: any) => {
            if (scopedSlots[`${item.field}-${sKey}`]) {
              item.componentProps.slots[sKey] =
                scopedSlots[`${item.field}-${sKey}`];
            }
          });
        }
      }
      return item;
    });
  };
  const schema = publicBindMethod({
    schema: JSON.parse(JSON.stringify(options.schema)),
    keyValue: options.keyValue
      ? options.keyValue
      : options.formList && options.formList.id
      ? options.formList.id
      : options.ref,
    path: options.path,
    type: options.type,
    scopedSlots: options.slots
  });
  callback && callback();
  return (
    <SlForm
      key={options.ref}
      ref={options.ref}
      isCol={options.isCol}
      disabled={options.disabled}
      rowGutter={options.rowGutter}
      formType={options.formType}
      schema={schema}
      style={options?.style}
      designer={options.designer}
      labelWidth={options?.labelWidth}
      props={{
        model: options.formList
      }}
      hideRequiredAsterisk={options.hideRequiredAsterisk}
      scopedSlots={options.slots}
      onInput={(value: any) =>
        options.onInputChange && options.onInputChange(value)
      }
    ></SlForm>
  );
}
