import { Component, Vue } from "vue-property-decorator";
import { ErrorLogModule } from "@/store/modules/error-log";
import { SvgIcon } from "@/components/SvgIcon";
import { Badge, Button } from "element-ui";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";

const prefixCls = useDesign().getPrefixCls("error-log");
@Component({
  name: "ErrorLog"
})
export default class SlErrorLog extends Vue {
  private dialogTableVisible = false;

  get errorLogs() {
    return ErrorLogModule.logs;
  }

  private clearAll() {
    this.dialogTableVisible = false;
    ErrorLogModule.ClearErrorLog();
  }

  private openDialog() {}

  render() {
    const renderContent = () => {
      return this.errorLogs.length ? (
        <div class={`${prefixCls}`}>
          <Badge
            is-dot={true}
            style="line-height: 25px; margin-top: -5px"
            on-click={this.openDialog}
          >
            <Button style="padding: 8px 10px" size="medium" type="danger">
              <SvgIcon name="bug" />
            </Button>
          </Badge>
        </div>
      ) : null;
    };
    return renderContent();
  }
}
