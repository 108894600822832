import Vue from "vue";
import SlMessageComponent from "@/components/SlMessageBox/src/SlMessageBox";

const MessageboxConstructor = Vue.extend(SlMessageComponent);

let instance: any = null; // 定义组件实例
const titleCase = (str: string) => {
  const newStr = str.split(" ");
  for (let i = 0; i < newStr.length; i++) {
    newStr[i] =
      newStr[i].slice(0, 1).toUpperCase() + newStr[i].slice(1).toLowerCase();
  }
  return newStr.join(" ");
};

const dealButtonData = (list: any) => {
  const arr = [
    {
      label: list.cancelButtonText,
      prop: "cancel",
      style: list.cancelButtonStyle,
      plain: true
    },
    {
      label: list.confirmButtonText,
      prop: "confirm",
      type: "primary",
      style: list.confirmButtonStyle
    }
  ];
  return arr.filter((item: any) => list[`show${titleCase(item.prop)}Button`]);
};
const MyMessageBox = function (options: any, hidden?: boolean) {
  instance = new MessageboxConstructor({
    data: options,
    propsData: {
      visible: !hidden,
      close: () => {
        const modal: any = document.querySelector(".v-modal");
        if (modal && hidden) {
          document.body.removeChild(modal);
        }
        instance.$el && instance.$el.remove();
      }
    }
  });
  if (!hidden) {
    instance.$mount(); // 实例组件挂载
    document.body.appendChild(instance.$el);
    if (options.slots) {
      instance.$scopedSlots = options.slots;
    }
    instance.buttonData = dealButtonData(instance);
  }
  return instance;
};

export default MyMessageBox;
