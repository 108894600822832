import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import {
  Table,
  TableColumn,
  Pagination,
  Radio,
  Checkbox,
  CheckboxButton,
  RadioButton,
  OptionGroup,
  Option
} from "element-ui";
import { componentMap } from "@/components/SlForm/src/componentMap";
import { useDesign } from "@/hooks/web/useDesign";
import { getSlot } from "@/utils/tsxHelper";
import "./index.scss";
import { SvgIcon } from "@/components";
import { isObject } from "@/utils/is";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("table");
@Component({
  name: "SlTable"
})
export default class SlTable extends Vue {
  $refs!: {
    tableRef: any;
    elTableRef: any;
  };

  @Prop({
    type: Array,
    default: () => []
  })
  readonly tableData: any;

  @Prop({
    type: Boolean,
    default: true
  })
  readonly isPagination: any;

  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  readonly tableProps: any;

  @Prop({
    type: Array,
    default: () => []
  })
  readonly tableHeader: any;

  @Prop({
    type: Object,
    default: () => {
      return {
        background: "#EEEFF0",
        borderRight: "none",
        textAlign: "center"
      };
    }
  })
  readonly headerCellStyle: any;

  @Prop({
    type: Object,
    default: () => {
      return {
        borderRight: "none"
      };
    }
  })
  readonly cellStyle: any;

  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  readonly queryList: any;

  @Prop({
    type: Boolean,
    default: true
  })
  readonly multiple: any;

  @Prop({
    type: Number,
    default: 0
  })
  readonly total: any;

  @Prop({
    type: Boolean,
    default: false
  })
  readonly isShowCheck: any;

  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  readonly requestProps: any;

  @Prop({
    type: Function
  })
  readonly spanMethod: any;
  @Prop({
    type: String,
    default: "prev, pager, next, slot"
  })
  readonly paginationLayout: any;
  @Prop({
    type: Object,
    default: () => {
      return {
        align: "center",
        "show-overflow-tooltip": true
      };
    }
  })
  readonly tableColumnProps: any;

  @Prop({
    type: Boolean,
    default: false
  })
  readonly isShowTool: any;

  @Emit("callback")
  onCallback(item: any) {
    return item;
  }

  private dataStore: any = {
    tableHeight: "0",
    radioValue: "",
    radioDefault: "       ",
    defaultProps: {
      border: true,
      stripe: true,
      headerCellStyle: {
        background: "#EEEFF0",
        borderRightColor: "#dfe6ec",
        textAlign: "center"
      },
      cellStyle: {
        borderRight: "none"
      }
    }
  };

  private handleCurrentChange = (value: any) => {
    this.onCallback({
      type: "current",
      value: value
    });
  };

  private handleSizeChange = (value: any) => {
    this.onCallback({
      type: "size",
      value: value
    });
  };

  private selectionChange = (data: any) => {
    this.onCallback({
      type: "checkbox",
      data: data
    });
  };

  // 重新计算高度
  private reloadTableHeader() {
    this.$nextTick(() => {
      const differNumber = this.isPagination ? 67 : 0;
      if (this.$refs.tableRef && this.$refs.tableRef.offsetHeight) {
        this.dataStore.tableHeight = `${
          this.$refs.tableRef?.offsetHeight - differNumber
        }px`;
      }
    });
  }

  private handleSelectionChange(index: any, row: any) {
    this.dataStore.radioValue = row;
    this.onCallback({
      type: "checkRadio",
      value: row
    });
  }

  private onClickIcon(options) {
    this.onCallback({
      type: "icon",
      value: options
    });
  }

  private getElTableRef() {
    return this.$refs.elTableRef;
  }

  mounted() {
    this.reloadTableHeader();
  }

  render() {
    const renderOptions = (item: FormSchema) => {
      switch (item.component) {
        case "Select":
          return renderSelectOptions(item);
        case "Radio":
        case "RadioButton":
          return renderRadioOptions(item);
        case "CheckboxGroup":
        case "CheckboxButton":
          return renderCheckboxOptions(item);
        default:
          break;
      }
    };
    const renderCheckboxOptions = (item: FormSchema) => {
      // 如果有别名，就取别名
      const labelAlias = item?.componentProps?.optionsAlias?.labelField;
      const valueAlias = item?.componentProps?.optionsAlias?.valueField;
      const Com =
        item.component === "CheckboxGroup" ? Checkbox : CheckboxButton;
      return item?.componentProps?.options?.map((option) => {
        return (
          <Com label={option[labelAlias || "value"]}>
            {option[valueAlias || "label"]}
          </Com>
        );
      });
    };

    const renderRadioOptions = (item: FormSchema) => {
      // 如果有别名，就取别名
      const labelAlias = item?.componentProps?.optionsAlias?.labelField;
      const valueAlias = item?.componentProps?.optionsAlias?.valueField;
      const Com = item.component === "Radio" ? Radio : RadioButton;
      return item?.componentProps?.options?.map((option) => {
        return (
          <Com label={option[labelAlias || "value"]}>
            {option[valueAlias || "label"]}
          </Com>
        );
      });
    };

    const renderSelectOptions = (item: FormSchema) => {
      // 如果有别名，就取别名
      const labelAlias = item?.componentProps?.optionsAlias?.labelField;
      return item?.componentProps?.options?.map((option) => {
        if (option?.options?.length) {
          return (
            <OptionGroup label={option[labelAlias || "label"]}>
              {option?.options?.map((v) => {
                return renderSelectOptionItem(item, v);
              })}
            </OptionGroup>
          );
        } else {
          return renderSelectOptionItem(item, option);
        }
      });
    };

    const renderSelectOptionItem = (
      item: FormSchema,
      option: ComponentOptions
    ) => {
      // 如果有别名，就取别名
      const labelAlias = item?.componentProps?.optionsAlias?.labelField;
      const valueAlias = item?.componentProps?.optionsAlias?.valueField;
      const attrList = {
        label: option[labelAlias || "label"],
        value: option[valueAlias || "value"],
        key: option[valueAlias || "value"]
      };
      return (
        <Option {...{ attrs: attrList }}>
          {() => {
            item?.componentProps?.optionsSlot
              ? getSlot(this.$scopedSlots, `${item.field}-option`, {
                  item: option
                })
              : undefined;
          }}
        </Option>
      );
    };
    const renderRadioTableColumn = () => {
      const radioScopedSlots: any = {
        default: (scope: any) => {
          return (
            <Radio
              label={scope.row}
              v-model={this.dataStore.radioValue}
              on-change={this.handleSelectionChange.bind(
                this,
                scope.$index,
                scope.row
              )}
            >
              {this.dataStore.radioDefault}
            </Radio>
          );
        }
      };
      return (
        <TableColumn
          width="50px"
          label="选择"
          align="center"
          scopedSlots={radioScopedSlots}
        ></TableColumn>
      );
    };
    const renderTable = () => {
      const renderTableCheck = () => {
        return this.isShowCheck ? (
          this.multiple ? (
            <TableColumn type="selection" align="center" width="55" />
          ) : (
            renderRadioTableColumn()
          )
        ) : null;
      };
      return (
        <Table
          data={this.tableData}
          ref={"elTableRef"}
          {...{
            attrs: Object.assign(this.dataStore.defaultProps, this.tableProps)
          }}
          maxHeight={this.dataStore.tableHeight}
          on={{ ...this.$listeners }}
        >
          {renderTableCheck()}
          {this.tableHeader.map((header: any, index: number) => {
            const propOptions: any = Object.assign(
              JSON.parse(JSON.stringify(this.tableColumnProps)),
              header
            );
            const scopedSlots = {
              header: (headerOptions: any) => {
                return (
                  <div
                    class={`${prefixCls}-table-column-header`}
                    style={{
                      "justify-content": header.icon
                        ? "space-between"
                        : "center"
                    }}
                  >
                    <span>{headerOptions.column.label}</span>
                    {header.icon ? (
                      <SvgIcon
                        on-click={this.onClickIcon.bind(this, {
                          ...headerOptions,
                          header
                        })}
                        icon-class={header.icon}
                      ></SvgIcon>
                    ) : null}
                  </div>
                );
              },
              default: (data: any) => {
                const Com = header.component
                  ? componentMap[header.component]
                  : null;

                const propStr: any = header.prop || header.field;
                const renderCom = () => {
                  if (header.componentProps && header.componentProps.on) {
                    const handles = Object.keys(header.componentProps.on);
                    handles.forEach((handle: any) => {
                      header.componentProps.on[handle] = (value: any) =>
                        this.onCallback({
                          type: propStr,
                          data: isObject(value)
                            ? {
                                ...value,
                                row: data.row,
                                rowIndex: data.rowIndex
                              }
                            : { value, row: data.row, rowIndex: data.rowIndex }
                        });
                    });
                  }
                  const slotsMap: any = {};
                  if (
                    !["SelectV2", "Cascader"].includes(
                      header?.component as string
                    ) &&
                    header?.componentProps?.options
                  ) {
                    slotsMap.default = () => renderOptions(header);
                  }
                  const renderFormItemContentSlots = () => {
                    const slots = Object.keys(slotsMap);
                    if (!slots.length) return null;
                    return slots.map((zItem: any) => {
                      return <template slot={zItem} />;
                    });
                  };
                  if (header?.component === "Progress") {
                    header.componentProps["percentage"] = data.row[propStr];
                  }
                  if (["Image", "Avatar"].includes(header?.component)) {
                    header.componentProps["src"] = data.row[propStr];
                  }
                  return (
                    <Com
                      v-model={data.row[propStr]}
                      {...{ props: header.componentProps }}
                      on={{ ...header.componentProps?.on }}
                      style={{
                        ...{ width: "100%" },
                        ...header.componentProps?.style
                      }}
                      scopedSlots={slotsMap}
                    >
                      {renderFormItemContentSlots()}
                    </Com>
                  );
                };
                return this.$scopedSlots.default
                  ? getSlot(this.$scopedSlots, "default", {
                      row: data.row,
                      header: header,
                      rowIndex: data.$index
                    })
                  : Com
                  ? renderCom()
                  : data.row[propStr];
              }
            };
            return (
              <TableColumn
                key={index}
                {...{ props: propOptions }}
                scopedSlots={scopedSlots}
              />
            );
          })}
        </Table>
      );
    };
    const renderPagination = () => {
      return this.isPagination ? (
        <Pagination
          class={`${prefixCls}-pagination`}
          on-size-change={this.handleSizeChange}
          on-current-change={this.handleCurrentChange}
          background={true}
          current-page={this.queryList.pageNum}
          pageSize={this.queryList.pageSize}
          layout={this.paginationLayout}
          total={this.total}
        >
          <span class={"total"}>共 {this.total} 项数据</span>
        </Pagination>
      ) : null;
    };
    return (
      <div class={`${prefixCls}`} style={{ height: "100%" }} ref="tableRef">
        <div
          class={`${prefixCls}-table`}
          style={{ height: this.dataStore.tableHeight }}
        >
          {renderTable()}
        </div>
        {renderPagination()}
      </div>
    );
  }
}
