import elementVariables from "@/styles/element-variables.scss";
interface ISettings {
  title: any; // Overrides the default title
  loginTile: any;
  theme: string;
  showSettings: boolean; // Controls settings panel display
  showTagsView: boolean; // Controls tagsview display
  showSidebarLogo: boolean; // Controls siderbar logo display
  fixedHeader: boolean; // If true, will fix the header component
  errorLog: string[]; // The env to enable the errorlog component, default 'production' only
  sidebarTextTheme: boolean; // If true, will change active text color for sidebar based on theme
  devServerPort: number; // Port number for webpack-dev-server
  mockServerPort: number; // Port number for mock server
}

// You can customize below settings :)
const settings: ISettings = {
  title: process.env.VUE_APP_TITLE,
  loginTile: process.env.VUE_APP_LOGIN_TITLE,
  theme: "#1890ff",
  showSettings: false,
  showTagsView: true,
  fixedHeader: false,
  showSidebarLogo: true,
  errorLog: ["production"],
  sidebarTextTheme: true,
  devServerPort: 9527,
  mockServerPort: 9528
};

export default settings;
