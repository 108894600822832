import { useDesign } from "@/hooks/web/useDesign";
import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { designerConfig } from "@/utils/config";
import i18n from "@/plugins/i18n";
import { useComProps } from "@/utils/useComProps";

import "./index.scss";
const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("form-widget");

@Component({
  name: "FormWidget"
})
export default class FormWidget extends mixins(i18n) {
  $refs!: {
    formPanelRef: any;
  };
  @Prop(Object) readonly designer: any;
  private comProps: any = useComProps.call(this, { prefixCls });
  private dataStore: any = {
    activeIndex: 0,
    tabData: [
      {
        label: "组件库",
        icon: "el-icon-set-up"
      },
      {
        label: "表单模板",
        icon: "el-icon-c-scale-to-original"
      }
    ]
  };

  private onBtnCallback(options: any) {
    console.log(options, "我是点击");
  }
  created() {
    this.designer.initDesigner(designerConfig.resetFormJson);
    this.designer.loadPresetCssCode(designerConfig.presetCssCode);
  }
  mounted() {
    this.designer.registerFormWidget(this);
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        {this.comProps.renderForm({
          schema: this.designer.widgetList,
          designer: this.designer,
          style: {
            height: "100%"
          },
          ref: "formPanelRef",
          rowGutter: this.designer.formConfig.rowGutter
        })}
        {!this.designer.widgetList || !this.designer.widgetList.length ? (
          <div class={`${prefixCls}-empty`}>
            {this.i18nt("designer.noWidgetHint")}
          </div>
        ) : null}
      </div>
    );
  }
}
