import { isFunction } from "@/utils/is";

export const getSlot = (slots: any, slot = "default", data?: Recordable) => {
  // Reflect.has 判断一个对象是否存在某个属性
  if (!slots || !Reflect.has(slots, slot)) {
    return null;
  }
  if (!isFunction(slots[slot])) {
    console.error(`${slot} is not a function!`);
    return null;
  }
  const slotFn: any = slots[slot];
  if (!slotFn) return null;
  return slotFn(data);
};
