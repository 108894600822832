import "babel-polyfill";
import Vue, { DirectiveOptions } from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import "normalize.css";
import ElScrollbar from "element-ui/packages/scrollbar";
import ElCheckbox from "element-ui/packages/checkbox";
import { Loading } from "element-ui";
import ElementUI from "element-ui";
import * as directives from "@/directives";
import * as filters from "@/filters";
import "@wangeditor/editor/dist/css/style.css";
import "./plugins/instance";
import "@/plugins/uEditor";
import "./icons";
import "@/styles/element-variables.scss";
import "@/styles/index.scss";
import "@/permission";
import "@/utils/error-log";
import "@/utils/rem";
import tools from "@/utils/tools";
import config from "@/config";
Vue.use(ElementUI);
Vue.use(ElScrollbar);
Vue.use(ElCheckbox);
Vue.use(Loading.directive);
// Register global directives
Object.keys(directives).forEach((key) => {
  Vue.directive(key, (directives as { [key: string]: DirectiveOptions })[key]);
});
// Register global filter functions
Object.keys(filters).forEach((key) => {
  Vue.filter(key, (filters as { [key: string]: Function })[key]);
});

Vue.config.productionTip = false;
Vue.prototype.$config = config;
Vue.prototype.$tools = tools;
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
