import { Vue, Component } from "vue-property-decorator";
import config from "@/config/config.json";
import { filterEmptyField, publicDelete, publicDownFile } from "@/utils";
import { getOrdersListApi, cancelOrdersApi } from "@/api/orders";
import { batchDictAllDataApi } from "@/api/system/dict";
import { useDesign } from "@/hooks/web/useDesign";
import "./index.scss";
import UseRenderCom from "@/hooks/useRenderCom";

const { getPrefixCls } = useDesign();
const prefixCls = getPrefixCls("orders-list");
@Component({
  name: "OrdersList"
})
export default class OrdersList extends Vue {
  $refs!: {
    filterRef: any;
    dialogRef: any;
    tableRef: any;
    contentRef: any;
    treeRef: any;
  };
  get useRenderCom() {
    return UseRenderCom.call(this);
  }
  // private useRenderCom = UseRenderCom.call(this);
  private dataStore: any = {
    isLoading: false,
    treeIsLoading: false,
    title: "订单列表",
    tableHeight: 0,
    queryList: {
      pageSize: 10,
      pageNum: 1
    },
    total: 0,
    tableData: [],
    dialogSchemaSlots: {},
    selectData: [],
    treeProps: {
      label: "label",
      children: "children"
    },
    treeData: [],
    selectMenuData: [],
    checkedKeys: [],
    optionList: {}
  };

  private handleExport() {
    const filterList: any = this.useRenderCom.getFormValue("filterRef");
    const params: any = {
      ...filterList,
      ...this.dataStore.queryList
    };
  }

  // 所有按钮事件统一处理
  private onClickMethod(options: any) {
    const { item, row } = options;
    const obj: any = {
      search: () => {
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      refresh: () => {
        this.useRenderCom.clearFormList("filterRef");
        this.dataStore.queryList.pageNum = 1;
        this.getTableData();
      },
      exportRole: () => this.handleExport(),
      cancel: () => {
        console.log("5555555", row);
        publicDelete({
          title: "提示",
          message: "确定取消该订单",
          callback: () => {
            cancelOrdersApi({ id: row.id }).then((res: any) => {
              console.log(res, "klklklkl");
              this.$message.success(res);
              this.getTableData();
            });
          }
        });
      }
    };
    obj[item.prop] && obj[item.prop]();
  }

  private onTableCallback(item: any) {
    const obj: any = {
      current: () => {
        this.dataStore.queryList.pageNum = item.value;
        this.getTableData();
      },
      size: () => {
        this.dataStore.queryList.pageNum = 1;
        this.dataStore.queryList.pageSize = item.value;
        this.getTableData();
      },
      handle: () => {
        this.onClickMethod({ item: item.data.item, row: item.data.row });
      }
    };
    if (!obj[item.type]) return;
    obj[item.type]();
  }

  // 批量处理字典信息
  private dealOptionList(list: any) {
    const keys = Object.keys(list);
    let schemaData: any[] = [];
    keys.forEach((key: string) => {
      const field = key.replace("order_", "");
      this.dataStore.optionList[field] = list[key];
      const arr = [
        {
          field: field,
          path: "componentProps.options",
          value: list[key]
        },
        {
          field: field,
          path: "componentProps.optionsAlias",
          value: {
            labelField: "dictLabel",
            valueField: "dictValue"
          }
        }
      ];
      schemaData = schemaData.concat(arr);
    });
    setTimeout(() => {
      this.useRenderCom.setFormSchema("filterRef", schemaData);
    }, 300);
  }

  // 获取字典信息
  private async batchDictAllData() {
    const types = ["order_status", "order_type"];
    const result = await batchDictAllDataApi(types.join(","));
    this.dealOptionList(result);
  }

  // 处理表格数据
  private dealTableData(data: any[]) {
    return data.map((ele) => {
      // ele["status"] = ele.status === "0";
      // ele["roleSorts"] = 2;
      return ele;
    });
  }

  private getTableData() {
    this.dataStore.isLoading = true;
    const filterList: any = this.useRenderCom.getFormValue("filterRef");
    const params: any = { ...filterList, ...this.dataStore.queryList };
    getOrdersListApi(filterEmptyField(params))
      .then((res: any) => {
        if (res) {
          this.dataStore.tableData = this.dealTableData(res.rows);
          this.dataStore.total = res.total;
        }
        this.dataStore.isLoading = false;
      })
      .catch(() => {
        this.dataStore.isLoading = false;
      });
  }

  async mounted() {
    await this.batchDictAllData();
    console.log(this.dataStore.optionList, "[[[]]]");
    this.$nextTick(() => {
      this.useRenderCom.dealContentTableHeight();
    });
    this.getTableData();
  }

  render() {
    return (
      <div class={`${prefixCls}`}>
        <div class={`${prefixCls}-content`} ref={"contentRef"}>
          <div class={`${prefixCls}-content-header`}>
            {this.useRenderCom.renderForm({
              ref: "filterRef",
              schema: config.orders.filterSchema,
              slots: {
                handle: (data: any) => {
                  return this.useRenderCom.renderButtons({
                    buttons: data.componentProps.buttons
                  });
                }
              }
            })}
          </div>
          {this.useRenderCom.renderTable({
            prefixCls: prefixCls,
            tableData: this.dataStore.tableData,
            tableHeader: config.orders.tableHeader,
            isShowCheck: false,
            queryList: this.dataStore.queryList,
            total: this.dataStore.total,
            isLoading: this.dataStore.isLoading,
            paginationLayout: "sizes, prev, pager, next, slot, jumper",
            style: {
              height: this.dataStore.tableHeight
            },
            ref: "tableRef",
            slots: {
              status: ({ row }) => {
                if (!this.dataStore.optionList.status) return "";
                const nItem: any = this.dataStore.optionList.status.find(
                  (ele: any) => ele.dictValue === row.status
                );
                return nItem ? nItem.dictLabel : "";
              },
              type: ({ row }) => {
                if (!this.dataStore.optionList.type) return "";
                const nItem: any = this.dataStore.optionList.type.find(
                  (ele: any) => ele.dictValue === row.type
                );
                return nItem ? nItem.dictLabel : "";
              }
            }
          })}
        </div>
      </div>
    );
  }
}
